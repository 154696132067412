import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  CardFooter,
  Input,
  InputGroup,
  InputGroupText,
  Button,
  Badge,
} from "reactstrap";
import { faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";
import InformationModal from "../../components/InformationModal";
import {
  ACTIONS,
  useEmployeeRoles,
} from "../../providers/employeeRolesProvider";
import { rolesApi } from "../../services/roleServices";

const columns = [
  {
    accessor: "name",
    Header: "Name",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { name } = rowData.row.original;
      return name || "-";
    },
  },
  {
    accessor: "isSuper",
    Header: "Login Enabled",
    headerProps: { className: "text-truncate text-center" },
    cellProps: { className: "text-truncate text-center" },
    Cell: (rowData) => (
      <Badge color={rowData.row.original.isSuper ? "success" : "danger"}>
        {rowData.row.original.isSuper ? "Yes" : "No"}
      </Badge>
    ),
  },
  {
    accessor: "isEnabledForBaseCrew",
    Header: "Enabled for Base Crews",
    headerProps: { className: "text-truncate text-center" },
    cellProps: { className: "text-truncate text-center" },
    Cell: (rowData) => (
      <Badge
        color={rowData.row.original.isEnabledForBaseCrew ? "success" : "danger"}
      >
        {rowData.row.original.isEnabledForBaseCrew ? "Yes" : "No"}
      </Badge>
    ),
  },
  {
    accessor: "isEnabledForCrew",
    Header: "Enabled for Crews",
    headerProps: { className: "text-truncate text-center" },
    cellProps: { className: "text-truncate text-center" },
    Cell: (rowData) => (
      <Badge
        color={rowData.row.original.isEnabledForCrew ? "success" : "danger"}
      >
        {rowData.row.original.isEnabledForCrew ? "Yes" : "No"}
      </Badge>
    ),
  },
  {
    accessor: "hasRestDay",
    Header: "Rest Day Enabled",
    headerProps: { className: "text-truncate text-center" },
    cellProps: { className: "text-truncate text-center" },
    Cell: (rowData) => (
      <Badge color={rowData.row.original.hasRestDay ? "success" : "danger"}>
        {rowData.row.original.hasRestDay ? "Yes" : "No"}
      </Badge>
    ),
  },
];

const EmployeeRoles = () => {
  const [employeeRolesContext, setEmployeeRolesContext] = useEmployeeRoles();

  const [loading, setLoading] = useState(true);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          employeeRolesContext.sortBy === sortBy?.id &&
          employeeRolesContext.direction === direction
        ) {
          return;
        }
        setEmployeeRolesContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setEmployeeRolesContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [
      employeeRolesContext.direction,
      employeeRolesContext.sortBy,
      setEmployeeRolesContext,
    ]
  );

  useEffect(() => {
    setLoading(true);
    rolesApi
      .getEmployeeRoles({
        search: employeeRolesContext.search,
        page: employeeRolesContext.page - 1,
        pageSize: employeeRolesContext.sizePerPage,
        sortBy: employeeRolesContext.sortBy,
        direction: employeeRolesContext.direction,
      })
      .then((employeeRoles) => {
        setEmployeeRolesContext({
          action: ACTIONS.GET_USERS_SUCCESS,
          payload: { employeeRoles },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    setEmployeeRolesContext,
    employeeRolesContext.sortBy,
    employeeRolesContext.direction,
    employeeRolesContext.sizePerPage,
    employeeRolesContext.page,
    employeeRolesContext.search,
    employeeRolesContext.refresh,
  ]);

  return (
    <Container fluid className="h-100">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns}
          data={employeeRolesContext.employeeRoles?.data || []}
          pageSize={employeeRolesContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: employeeRolesContext.sortBy,
            direction: employeeRolesContext.direction,
          }}
        >
          <Card className="mb-3 w-100">
            <CardHeader className="d-flex">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Employee Roles</h3>
                <small className="text-muted ml-2 pt-1">
                  ({employeeRolesContext.employeeRoles?.count || 0})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup className="mr-3">
                  <Input
                    className="border-right-0"
                    maxLength="50"
                    placeholder="Search for.."
                    value={employeeRolesContext.search}
                    onChange={(evt) =>
                      setEmployeeRolesContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-primary text-white border-left-0 border-primary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="mr-3 rounded-circle d-flex custom-rounded-button"
                  color="primary"
                  onClick={() =>
                    setEmployeeRolesContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              <AdvanceTable
                table
                isLoading={loading}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={employeeRolesContext.employeeRoles?.count || 0}
                pageCount={employeeRolesContext.employeeRoles.totalPages}
                currentPage={employeeRolesContext.page - 1}
                onPageChange={(page) =>
                  setEmployeeRolesContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={employeeRolesContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setEmployeeRolesContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default EmployeeRoles;
