import React, { useEffect, useState, useRef } from "react";

import moment from "moment";

import * as FlexmonsterReact from "react-flexmonster";

import {
  Button,
  Col,
  CustomInput,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";

import { workOrdersApi } from "../../../services/workOrdersServices";
import { utilsHelper } from "../../../helpers/utilsHelper";
import WeekSelector from "../WeekSelector";

const ALL_JOB_DATA = "ALL_JOB_DATA";
const WEEKLY_DATA = "WEEKLY_DATA";

const COLUMNS = {
  Date: {
    type: "string",
  },
  Driver: {
    type: "string",
  },
  "Employee Code": {
    type: "string",
  },
  "Employee Name": {
    type: "string",
  },
  Hours: {
    type: "string",
  },
  Mileage: {
    type: "string",
  },
  Operation: {
    type: "string",
  },
  "Travel Hours": {
    type: "string",
  },
  Type: {
    type: "string",
  },
  Workorder: {
    type: "string",
  },
  Customer: {
    type: "string",
  },
  Location: {
    type: "string",
  },
};

const report = {
  dataSource: {
    data: [COLUMNS],
  },
  slice: {
    rows: [
      {
        uniqueName: "Date",
      },
      {
        uniqueName: "Driver",
      },
      {
        uniqueName: "Employee Code",
      },
      {
        uniqueName: "Employee Name",
      },
      {
        uniqueName: "Hours",
      },
      {
        uniqueName: "Mileage",
      },
      {
        uniqueName: "Operation",
      },
      {
        uniqueName: "Travel Hours",
      },
      {
        uniqueName: "Type",
      },
      {
        uniqueName: "Workorder",
      },
      {
        uniqueName: "Customer",
      },
      {
        uniqueName: "Location",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Job Time Report",
    },
  },
};

const JobTimeFlexMonsterModal = ({ onClose }) => {
  const [workOrderDetails] = useWorkOrderDetails();

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [dataMode, setDataMode] = useState(ALL_JOB_DATA);
  const [crew, setCrew] = useState();
  const [loading, setLoading] = useState();

  const pivot = useRef(null);

  useEffect(() => {
    const updateData = async () => {
      if (pivot) {
        setLoading(true);
        const reportData = await workOrdersApi.fetchJobTimeReport({
          format: "json",
          id: workOrderDetails.workOrder.id,
          weekStart:
            dataMode === WEEKLY_DATA ? monday.format("YYYY-MM-DD") : null,
          crewId: crew,
        });
        setLoading(false);
        if (reportData) {
          pivot.current.flexmonster.updateData({
            data: [
              COLUMNS,
              ...reportData.map((d) => ({
                "Employee Name": `${d.Last}, ${d.First}`,
                ...d,
              })),
            ],
          });
        }
      }
    };
    updateData();
  }, [pivot, monday, crew, dataMode, workOrderDetails.workOrder.id]);

  const getFilename = () => `Job Time Report`;

  return (
    <Modal isOpen={true} size="xl">
      <ModalHeader>Job Time Report</ModalHeader>
      <ModalBody className="d-flex flex-column">
        <div className="d-flex">
          <FormGroup className="px-2 d-flex align-items-center mb-0 flex-grow-1">
            <Label className="mb-0 text-sm-left">Data included</Label>
            <Col className="flex-grow-1">
              <CustomInput
                required
                id="dataSelect"
                type="select"
                name="dataSelect"
                onChange={(event) => setDataMode(event.currentTarget.value)}
                value={dataMode}
              >
                <option value={ALL_JOB_DATA}>All Job Data</option>
                <option value={WEEKLY_DATA}>Weekly</option>
              </CustomInput>
            </Col>
          </FormGroup>
          {dataMode === WEEKLY_DATA ? (
            <WeekSelector
              loading={loading}
              monday={monday}
              setMonday={setMonday}
            />
          ) : null}
          <FormGroup className="px-2 d-flex align-items-center mb-0 flex-grow-1">
            <Label className="mb-0 text-sm-left">Crew</Label>
            <Col className="flex-grow-1">
              <CustomInput
                required
                id="crewSelect"
                type="select"
                name="crewSelect"
                onChange={(event) => setCrew(event.currentTarget.value)}
                value={crew}
              >
                <option value={""}>All Crews</option>
                {workOrderDetails.workOrder.crewWorkOrders
                  .filter((crewWorkOrder) =>
                    crewWorkOrder.crew.employeeCrews
                      .filter((ec) =>
                        utilsHelper.isEmployeeCrewActiveOnDate(ec)
                      )
                      .find((ec) => ec.isCrewLead)
                  )
                  .map((crewWorkOrder) => {
                    const sup = crewWorkOrder.crew.employeeCrews
                      .filter((ec) =>
                        utilsHelper.isEmployeeCrewActiveOnDate(ec)
                      )
                      .find((ec) => ec.isCrewLead);
                    return (
                      <option
                        key={crewWorkOrder.id}
                        value={crewWorkOrder.crew.id}
                      >
                        {`${sup.employee.firstName} ${sup.employee.lastName}`}
                      </option>
                    );
                  })}
              </CustomInput>
            </Col>
          </FormGroup>
        </div>
        <FlexmonsterReact.Pivot
          reportcomplete={() =>
            utilsHelper.adjustTableSizes(pivot.current.flexmonster)
          }
          ref={pivot}
          licenseKey={process.env.REACT_APP_FLEX_M_KEY}
          toolbar={true}
          height="600px"
          report={report}
          beforetoolbarcreated={(toolbar) => {
            utilsHelper.customizeToolbarReport(toolbar, pivot, getFilename());
          }}
        />
      </ModalBody>
      <ModalFooter className="justify-content-between mt-n2">
        <Button color={"secondary"} onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default JobTimeFlexMonsterModal;
