import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import ReactPaginate from "react-paginate";

import { employeesApi } from "../../../services/employeeServices";

import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import {
  faChevronLeft,
  faChevronRight,
  faPen,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../providers/authProvider";
import { utilsHelper } from "../../../helpers/utilsHelper";

const sizePerPage = 2;

const CompaniesModal = ({ onCreate, onClose, onEdit, onDelete }) => {
  const [authContext] = useAuth();
  const [companies, setCompanies] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    setLoading(true);
    employeesApi
      .getSubContractorsCompanies({ page: page - 1, pageSize: sizePerPage })
      .then((data) => {
        setLoading(false);
        setCompanies(data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="md">
      <ModalHeader className="d-flex justify-content-between" close={closeBtn}>
        Contract Employees
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <Loader size="sm" />
        ) : (
          <ListGroup>
            <ListGroupItem
              className="py-2 border-bottom font-weight-bold text-body bg-lighter d-flex justify-content-between"
              tag="div"
            >
              <span className="text-underline">Companies</span>
              {!utilsHelper.isReadOnly(authContext) ? (
                <Button
                  className="rounded"
                  size="sm"
                  color="success"
                  onClick={onCreate}
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2 text-white" />
                  Add Company
                </Button>
              ) : null}
            </ListGroupItem>
            {companies.count > 0 ? (
              companies.data.map((company) => (
                <ListGroupItem
                  key={company.id}
                  className="py-2 border-bottom text-body d-flex justify-content-between"
                >
                  <span>{company.name}</span>
                  {!utilsHelper.isReadOnly(authContext) ? (
                    <div className="d-flex justify-content-end align-items-center">
                      <Button
                        size="sm"
                        className="rounded d-flex align-items-center"
                        color={"warning"}
                        onClick={() => onEdit(company.id)}
                      >
                        <FontAwesomeIcon icon={faPen} className="mr-2" />
                        <span>Edit</span>
                      </Button>
                      <Button
                        size="sm"
                        className="rounded ml-2 d-flex align-items-center ml-2"
                        color={"danger"}
                        onClick={() => onDelete(company.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} className="mr-2" />
                        <span>Remove</span>
                      </Button>
                    </div>
                  ) : null}
                </ListGroupItem>
              ))
            ) : (
              <ListGroupItem>No companies</ListGroupItem>
            )}
          </ListGroup>
        )}
        {companies?.count > sizePerPage ? (
          <div className="mt-3 d-flex justify-content-center">
            <ReactPaginate
              forcePage={page - 1}
              previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
              nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
              breakLabel={"..."}
              pageCount={companies?.totalPages || 0}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(event) => setPage(event.selected + 1)}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              containerClassName={
                "cursor-pointer col-12 pagination my-0 d-flex justify-content-center align-items-center"
              }
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Col>
          <Row className="justify-content-between">
            <Button color={"secondary"} onClick={onClose}>
              Close
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default CompaniesModal;
