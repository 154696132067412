import {
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faDownload,
  faStickyNote,
  faFilePdf,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Collapse,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";

import { Document, Page } from "react-pdf";
import Loader from "../../components/Loader";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import { incidentReportApi } from "../../services/incidentReportServices";
import { witnessReportApi } from "../../services/witnessReportServices";
import { utilsHelper } from "../../helpers/utilsHelper";
import { useAuth } from "../../providers/authProvider";
import InformationModal from "../../components/InformationModal";
import InjuryReportNoteModal from "../../components/admin/notes/InjuryReportNoteModal";
import InjuryReportPDFModal from "../../components/InjuryReportPDFModal";
import { injuryReportAttachmentApi } from "../../services/injuryReportAttachmentServices";

const TYPE_PDF = "pdf";

const Incident = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [incidents, setIncidents] = useState([]);
  const [authContext] = useAuth();
  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });
  const [attachmentsInfo, setAttachmentsInfo] = useState([]);
  const [fetchPDF, setFetchPDF] = useState(true);
  const [injuryReportAttachmentModal, setInjuryReportAttachmentModal] =
    useState({
      isOpen: false,
      injuryReportId: null,
      files: null,
    });
  const [injuryReportNotesModal, setInjuryReportNotesModal] = useState({
    isOpen: false,
    injuryReportId: null,
  });
  const [selectedsetIncident, setSelectedsetIncident] = useState(null);
  const [selectedWitnessIndividual, setSelectedWitnessIndividual] =
    useState(null);
  const [loading, setLoading] = useState();
  const [report, setReport] = useState();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDownload = (type) => {
    if (type === TYPE_PDF) {
      return window.open(report, "new");
    }
  };

  const onEditPDF = (injuryReportId) => {
    setInjuryReportAttachmentModal({
      isOpen: true,
      injuryReportId: injuryReportId,
      files: null,
    });
  };

  const onViewPDFAttachment = (files) => {
    setInjuryReportAttachmentModal({
      isOpen: true,
      injuryReportId: null,
      files: files,
    });
  };

  const onEditNotes = (injuryReportId) => {
    setInjuryReportNotesModal({
      isOpen: true,
      injuryReportId: injuryReportId,
    });
  };

  const [openIndexes, setOpenIndexes] = useState([]);

  const toggleIncident = (index) => {
    if (openIndexes.includes(index)) {
      setOpenIndexes(openIndexes.filter((i) => i !== index));
    } else {
      setOpenIndexes([...openIndexes, index]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await incidentReportApi.incidentSummaryReport({
          id: selectedsetIncident.id,
          browserTimeOffset: moment().utcOffset() / 60,
        });
        const data = new Blob([res], { type: "application/pdf" });
        const URL = window.URL.createObjectURL(data);
        setReport(URL);
        setPageNumber(1);
        setNumPages(null);
        setLoading(false);
      } catch (err) {
        setReport(null);
        setLoading(false);
      }
    };
    if (selectedsetIncident) {
      setSelectedWitnessIndividual(null);
      fetchData();
    }
  }, [selectedsetIncident]);

  useEffect(() => {
    setIncidents(workOrderDetails.workOrder.injuryReports);
  }, [workOrderDetails.workOrder.injuryReports]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await witnessReportApi.witnessIndividualReport({
          id: selectedWitnessIndividual.id,
          browserTimeOffset: moment().utcOffset() / 60,
        });
        const data = new Blob([res], { type: "application/pdf" });
        const URL = window.URL.createObjectURL(data);
        setReport(URL);
        setPageNumber(1);
        setNumPages(null);
        setLoading(false);
      } catch (err) {
        setReport(null);
        setLoading(false);
      }
    };
    if (selectedWitnessIndividual) {
      setSelectedsetIncident(null);
      fetchData();
    }
  }, [selectedWitnessIndividual]);

  useEffect(() => {
    if (fetchPDF) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const attachments =
            await injuryReportAttachmentApi.getInjuryReportAttachmentByWorkOrderId(
              {
                id: workOrderDetails.workOrder.id,
                type: "PDF",
              }
            );
          if (attachments.length > 0) {
            const docs = attachments.map((att) => {
              const splitUrl = att.content.split("/");
              return {
                id: att.id,
                name: splitUrl[splitUrl.length - 1],
                file: null,
                injuryReportId: att.injuryReportId,
              };
            });
            const infos = utilsHelper.mapFilesToAttachmentInfo(docs);
            setAttachmentsInfo(infos);
          } else {
            setAttachmentsInfo([]);
          }
          setLoading(false);
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: `Injury Report PDF`,
            body:
              err?.response?.data[0]?.msg ||
              "There was an error with your request.",
          });
        }
      };
      fetchData();
      setFetchPDF(false);
    }
  }, [workOrderDetails.workOrder.id, fetchPDF]);

  const viewPDFButton = (incident) => {
    const incidentAttachments = attachmentsInfo.filter(
      (item) => item.injuryReportId === incident.id
    );
    return attachmentsInfo.length > 0 && incidentAttachments.length > 0 ? (
      <Button
        className="rounded mb-2 mr-2"
        size="sm"
        color="success"
        onClick={() => onViewPDFAttachment(incidentAttachments)}
      >
        <FontAwesomeIcon icon={faEye} className="mr-1 text-white" />
        View PDF
      </Button>
    ) : null;
  };

  return (
    <>
      <Row className="d-flex">
        <Col className="flex-shrink-0">
          <div className="d-flex d-flex-row justify-content-between border-bottom">
            <h4>Incident</h4>
            <div>
              {report ? (
                <Button
                  color="tertiary"
                  size="sm"
                  className="rounded"
                  onClick={() => onDownload(TYPE_PDF)}
                >
                  <FontAwesomeIcon icon={faDownload} className="mr-2" />
                  <span>PDF</span>
                </Button>
              ) : (
                <Badge className="d-flex align-items-center" pill>
                  Click on an entry to see the report
                </Badge>
              )}
            </div>
          </div>
          {!incidents?.length ? (
            <div className="text-center text-muted mt-4">
              No data has been recorded
            </div>
          ) : (
            <Row className="d-flex">
              <Col>
                <ListGroup className="mt-3">
                  {incidents.map((incident, index) => (
                    <div key={index} className="mb-3">
                      <Button
                        onClick={() => toggleIncident(index)}
                        style={{ width: "100%" }}
                      >
                        <div className="d-flex flex-row justify-content-between m-1 align-middle align-items-center">
                          {incident.name ? (
                            <span>{incident.name}</span>
                          ) : (
                            <>
                              <div className="d-flex flex-row align-items-center">
                                {workOrderDetails.workOrder.customerName}
                              </div>
                              <div className="d-flex flex-column ml-auto mr-2">
                                <Badge
                                  className="mb-1"
                                  pill
                                  color={"light"}
                                  style={{ color: "black" }}
                                >
                                  {`Work date ${
                                    incident.crewWorkDay
                                      ? moment(
                                          incident.crewWorkDay.date
                                        ).format("MM/DD/YYYY")
                                      : "N/A"
                                  }`}
                                </Badge>
                                <Badge pill color={"success"}>
                                  {`Created at ${moment(
                                    incident.createdAt
                                  ).format("MM/DD/YYYY HH:mm")}`}
                                </Badge>
                              </div>
                            </>
                          )}
                          <FontAwesomeIcon
                            icon={
                              openIndexes.includes(index)
                                ? faChevronDown
                                : faChevronRight
                            }
                            fixedWidth
                            className="my-1"
                          />
                        </div>
                      </Button>
                      <Collapse
                        isOpen={openIndexes.includes(index)}
                        className="p-2"
                      >
                        <div className="d-flex align-items-center border-bottom mt-2">
                          <span className="mr-auto">Injury Report</span>
                          <div>
                            {!utilsHelper.isReadOnly(authContext) && (
                              <>
                                <Button
                                  className="rounded mb-2 mr-2"
                                  size="sm"
                                  color="success"
                                  onClick={() => onEditNotes(incident.id)}
                                >
                                  <FontAwesomeIcon
                                    icon={faStickyNote}
                                    className="mr-1 text-white"
                                  />
                                  Notes
                                </Button>
                                <Button
                                  className="rounded mb-2 mr-2"
                                  size="sm"
                                  color="success"
                                  onClick={() => onEditPDF(incident.id)}
                                >
                                  <FontAwesomeIcon
                                    icon={faFilePdf}
                                    className="mr-1 text-white"
                                  />
                                  Upload PDF
                                </Button>
                                {viewPDFButton(incident)}
                              </>
                            )}
                          </div>
                        </div>
                        <ListGroupItem
                          action
                          key={incident.id}
                          className="d-flex justify-content-between align-items-center py-2 cursor-pointer rounded mt-3"
                          tag="div"
                          onClick={() => setSelectedsetIncident(incident)}
                        >
                          {incident.employeesCrew &&
                          incident.employeesCrew.length > 0 ? (
                            <div className="d-flex flex-column">
                              {incident.employeesCrew.map(
                                ({ employeeCrew }, index) => (
                                  <div key={index}>
                                    <span>{`${employeeCrew?.employee?.firstName} ${employeeCrew?.employee?.lastName}`}</span>
                                  </div>
                                )
                              )}
                            </div>
                          ) : (
                            "PDF Report"
                          )}
                          {incident.name && (
                            <div className="d-flex flex-column">
                              <Badge
                                className="d-flex align-items-center justify-content-center mb-1"
                                pill
                                color={"secondary"}
                              >
                                {`Work date ${
                                  incident.crewWorkDay
                                    ? moment(incident.crewWorkDay.date).format(
                                        "MM/DD/YYYY"
                                      )
                                    : "N/A"
                                }`}
                              </Badge>
                              <Badge
                                className="d-flex align-items-center"
                                pill
                                color={"success"}
                              >
                                {`Created at ${moment(
                                  incident.createdAt
                                ).format("MM/DD/YYYY HH:mm")}`}
                              </Badge>
                            </div>
                          )}
                        </ListGroupItem>
                        {incident.witnessIndividualsReports.length > 0 ? (
                          <>
                            <div className="d-flex d-flex-row justify-content-between border-bottom">
                              <span style={{ marginTop: "20px" }}>
                                Witness Reports
                              </span>
                            </div>
                            {incident.witnessIndividualsReports.map(
                              (individual) => {
                                const name =
                                  individual.otherWitnessName ||
                                  `${individual.employeeCrew?.employee?.firstName} ${individual.employeeCrew?.employee?.lastName}`;
                                return (
                                  <ListGroupItem
                                    action
                                    key={individual.id}
                                    className="d-flex justify-content-between align-items-center py-2 cursor-pointer rounded mt-3 border"
                                    tag="div"
                                    onClick={() =>
                                      setSelectedWitnessIndividual(individual)
                                    }
                                  >
                                    <div className="d-flex flex-column">
                                      <span>{name}</span>
                                    </div>
                                    <div className="d-flex flex-column">
                                      <Badge
                                        className="d-flex align-items-center justify-content-center mb-1"
                                        pill
                                        color={"secondary"}
                                      >
                                        {`Work date ${
                                          incident.crewWorkDay
                                            ? moment(
                                                incident.crewWorkDay.date
                                              ).format("MM/DD/YYYY")
                                            : "N/A"
                                        }`}
                                      </Badge>
                                      <Badge
                                        className="d-flex align-items-center"
                                        pill
                                        color={"success"}
                                      >
                                        {`Created at ${moment(
                                          individual.createdAt
                                        ).format("MM/DD/YYYY HH:mm")}`}
                                      </Badge>
                                    </div>
                                  </ListGroupItem>
                                );
                              }
                            )}
                          </>
                        ) : null}
                      </Collapse>
                    </div>
                  ))}
                </ListGroup>
              </Col>
              <Col className="flex-grow-1 d-flex flex-column col my-3">
                {loading ? (
                  <div className="my-2">
                    <Loader size="sm" />
                  </div>
                ) : !report ? (
                  <div className="text-center text-muted mt-4">
                    No PDF has been loaded
                  </div>
                ) : (
                  <>
                    <Document
                      className="d-flex flex-column justify-content-between align-items-center text-body bg-white"
                      file={report}
                      onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                    >
                      <Page
                        className="w-100 border rounded"
                        pageNumber={pageNumber}
                      />
                    </Document>
                    <div className="d-flex justify-content-between p-2 align-items-center text-body bg-white border mt-4 mx-1 rounded">
                      <Button
                        className="rounded"
                        onClick={() =>
                          setPageNumber(Math.max(pageNumber - 1, 1))
                        }
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </Button>
                      <span>
                        Page {pageNumber} of {numPages}
                      </span>
                      <Button
                        className="rounded"
                        onClick={() =>
                          setPageNumber(Math.min(pageNumber + 1, numPages))
                        }
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </Button>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : injuryReportAttachmentModal.isOpen ? (
        <InjuryReportPDFModal
          injuryReportId={injuryReportAttachmentModal.injuryReportId}
          onCloseModal={() =>
            setInjuryReportAttachmentModal({
              isOpen: false,
            })
          }
          files={injuryReportAttachmentModal.files}
          setFetchPDF={setFetchPDF}
        />
      ) : injuryReportNotesModal.isOpen ? (
        <InjuryReportNoteModal
          injuryReportId={injuryReportNotesModal.injuryReportId}
          onCloseModal={() =>
            setInjuryReportNotesModal({
              isOpen: false,
            })
          }
        />
      ) : null}
    </>
  );
};

export default Incident;
