import React, { useReducer, createContext, useContext } from "react";

const COMPLETED_STATUSES = [
  "Completed",
  "Warranty",
  "Ready for Accounting",
  "Invoiced",
  "Archived",
];

const ACTIONS = {
  GET_WORK_ORDER_STATUS_SUCCESS: "GET_WORK_ORDER_STATUS_SUCCESS",
};

const initialState = {
  workOrderStatus: [],
  COMPLETED_STATUSES,
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_WORK_ORDER_STATUS_SUCCESS: {
      const { payload } = data;
      const { workOrderStatus } = payload;

      return {
        ...state,
        workOrderStatus,
      };
    }

    default:
      return {
        ...initialState,
      };
  }
};

const WorkOrderStatusContext = createContext(initialState);

const WorkOrderStatusProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <WorkOrderStatusContext.Provider value={stateAndDispatch}>
      {children}
    </WorkOrderStatusContext.Provider>
  );
};

export const useWorkOrderStatus = () => useContext(WorkOrderStatusContext);

export { WorkOrderStatusContext, WorkOrderStatusProvider, ACTIONS };
