import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import { apiHelper } from "../helpers/apiHelper";

export const generalWorkTimesApi = {
  getPerDiemAmounts: () => {
    return axios(`/api/perdiem-amount`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getGeneralWorkTimes: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(
      data.id
        ? `/api/general-work-time/${data.id}`
        : `/api/general-work-time?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getGeneralWorkTime: (data) => {
    return axios(`/api/general-work-time/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createGeneralWorkTime: (data) => {
    return axios(`/api/general-work-time`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateGeneralWorkTime: (data) => {
    return axios(`/api/general-work-time/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  deleteGeneralWorkTime: (data) => {
    return axios(`/api/general-work-time/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getGeneralWorkTimesTypes: () => {
    return axios(`/api/general-work-time-type`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result)
      .catch((err) => apiHelper.handleError(err));
  },
};
