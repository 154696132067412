import { Toolbar, DateHelper } from "@bryntum/gantt";

import moment from "moment";

/**
 * @module GanttToolbar
 */

/**
 * @extends Core/widget/Toolbar
 */
export default class GanttToolbar extends Toolbar {
  // Factoryable type name
  static get type() {
    return "gantttoolbar";
  }

  static get $name() {
    return "GanttToolbar";
  }

  // Called when toolbar is added to the Gantt panel
  set parent(parent) {
    super.parent = parent;

    const me = this;

    me.gantt = parent;

    parent.project.on({
      load: "updateStartDateField",
      refresh: "updateStartDateField",
      thisObj: me,
    });

    me.styleNode = document.createElement("style");
    document.head.appendChild(me.styleNode);
  }

  get parent() {
    return super.parent;
  }

  static get configurable() {
    return {
      items: [
        {
          type: "datefield",
          ref: "startDateField",
          label: "Start Date",
          flex: "0 0 10em",
          cls: "mb-0 text-success date-container rounded",
          listeners: {
            change: "up.onStartDateChange",
          },
        },
        "->",
        {
          type: "buttonGroup",
          align: "end",
          cls: "border-white",
          items: [
            {
              ref: "previousButton",
              cls: "border-0 text-white bg-success mr-1 rounded",
              icon: "b-fa b-fa-angle-left",
              onAction: "up.onShiftPreviousClick",
            },
            {
              ref: "nextButton",
              cls: "border-0 text-white bg-success rounded",
              icon: "b-fa b-fa-angle-right",
              onAction: "up.onShiftNextClick",
            },
          ],
        },
      ],
    };
  }

  updateStartDateField() {
    const { startDateField } = this.widgetMap;
    startDateField.value = this.gantt.project.startDate;

    // This handler is called on project.load/propagationComplete, so now we have the
    // initial start date. Prior to this time, the empty (default) value would be
    // flagged as invalid.
    startDateField.required = true;
  }

  onShiftPreviousClick() {
    // Substract one week and then change ganttStart and projectStart
    const value = DateHelper.add(
      this.gantt.project.startDate || moment().startOf("week").toDate(),
      -1,
      "week"
    );
    this.gantt.startDate = value;
    this.gantt.project.setStartDate(value);
  }

  onShiftNextClick() {
    // Add one week and then change ganttStart and projectStart
    const value = DateHelper.add(
      this.gantt.project.startDate || moment().startOf("week").toDate(),
      1,
      "week"
    );
    this.gantt.startDate = value;
    this.gantt.project.setStartDate(value);
  }

  onStartDateChange({ value, oldValue }) {
    if (!value || !oldValue) {
      const initialDate = moment().startOf("week").toDate();
      this.gantt.project.setStartDate(initialDate);
      return;
    }
    this.gantt.startDate = value;
    this.gantt.project.setStartDate(value);
  }
}

GanttToolbar.initClass();
