import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  InputGroup,
  Label,
} from "reactstrap";
import { equipmentApi } from "../../../services/equimentServices";
import Loader from "../../Loader";
import SelectWrapper from "../SelectWrapper";

const AddEquipmentModal = ({ onSubmit, onClose, equipments }) => {
  const [loading, setLoading] = useState(false);
  const [equipmentsInput, setEquipmentsInput] = useState();
  const [quantityInput, setQuantityInput] = useState(1);

  const doSubmit = () => {
    setLoading(true);
    onSubmit(equipmentsInput, quantityInput);
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Add Equipment</ModalHeader>
      <ModalBody>
        {loading ? (
          <Loader size={"sm"} />
        ) : (
          <div>
            <InputGroup className="col-12 align-items-center mb-3" size="m">
              <Label sm={3} className="text-sm-left">
                Equipment
              </Label>
              <SelectWrapper
                entity="equipment"
                formatItemFunction={(option) => ({
                  value: option.id,
                  label: option.name,
                })}
                filterItemFunction={(currentEquipment) =>
                  !equipments.find(
                    (equipment) =>
                      currentEquipment.id === equipment.equipment.id
                  )
                }
                fetchFunction={equipmentApi.getEquipments}
                defaultSelected={equipmentsInput}
                onSelected={setEquipmentsInput}
              />
            </InputGroup>
            <InputGroup className="col-12" size="m">
              <Label sm={3} className="text-sm-left">
                Quantity
              </Label>
              <Input
                type="number"
                step={1}
                max={9999999999}
                min={1}
                required={true}
                disabled={!equipmentsInput}
                value={quantityInput}
                onChange={(evt) => setQuantityInput(evt.target.value)}
              />
            </InputGroup>
          </div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Cancel
        </Button>{" "}
        <Button
          disabled={!equipmentsInput || quantityInput === 0}
          color={"primary"}
          onClick={doSubmit}
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddEquipmentModal;
