import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { workOrdersApi } from "../../../services/workOrdersServices";
import ConfirmationModal from "../../../components/ConfirmationModal";
import { reportsHelper } from "../../../helpers/reportsHelper";
import WeekSelector from "../../../components/admin/WeekSelector";
import PeriodTypeSelector from "../../../components/admin/PeriodTypeSelector";
import CustomPeriodSelector from "../../../components/admin/CustomPeriodSelector";
import Loader from "../../../components/Loader";
import { useAuth } from "../../../providers/authProvider";

const NAME_COLUMN = "Work Order #";

const STATUS_COMPLETED = 4;
const STATUS_READY_FOR_ACCOUNTING = 5;
const STATUS_INVOICED = 6;

const BUTTON_COLORS = {
  [STATUS_COMPLETED]: "warning",
  [STATUS_READY_FOR_ACCOUNTING]: "primary",
  [STATUS_INVOICED]: "success",
};

const BUTTON_TEXT = {
  [STATUS_COMPLETED]: "Mark Ready for Accounting",
  [STATUS_READY_FOR_ACCOUNTING]: "Mark Invoiced",
};

const NEXT_STATUS = {
  [STATUS_COMPLETED]: STATUS_READY_FOR_ACCOUNTING,
  [STATUS_READY_FOR_ACCOUNTING]: STATUS_INVOICED,
};

const COLUMNS = {
  "Work Order #": {
    type: "string",
  },
  Customer: {
    type: "string",
  },
  Description: {
    type: "string",
  },
  Dates: {
    type: "string",
  },
  "Contract Type": {
    type: "string",
  },
  Status: {
    type: "string",
  },
  Actions: {
    type: "string",
  },
};

const report = {
  dataSource: {
    data: [COLUMNS],
  },
  slice: {
    rows: [
      {
        uniqueName: "Work Order #",
      },
      {
        uniqueName: "Customer",
      },
      {
        uniqueName: "Description",
      },
      {
        uniqueName: "Dates",
      },
      {
        uniqueName: "Contract Type",
      },
      {
        uniqueName: "Status",
      },
      {
        uniqueName: "Actions",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Completed Jobs",
    },
  },
};

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const REPORT_NAME = "Completed Jobs_";

const TYPE_WEEKLY = "TYPE_WEEKLY";

const CompletedJobsReport = () => {
  const [authContext] = useAuth();
  const pivot = useRef(null);
  const navigate = useNavigate();

  const [refresh, setRefresh] = useState();
  const [workOrders, setWorkOrders] = useState({});

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [data, setData] = useState(null);

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));

  const [loading, setLoading] = useState();

  const getFilename = () =>
    reportsHelper.getFileName(
      monday,
      periodType.value === TYPE_WEEKLY,
      REPORT_NAME
    );

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const addButtonStatusCell = useCallback(
    (cell, data) => {
      if (data.type !== "header" && data.measure?.uniqueName === "Actions") {
        const workOrder = workOrders.data[data.rowIndex - 1];
        if (
          workOrder &&
          workOrder.statusId &&
          BUTTON_TEXT[workOrder.statusId]
        ) {
          cell.text = `<input 
          class="btn btn-${
            BUTTON_COLORS[workOrder.statusId]
          } text-white rounded text-center col-12 cursor-pointer"
          type="button" 
          value="${BUTTON_TEXT[workOrder.statusId]}"
        >`;
          cell.style["z-index"] = 1;
        }
      }
    },
    [workOrders]
  );

  const onStatusClick = useCallback(
    async (cell) => {
      if (cell.hierarchy && cell.hierarchy.caption === "Actions") {
        const columns = pivot.current.flexmonster.getAllHierarchies();
        const workOrderNumberIndex = columns
          ? columns.findIndex((e) => e.uniqueName === "Work Order #")
          : -1;
        const workOrderNumber =
          pivot.current.flexmonster.getCell(cell.rowIndex, workOrderNumberIndex)
            ?.label || "-";
        const workOrder = workOrders.data.find(
          (wo) =>
            utilsHelper.getWorkOrderNumberWithGrouped(wo) === workOrderNumber
        );
        if (!workOrder) {
          return;
        }
        if (!workOrder || !NEXT_STATUS[workOrder.statusId]) {
          return;
        }
        setConfirmationModal({
          isOpen: true,
          confirmColor: "danger",
          rawBody: true,
          onSubmit: async () => {
            await workOrdersApi.updateWorkOrderStatus({
              ...workOrder,
              statusId: NEXT_STATUS[workOrder.statusId],
            });
            setConfirmationModal(initConfirmationModal);
            setRefresh((prev) => !prev);
          },
          onClose: () => {
            setConfirmationModal(initConfirmationModal);
          },
          title: `Update Work Order ${utilsHelper.getWorkOrderNumberWithGrouped(
            workOrder
          )} Status`,
          body: `
        <div>
          <p>This action will change the status of the Work Order.</p>
          <span>Do you want to continue?</span>
        </div>
      `,
        });
      }
    },
    [workOrders]
  );

  useEffect(() => {
    if (pivot && workOrders.data) {
      pivot.current.flexmonster.updateData({
        data: [
          COLUMNS,
          ...workOrders.data.map((wo) => ({
            "Work Order #": utilsHelper.getWorkOrderNumberWithGrouped(wo),
            Customer: wo.customerName,
            Description: utilsHelper.getWorkOrderDescription(wo),
            Dates: `${utilsHelper.formatDate(
              wo.startDate,
              "MM/DD/YYYY"
            )}, ${utilsHelper.formatDate(wo.endDate, "MM/DD/YYYY")}`,
            "Contract Type": wo.workOrderContractType?.contractType || "-",
            Status: wo.status,
            Actions: null,
          })),
        ],
      });
      pivot.current.flexmonster.customizeCell(addButtonStatusCell);
      pivot.current.flexmonster.on("cellclick", onStatusClick);
    }
  }, [pivot, workOrders, addButtonStatusCell, onStatusClick]);

  useEffect(() => {
    setLoading(true);
    const condition = {
      jobSourceId: authContext.currentUser.jobSourceId,
    };
    if (periodType.value === TYPE_WEEKLY) {
      condition.completedStartDate = moment(monday).format("YYYY-MM-DD");
      condition.completedEndDate = moment(monday)
        .endOf("isoWeek")
        .format("YYYY-MM-DD");
    } else {
      condition.completedStartDate =
        moment(customStartDate).format("YYYY-MM-DD");
      condition.completedEndDate = moment(customEndDate).format("YYYY-MM-DD");
    }
    workOrdersApi
      .getWorkOrders(condition)
      .then((workOrders) => {
        setLoading(false);
        setWorkOrders(workOrders);
        setData(workOrders.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    monday,
    setData,
    periodType,
    refresh,
    customEndDate,
    customStartDate,
    authContext.currentUser.jobSourceId,
  ]);

  useEffect(() => {
    if (pivot?.current?.flexmonster && data?.length) {
      pivot.current.flexmonster.on("celldoubleclick", function (cell) {
        reportsHelper.navigateToWorkOrder(
          cell,
          data,
          NAME_COLUMN,
          null,
          navigate
        );
      });
    }
  }, [pivot, data, navigate]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-end">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : null}
              <PeriodTypeSelector
                periodType={periodType}
                setPeriodType={setPeriodType}
              />
              {periodType.value === TYPE_WEEKLY ? (
                <WeekSelector
                  loading={loading}
                  monday={monday}
                  setMonday={setMonday}
                />
              ) : (
                <CustomPeriodSelector
                  defaultEndDate={customEndDate}
                  defaultStartDate={customStartDate}
                  onSubmit={(startDate, endDate) => {
                    setCustomStartDate(startDate);
                    setCustomEndDate(endDate);
                  }}
                />
              )}
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  //key={(monday + periodType.value).toLocaleString()}
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default CompletedJobsReport;
