const api_dev = "https://pb-api.dev.belovedrobot.com/";
const api_beta = "https://pb-api.beta.belovedrobot.com/";
const api_prod = "https://ccx-api.processbarron.com/";
const api_local = "http://localhost:3000/";
let apiURL, documentsBucket;

if (process.env.REACT_APP_ENV === "production") {
  apiURL = api_prod;
  documentsBucket = "pb-api-prod";
} else if (process.env.REACT_APP_ENV === "development") {
  apiURL = api_dev;
  documentsBucket = "pb-api-dev";
} else if (process.env.REACT_APP_ENV === "beta") {
  apiURL = api_beta;
  documentsBucket = "pb-api-dev";
} else {
  apiURL = api_local;
  documentsBucket = "pb-api-dev";
}

const config = { apiURL, documentsBucket };

export default config;
