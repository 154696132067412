import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const DateRangeLabel = ({ title, startDate, endDate, className }) => {
  return (
    <div
      className={`d-flex mx-2 align-items-center small justify-content-center ${className}`}
    >
      <strong>{title}: </strong>
      <div className="d-flex mx-2 align-items-center p-2 border rounded bg-white">
        <FontAwesomeIcon
          icon={faCalendar}
          className="mr-2 text-secondary"
          style={{ paddingBottom: "1px" }}
        />
        <span>{moment(startDate).format("MM/DD/YYYY")}</span>
      </div>
      <div className="d-flex mx-2 align-items-center p-2 border rounded bg-white">
        <FontAwesomeIcon
          icon={faCalendar}
          className="mr-2 text-secondary"
          style={{ paddingBottom: "1px" }}
        />
        <span>{moment(endDate).format("MM/DD/YYYY")}</span>
      </div>
    </div>
  );
};

export default DateRangeLabel;
