import React from "react";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import Header from "../../components/Header";
import HeaderTitle from "../../components/HeaderTitle";

const Home = () => {
  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Header className="mb-3 ">
        <HeaderTitle className="d-flex flex-wrap justify-content-between">
          <span className="col-12 px-0 col-lg-6 text-dark">Welcome</span>
          <div className="d-flex justify-content-end align-items-center mt-2 mt-lg-0 col-12 col-lg-6 px-0"></div>
        </HeaderTitle>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardHeader></CardHeader>
            <CardBody className="d-flex flex-column"></CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
