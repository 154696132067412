import React from "react";
import { ListGroup } from "reactstrap";

const BarkBinScrews = ({ inspectionAnswer }) => {
  const jsonContent = JSON.parse(inspectionAnswer.content);

  const getBackgroundColor = (status) => {
    switch (status) {
      case "replaced":
        return "#57FE00";
      case "noChange":
        return "white";
      case "repaired":
        return "#1E0AFF";
      case "replaceNextOutage":
        return "#F58100";
      default:
        return "white";
    }
  };

  if (!jsonContent) {
    return null;
  }

  return (
    <div className="col-12 px-0 mt-2">
      <div className="grid-container-bark-bin-screws">
        <div className="top-message-bark-bin-screws">
          {jsonContent?.top?.toUpperCase()}
        </div>
        <div className="bottom-message-bark-bin-screws">
          {jsonContent?.bottom?.toUpperCase()}
        </div>
        <div className="bars-container-bark-bin-screws">
          <ListGroup className="mb-2 d-flex flex-nowrap">
            <div style={{ whiteSpace: "nowrap", position: "relative" }}>
              {jsonContent &&
                jsonContent.screws?.map((screw, index) => (
                  <div
                    key={index}
                    style={{ display: "inline-block", position: "relative" }}
                  >
                    <div className="number-of-screw">{index + 1}</div>
                    <div
                      className="bars-bark-bin-screws"
                      style={{
                        backgroundColor: getBackgroundColor(screw.status),
                      }}
                    ></div>
                  </div>
                ))}
            </div>
          </ListGroup>
        </div>
      </div>
      <div className="legend-container">
        <span className="legend-container-title">LEGEND:</span>
        <div className="legend-item">
          <div
            class="circle-legend"
            style={{ backgroundColor: getBackgroundColor("") }}
          ></div>
          <span>NO CHANGE</span>
        </div>
        <div className="legend-item">
          <div
            className="circle-legend"
            style={{ backgroundColor: getBackgroundColor("replaced") }}
          ></div>
          <span>REPLACED</span>
        </div>
        <div className="legend-item">
          <div
            className="circle-legend"
            style={{ backgroundColor: getBackgroundColor("repaired") }}
          ></div>
          <span>REPAIRED</span>
        </div>
        <div className="legend-item">
          <div
            className="circle-legend"
            style={{ backgroundColor: getBackgroundColor("replaceNextOutage") }}
          ></div>
          <span>REPLACE NEXT OUTAGE</span>
        </div>
      </div>
    </div>
  );
};

export default BarkBinScrews;
