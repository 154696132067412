import React, { useEffect, useState } from "react";

import {
  Alert,
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import moment from "moment";
import { commonApi } from "../../services/commonServices";
import { expensesApi } from "../../services/expensesServices";
import InformationModal from "../InformationModal";
import Loader from "../Loader";
import { useAuth } from "../../providers/authProvider";
import { utilsHelper } from "../../helpers/utilsHelper";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generalExpensesApi } from "../../services/generalExpensesServices";

const EXPENSE_TYPE_GENERAL = "general";
const EXPENSE_TYPE_MILEAGE = "mileage";
const PAYMENT_TYPE_CASH = "cash";
const PAYMENT_TYPE_CARD = "card";

const STATUS_PENDING = "pending";
const STATUS_APPROVED = "approved";
const STATUS_DECLINED = "declined";
const STATUS_PROCESSED_BY_ACCOUNTING = "processed_by_accounting";

const UpdateWoExpense = ({ onClose, onSubmit, expense }) => {
  const [authContext] = useAuth();

  const [loading, setLoading] = useState(false);

  const [expenseOptions, setExpenseOptions] = useState([]);
  const [localExpense, setLocalExpense] = useState(expense);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const data = { ...localExpense };
      if (data.type === EXPENSE_TYPE_MILEAGE) {
        data.expenseOptionId = null;
      }
      if (expense.status !== data.status) {
        if (data.status === STATUS_APPROVED) {
          data.approvedAt = moment().toISOString();
          data.approvedBy = authContext.currentUser.id;
          data.declinedAt = null;
          data.declinedBy = null;
          data.declinedReason = null;
        } else if (data.status === STATUS_PROCESSED_BY_ACCOUNTING) {
          data.accountingProcessedAt = moment().toISOString();
          data.accountingProcessedBy = authContext.currentUser.id;
          data.declinedAt = null;
          data.declinedBy = null;
          data.declinedReason = null;
        } else {
          data.approvedAt = null;
          data.approvedBy = null;
          if (data.status === STATUS_DECLINED) {
            data.declinedAt = moment().toISOString();
            data.declinedBy = authContext.currentUser.id;
          } else {
            data.declinedAt = null;
            data.declinedBy = null;
            data.declinedReason = null;
          }
        }
      }
      await (expense.isGeneral
        ? generalExpensesApi.updateGeneralExpense(data)
        : expensesApi.updateExpense(data));
      setLoading(false);
      onSubmit();
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Update Expenses",
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const expenseOptions = await commonApi.getExpenseOptions();
        setExpenseOptions(expenseOptions);
      } catch (error) {
        console.error(`Error fetching expense options: ${error}`);
      }
    };
    fetchData();
  }, []);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const expenseStatusText = utilsHelper.getExpenseStatusText(expense);

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="md">
      <ModalHeader close={closeBtn}>Update Expense</ModalHeader>
      {loading ? (
        <Loader />
      ) : (
        <Form className="px-1" onSubmit={handleSubmit}>
          <ModalBody>
            {expenseStatusText ? (
              <Alert
                color={
                  expense.status === STATUS_DECLINED ? "danger" : "success"
                }
                className="w-100"
              >
                <div className="alert-icon d-flex align-items-center">
                  <FontAwesomeIcon icon={faBell} fixedWidth />
                </div>
                <div className="small alert-message text-center d-flex flex-column">
                  <span>{expenseStatusText}</span>
                  <span className="font-italic">{expense.declinedReason}</span>
                </div>
              </Alert>
            ) : null}
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label for="employee">
                    <span>Employee</span>
                  </Label>
                  <Input
                    readOnly
                    required={true}
                    id="employee"
                    name="employee"
                    value={
                      localExpense.employee
                        ? `${localExpense.employee.firstName} ${localExpense.employee.lastName}`
                        : "-"
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="expenseTypeSelect">
                    <span>Type</span>
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <CustomInput
                    required={true}
                    id="expenseTypeSelect"
                    type="select"
                    name="expenseTypeSelect"
                    value={localExpense.type || ""}
                    onChange={(e) =>
                      setLocalExpense({
                        ...localExpense,
                        type: e.target.value,
                      })
                    }
                  >
                    <option value={""}>Select the expense type...</option>
                    <option value={EXPENSE_TYPE_GENERAL}>General</option>
                    <option value={EXPENSE_TYPE_MILEAGE}>Mileage</option>
                  </CustomInput>
                </FormGroup>
                {localExpense.type === EXPENSE_TYPE_GENERAL ? (
                  <FormGroup>
                    <Label for="expenseOptionsSelect">
                      <span>Option</span>
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <CustomInput
                      id="expenseOptionsSelect"
                      type="select"
                      name="expenseOptionsSelect"
                      value={localExpense.expenseOptionId || ""}
                      onChange={(e) =>
                        setLocalExpense({
                          ...localExpense,
                          expenseOptionId: e.target.value,
                        })
                      }
                    >
                      <option value={""}>None</option>
                      {expenseOptions.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </CustomInput>
                  </FormGroup>
                ) : null}
                <FormGroup>
                  <Label for="paymentTypeSelect">
                    <span>Payment Type</span>
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <CustomInput
                    required={true}
                    id="paymentTypeSelect"
                    type="select"
                    name="paymentTypeSelect"
                    value={localExpense.paymentType || ""}
                    onChange={(e) =>
                      setLocalExpense({
                        ...localExpense,
                        paymentType: e.target.value,
                      })
                    }
                  >
                    <option value={""}>Select the payment type...</option>
                    <option value={PAYMENT_TYPE_CASH}>Cash</option>
                    <option value={PAYMENT_TYPE_CARD}>Card</option>
                  </CustomInput>
                </FormGroup>
                <FormGroup>
                  <Label for="amount">
                    <span>Amount</span>
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    required={true}
                    id="amount"
                    min={0}
                    step={0.01}
                    max={999999999}
                    placeholder="Enter the amount.."
                    type="number"
                    value={localExpense.amount || ""}
                    onChange={(e) =>
                      setLocalExpense({
                        ...localExpense,
                        amount: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="description">
                    <span>Description</span>
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    required={true}
                    id="description"
                    rows={4}
                    placeholder="Enter the description ..."
                    type="textarea"
                    value={localExpense.description || ""}
                    onChange={(e) =>
                      setLocalExpense({
                        ...localExpense,
                        description: e.target.value,
                      })
                    }
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="statusSelect">
                    <span>Status</span>
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <CustomInput
                    required={true}
                    id="statusSelect"
                    type="select"
                    name="statusSelect"
                    value={localExpense.status || ""}
                    onChange={(e) =>
                      setLocalExpense({
                        ...localExpense,
                        status: e.target.value,
                      })
                    }
                  >
                    <option value={""}>Select the status</option>
                    <option value={STATUS_PENDING}>Pending</option>
                    <option value={STATUS_APPROVED}>Approved</option>
                    <option value={STATUS_DECLINED}>Declined</option>
                    <option value={STATUS_PROCESSED_BY_ACCOUNTING}>
                      Processed by Accounting
                    </option>
                  </CustomInput>
                </FormGroup>
                {localExpense.status === STATUS_DECLINED ? (
                  <FormGroup className="mb-0">
                    <Label for="declinedReason">
                      <span>Declined Reason</span>
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <Input
                      required={true}
                      id="declinedReason"
                      rows={4}
                      placeholder="Enter the reason ..."
                      type="textarea"
                      value={localExpense.declinedReason || ""}
                      onChange={(e) =>
                        setLocalExpense({
                          ...localExpense,
                          declinedReason: e.target.value,
                        })
                      }
                    />
                  </FormGroup>
                ) : null}
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <Button color={"secondary"} onClick={onClose}>
              Cancel
            </Button>
            <Button color={"primary"} type={"submit"}>
              Confirm
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

export default UpdateWoExpense;
