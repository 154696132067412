import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const CustomCheckbox = ({ onClick, checked, rounded = true, className }) => {
  return (
    <div
      className={`bg-primary border border-primary ${
        rounded ? "rounded" : ""
      } cursor-pointer px-1 ${className}`}
      onClick={() => (onClick ? onClick(!checked) : null)}
    >
      {checked ? (
        <FontAwesomeIcon icon={faCheck} className="text-white" size="sm" />
      ) : (
        <FontAwesomeIcon icon={faCheck} className="text-primary" size="sm" />
      )}
    </div>
  );
};

export default CustomCheckbox;
