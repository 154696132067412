import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import moment from "moment";
import { apiHelper } from "../helpers/apiHelper";

export const crewsApi = {
  getCrews: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(data.id ? `/api/crews/${data.id}` : `/api/crews?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getWorkOrderCrews: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/${data.workOrderId}/work-order-crews?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createCrew: (workOrderId, employees, shift, outage) => {
    return axios(`/api/${workOrderId}/crews`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data: { employees, shift, outage, createdAt: moment().toISOString() },
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateEmployeeCrew: (data) => {
    return axios(`/api/employee-crews/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateCrew: (workOrderId, employees, id, shift, outage) => {
    return axios(`/api/${workOrderId}/crews/${id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data: { employees, shift, outage, updatedAt: moment().toISOString() },
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateVanDriver: (data) =>
    axios(`/api/crews/${data.id}/set-van-driver`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  saveBaseCrew: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/crews`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    }).then((result) => result.data);
  },
  updateBaseCrew: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/crews/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    }).then((result) => result.data);
  },
  deleteCrew: (data) =>
    axios(`/api/crews/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
      data,
    }).then((result) => result.data),
};
