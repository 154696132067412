import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { usersApi } from "../../../services/userServices";
import { rolesApi } from "../../../services/roleServices";
import { jobsApi } from "../../../services/jobServices";
import InformationModal from "../../InformationModal";

import { employeesApi } from "../../../services/employeeServices";
import Loader from "../../Loader";
import { utilsHelper } from "../../../helpers/utilsHelper";
import SelectWrapper from "../SelectWrapper";

const USER_ROLE_APP_USER = 3;
const USER_ROLE_MANAGER_APP_USER = 6;
const USER_ROLE_READ_ONLY = 4;

const EMPLOYEE_TYPE = "1";
const MAX_PAGE_SIZE = 999;
const JOB_SOURCE_ALL = 4;

const UserModal = ({ userId, onSubmit, onClose }) => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    rePassword: "",
    jobSourceId: "",
    userRoleId: "",
    isActive: true,
  });

  const [toggleModal, setToggleModal] = useState(true);
  const [newPassword, setNewPassword] = useState();
  const [reNewPassword, setRepeatNewPassword] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();
  const [roles, setRoles] = useState([]);
  const [sources, setSources] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState();

  const toggle = () => setToggleModal(!toggleModal);

  const submitChangePassword = (e) => {
    e.preventDefault();
    if (userId) {
      setLoading(true);
      usersApi
        .changePassword({ id: userId, password: newPassword })
        .then(() => {
          setLoading(false);
          toggle();
        });
    }
  };

  const doSubmit = async (e) => {
    e.preventDefault();
    if (
      (user.userRoleId === USER_ROLE_APP_USER ||
        user.userRoleId === USER_ROLE_MANAGER_APP_USER) &&
      !user.employeeId
    ) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: "App users/Manager App users must have an employee associated",
      });
    }
    setLoading(true);
    if (user.id) {
      const {
        id,
        firstName,
        lastName,
        email,
        jobSourceId,
        userRoleId,
        employeeId,
        isActive,
      } = user;
      try {
        await usersApi.update({
          id,
          firstName: firstName || user.employee?.firstName,
          lastName: lastName || user.employee?.lastName,
          email,
          employeeId,
          jobSourceId,
          userRoleId,
          isActive: Boolean(isActive),
        });
        setLoading(false);
        onSubmit();
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Update User",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    } else {
      if (
        user.password &&
        user.rePassword &&
        user.password !== user.rePassword
      ) {
        return setInformationModal({
          isOpen: true,
          title: "Create User",
          body: "Passwords don't match",
        });
      }
      try {
        if (parseInt(user.userRoleId) === USER_ROLE_READ_ONLY) {
          user.jobSourceId = JOB_SOURCE_ALL;
        }
        await usersApi.save(user);
        setLoading(false);
        onSubmit();
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Create User",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    }
  };

  // Load User
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const user = await usersApi.getUsers({ id: userId });
        setLoading(false);
        if (user.employee) {
          setSelectedEmployee(user.employee);
        }
        setUser(user);
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    };
    if (userId) {
      fetchData();
    }
  }, [userId]);

  // Load Roles
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await rolesApi.getUserRoles();
        setLoading(false);
        setRoles(result.data);
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await jobsApi.getJobSources();
        setLoading(false);
        setSources(result);
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, []);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <>
      <Modal isOpen={toggleModal} size="sm">
        <Form onSubmit={doSubmit}>
          <ModalHeader
            className="d-flex justify-content-between"
            close={closeBtn}
          >
            {userId ? "Edit" : "Create"} User
          </ModalHeader>
          <ModalBody>
            <Row>
              {loading ? (
                <Loader size="sm" />
              ) : (
                <Col sm="12">
                  <FormGroup>
                    <Label for="name">
                      <span>First Name</span>
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <Input
                      disabled={user.employeeId}
                      type="text"
                      name="firstName"
                      value={user.firstName || user.employee?.firstName}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          firstName: e.target.value,
                        })
                      }
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="name">
                      <span>Last Name</span>
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <Input
                      disabled={user.employeeId}
                      type="text"
                      name="lastName"
                      value={user.lastName || user.employee?.lastName}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          lastName: e.target.value,
                        })
                      }
                      required
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="name">
                      <span>Email</span>
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <Input
                      type="email"
                      name="email"
                      value={user.email}
                      onChange={(e) =>
                        setUser({
                          ...user,
                          email: e.target.value.trim(),
                        })
                      }
                      required
                    />
                  </FormGroup>
                  {!userId && (
                    <>
                      <FormGroup>
                        <Label for="name">
                          <span>Password</span>
                          <span className="text-danger ml-1">*</span>
                        </Label>
                        <Input
                          type="password"
                          name="e-password"
                          value={user.password}
                          onChange={(e) =>
                            setUser({
                              ...user,
                              password: e.target.value,
                            })
                          }
                          required
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="name">
                          <span>Repeat Password</span>
                          <span className="text-danger ml-1">*</span>
                        </Label>
                        <Input
                          className={`mb-1 form-control ${
                            !user.password?.length || !user.rePassword?.length
                              ? ""
                              : user.password !== user.rePassword
                              ? "border-danger"
                              : "border-success"
                          }`}
                          type="password"
                          name="re-e-password"
                          value={user.rePassword || ""}
                          onChange={(e) =>
                            setUser({
                              ...user,
                              rePassword: e.target.value,
                            })
                          }
                          required
                        />
                      </FormGroup>
                    </>
                  )}
                  {parseInt(user.userRoleId) !== USER_ROLE_READ_ONLY ? (
                    <FormGroup>
                      <Label for="name">
                        <span>Division</span>
                        <span className="text-danger ml-1">*</span>
                      </Label>
                      <Input
                        type="select"
                        name="userCompany"
                        id="userCompany"
                        onChange={(e) =>
                          setUser({
                            ...user,
                            jobSourceId: e.target.value,
                          })
                        }
                        value={user.jobSourceId}
                        required
                      >
                        <option value={""}>Select a Company</option>
                        {sources
                          .filter((source) => source.id !== JOB_SOURCE_ALL)
                          .map((source) => (
                            <option key={source.id} value={source.id}>
                              {source.sourceKey}
                            </option>
                          ))}
                      </Input>
                    </FormGroup>
                  ) : null}
                  <FormGroup>
                    <Label for="name">
                      <span>Role</span>
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <Input
                      type="select"
                      name="roleSelect"
                      id="roleSelect"
                      onChange={(e) =>
                        setUser({
                          ...user,
                          userRoleId: e.target.value,
                          employeeId:
                            e.target.value === USER_ROLE_APP_USER ||
                            e.target.value === USER_ROLE_MANAGER_APP_USER
                              ? user.employeeId
                              : null,
                        })
                      }
                      value={user.userRoleId}
                      required
                    >
                      <option value={""}>Select a Role</option>
                      {roles.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                  {(parseInt(user.userRoleId) === USER_ROLE_APP_USER ||
                    parseInt(user.userRoleId) === USER_ROLE_MANAGER_APP_USER) &&
                  user.jobSourceId ? (
                    <FormGroup className="mb-0">
                      <Label for="name">
                        <span>Employee</span>
                        <span className="text-danger ml-1">*</span>
                      </Label>
                      <SelectWrapper
                        key={`employee-${user.jobSourceId}`}
                        entity="employee"
                        formatItemFunction={
                          utilsHelper.getEmployeeOptionWithPreferredName
                        }
                        fetchFunction={employeesApi.getEmployees}
                        fetchParameters={{
                          isSuper: true,
                          isActive: true,
                          jobSourceId: user.jobSourceId,
                          type: EMPLOYEE_TYPE,
                          pageSize: MAX_PAGE_SIZE,
                        }}
                        defaultSelected={selectedEmployee}
                        onSelected={(employee) =>
                          setUser({
                            ...user,
                            employeeId: employee?.id || "",
                            firstName: employee?.firstName || "",
                            lastName: employee?.lastName || "",
                          })
                        }
                      />
                    </FormGroup>
                  ) : null}
                  <FormGroup>
                    <Label>
                      <span>Status</span>
                      <span className="text-danger ml-1">*</span>
                    </Label>
                    <select
                      className="form-control"
                      type="select"
                      name="statusSelect"
                      id="statusSelect"
                      onChange={(e) => {
                        setUser({
                          ...user,
                          isActive: e.target.value.trim() === "true",
                        });
                      }}
                      value={user.isActive ? "true" : "false"}
                      required
                    >
                      <option value={true}>Active</option>
                      <option value={false}>Inactive</option>
                    </select>
                  </FormGroup>
                  {userId ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <Button color="info" onClick={toggle}>
                        Update Password
                      </Button>
                    </div>
                  ) : null}
                </Col>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Col>
              <Row className="justify-content-between">
                <Button color={"secondary"} onClick={onClose}>
                  Cancel
                </Button>
                <Button color={"primary"} type="submit">
                  Save
                </Button>
              </Row>
            </Col>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={!toggleModal} size="sm">
        <Form onSubmit={submitChangePassword}>
          <ModalHeader
            className="d-flex justify-content-between"
            close={
              <Button className="close" color="none" onClick={toggle}>
                &times;
              </Button>
            }
          >
            Update Password
          </ModalHeader>
          <ModalBody>
            <Row>
              {loading ? (
                <Loader size="sm" />
              ) : (
                <Col sm="12">
                  <FormGroup>
                    <Label for="name">New Password</Label>
                    <Input
                      autoComplete="new-password"
                      type="password"
                      name="password"
                      value={newPassword || ""}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                  </FormGroup>
                  <FormGroup className="mb-0">
                    <Label for="name">Repeat New Password</Label>
                    <Input
                      className={`mb-1 form-control ${
                        !newPassword?.length || !reNewPassword?.length
                          ? ""
                          : newPassword !== reNewPassword
                          ? "border-danger"
                          : "border-success"
                      }`}
                      autoComplete="new-password"
                      type="password"
                      name="re-password"
                      value={reNewPassword || ""}
                      onChange={(e) => setRepeatNewPassword(e.target.value)}
                      required
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <Col>
              <Row className="justify-content-between">
                <Button color={"secondary"} onClick={toggle}>
                  Cancel
                </Button>
                <Button
                  disabled={
                    !newPassword ||
                    !reNewPassword ||
                    newPassword !== reNewPassword
                  }
                  color={"primary"}
                  type="submit"
                >
                  Change Password
                </Button>
              </Row>
            </Col>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default UserModal;
