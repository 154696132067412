import React, { useRef } from "react";
import * as FlexmonsterReact from "react-flexmonster";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { reportsHelper } from "../../../helpers/reportsHelper";
import { useNavigate } from "react-router";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";

const DATE_DETAIL = "DATE_DETAIL";
const NAME_COLUMN = "Work Order #";

const WeeklyPayrollDetailReport = ({ hours, date, type, label, onClose }) => {
  const navigate = useNavigate();
  const pivot = useRef(null);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const COLUMNS = () => {
    const columns = {
      Employee: {
        type: "string",
      },
      Date: {
        type: "string",
      },
      Type: {
        type: "string",
      },
      "Flat Rate Rest Day": {
        type: "string",
      },
      "Flat Rate PickUp": {
        type: "string",
      },
      "Created By": {
        type: "string",
      },
    };
    if (type === DATE_DETAIL) {
      columns["Hours"] = {
        type: "string",
      };
      columns["Work Order #"] = {
        type: "string",
      };
      columns["Customer"] = {
        type: "string",
      };
    }
    columns["Created By"] = {
      type: "string",
    };
    return columns;
  };

  const report = {
    dataSource: {
      data: [
        COLUMNS(),
        ...hours.map((hour) => {
          const data = {
            Employee: label,
            Date: date || hour.date,
            Hours: hour.hours ? hour.hours.toFixed(2) : "-",
            Type: hour.type,
            "Flat Rate Rest Day": hour.flatRateRestDay
              ? hour.flatRateRestDay.toFixed(2)
              : "-",
            "Flat Rate PickUp": hour.flatRatePickUp
              ? hour.flatRatePickUp.toFixed(2)
              : "-",
            "Work Order #": hour.workOrder || "Uncoded",
            Customer: hour.customerName,
            "Created By": hour.createdBy,
          };
          if (type === DATE_DETAIL) {
            data["Hours"] = hour.hours ? hour.hours.toFixed(2) : "-";
            data["Work Order #"] = hour.workOrder || "Uncoded";
            data["Customer"] = hour.customerName;
          }
          data["Created By"] = hour.createdBy;
          return data;
        }),
      ],
    },
    slice: {
      rows: [
        {
          uniqueName: "Employee",
        },
        {
          uniqueName: "Date",
        },
        {
          uniqueName: "Type",
        },
        {
          uniqueName: "Flat Rate Rest Day",
        },
        {
          uniqueName: "Flat Rate PickUp",
        },
      ],
      expands: {
        expandAll: true,
      },
    },
    options: {
      grid: {
        type: "flat",
        showTotals: "off",
        showGrandTotals: "off",
        title: "Employee Work Times",
      },
    },
  };

  if (type === DATE_DETAIL) {
    report.slice.rows.push({
      uniqueName: "Hours",
    });
    report.slice.rows.push({
      uniqueName: "Work Order #",
    });
    report.slice.rows.push({
      uniqueName: "Customer",
    });
  }
  report.slice.rows.push({
    uniqueName: "Created By",
  });

  return (
    <Modal isOpen={true} onClosed={onClose} size="xl">
      <ModalHeader close={closeBtn}>Hours Detail Report</ModalHeader>
      <ModalBody>
        <div className="rounded border height-100">
          <FlexmonsterReact.Pivot
            ref={pivot}
            licenseKey={process.env.REACT_APP_FLEX_M_KEY}
            toolbar={true}
            height="100%"
            report={report}
            celldoubleclick={(cell) =>
              reportsHelper.navigateToWorkOrder(
                cell,
                hours,
                NAME_COLUMN,
                onClose,
                navigate
              )
            }
            beforetoolbarcreated={(toolbar) =>
              utilsHelper.customizeToolbarReport(
                toolbar,
                pivot,
                "WeeklyPayrollDetailReport"
              )
            }
          />
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <Button color={"primary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WeeklyPayrollDetailReport;
