import React, { useState } from "react";
import { Tooltip } from "reactstrap";

const TooltipItem = (props) => {
  const { position = "top", id, enabled = true, align = "center" } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <div
      className={`${
        props.className || ""
      } d-flex justify-content-${align} align-items-center cursor-pointer`}
    >
      <div
        className="w-100 d-flex justify-content-center align-items-center"
        id={"tooltip-" + id}
      >
        {props.children}
      </div>
      {enabled ? (
        <Tooltip
          placement={position}
          isOpen={tooltipOpen}
          target={"tooltip-" + id}
          toggle={toggle}
        >
          {props.title}
        </Tooltip>
      ) : null}
    </div>
  );
};

export default TooltipItem;
