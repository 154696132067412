import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import { apiHelper } from "../helpers/apiHelper";

export const travelTimesApi = {
  createTravelTime: (data) =>
    axios(`/api/travel-time`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  getTravelTimes: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/travel-time?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateTravelTime: (data) =>
    axios(`/api/travel-time/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  deleteTravelTime: (data) =>
    axios(`/api/travel-time/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
};
