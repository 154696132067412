import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../../../providers/authProvider";
import { LoginForm } from "./LoginForm";

const SignIn = () => {
  const [authContext] = useAuth();
  const location = useLocation();

  const redirectTo = () => {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      return <Navigate to={params.get("returnURL")} />;
    }
    return <Navigate to={`/work-orders/all`} />;
  };

  if (!authContext.currentUser) {
    return <LoginForm />;
  }

  return redirectTo(location);
};

export default SignIn;
