import React, { useCallback, useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  CardFooter,
  Input,
  InputGroup,
  InputGroupText,
  Button,
  Badge,
} from "reactstrap";

import { useParams } from "react-router";

import { useNavigate, useLocation } from "react-router-dom";

import { faPlus, faSearch, faSync } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useWorkOrderStatus } from "../../providers/workOrderStatusProvider";
import { nonJobProjectsApi } from "../../services/nonJobProjectsServices";
import { utilsHelper } from "../../helpers/utilsHelper";
import { useAuth } from "../../providers/authProvider";

import NonJobProjectModal from "../../components/admin/nonJobTime/NonJobProjectModal";

import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";
import InformationModal from "../../components/InformationModal";

const NONJOB = "NONJOB";
const STATUS_COMPLETED = 4;

const INIT_PAGE_SIZE = 10;
const INIT_PAGE = 1;

const columns = [
  {
    accessor: "workOrderNumber",
    Header: "Project #",
    width: 100,
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { workOrderNumber } = rowData.row.original;
      return workOrderNumber || "-";
    },
  },
  {
    accessor: "supervisor",
    Header: "Supervisor",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    disableSortBy: true,
    Cell: (rowData) => {
      const { supervisor } = rowData.row.original;
      return supervisor || "-";
    },
  },
  {
    accessor: "description",
    Header: "Description",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { description } = rowData.row.original;
      return description || "-";
    },
  },
  {
    accessor: "startDate",
    Header: "Start Date",
    width: 100,
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { startDate } = rowData.row.original;
      return (
        <Badge color="success">
          {utilsHelper.formatDate(startDate, "MM/DD/YYYY")}
        </Badge>
      );
    },
  },
  {
    accessor: "endDate",
    Header: "End Date",
    width: 100,
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { endDate } = rowData.row.original;
      return (
        <Badge color="success">
          {utilsHelper.formatDate(endDate, "MM/DD/YYYY")}
        </Badge>
      );
    },
  },
  {
    accessor: "status",
    Header: "Status",
    width: 100,
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { status } = rowData.row.original;
      return <Badge>{status}</Badge>;
    },
  },
];

const NonJobProjects = () => {
  const [authContext] = useAuth();
  const [workOrderStatusContext] = useWorkOrderStatus();
  const location = useLocation();

  const [isLoading, setLoading] = useState(true);
  const [nonJobProjects, setNonJobProjects] = useState({});
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("workOrderNumber");
  const [direction, setDirection] = useState("asc");
  const [refresh, setRefresh] = useState();

  const navigate = useNavigate();

  const { workOrderStatusId } = useParams();

  const [createModal, setCreateModal] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const localSortBy = data.id;
        const localDirection = data.desc ? "desc" : "asc";
        if (localSortBy?.id === sortBy && localDirection === direction) {
          return;
        }
        setSortBy(localSortBy);
        setDirection(localDirection);
        setPage(INIT_PAGE);
      } else {
        setSortBy(null);
        setDirection(null);
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  useEffect(() => {
    setLoading(true);
    const condition = {
      search,
      workOrderType: NONJOB,
      page: page - 1,
      pageSize: sizePerPage,
      sortBy: sortBy,
      direction: direction,
      jobSourceId: authContext.currentUser.jobSourceId,
    };
    if (location.pathname.indexOf("/history") > -1) {
      condition.statusId = `[${STATUS_COMPLETED}]`;
    }
    nonJobProjectsApi
      .getNonJobProjects(condition)
      .then((data) => {
        setNonJobProjects(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: `Work Orders`,
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
        });
      });
  }, [
    sortBy,
    direction,
    sizePerPage,
    page,
    search,
    refresh,
    authContext.currentUser.jobSourceId,
    location.pathname,
  ]);

  const onSeeDetails = (entry) => navigate(`/work-orders/details/${entry.id}`);

  const status = workOrderStatusContext.workOrderStatus.find(
    (s) => s.id === workOrderStatusId
  );

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns}
          data={nonJobProjects.data || []}
          pageSize={sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: sortBy,
            direction: direction,
          }}
        >
          <Card className="mb-3 w-100">
            <CardHeader className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-column align-items-start">
                <div className="d-flex mb-1 align-items-center">
                  <h3 className="mb-0">Non Job Time</h3>
                  <small className="text-muted ml-2 pt-1">
                    ({nonJobProjects.count})
                  </small>
                </div>
                <Badge>{status?.name || "All"}</Badge>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup className="mr-3">
                  <Input
                    className="border-right-0"
                    maxLength="50"
                    placeholder="Search for.."
                    value={search}
                    onChange={(evt) => {
                      setSearch(evt.target.value);
                      setPage(INIT_PAGE);
                    }}
                  />
                  <InputGroupText className="search-input input-group-text bg-primary text-white border-left-0 border-primary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                {!utilsHelper.isReadOnly(authContext) ? (
                  <Button
                    size="sm"
                    className="mr-3 rounded-circle d-flex custom-rounded-button"
                    color="primary"
                    onClick={() => setCreateModal(true)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                ) : null}
                <Button
                  size="sm"
                  className="mr-3 rounded-circle d-flex custom-rounded-button"
                  color="primary"
                  onClick={() => setRefresh(!refresh)}
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="d-flex flex-column overflow-x-auto">
              <AdvanceTable
                onRowClick={(row) => onSeeDetails(row.original)}
                table
                isLoading={isLoading}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0 overflow-hidden",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={nonJobProjects.count}
                pageCount={nonJobProjects.totalPages}
                currentPage={page - 1}
                onPageChange={(page) => setPage(page)}
                pageSize={sizePerPage}
                onPageSizeChange={(sizePerPage) => {
                  setSizePerPage(sizePerPage);
                  setPage(INIT_PAGE);
                }}
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {createModal ? (
        <NonJobProjectModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setInformationModal({
              isOpen: true,
              title: `Create Non Job Project`,
              body: "Non Job Project created successfully",
            });
            setRefresh(!refresh);
          }}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default NonJobProjects;
