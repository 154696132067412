import React, { useEffect, useState } from "react";
import { Badge, Button, Card, ListGroup, ListGroupItem } from "reactstrap";
import {
  faPen,
  faPhotoVideo,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../../providers/workOrderDetailsProvider";
import { expensesApi } from "../../../services/expensesServices";
import { utilsHelper } from "../../../helpers/utilsHelper";
import InformationModal from "../../InformationModal";
import UploadPhotosModal from "../../UploadPhotosModal";
import TooltipItem from "../../TooltipItem";
import { generalExpensesApi } from "../../../services/generalExpensesServices";
import ExpensePhotos from "../ExpensePhotosModal";
import { useAuth } from "../../../providers/authProvider";

const EXPENSE_TYPE_MILEAGE = "mileage";

const STATUS_PENDING = "pending";
const STATUS_DECLINED = "declined";

const Expense = ({ expense, onDelete, onEdit }) => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const [showAddPhotoModal, setShowAddPhotoModal] = useState(false);
  const [seePhotosModal, setSeePhotosModal] = useState(false);

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const [woNumber, setWoNumber] = useState();

  useEffect(() => {
    if (expense.workOrderId === workOrderDetails.workOrder.id) {
      setWoNumber();
    } else {
      const groupedWo = workOrderDetails.workOrder.groupedWorkOrders?.find(
        (wo) => wo.id === expense.workOrderId
      );
      if (!groupedWo) {
        setWoNumber();
      } else {
        setWoNumber(groupedWo.workOrderNumber);
      }
    }
  }, [expense.workOrderId, workOrderDetails.workOrder]);

  const onSaveImages = async (images = []) => {
    for (const key in images) {
      const url = images[key];
      if (expense.isGeneral) {
        await generalExpensesApi.updateGeneralExpense({
          ...expense,
          attachmentUrl: url,
        });
      } else {
        await expensesApi.createExpenseAttachment({
          expenseId: expense.id,
          attachmentUrl: url,
        });
      }
    }
    setWorkOrderDetails({
      action: ACTIONS.REFRESH,
    });
  };

  const expenseStatusText = utilsHelper.getExpenseStatusText(expense);

  return (
    <div key={expense.id} className="col-12 col-md-6 col-lg-4">
      <Card className="my-3">
        <ListGroup>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center p-2 bg-lighter"
            tag="div"
          >
            <div className="col-4 pl-0 d-flex flex-wrap">
              <Badge
                className="text-capitalize text-left mr-2 my-1"
                color="info"
              >
                {expense.type}
              </Badge>
              {expense.status === STATUS_PENDING ? (
                <Badge className="text-capitalize text-left my-1" color="dark">
                  {expense.status}
                </Badge>
              ) : (
                <TooltipItem
                  id={`${expense.id}-status-tooltip`}
                  title={expenseStatusText}
                >
                  <Badge
                    className="text-capitalize text-left my-1"
                    color={
                      expense.status === STATUS_DECLINED ? "danger" : "success"
                    }
                  >
                    {expense.status}
                  </Badge>
                </TooltipItem>
              )}
            </div>
            <div className="text-underline col-4 text-center">
              {utilsHelper.capitalize(expense.paymentType)}
            </div>
            {!utilsHelper.isReadOnly(authContext) ? (
              <div className="d-flex col-4 pr-0 align-items-center justify-content-end">
                {onEdit ? (
                  <FontAwesomeIcon
                    onClick={() => onEdit(expense)}
                    icon={faPen}
                    className="mx-3 text-warning cursor-pointer"
                  />
                ) : null}
                {onDelete ? (
                  <FontAwesomeIcon
                    onClick={() => onDelete(expense)}
                    icon={faTrash}
                    className="text-danger cursor-pointer"
                  />
                ) : null}
              </div>
            ) : null}
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-between p-0 align-items-center small"
            tag="div"
          >
            <span className="text-truncate py-2 col-6">
              {expense.description || "No Description"}
            </span>
            <span className="text-danger py-2 col-6 border-left text-right">
              {expense.type === EXPENSE_TYPE_MILEAGE
                ? utilsHelper.formatDecimal(expense.amount, 2)
                : utilsHelper.formatCurrency(expense.amount)}
            </span>
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-between p-0 align-items-center small"
            tag="div"
          >
            <div className="text-truncate py-2 col-6">
              {expense.expenseOption
                ? expense.expenseOption.name
                : "Expense Option N/A"}
            </div>
            <div className="text-truncate py-2 col-6 border-left text-right">
              {expense.employee
                ? utilsHelper.getEmployeeLabelWithPreferredName(
                    expense.employee
                  )
                : "-"}
            </div>
          </ListGroupItem>
          {woNumber ? (
            <ListGroupItem
              className="d-flex justify-content-between align-items-center p-2 small"
              tag="div"
            >
              <Badge className="text-right ml-2" color="info">
                {woNumber}
              </Badge>
            </ListGroupItem>
          ) : null}
          {expense.type !== EXPENSE_TYPE_MILEAGE ? (
            <ListGroupItem
              className="d-flex justify-content-center align-items-center p-2 small"
              tag="div"
            >
              {!utilsHelper.isReadOnly(authContext) ? (
                <Button
                  size="sm"
                  className="rounded mr-2"
                  onClick={() => setShowAddPhotoModal(true)}
                >
                  <FontAwesomeIcon icon={faUpload} className="mr-2" />
                  <span>
                    {expense.isGeneral && expense.attachmentUrl
                      ? "Edit"
                      : "Add"}{" "}
                    Photo
                  </span>
                </Button>
              ) : null}
              {expense.expenseAttachments?.length > 0 ||
              expense.attachmentUrl ? (
                <Button
                  size="sm"
                  onClick={() => setSeePhotosModal(true)}
                  className="rounded mr-2"
                >
                  <FontAwesomeIcon icon={faPhotoVideo} className="mr-2" />
                  <span>See Photo{!expense.isGeneral ? "s" : ""}</span>
                </Button>
              ) : null}
            </ListGroupItem>
          ) : null}
        </ListGroup>
      </Card>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : showAddPhotoModal ? (
        <UploadPhotosModal
          isMulti={!expense.isGeneral}
          onClose={() => setShowAddPhotoModal(false)}
          title={"Add Expense's Photos"}
          onSubmit={(response) => onSaveImages(response)}
        />
      ) : seePhotosModal ? (
        <ExpensePhotos
          expense={expense}
          onClose={() => setSeePhotosModal(false)}
          onDeleteImageSuccess={() =>
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            })
          }
        />
      ) : null}
    </div>
  );
};

export default Expense;
