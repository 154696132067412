import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { Document, Page } from "react-pdf";
import {
  faChevronLeft,
  faChevronRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { reportsApi } from "../../services/reportsServices";

import InformationModal from "../InformationModal";
import Loader from "../Loader";
import { useAuth } from "../../providers/authProvider";

import moment from "moment";

const TYPE_WEEKLY = "TYPE_WEEKLY";

const TYPE_PDF = "pdf";

const TravelLivingSummaryModal = ({
  periodType,
  monday,
  customStartDate,
  customEndDate,
  employeeId,
  onClose,
}) => {
  const [authContext] = useAuth();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(null);

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  useEffect(() => {
    const condition = {
      jobSourceId: authContext.currentUser.jobSourceId,
      employeeId,
    };
    if (periodType.value === TYPE_WEEKLY) {
      condition.startDate = moment(monday).format("YYYY-MM-DD");
      condition.endDate = moment(monday).endOf("isoWeek").format("YYYY-MM-DD");
    } else {
      condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
      condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
    }
    const fetchReport = async () => {
      try {
        setLoading(true);
        const res = await reportsApi.travelAndLivingSummaryPdf(condition);
        const pdf = new Blob([res], { type: "application/pdf" });
        const URL = window.URL.createObjectURL(pdf);
        setLoading(false);
        setReport(URL);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchReport();
  }, [
    authContext.currentUser.jobSourceId,
    employeeId,
    customEndDate,
    customStartDate,
    monday,
    periodType,
  ]);

  const onDownload = (type) => {
    if (type === TYPE_PDF) {
      return window.open(report, "new");
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal size="lg" isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        <div className="d-flex flex-column align-items-start">
          <span>Summary PDF</span>
          <Button
            color="tertiary"
            size="sm"
            className="rounded"
            onClick={() => onDownload(TYPE_PDF)}
          >
            <FontAwesomeIcon icon={faDownload} className="mr-2" />
            <span>PDF</span>
          </Button>
        </div>
      </ModalHeader>
      {loading ? (
        <div className="my-2">
          <Loader size="sm" />
        </div>
      ) : (
        <ModalBody className="d-flex flex-column align-items-center">
          {loading ? (
            <Loader size="sm" />
          ) : !report ? (
            <div className="text-center text-muted">No PDF has been loaded</div>
          ) : (
            <>
              <Document
                className="d-flex flex-column justify-content-between align-items-center text-body bg-white"
                file={report}
                onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              >
                <Page
                  className="w-100 border rounded"
                  pageNumber={pageNumber}
                />
              </Document>
              <div className="d-flex justify-content-between p-2 align-items-center text-body bg-white border mt-4 mx-1 rounded w-50">
                <Button
                  className="rounded"
                  onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </Button>
                <span>
                  Page {pageNumber} of {numPages}
                </span>
                <Button
                  className="rounded"
                  onClick={() =>
                    setPageNumber(Math.min(pageNumber + 1, numPages))
                  }
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </div>
            </>
          )}
        </ModalBody>
      )}
      <ModalFooter className="justify-content-between">
        <Button color={"primary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TravelLivingSummaryModal;
