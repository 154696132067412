import {
  faExclamationCircle,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "reactstrap";
import TooltipItem from "../../TooltipItem";
import { useAuth } from "../../../providers/authProvider";
import { utilsHelper } from "../../../helpers/utilsHelper";
import moment from "moment";

const InjuryReportNote = ({ note, doDelete, doEdit }) => {
  const [authContext] = useAuth();

  return (
    <div
      key={note.id}
      className="my-2 col-12 d-flex justify-content-between align-items-start px-0"
    >
      <div
        className="p-2 my-1 rounded col-12 d-flex justify-content-between align-items-start"
        style={{ backgroundColor: "#FDF1DB" }}
      >
        <div className="flex-grow-1 px-0" style={{ textAlign: "left" }}>
          <span className="text-wrap col-12 px-0 ml-1">{note.content}</span>
        </div>
        <div className="d-flex">
          {note.userInjuryReportAttachmentAuthor && (
            <TooltipItem
              className="ml-2"
              id={`${note.id}-tooltipAuthor`}
              title={`Created by ${`${note.userInjuryReportAttachmentAuthor.firstName}
               ${note.userInjuryReportAttachmentAuthor.lastName}`}\nCreated at ${moment(
                note.createdAt
              ).format("MM/DD/YYYY HH:mm")}`}
            >
              <FontAwesomeIcon
                size="lg"
                icon={faExclamationCircle}
                className="ml-2 text-info"
                style={{ paddingBottom: "1px" }}
              />
            </TooltipItem>
          )}
          {note.userInjuryReportAttachmentEditor && (
            <TooltipItem
              className="ml-2"
              id={`${note.id}-tooltipEditor`}
              title={`Modified by ${`${note.userInjuryReportAttachmentEditor.firstName} 
              ${note.userInjuryReportAttachmentEditor.lastName}`}\nUpdated at ${moment(
                note.updatedAt
              ).format("MM/DD/YYYY HH:mm")}`}
            >
              <FontAwesomeIcon
                size="lg"
                icon={faExclamationCircle}
                className="ml-1 text-warning"
                style={{ paddingBottom: "1px" }}
              />
            </TooltipItem>
          )}
        </div>
        <div className="d-flex">
          {!utilsHelper.isReadOnly(authContext) ? (
            <>
              <Button
                size="sm"
                className="rounded d-flex align-items-center justify-content-center ml-2"
                color="danger"
                onClick={() => doDelete()}
              >
                <FontAwesomeIcon size="sm" icon={faTrash} className="mr-1" />
                <small>Delete</small>
              </Button>
              <Button
                size="sm"
                className="rounded d-flex align-items-center justify-content-center ml-2"
                color="warning"
                onClick={() => doEdit()}
              >
                <FontAwesomeIcon size="sm" icon={faPen} className="mr-1" />
                <small>Edit</small>
              </Button>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default InjuryReportNote;
