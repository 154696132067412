import axios from "axios";
import config from "../config/config";
import moment from "moment";
import { utilsHelper } from "../helpers/utilsHelper";
import { apiHelper } from "../helpers/apiHelper";

export const expensesApi = {
  getAllExpenses: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/expense/all?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getExpenses: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/expense?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createExpense: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/expense/ `, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createExpenseAttachment: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/expense-attachment/ `, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  deleteExpensesAttachment: (data) =>
    axios(`/api/expense-attachment/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  deleteExpense: (id) =>
    axios(`/api/expense/${id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  updateExpense: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/expense/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  approveExpenseBulk: (data) => {
    return axios(`/api/expense/bulk/approve`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  processedAccountingBulk: (data) => {
    return axios(`/api/expense/bulk/processed-accounting`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
