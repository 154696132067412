import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import { reportsApi } from "../../../../services/reportsServices";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { utilsHelper } from "../../../../helpers/utilsHelper";
import WeekSelector from "../../../../components/admin/WeekSelector";
import { reportsHelper } from "../../../../helpers/reportsHelper";
import PeriodTypeSelector from "../../../../components/admin/PeriodTypeSelector";
import CustomPeriodSelector from "../../../../components/admin/CustomPeriodSelector";
import Loader from "../../../../components/Loader";
import { useAuth } from "../../../../providers/authProvider";

const report = {
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
  ],
  dataSource: {
    data: [
      {
        CompanyId: {
          type: "string",
        },
        "Employee Code": {
          type: "string",
        },
        Name: {
          type: "string",
        },
        Operation: {
          type: "string",
        },
        Hours: {
          type: "number",
        },
        PayCode: {
          type: "string",
        },
        "Created By": {
          type: "string",
        },
      },
    ],
  },
  slice: {
    rows: [
      {
        uniqueName: "CompanyId",
      },
      {
        uniqueName: "Employee Code",
      },
      {
        uniqueName: "Name",
      },
      {
        uniqueName: "Operation",
      },
      {
        uniqueName: "Hours",
      },
      {
        uniqueName: "PayCode",
      },
      {
        uniqueName: "Created By",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Payroll Report",
    },
  },
};

const REPORT_NAME = "Payroll_Report_";
const TYPE_WEEKLY = "TYPE_WEEKLY";

const PayrollReport = () => {
  const [authContext] = useAuth();
  const pivot = useRef(null);

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [loading, setLoading] = useState();

  const getFilename = () =>
    reportsHelper.getFileName(
      monday,
      periodType.value === TYPE_WEEKLY,
      REPORT_NAME
    );

  useEffect(() => {
    setLoading(true);
    const condition = {
      jobSourceId: authContext.currentUser.jobSourceId,
    };
    if (periodType.value === TYPE_WEEKLY) {
      condition.startDate = moment(monday).format("YYYY-MM-DD");
      condition.endDate = moment(monday).endOf("isoWeek").format("YYYY-MM-DD");
    } else {
      condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
      condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
    }
    reportsApi
      .payrollReport(condition)
      .then((result) => {
        setLoading(false);
        if (pivot) {
          pivot.current.flexmonster.updateData({
            data: [
              {
                CompanyId: {
                  type: "string",
                },
                "Employee Code": {
                  type: "string",
                },
                Name: {
                  type: "string",
                },
                Operation: {
                  type: "string",
                },
                Hours: {
                  type: "number",
                },
                PayCode: {
                  type: "string",
                },
                "Created By": {
                  type: "string",
                },
              },
              ...result.hours,
            ],
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    monday,
    periodType,
    pivot,
    customEndDate,
    customStartDate,
    authContext.currentUser.jobSourceId,
  ]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-end">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : null}
              <PeriodTypeSelector
                periodType={periodType}
                setPeriodType={setPeriodType}
              />
              {periodType.value === TYPE_WEEKLY ? (
                <WeekSelector
                  loading={loading}
                  monday={monday}
                  setMonday={setMonday}
                />
              ) : (
                <CustomPeriodSelector
                  defaultEndDate={customEndDate}
                  defaultStartDate={customStartDate}
                  onSubmit={(startDate, endDate) => {
                    setCustomStartDate(startDate);
                    setCustomEndDate(endDate);
                  }}
                />
              )}
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  //key={(monday + periodType.value).toLocaleString()}
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PayrollReport;
