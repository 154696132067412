import React, { useEffect, useState } from "react";

import { Col, Row, ListGroup, ListGroupItem } from "reactstrap";
import Loader from "../../components/Loader";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import { workOrdersApi } from "../../services/workOrdersServices";
import CustomCheckbox from "../../components/CustomCheckbox";
import { utilsHelper } from "../../helpers/utilsHelper";
import { useAuth } from "../../providers/authProvider";

const MAX_PAGE_SIZE = 999;

const WorkOrderChecklist = () => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();

  const [generalLoading, setGeneralLoading] = useState();
  const [itemLoading, setItemLoading] = useState({});
  const [workOrderCheckList, setWorkOrderCheckList] = useState([]);
  const [checkListItems, setCheckListItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setGeneralLoading(true);
      const workOrderCheckList = await workOrdersApi.getWorkOrderChecklist({
        workOrderId: workOrderDetails.workOrder.id,
        pageSize: MAX_PAGE_SIZE,
      });
      const checkListItems = await workOrdersApi.getChecklistItems({
        pageSize: MAX_PAGE_SIZE,
      });
      setGeneralLoading(false);
      setCheckListItems(checkListItems.data);
      setWorkOrderCheckList(workOrderCheckList.data);
    };
    fetchData();
  }, [workOrderDetails.workOrder.id]);

  const onItemChange = async (checkListItemId) => {
    const workOrderCheckListValue = workOrderCheckList.find(
      (item) => item.checkListItemId === checkListItemId
    );
    setItemLoading({ [checkListItemId]: true });
    if (workOrderCheckListValue) {
      await workOrdersApi.updateWorkOrderChecklist({
        id: workOrderCheckListValue.id,
        value: !isCheckedElement(checkListItemId),
        workOrderId: workOrderCheckListValue.workOrderId,
        checkListItemId,
      });
      workOrderCheckListValue.value = !isCheckedElement(checkListItemId);
      const newValues = [...workOrderCheckList];
      newValues.splice(
        workOrderCheckList.findIndex(
          (item) => item.id === workOrderCheckListValue.id
        ),
        1,
        workOrderCheckListValue
      );
      setWorkOrderCheckList(newValues);
    } else {
      const newValue = await workOrdersApi.createWorkOrderChecklist({
        value: !isCheckedElement(checkListItemId),
        workOrderId: workOrderDetails.workOrder.id,
        checkListItemId,
      });
      setWorkOrderCheckList([...workOrderCheckList, newValue]);
    }
    setItemLoading({ [checkListItemId]: false });
  };

  const isCheckedElement = (checkListItemId) => {
    const workOrderCheckListValue = workOrderCheckList.find(
      (item) => item.checkListItemId === checkListItemId
    );
    return Boolean(workOrderCheckListValue?.value);
  };

  return (
    <Row>
      <Col sm="12">
        <h4 className="pb-2">Job Complete Checklist</h4>
        {generalLoading ? (
          <Loader size="sm" />
        ) : (
          <ListGroup>
            {checkListItems.map((checkListItem) => (
              <ListGroupItem
                key={checkListItem.id}
                className={`d-flex justify-content-between align-items-center ${
                  !utilsHelper.isReadOnly(authContext) ? "cursor-pointer" : ""
                }`}
                onClick={() =>
                  utilsHelper.isReadOnly(authContext)
                    ? null
                    : onItemChange(checkListItem.id)
                }
              >
                <div className="flex-shrink-0">{checkListItem.text}</div>
                {itemLoading[checkListItem.id] ? (
                  <Loader size="sm" align="end" />
                ) : !utilsHelper.isReadOnly(authContext) ? (
                  <div className="min-width-50 d-flex justify-content-end">
                    <CustomCheckbox
                      checked={isCheckedElement(checkListItem.id)}
                    />
                  </div>
                ) : null}
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
      </Col>
    </Row>
  );
};
export default WorkOrderChecklist;
