import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  ListGroup,
  ListGroupItem,
  Input,
} from "reactstrap";
import { useWorkOrderDetails } from "../../../../providers/workOrderDetailsProvider";
import Loader from "../../../Loader";
import InformationModal from "../../../InformationModal";
import { workOrdersApi } from "../../../../services/workOrdersServices";

const CodeEntryTimeModal = ({ data, onSubmit, onClose }) => {
  const { employee, type, date, crewWorkDayId, workTimes } = data;
  const [workOrderDetails] = useWorkOrderDetails();
  const [loading, setLoading] = useState();
  const [uncodedHours, setUncodedHours] = useState(0);
  const [codedHours, setCodedHours] = useState(0);
  const [localWorkTimes, setLocalWorkTimes] = useState({});

  useEffect(() => {
    const localWorkTimes = workTimes.reduce((p, c) => {
      p[c.workOrderId] = c;
      return p;
    }, {});
    if (!localWorkTimes[workOrderDetails.workOrder.id]) {
      localWorkTimes[workOrderDetails.workOrder.id] = {
        employeeId: employee.id,
        workOrderId: workOrderDetails.workOrder.id,
        crewWorkDayId,
        hours: 0,
        type,
        note: "Coded from website",
      };
    }
    setLocalWorkTimes({
      ...localWorkTimes,
    });
  }, [
    workTimes,
    crewWorkDayId,
    employee.id,
    type,
    workOrderDetails.workOrder.id,
  ]);

  useEffect(() => {
    const uncodedHours = Object.values(localWorkTimes).reduce(
      (p, c) =>
        p +
        (c.workOrderId === workOrderDetails.workOrder.id
          ? parseFloat(c.hours || 0)
          : 0),
      0
    );
    const codedHours = Object.values(localWorkTimes).reduce(
      (p, c) =>
        p +
        (c.workOrderId !== workOrderDetails.workOrder.id
          ? parseFloat(c.hours || 0)
          : 0),
      0
    );
    setCodedHours(codedHours);
    setUncodedHours(uncodedHours);
  }, [localWorkTimes, workOrderDetails.workOrder.id]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const doSubmit = async (event) => {
    event.preventDefault();
    const originalHours = workTimes.reduce(
      (p, c) => p + parseFloat(c.hours),
      0
    );
    const sendingHours = Object.values(localWorkTimes).reduce(
      (p, c) => p + parseFloat(c.hours),
      0
    );
    if (sendingHours > originalHours) {
      return setInformationModal({
        isOpen: true,
        title: "Code Time",
        body: "You can't code more hours than the available ones.",
      });
    }
    try {
      setLoading(true);
      const workTimes = Object.values(localWorkTimes).map((workTime) => ({
        ...workTime,
        hours: workTime.hours || 0,
      }));
      await workOrdersApi.codeTime({
        id: workOrderDetails.workOrder.id,
        workTimes,
        employeeId: employee.id,
        crewWorkDayId,
        type,
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Code Time",
        body: "Time coded successfully.",
        onClose: () => {
          onSubmit();
        },
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Code Time",
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onCodedTimeChange = (workOrderId, hours) => {
    const newWorkTimes = JSON.parse(JSON.stringify(localWorkTimes));
    let variation = 0;
    if (!newWorkTimes[workOrderId]) {
      variation = parseFloat(hours).toFixed(1);
      newWorkTimes[workOrderId] = {
        employeeId: employee.id,
        workOrderId,
        crewWorkDayId,
        hours,
        type,
        note: "Coded from website",
      };
    } else {
      variation = parseFloat(hours - newWorkTimes[workOrderId].hours).toFixed(
        1
      );
      newWorkTimes[workOrderId].hours = hours;
    }
    const newValue =
      parseFloat(newWorkTimes[workOrderDetails.workOrder.id].hours).toFixed(1) -
      variation;
    if (newValue < 0) {
      setInformationModal({
        isOpen: true,
        title: "Code Time",
        body: "Can't code more hours than available.",
      });
      setLocalWorkTimes(JSON.parse(JSON.stringify(localWorkTimes)));
    } else {
      newWorkTimes[workOrderDetails.workOrder.id].hours = newValue;
      setLocalWorkTimes(newWorkTimes);
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Code Time</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <ListGroup>
              <ListGroupItem
                className="d-flex py-0 border-bottom bg-lighter"
                tag="div"
              >
                <div className="col-3 py-2">Date</div>
                <div className="col-6 py-2 border-left border-right">
                  Employee
                </div>
                <div className="col-3 py-2">Type</div>
              </ListGroupItem>
              <ListGroupItem className="d-flex py-0 border-bottom" tag="div">
                <div className="col-3 py-2 ">{date}</div>
                <div className="col-6 py-2 border-left border-right">{`${employee.firstName} ${employee.lastName}`}</div>
                <div className="col-3 py-2 text-capitalize">{type}</div>
              </ListGroupItem>
              <ListGroupItem
                className="d-flex py-0 border-bottom bg-lighter"
                tag="div"
              >
                <div className="col-6 py-2">Coded Hours</div>
                <div className="col-6 py-2 border-left">Uncoded Hours</div>
              </ListGroupItem>
              <ListGroupItem className="d-flex py-0 border-bottom" tag="div">
                <div className="col-6 py-2">{codedHours.toFixed(2)}</div>
                <div className="col-6 py-2 border-left">
                  {uncodedHours.toFixed(2)}
                </div>
              </ListGroupItem>
              <ListGroupItem
                className="text-center py-2 border-bottom bg-lighter"
                tag="div"
              >
                Work Orders
              </ListGroupItem>
              {workOrderDetails.workOrder.groupedWorkOrders.map(
                (groupedWorkOrder) => {
                  const workTime = Object.values(localWorkTimes).find(
                    (workTime) => workTime.workOrderId === groupedWorkOrder.id
                  );
                  return (
                    <ListGroupItem
                      className="d-flex py-2 border-bottom align-items-center justify-content-between"
                      tag="div"
                      key={groupedWorkOrder.id}
                    >
                      <span>{groupedWorkOrder.workOrderNumber}</span>
                      <div className="col-3">
                        <Input
                          type="number"
                          placeholder="Hours.."
                          max={24}
                          min={0}
                          step={0.01}
                          value={workTime?.hours || ""}
                          onChange={(event) =>
                            onCodedTimeChange(
                              groupedWorkOrder.id,
                              event.currentTarget.value || 0
                            )
                          }
                        />
                      </div>
                    </ListGroupItem>
                  );
                }
              )}
            </ListGroup>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          <Button color={"primary"} type={"submit"}>
            Confirm
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CodeEntryTimeModal;
