import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import moment from "moment";
import { apiHelper } from "../helpers/apiHelper";

export const employeesApi = {
  getEmployees: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(
      data.id ? `/api/employees/${data.id}` : `/api/employees?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  save: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/employees`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  update: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/employees/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  delete: (data) => {
    return axios(`/api/employees/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getSubContractorsCompanies: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(
      data.id
        ? `/api/sub-contractor-companies/${data.id}`
        : `/api/sub-contractor-companies?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createSubContractorsCompany: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/sub-contractor-companies`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateSubContractorsCompany: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/sub-contractor-companies/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  deleteSubContractorsCompany: (data) => {
    return axios(`/api/sub-contractor-companies/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateEmployeeWorkDays: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/employee-work-days/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
