import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import InformationModal from "../../InformationModal";

import { employeesApi } from "../../../services/employeeServices";
import Loader from "../../Loader";

const CompanyModal = ({ companyId, onClose }) => {
  const [company, setCompany] = useState({
    name: "",
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();

  useEffect(() => {
    if (companyId) {
      setLoading(true);
      employeesApi
        .getSubContractorsCompanies({ id: companyId })
        .then((data) => {
          setLoading(false);
          setCompany(data);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [companyId]);

  const doSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (company.id) {
      employeesApi
        .updateSubContractorsCompany(company)
        .then(() => {
          setLoading(false);
          onClose();
        })
        .catch((err) => {
          setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Update Company",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        });
    } else {
      employeesApi
        .createSubContractorsCompany(company)
        .then(() => {
          setLoading(false);
          onClose();
        })
        .catch((err) => {
          setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Create Company",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {companyId ? "Edit" : "Create"} Company
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col sm="12">
                <FormGroup className="mb-0">
                  <Label for="name">Name</Label>
                  <Input
                    placeholder="Enter the company name..."
                    type="text"
                    name="name"
                    value={company.name}
                    onChange={(e) =>
                      setCompany({
                        ...company,
                        name: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default CompanyModal;
