import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router";
import { reportsApi } from "../../../services/reportsServices";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { reportsHelper } from "../../../helpers/reportsHelper";
import WeekSelector from "../../../components/admin/WeekSelector";
import PeriodTypeSelector from "../../../components/admin/PeriodTypeSelector";
import CustomPeriodSelector from "../../../components/admin/CustomPeriodSelector";
import Loader from "../../../components/Loader";
import { useAuth } from "../../../providers/authProvider";

const NAME_COLUMN = "Work Order #";

const report = {
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
    {
      name: "daily",
      thousandsSeparator: ",",
      decimalPlaces: 1,
      maxDecimalPlaces: 1,
    },
  ],
  dataSource: {
    data: [
      {
        "Company ID": {
          type: "string",
        },
        Name: {
          type: "string",
        },
        Operation: {
          type: "string",
        },
        Date: {
          type: "string",
        },
        EmployeeRole: {
          type: "string",
        },
        "Work Order #": {
          type: "string",
        },
        Customer: {
          type: "string",
        },
        "Work Time": {
          type: "number",
        },
        "Daily Mileage": {
          type: "number",
        },
        "Hours Per Diem": {
          type: "number",
        },
        "Cash Advance": {
          type: "number",
        },
        "Adjustment Amount": {
          type: "number",
        },
        "Adjustment Reason": {
          type: "string",
        },
        "Mileage Per Diem": {
          type: "number",
        },
        "Actual Mileage From Job": {
          type: "number",
        },
        "Actual Mileage To Job": {
          type: "number",
        },
        "Actual Travel Time From Job": {
          type: "number",
        },
        "Actual Travel Time To Job": {
          type: "number",
        },
        "Daily Mileage Per Diem": {
          type: "number",
        },
        "Travel Mileage Per Diem": {
          type: "number",
        },
        "Non-Job Time": {
          type: "number",
        },
        "Non-Job Hours Per Diem": {
          type: "number",
        },
        "Per Diem Type": {
          type: "string",
        },
        "Daily Total": {
          type: "number",
        },
        Shift: {
          type: "string",
        },
        "Created By": {
          type: "string",
        },
      },
    ],
  },
  slice: {
    rows: [
      {
        uniqueName: "Company ID",
      },
      {
        uniqueName: "Name",
      },
      {
        uniqueName: "Operation",
      },
      {
        uniqueName: "Contractor",
      },
      {
        uniqueName: "Date",
      },
      {
        uniqueName: "EmployeeRole",
      },
      {
        uniqueName: "Work Order #",
      },
      {
        uniqueName: "Customer",
      },
      {
        uniqueName: "Work Time",
      },
      {
        uniqueName: "Daily Mileage",
      },
      {
        uniqueName: "Hours Per Diem",
      },
      {
        uniqueName: "Cash Advance",
      },
      {
        uniqueName: "Adjustment Amount",
      },
      {
        uniqueName: "Adjustment Reason",
      },
      {
        uniqueName: "Mileage Per Diem",
      },
      {
        uniqueName: "Actual Mileage From Job",
      },
      {
        uniqueName: "Actual Mileage To Job",
      },
      {
        uniqueName: "Actual Travel Time From Job",
      },
      {
        uniqueName: "Actual Travel Time To Job",
      },
      {
        uniqueName: "Daily Mileage Per Diem",
      },
      {
        uniqueName: "Travel Mileage Per Diem",
      },
      {
        uniqueName: "Non-Job Time",
      },
      {
        uniqueName: "Non-Job Hours Per Diem",
      },
      {
        uniqueName: "Per Diem Type",
      },
      {
        uniqueName: "Daily Total",
      },
      {
        uniqueName: "Shift",
      },
      {
        uniqueName: "Created By",
      },
    ],
    measures: [
      {
        uniqueName: "Daily Mileage",
        aggregation: "sum",
        active: true,
        format: "daily",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Travel and Living",
    },
  },
};

const HOURS_RED = "#dc35452e";

const REPORT_NAME = "Travel and Living_";

const TYPE_WEEKLY = "TYPE_WEEKLY";

const TravelAndLiving = () => {
  const [authContext] = useAuth();
  const pivot = useRef(null);
  const navigate = useNavigate();

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [indexDuplicates, setIndexDuplicates] = useState(new Set());
  const [data, setData] = useState(null);

  const [loading, setLoading] = useState();

  const getFilename = () =>
    reportsHelper.getFileName(
      monday,
      periodType.value === TYPE_WEEKLY,
      REPORT_NAME
    );

  useEffect(() => {
    setLoading(true);
    const condition = {
      jobSourceId: authContext.currentUser.jobSourceId,
    };
    if (periodType.value === TYPE_WEEKLY) {
      condition.startDate = moment(monday).format("YYYY-MM-DD");
      condition.endDate = moment(monday).endOf("isoWeek").format("YYYY-MM-DD");
    } else {
      condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
      condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
    }
    reportsApi
      .travelAndLivingReport(condition)
      .then((result) => {
        setLoading(false);
        setData(result);
        if (pivot) {
          pivot.current.flexmonster.updateData({
            data: [
              {
                "Company ID": {
                  type: "string",
                },
                "EE Code": {
                  type: "string",
                },
                Name: {
                  type: "string",
                },
                Operation: {
                  type: "string",
                },
                Date: {
                  type: "string",
                },
                EmployeeRole: {
                  type: "string",
                },
                "Work Order #": {
                  type: "string",
                },
                Customer: {
                  type: "string",
                },
                "Work Time": {
                  type: "number",
                },
                "Daily Mileage": {
                  type: "number",
                },
                "Mileage Per Diem": {
                  type: "number",
                },
                "Actual Mileage From Job": {
                  type: "number",
                },
                "Actual Mileage To Job": {
                  type: "number",
                },
                "Actual Travel Time From Job": {
                  type: "number",
                },
                "Actual Travel Time To Job": {
                  type: "number",
                },
                "Daily Mileage Per Diem": {
                  type: "number",
                },
                "Travel Mileage Per Diem": {
                  type: "number",
                },
                "Non-Job Time": {
                  type: "number",
                },
                "Non-Job Hours Per Diem": {
                  type: "number",
                },
                "Per Diem Type": {
                  type: "string",
                },
                "Hours Per Diem": {
                  type: "number",
                },
                "Cash Advance": {
                  type: "number",
                },
                "Adjustment Amount": {
                  type: "number",
                },
                "Adjustment Reason": {
                  type: "string",
                },
                "Daily Total": {
                  type: "number",
                },
                Shift: {
                  type: "string",
                },
                "Created By": {
                  type: "string",
                },
              },
              ...result.map((entry) => ({
                ...entry,
                Date: moment(entry.Date).format("MM/DD/YYYY"),
                "Company ID": entry.Department,
                "EE Code": entry["Employee Code"],
                Name: entry["Employee"],
                "Non-Job Time":
                  entry["Non-Job Credited Time"] +
                  entry["Non-Job Non-Credited Time"],
              })),
            ],
          });
          const duplicates = findDuplicates(result);
          setIndexDuplicates(duplicates);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    monday,
    pivot,
    setData,
    periodType,
    setIndexDuplicates,
    customEndDate,
    customStartDate,
    authContext.currentUser.jobSourceId,
  ]);

  useEffect(() => {
    const customizeCell = (cell, data) => {
      if (indexDuplicates.has(data.rowIndex)) {
        cell.style["background-color"] = HOURS_RED;
      }
    };
    pivot.current.flexmonster.customizeCell(customizeCell);
  }, [indexDuplicates]);

  useEffect(() => {
    if (pivot?.current?.flexmonster && data?.length) {
      pivot.current.flexmonster.on("celldoubleclick", function (cell) {
        reportsHelper.navigateToWorkOrder(
          cell,
          data,
          NAME_COLUMN,
          null,
          navigate
        );
      });
    }
  }, [pivot, data, navigate]);

  const findDuplicates = (result) => {
    const repeated = new Set();

    for (let i = 0; i < result.length - 1; i++) {
      for (let j = i + 1; j < result.length; j++) {
        if (
          result[i]["Employee Code"] === result[j]["Employee Code"] &&
          result[i]["Date"] === result[j]["Date"]
        ) {
          repeated.add(i + 1);
          repeated.add(j + 1);
        }
      }
    }
    return repeated;
  };

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-end">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : null}
              <PeriodTypeSelector
                periodType={periodType}
                setPeriodType={setPeriodType}
              />
              {periodType.value === TYPE_WEEKLY ? (
                <WeekSelector
                  loading={loading}
                  monday={monday}
                  setMonday={setMonday}
                />
              ) : (
                <CustomPeriodSelector
                  defaultEndDate={customEndDate}
                  defaultStartDate={customStartDate}
                  onSubmit={(startDate, endDate) => {
                    setCustomStartDate(startDate);
                    setCustomEndDate(endDate);
                  }}
                />
              )}
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  //key={(monday + periodType.value).toLocaleString()}
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TravelAndLiving;
