import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Collapse, Row } from "reactstrap";
import ChangeOrderForm from "../../components/admin/ChangeOrderForm";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import { utilsHelper } from "../../helpers/utilsHelper";

const WorkOrderChangeOrders = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [selectedChangeOrder, setSelectedChangeOrder] = useState();

  const changeOrders = workOrderDetails.workOrder.changeOrders.sort((x, y) => {
    if (moment(x.crewWorkDay.date).isBefore(moment(y.crewWorkDay.date))) {
      return 1;
    }
    if (moment(y.crewWorkDay.date).isBefore(moment(x.crewWorkDay.date))) {
      return -1;
    }
    return x.id < y.id ? 1 : -1;
  });

  return (
    <Row>
      <Col sm="12">
        <h4 className="border-bottom pb-2">Change Orders</h4>
        {!changeOrders || !changeOrders.length ? (
          <div className="text-center text-muted mt-4 small">
            No data has been recorded
          </div>
        ) : (
          changeOrders.map((changeOrder) => {
            const isSelected = selectedChangeOrder === changeOrder.id;
            return (
              <div key={changeOrder.id}>
                <div className="my-3">
                  <Button
                    onClick={() =>
                      setSelectedChangeOrder(isSelected ? null : changeOrder.id)
                    }
                    style={{ width: "100%" }}
                  >
                    <div className="d-flex flex-row justify-content-between m-1 align-middle">
                      <span>
                        {`${utilsHelper.formatDate(
                          changeOrder.crewWorkDay.date,
                          "MM/DD/YYYY"
                        )} - ${changeOrder.user.firstName} ${
                          changeOrder.user.lastName
                        }`}
                      </span>
                      <FontAwesomeIcon
                        icon={isSelected ? faChevronDown : faChevronRight}
                        fixedWidth
                        className="my-1"
                      />
                    </div>
                  </Button>
                  <Collapse isOpen={isSelected} className="p-2">
                    <ChangeOrderForm changeOrder={changeOrder} />
                  </Collapse>
                </div>
              </div>
            );
          })
        )}
      </Col>
    </Row>
  );
};

export default WorkOrderChangeOrders;
