import moment from "moment";
import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Col,
  ListGroup,
  ListGroupItem,
  Input,
  FormText,
} from "reactstrap";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import { perDiemAdjustmentsApi } from "../../services/perDiemAdjustmentServices";
import InformationModal from "../InformationModal";
import Loader from "../Loader";
import { utilsHelper } from "../../helpers/utilsHelper";

const AdjustPerDiemModal = ({
  onClose,
  onSubmit,
  crewWorkDay,
  employeeId,
  perDiemEarned,
  employeeName,
}) => {
  const [workOrderDetails] = useWorkOrderDetails();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingBulk, setLoadingBulk] = useState(false);
  const [isGlobal, setIsGlobal] = useState(false);
  const [payloadAdjustment, setPayloadAdjustment] = useState({
    amount: "",
    reason: "",
  });
  const [globalAdjustment, setGlobalAdjustment] = useState({});
  const [currentAdjustment, setCurrentAdjustment] = useState({});

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const validateNotZero = (target) => {
    if (parseFloat(target.value) === 0) {
      target.setCustomValidity("Enter a amount other than zero.");
    } else {
      target.setCustomValidity("");
    }
  };

  const doSubmit = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);

      const payload = { ...payloadAdjustment };

      const isCreateGlobal = isGlobal && !globalAdjustment.id;
      const isCreateDay = !isGlobal && !currentAdjustment.id;
      const isCreate = isCreateGlobal || isCreateDay;

      if (isCreateGlobal) {
        payload.isGlobal = true;
        payload.employeeId = employeeId;
        payload.workOrderId = workOrderDetails.workOrder.id;
      } else if (isCreateDay) {
        payload.crewWorkDayId = crewWorkDay.id;
        payload.employeeId = employeeId;
        payload.workOrderId = workOrderDetails.workOrder.id;
      }

      let response = {};
      const path = isCreate ? "create" : "update";

      if (payloadAdjustment.touched) {
        response = await perDiemAdjustmentsApi[path](payload);
      }

      const limits = response.limits || [];
      const hasLimits = limits.length > 0;

      const dates = limits
        .map((limit) => moment(limit.date).format("MM/DD/YYYY"))
        .join(", ");

      const bodyModal = !hasLimits
        ? "Adjustment applied successfully."
        : `The per diem increase was successfully applied, except for those days where the per diem increase is $55: ${dates}`;

      setLoading(false);

      if (payloadAdjustment.touched) {
        setInformationModal({
          isOpen: true,
          title: "Adjust Per Diem",
          body: bodyModal,
          onClose: onSubmit,
        });
      } else {
        onSubmit();
      }
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Adjust Per Diem",
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const changeIncreaseType = (value) => {
    const isGlobal = value === "true";
    const adjustment = isGlobal ? globalAdjustment : currentAdjustment;

    setIsGlobal(isGlobal);
    setPayloadAdjustment({
      ...adjustment,
      amount: adjustment.amount || "",
      reason: adjustment.reason || "",
    });
  };

  useEffect(() => {
    const defaultGlobalAdjustment =
      workOrderDetails.workOrder.perDiemAdjustments.find(
        (a) => a.employeeId === employeeId && a.isGlobal
      );

    const defaultCurrentAdjustment = crewWorkDay.perDiemAdjustments.find(
      (perDiemAdjustment) => perDiemAdjustment.employeeId === employeeId
    );

    if (defaultGlobalAdjustment) {
      setGlobalAdjustment({ ...defaultGlobalAdjustment });
    }

    if (defaultCurrentAdjustment) {
      setCurrentAdjustment({ ...defaultCurrentAdjustment });
    }

    if (defaultGlobalAdjustment || defaultCurrentAdjustment) {
      const adjustment = defaultCurrentAdjustment || defaultGlobalAdjustment;

      setIsGlobal(adjustment.isGlobal || false);
      setPayloadAdjustment({
        ...adjustment,
        amount: adjustment.amount || "",
        reason: adjustment.reason || "",
      });
    }
  }, [crewWorkDay, employeeId, workOrderDetails.workOrder]);

  const clearPerDiem = async (event) => {
    event.preventDefault();

    setLoadingBulk(true);

    try {
      const items = [
        {
          employeeId: employeeId,
          deleted: true,
          id: payloadAdjustment.id,
          reason: payloadAdjustment.reason,
          workOrderId: workOrderDetails.workOrder.id,
          isGlobal: payloadAdjustment.isGlobal,
          amount: payloadAdjustment.amount,
          createdAt: moment().toISOString(),
          updatedAt: moment().toISOString(),
        },
      ];

      await perDiemAdjustmentsApi.editBulk({ items });

      setLoadingBulk(false);

      setInformationModal({
        isOpen: true,
        title: `Add Per Diem Increase`,
        body: `Per Diem Increase is deleted successfully.`,
        onClose: onSubmit,
      });
    } catch (error) {}
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Add Per Diem Increase</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <ListGroup>
            <ListGroupItem
              className="d-flex justify-content-between align-items-center py-2 border-bottom bg-lighter"
              tag="div"
            >
              <span className="font-weight-bold">Name:</span>
              <span>{employeeName}</span>
            </ListGroupItem>
            <ListGroupItem
              className="d-flex justify-content-between align-items-center py-2 border-bottom bg-lighter"
              tag="div"
            >
              <span className="font-weight-bold">Earned Amount:</span>
              <span>{utilsHelper.formatCurrency(perDiemEarned)}</span>
            </ListGroupItem>
          </ListGroup>
          <ListGroup className="mt-3">
            <ListGroupItem
              className="d-flex font-weight-bold align-items-center py-2 border-bottom bg-lighter small justify-content-center"
              tag="div"
            >
              Per Diem Increase
            </ListGroupItem>
            <ListGroupItem
              className="d-flex flex-column py-3 border-bottom"
              tag="div"
            >
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Increase for
                </Label>
                <Col sm={8} className="text-left">
                  <select
                    className="form-control"
                    type="select"
                    name="statusSelect"
                    id="statusSelect"
                    onChange={(e) => changeIncreaseType(e.target.value)}
                    value={isGlobal}
                    required
                  >
                    <option value={"false"}>
                      {moment(crewWorkDay.date).format("MM/DD/YYYY")}
                    </option>
                    <option value={"true"}>Duration of Job</option>
                  </select>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Amount
                </Label>
                <Col sm={8} className="text-left">
                  <Input
                    type="number"
                    placeholder="Enter the amount.."
                    max={99999}
                    required={true}
                    value={payloadAdjustment.amount}
                    onChange={(evt) => {
                      validateNotZero(evt.target);
                      setPayloadAdjustment({
                        ...payloadAdjustment,
                        amount: evt.target.value,
                        touched: true,
                      });
                    }}
                  />
                  <FormText>Enter only the $ amount of the increase.</FormText>
                </Col>
              </FormGroup>
              <FormGroup row className="mb-0">
                <Label sm={4} className="text-sm-left">
                  Reason
                </Label>
                <Col sm={8} className="text-left">
                  <Input
                    type="text"
                    placeholder="Enter the reason.."
                    required={true}
                    maxLength="100"
                    value={payloadAdjustment.reason}
                    onChange={(evt) => {
                      setPayloadAdjustment({
                        ...payloadAdjustment,
                        reason: evt.target.value,
                        touched: true,
                      });
                    }}
                  />
                </Col>
              </FormGroup>
            </ListGroupItem>
          </ListGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Discard
          </Button>

          <div className="d-flex" style={{ gap: "15px" }}>
            {payloadAdjustment.id &&
              (loadingBulk ? (
                <div className="min-width-50">
                  <Loader size="sm" />
                </div>
              ) : (
                <Button color={"gray"} onClick={clearPerDiem}>
                  Clear Per Diem
                </Button>
              ))}

            {loading ? (
              <div className="min-width-50">
                <Loader size="sm" />
              </div>
            ) : (
              <Button color={"warning"} type="submit">
                Save
              </Button>
            )}
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AdjustPerDiemModal;
