import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Label,
} from "reactstrap";

import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import InformationModal from "../InformationModal";
import Loader from "../Loader";
import ReactQuill from "react-quill";
import { contactApi } from "../../services/contactServices";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";

const REPLY_TO_EMAIL = "sallen@processbarron.com";

const SendInspectionReportEmailModal = ({ fileName, onClose, pdf }) => {
  const [workOrderDetails] = useWorkOrderDetails();

  const [loading, setLoading] = useState(false);

  const [content, setContent] = useState("");
  const [subject, setSubject] = useState("Inspection Report");

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const customersWithEmail = workOrderDetails.workOrder.customers.filter(
    (customer) => customer.email
  );

  const customersWithoutEmail = workOrderDetails.workOrder.customers.filter(
    (customer) => !customer.email
  );

  const onSendReport = async () => {
    if (!content.length) {
      return setInformationModal({
        isOpen: true,
        title: `Send Inspection Report by Email`,
        body: `Please add the email content.`,
      });
    }
    try {
      const attachment = new Blob([pdf], { type: "application/pdf" });
      setLoading(true);
      const payload = {
        recipients: customersWithEmail.map((c) => c.email).join(", "),
        content,
        subject,
        attachment,
        replyTo: REPLY_TO_EMAIL,
        fileName,
      };
      await contactApi.sendEmail(payload);
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Send Inspection Report by Email`,
        body: `Email${
          customersWithEmail.length > 1 ? "s" : ""
        } sent succesfully.`,
        onClose: () => onClose(),
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Send Inspection Report by Email`,
        body: `There was an error with your request.`,
      });
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        <span>Send Inspection report by email</span>
      </ModalHeader>
      <ModalBody className="d-flex flex-column align-items-center">
        {workOrderDetails.workOrder.customers.length ===
        customersWithoutEmail.length ? (
          <ListGroupItem
            className="d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold"
            tag="div"
          >
            None of the customers has an email associated
          </ListGroupItem>
        ) : (
          <ListGroup className="w-100">
            <ListGroupItem
              className="d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold"
              tag="div"
            >
              <span>{`PDF will be sent to the following email${
                customersWithEmail.length > 1 ? "s" : ""
              }`}</span>
            </ListGroupItem>
            {customersWithEmail.map((cus) => (
              <ListGroupItem
                className="d-flex justify-content-between align-items-center py-2"
                tag="div"
                key={cus.id}
              >
                <span>{cus.customerName}:</span>
                <span className="text-muted font-italic ml-1">{cus.email}</span>
              </ListGroupItem>
            ))}
            {customersWithoutEmail.length ? (
              <>
                <ListGroupItem
                  className="d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold"
                  tag="div"
                >
                  Missing emails
                </ListGroupItem>
                {customersWithoutEmail.map((cus) => (
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center py-2"
                    tag="div"
                  >
                    {cus.customerName}
                  </ListGroupItem>
                ))}
              </>
            ) : null}
          </ListGroup>
        )}
        {workOrderDetails.workOrder.customers.length !==
          customersWithoutEmail.length ||
        (workOrderDetails.workOrder.customers.length === 1 &&
          customersWithoutEmail.length === 1) ? (
          <ListGroup className="w-100 mt-2">
            <ListGroupItem
              className="d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold"
              tag="div"
            >
              <span>Email</span>
            </ListGroupItem>
            <ListGroupItem className="align-items-center py-2" tag="div">
              <Row className="mb-3 d-flex align-items-center">
                <Col sm="4">
                  <Label className="mb-0" for="subject">
                    Subject
                  </Label>
                </Col>
                <Col sm="8">
                  <Input
                    maxLength="100"
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="Enter the subject..."
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required={true}
                  />
                </Col>
              </Row>
              <Row className="mb-3 d-flex align-items-center">
                <Col sm="4">
                  <Label className="mb-0" for="subject">
                    Reply To Email
                  </Label>
                </Col>
                <Col sm="8">
                  <Input
                    maxLength="50"
                    type="email"
                    placeholder="Enter an email address..."
                    value={REPLY_TO_EMAIL}
                    disabled
                  />
                </Col>
              </Row>
              <div className="mt-3">
                <Col sm="12 border rounded">
                  <ReactQuill
                    placeholder="Type something..."
                    theme={"snow"}
                    value={content}
                    onChange={(content) => setContent(content)}
                  />
                </Col>
              </div>
            </ListGroupItem>
          </ListGroup>
        ) : null}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
        {loading ? (
          <div className="my-2">
            <Loader size="sm" />
          </div>
        ) : (
          <Button
            color={"primary"}
            onClick={onSendReport}
            disabled={
              workOrderDetails.workOrder.customers.length ===
                customersWithoutEmail.length ||
              (workOrderDetails.workOrder.customers.length === 1 &&
                customersWithoutEmail.length === 1)
            }
          >
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
            <span>Confirm</span>
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default SendInspectionReportEmailModal;
