import {
  faMinus,
  faPen,
  faPlus,
  faSearch,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import Badge from "reactstrap/lib/Badge";
import ListGroup from "reactstrap/lib/ListGroup";
import ListGroupItem from "reactstrap/lib/ListGroupItem";
import BaseCrewModal from "../../components/admin/BaseCrewModal";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import ConfirmationModal from "../../components/ConfirmationModal";
import InformationModal from "../../components/InformationModal";
import Loader from "../../components/Loader";
import { ACTIONS, useCrews } from "../../providers/crewsProvider";

import { crewsApi } from "../../services/crewServices";
import { utilsHelper } from "../../helpers/utilsHelper";
import { useAuth } from "../../providers/authProvider";

const Crews = () => {
  const [authContext] = useAuth();
  const [crewsContext, setCrewsContext] = useCrews();

  const [loading, setLoading] = useState(true);
  const [crewsLoading, setCrewsLoading] = useState(true);
  const [createBaseCrewModal, setCreateBaseCrewModal] = useState(false);
  const [editBaseCrewModal, setEditBaseCrewModal] = useState(false);
  const [editCrewId, setEditCrewId] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onCreate = () => setCreateBaseCrewModal(true);

  const onEdit = (crewId) => {
    setEditCrewId(crewId);
    setEditBaseCrewModal(true);
  };

  const onClose = () => {
    setCreateBaseCrewModal(false);
    setEditBaseCrewModal(false);
    setCrewsContext({ action: ACTIONS.REFRESH });
  };

  const onDeleteCrew = (crewId) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => {
        setCrewsLoading({ [crewId]: true });
        setConfirmationModal(initConfirmationModal);
        crewsApi
          .deleteCrew({ id: crewId })
          .then(() => {
            setCrewsLoading({ [crewId]: false });
            setCrewsContext({ action: ACTIONS.REFRESH });
          })
          .catch(() => {
            setCrewsLoading({ [crewId]: false });
          });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Crew",
      body: `<span class="text-center text-danger">Do you confirm you want to remove this base crew?</span>`,
    });
  };

  const CellData = ({ crew }) => {
    const members = crew.employeeCrews
      .filter((ec) => !ec.isCrewLead && !ec.disabledAt)
      .map((member) => {
        const { employee, role } = member;
        return {
          id: employee.id,
          name: `${employee.firstName} ${employee.lastName}`,
          role: role,
        };
      });

    return (
      <Col className="col-12 px-0">
        <ListGroup color="primary" className="border rounded mt-2">
          <ListGroupItem
            className="cursor-default d-flex justify-content-between align-items-center py-2 border-bottom border-right-0 border-left-0 font-weight-bold text-body bg-lighter"
            tag="div"
          >
            <span className="px-0 col-4"></span>
            <span className="text-underline px-0 col-4 text-center">
              Members
            </span>
            <div className="min-width-50 px-0 col-4 d-flex justify-content-end align-items-center">
              {crewsLoading[crew.id] ? (
                <Loader size="sm" align="end" />
              ) : !utilsHelper.isReadOnly(authContext) ? (
                <>
                  <Button
                    color="warning"
                    className="rounded d-flex align-items-center"
                    size="sm"
                    onClick={() => onEdit(crew.id)}
                  >
                    <FontAwesomeIcon icon={faPen} className="mr-2" />
                    <span>Edit</span>
                  </Button>
                  <Button
                    color="danger"
                    className="rounded ml-2 d-flex align-items-center"
                    size="sm"
                    onClick={() => onDeleteCrew(crew.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} className="mr-2" />
                    <span>Delete</span>
                  </Button>
                </>
              ) : null}
            </div>
          </ListGroupItem>
          {members.length ? (
            members.map((member) => (
              <ListGroupItem
                key={`${crew.id}-${member.id}`}
                className="border-right-0 border-left-0 d-flex justify-content-between align-items-center"
              >
                <span>{member.name}</span>
                <Badge color="info">{member.role.name}</Badge>
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem className="d-flex justify-content-between align-items-center">
              <span>No members to show</span>
            </ListGroupItem>
          )}
        </ListGroup>
      </Col>
    );
  };

  // Load Base Crews
  useEffect(() => {
    setLoading(true);
    crewsApi
      .getCrews({
        isBase: true,
        search: crewsContext.search,
        page: crewsContext.page - 1,
        pageSize: crewsContext.sizePerPage,
        sortBy: crewsContext.sortBy,
        direction: crewsContext.direction,
      })
      .then((crews) => {
        setCrewsContext({
          action: ACTIONS.GET_CREWS_SUCCESS,
          payload: { crews },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    setCrewsContext,
    crewsContext.sortBy,
    crewsContext.direction,
    crewsContext.sizePerPage,
    crewsContext.page,
    crewsContext.search,
    crewsContext.refresh,
  ]);

  const Cell = ({ crew }) => {
    const [crewsContext, setCrewsContext] = useCrews();

    const employeeCrewSupervisor = crew.employeeCrews
      .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec))
      .find((employeeCrew) => employeeCrew.isCrewLead);

    return (
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <FontAwesomeIcon
            icon={crewsContext.cellOpen[crew.id] ? faMinus : faPlus}
            className="cursor-pointer text-primary"
            onClick={() =>
              setCrewsContext({
                action: ACTIONS.TOOGLE_CELL,
                payload: { crew },
              })
            }
          />
          {employeeCrewSupervisor ? (
            <div className="ml-3 d-flex justify-content-between align-items-center flex-grow-1">
              <span>{`${employeeCrewSupervisor.employee.firstName} ${employeeCrewSupervisor.employee.lastName}`}</span>
              <Badge>{employeeCrewSupervisor.role.name}</Badge>
            </div>
          ) : (
            <span className="ml-3">N/A</span>
          )}
        </div>
        {crewsContext.cellOpen[crew.id] ? <CellData crew={crew} /> : null}
      </div>
    );
  };

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={[
            {
              accessor: "id",
              Header: "Crew Lead",
              headerProps: { className: "text-truncate" },
              Cell: (rowData) => <Cell crew={rowData.row.original} />,
            },
          ]}
          data={crewsContext.crews?.data || []}
          pageSize={crewsContext.sizePerPage}
        >
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Crews</h3>
                <small className="text-muted ml-2 pt-1">
                  ({crewsContext.crews?.count || 0})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup size="m" className="mr-3">
                  <Input
                    maxLength="50"
                    placeholder="Search for.."
                    value={crewsContext.search}
                    onChange={(evt) =>
                      setCrewsContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-primary text-white border-left-0 border-primary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                {!utilsHelper.isReadOnly(authContext) ? (
                  <Button
                    size="sm"
                    className="mr-3 rounded-circle d-flex custom-rounded-button"
                    color="primary"
                    onClick={onCreate}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                ) : null}
                <Button
                  size="sm"
                  className="mr-3 rounded-circle d-flex custom-rounded-button"
                  color="primary"
                  onClick={() =>
                    setCrewsContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              <AdvanceTable
                table
                isLoading={loading}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={crewsContext.crews?.count || 0}
                pageCount={crewsContext.crews.totalPages}
                currentPage={crewsContext.page - 1}
                onPageChange={(page) =>
                  setCrewsContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={crewsContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setCrewsContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {createBaseCrewModal ? (
        <BaseCrewModal
          onSubmit={() => {
            setInformationModal({
              isOpen: true,
              title: "Create Base Crew",
              body: "Base Crew created successfully.",
            });
            onClose();
          }}
          onClose={onClose}
        />
      ) : editBaseCrewModal ? (
        <BaseCrewModal
          crewId={editCrewId}
          onSubmit={() => {
            setInformationModal({
              isOpen: true,
              title: "Create Base Crew",
              body: "Base Crew updated successfully.",
            });
            onClose();
          }}
          onClose={onClose}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default Crews;
