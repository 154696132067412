import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../helpers/apiHelper";

export const crewWorkDayEquipmentsApi = {
  updateCrewWorkOrderEquipments: (data) => {
    return axios(`/api/crewworkdayequipments/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
