import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import moment from "moment";
import { apiHelper } from "../helpers/apiHelper";

export const commonApi = {
  getSettings: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(
      data.id ? `/api/settings/${data.id}` : `/api/settings?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  setSetting: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/settings/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data: data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getExpenseOptions: (data) => {
    return axios(`/api/expenseOption`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
