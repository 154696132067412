import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Input,
  Label,
  Form,
} from "reactstrap";
import Loader from "../../Loader";
import ConfirmationModal from "../../ConfirmationModal";
import InformationModal from "../../InformationModal";
import { injuryReportAttachmentApi } from "../../../services/injuryReportAttachmentServices";
import InjuryReportNote from "./InjuryReportNote";
import InjuryReportEditNoteModal from "./InjuryReportEditNoteModal";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const InjuryReportNoteModal = ({ onCloseModal, injuryReportId }) => {
  const [loading, setLoading] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [editNoteModal, setEditNoteModal] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const notes =
        await injuryReportAttachmentApi.getInjuryReportAttachmentQuery({
          injuryReportId,
          type: "NOTE",
        });
      setNotes(notes);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Injury Report Notes`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  }, [injuryReportId]);

  const doSubmit = async () => {
    try {
      setLoading(true);
      await injuryReportAttachmentApi.saveInjuryReportAttachment({
        type: "NOTE",
        injuryReportId: injuryReportId,
        content: newNote,
      });
      fetchData();
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Injury Report Notes`,
        body: `Note created successfully`,
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
          onCloseModal();
        },
      });
      setNewNote("");
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Injury Report Notes`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const doDelete = (note) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => {
        setConfirmationModal(initConfirmationModal);
        submitDelete(note);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Note from Injury Report",
      body: `Are you sure you want to delete this note?`,
    });
  };

  const submitDelete = async (note) => {
    try {
      setLoading(true);
      await injuryReportAttachmentApi.deleteInjuryReportAttachment(note);
      fetchData();
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Injury Report Note`,
        body: `Note deleted successfully`,
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
        },
      });
      setNewNote("");
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Injury Report Notes`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onCloseModal}>
      &times;
    </Button>
  );

  return confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : editNoteModal ? (
    <InjuryReportEditNoteModal
      noteReport={editNoteModal}
      onCloseModal={() => setEditNoteModal(false)}
      setInformationModal={setInformationModal}
      fetchData={() => fetchData()}
      onClosePrincipalModal={onCloseModal}
    />
  ) : (
    <Modal isOpen={true} onClosed={onCloseModal}>
      <ModalHeader close={closeBtn}>Injury Report Notes</ModalHeader>
      <ModalBody className="text-center">
        {loading ? (
          <Loader size={"sm"} />
        ) : (
          <Row className="inner-section">
            {notes?.length ? (
              <div className="px-2 mb-3 col-12">
                {notes.map((note) => (
                  <InjuryReportNote
                    key={note.id}
                    note={note}
                    doDelete={() => doDelete(note)}
                    fetchData={() => fetchData()}
                    doEdit={() => setEditNoteModal(note)}
                  />
                ))}
              </div>
            ) : (
              <div className="col-12 small">No notes have been created</div>
            )}
            <Form
              onSubmit={doSubmit}
              className="my-2 mx-2 w-100 d-flex flex-column border-top pt-3"
            >
              <Label className="text-left font-weight-bold">New Note</Label>
              <Input
                required={true}
                type="textarea"
                rows={4}
                placeholder="Enter the note..."
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
              />
              <Button
                size="sm"
                className="mt-2 mr-auto rounded"
                disabled={loading}
                color="primary"
                type="submit"
              >
                <span>Save</span>
              </Button>
            </Form>
          </Row>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button className="ml-auto" color={"secondary"} onClick={onCloseModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InjuryReportNoteModal;
