import React, { useReducer, createContext, useContext } from "react";

const MAX_PAGE_SIZE = 100;

const initialState = {
  crews: {},
  sizePerPage: 10,
  page: 1,
  search: "",
  sortBy: "id",
  direction: "asc",
  cellOpen: {},
};

const ACTIONS = {
  SORT: "SORT",
  SEARCH: "SEARCH",
  REFRESH: "REFRESH",
  PAGE_CHANGE: "PAGE_CHANGE",
  PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
  GET_CREWS_SUCCESS: "GET_CREWS_SUCCESS",
  TOOGLE_CELL: "TOOGLE_CELL",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.TOOGLE_CELL: {
      const { payload } = data;
      const { crew } = payload;

      return {
        ...state,
        cellOpen: {
          ...state.cellOpen,
          [crew.id]: !state.cellOpen[crew.id],
        },
      };
    }

    case ACTIONS.GET_CREWS_SUCCESS: {
      const { payload } = data;
      const { crews } = payload;

      return {
        ...state,
        crews,
      };
    }

    case ACTIONS.SORT: {
      const { payload } = data;
      const { sortBy, direction } = payload;

      return {
        ...state,
        sortBy,
        direction,
        page: 1,
      };
    }

    case ACTIONS.SEARCH: {
      const { payload } = data;
      const { search } = payload;

      return {
        ...state,
        search,
        page: 1,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case ACTIONS.PAGE_CHANGE: {
      const { payload } = data;
      const { page } = payload;
      return {
        ...state,
        page,
      };
    }

    case ACTIONS.PAGE_SIZE_CHANGE: {
      const { payload } = data;
      const { sizePerPage } = payload;
      return {
        ...state,
        sizePerPage,
        page: 1,
      };
    }

    default:
      throw new Error();
  }
};

const CrewsContext = createContext(initialState);

const CrewsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <CrewsContext.Provider value={stateAndDispatch}>
      {children}
    </CrewsContext.Provider>
  );
};

export const useCrews = () => useContext(CrewsContext);

export { CrewsContext, CrewsProvider, ACTIONS, MAX_PAGE_SIZE };
