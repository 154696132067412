import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import moment from "moment";
import { apiHelper } from "../helpers/apiHelper";

export const usersApi = {
  getUsers: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(data.id ? `/api/users/${data.id}` : `/api/users?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  save: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/users`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  update: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/users/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  delete: (data) => {
    return axios(`/api/users/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((error) => ({ error }));
  },
  changePassword: ({ id, password }) => {
    return axios(`/api/users/${id}/password`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data: { password },
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  forgotPassword: (data) => {
    return axios(`/api/users/password/reset`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  recoverPassword: (data) => {
    return axios(`/api/users/password/recover`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => {
        if (err.isAxiosError) {
          throw Error(err.response.data[0].param);
        }
        throw Error(err);
      });
  },
};
