import React, { useEffect, useState } from "react";

import { useAuth } from "../../../providers/authProvider";

import placeholder from "../../../assets/img/placeholder.png";
import { CustomInput } from "reactstrap";
import { jobsApi } from "../../../services/jobServices";
import { usersApi } from "../../../services/userServices";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { useLocation } from "react-router-dom";
import { utilsHelper } from "../../../helpers/utilsHelper";

const EXECUTIVE_USER_ROLE = "Executive";
const MANAGER_USER_ROLE = "Manager";
const READ_ONLY_USER_ROLE = "Read Only";

const SideBarUser = () => {
  const [authContext] = useAuth();
  const location = useLocation();

  const [user, setUser] = useState({});
  const [sources, setSources] = useState([]);
  const [loading, setLoading] = useState();
  const isReadOnlyUser = user?.role?.name === READ_ONLY_USER_ROLE;

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const updateData = async () => {
      setLoading(true);
      try {
        await usersApi.update(user);
        setLoading(false);
        if (location.pathname.indexOf("/details") > -1) {
          window.location.href = `${window.location.origin}/work-orders/all`;
        } else {
          window.location.reload();
        }
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Update User",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    };
    if (
      user.jobSourceId &&
      authContext.currentUser.jobSourceId &&
      user.jobSourceId !== authContext.currentUser.jobSourceId
    ) {
      updateData();
    }
  }, [location.pathname, user, authContext.currentUser.jobSourceId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await jobsApi.getJobSources();
        setLoading(false);
        setSources(data);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (authContext.currentUser) {
      setUser({
        ...authContext.currentUser,
      });
    }
  }, [authContext.currentUser, setUser]);

  const onJobSourceChange = (event) =>
    setUser({
      ...user,
      jobSourceId: event.currentTarget.value,
    });

  return (
    <div className="sidebar-user p-3 d-flex flex-column align-items-center justify-content-center">
      <div
        className="rounded-circle bg-lightgreen img-thumbnail d-flex align-items-center justify-content-center"
        style={{
          backgroundPosition: "center",
          backgroundImage: `url(${placeholder})`,
          width: "96px",
          height: "96px",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <span className="text-muted mt-1 font-weight-bold">
        {`${user?.firstName} ${user?.lastName}`}
      </span>
      <small className="text-muted">{user?.role?.name || ""}</small>
      {user?.role?.name === EXECUTIVE_USER_ROLE ||
      user?.role?.name === MANAGER_USER_ROLE ? (
        <div className="min-width-50 mt-3">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <CustomInput
              required
              id="companySelect"
              type="select"
              name="companySelect"
              onChange={onJobSourceChange}
              value={user.jobSourceId}
            >
              {sources
                .sort((x, y) => (x.sourceKey < y.sourceKey ? 1 : -1))
                .map((source) => (
                  <option key={source.id} value={source.id}>
                    {source.sourceKey}
                  </option>
                ))}
            </CustomInput>
          )}
        </div>
      ) : (
        <small className="text-muted">
          {isReadOnlyUser ? "ALL" : user.originalJobSource?.sourceKey}
        </small>
      )}
      {utilsHelper.getCurrentEnvironment() ? (
        <span className="badge mb-n3 mt-2 badge badge-warning text-capitalize">
          {utilsHelper.getCurrentEnvironment()}
        </span>
      ) : null}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </div>
  );
};

export default SideBarUser;
