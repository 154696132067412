import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import { apiHelper } from "../helpers/apiHelper";
import moment from "moment";

export const workOrdersApi = {
  getWorkOrderStatuses: () => {
    return axios(`/api/work-orders-status`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getWorkOrders: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/work-orders?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  codeTime: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/work-orders/${data.id}/code-time`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  markAsRushJob: (data) => {
    return axios(`/api/work-orders/set-as-rush/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "patch",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  markAsOneDayJob: (data) => {
    return axios(`/api/work-orders/set-as-oneday/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "patch",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  markAsRegularJob: (data) => {
    return axios(`/api/work-orders/set-as-regular/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "patch",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createWorkOrder: (data) => {
    return axios(`/api/work-orders`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateWorkOrder: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/work-orders/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  completeWorkOrder: (data) => {
    return axios(`/api/work-orders/${data.id}/complete`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "patch",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateWorkOrderStatus: (data) => {
    return axios(`/api/work-orders/${data.id}/statusId`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "patch",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getWorkOrder: (id, token) => {
    const request = {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    };
    if (token) {
      request.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return axios(`/api/work-orders/${id}`, request)
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getWorkOrdersDetailsCWR: (workOrderId) => {
    const request = {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    };
    return axios(`/api/work-orders/workorderdetailscwr/${workOrderId}`, request)
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createTentativeWorkOrder: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/work-orders/full`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => {
        const workOrderId = result.data.id;
        const isActive = true;
        ["DAY", "NIGHT"].forEach((shift) => {
          [
            { roleId: 1, count: data.superintendents },
            { roleId: 2, count: data.crewmembers },
          ].forEach(({ roleId, count }) => {
            axios(`/api/workOrderCrew`, {
              baseURL: config.apiURL,
              withCredentials: true,
              headers: {
                "web-version": `web-${process.env.REACT_APP_VERSION},}`,
              },
              method: "post",
              data: {
                workOrderId,
                shift,
                roleId,
                count,
                isActive,
                createdAt: moment().toISOString(),
              },
            }).catch((err) => apiHelper.handleError(err));
          });
        });
      })
      .catch((err) => apiHelper.handleError(err));
  },
  groupWorkOrders: (data) =>
    axios(`/api/work-orders/group`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  addToGroup: (data) =>
    axios(`/api/work-orders/${data.id}/group/${data.addId}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "patch",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  ungroupWorkOrders: (data) =>
    axios(`/api/work-orders/${data.id}/ungroup/${data.removedId}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "patch",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  fetchExpensesReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/expenses/${data.id}?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
      responseType: data.format === "json" ? "json" : "blob",
    })
      .then((response) => {
        if (data.format === "json") {
          return response.data;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `expenses-report-${data.weekStart || "all"}.csv`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => apiHelper.handleError(err));
  },
  fetchJobTimeReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/time-travel/${data.id}?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
      responseType: data.format === "json" ? "json" : "blob",
    })
      .then((response) => {
        if (data.format === "json") {
          return response.data;
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `job-time-report-${data.weekStart || "all"}.csv`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => apiHelper.handleError(err));
  },
  getWorkOrderLaborSummary: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/work-order-labor-summary?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getWorkOrderWorkTimes: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/work-time?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getChecklistItems: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/check-list-items?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getWorkOrderChecklist: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/work-order-check-lists?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateWorkOrderChecklist: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/work-order-check-lists/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createWorkOrderChecklist: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/work-order-check-lists`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateWorkOrderCrew: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/work-order-crews/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getPerDiemEarnedByCrewWorkDay: (workOrderId) => {
    return axios(`/api/work-orders/get-perdiem/${workOrderId}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => {
        apiHelper.handleError(err);
      });
  },
};
