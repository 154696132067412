import React, { useState } from "react";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import moment from "moment";

import InformationModal from "../InformationModal";
import { useAuth } from "../../providers/authProvider";
import { generalExpensesApi } from "../../services/generalExpensesServices";
import { expensesApi } from "../../services/expensesServices";
import Loader from "../Loader";

const STATUS_DECLINED = "declined";

const DeclineExpenseModal = ({ expense, onClose, onSubmit }) => {
  const [authContext] = useAuth();

  const [loading, setLoading] = useState(false);
  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const [declinedReason, setDeclinedReason] = useState();

  const doSubmit = async (evt) => {
    evt.preventDefault();
    try {
      setLoading(true);
      const localExpense = { ...expense };
      localExpense.approvedAt = null;
      localExpense.approvedBy = null;
      localExpense.status = STATUS_DECLINED;
      localExpense.declinedReason = declinedReason;
      localExpense.declinedAt = moment().toISOString();
      localExpense.declinedBy = authContext.currentUser.id;
      await (localExpense.isGeneral
        ? generalExpensesApi.updateGeneralExpense(localExpense)
        : expensesApi.updateExpense(localExpense));
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Decline Expense`,
        body: `Expense declined successfully`,
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
          onSubmit();
        },
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Decline Expense",
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Decline Expense</ModalHeader>
      {loading ? (
        <div className="my-2">
          <Loader size="sm" />
        </div>
      ) : (
        <Form onSubmit={doSubmit}>
          <ModalBody>
            <FormGroup className="mb-0">
              <Label for="declinedReason">
                <span>Declined Reason</span>
                <span className="text-danger ml-1">*</span>
              </Label>
              <Input
                required={true}
                id="declinedReason"
                rows={4}
                placeholder="Enter the reason ..."
                type="textarea"
                value={declinedReason || ""}
                onChange={(e) => setDeclinedReason(e.currentTarget.value)}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <Button color={"primary"} onClick={onClose}>
              Close
            </Button>
            <Button color={"danger"} type="submit">
              Confirm
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

export default DeclineExpenseModal;
