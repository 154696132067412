import {
  faPen,
  faSearch,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";

import GeneralWorkTimeModal from "../../components/admin/generalworktimes/GeneralWorkTimeModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import InformationModal from "../../components/InformationModal";

import {
  ACTIONS,
  useGeneralWorkTimes,
} from "../../providers/generalWorkTimesProvider";
import { generalWorkTimesApi } from "../../services/generalWorkTimeServices";
import { utilsHelper } from "../../helpers/utilsHelper";
import { employeesApi } from "../../services/employeeServices";
import WeekSelector from "../../components/admin/WeekSelector";
import moment from "moment";
import PeriodTypeSelector from "../../components/admin/PeriodTypeSelector";
import CustomPeriodSelector from "../../components/admin/CustomPeriodSelector";
import Loader from "../../components/Loader";
import SelectWrapper from "../../components/admin/SelectWrapper";
import { useAuth } from "../../providers/authProvider";

const MAX_PAGE_SIZE = 30;
const EMPLOYEE_DEFAULT_TYPE = 1;

const columns = (onEdit, onDelete, IS_READ_ONLY) => {
  const cols = [
    {
      accessor: "employee.id",
      Header: "Employee",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      width: 250,
      Cell: (rowData) => {
        return utilsHelper.getEmployeeLabelWithPreferredName(
          rowData.row.original.employee
        );
      },
    },
    {
      accessor: "generalWorkTimeType.name",
      Header: "Work Time Type",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      width: 150,
    },
    {
      accessor: "date",
      Header: "Date",
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      width: 100,
      Cell: (rowData) => {
        return (
          <Badge color="primary">{rowData.row.original.date || "-"}</Badge>
        );
      },
    },
    {
      accessor: "hours",
      Header: "Hours",
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      width: 100,
    },
    {
      accessor: "perDiemSuplemented",
      Header: "Per Diem Suplemented",
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      width: 150,
      Cell: (rowData) => {
        return (
          <Badge
            color={
              rowData.row.original.perDiemSuplemented ? "success" : "danger"
            }
          >
            {rowData.row.original.perDiemSuplemented ? "Yes" : "No"}
          </Badge>
        );
      },
    },
    {
      accessor: "perDiemAmount",
      Header: "Per Diem Amount",
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      width: 150,
      Cell: (rowData) => {
        return rowData.row.original.perDiemAmount
          ? utilsHelper.formatCurrency(
              rowData.row.original.perDiemAmount?.amount
            )
          : "Default";
      },
    },
    {
      accessor: "user.id",
      Header: "Created By",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      width: 200,
      Cell: (rowData) => {
        return `${rowData.row.original.user.firstName} ${rowData.row.original.user.lastName}`;
      },
    },
  ];
  if (!IS_READ_ONLY) {
    cols.push({
      accessor: "id",
      Header: "Actions",
      disableSortBy: true,
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      width: 150,
      Cell: (rowData) => (
        <div className="d-flex align-items-center justify-content-center">
          <Button
            size="sm"
            className="rounded d-flex align-items-center"
            color="warning"
            onClick={() => onEdit(rowData.row.original)}
          >
            <FontAwesomeIcon icon={faPen} className="mr-2" />
            <span>Edit</span>
          </Button>{" "}
          <Button
            size="sm"
            className="rounded d-flex align-items-center ml-2"
            color="danger"
            onClick={() => onDelete(rowData.row.original)}
          >
            <FontAwesomeIcon icon={faTrash} className="mr-2" />
            <span>Delete</span>
          </Button>
        </div>
      ),
    });
  }
  return cols;
};

const TYPE_WEEKLY = "TYPE_WEEKLY";

const NonJobTimes = () => {
  const [authContext] = useAuth();
  const [generalWorkTimesContext, setGeneralWorkTimesContext] =
    useGeneralWorkTimes();

  const [loading, setLoading] = useState(true);

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [employee, setEmployee] = useState();

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [createGeneralWorkTimeModal, setCreateGeneralWorkTimeModal] =
    useState(false);

  const [editGeneralWorkTimeModal, setEditGeneralWorkTimeModal] =
    useState(false);

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          generalWorkTimesContext.sortBy === sortBy?.id &&
          generalWorkTimesContext.direction === direction
        ) {
          return;
        }
        setGeneralWorkTimesContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setGeneralWorkTimesContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [
      generalWorkTimesContext.direction,
      generalWorkTimesContext.sortBy,
      setGeneralWorkTimesContext,
    ]
  );

  const onEdit = (generalWorkTime) => {
    setEditGeneralWorkTimeModal(generalWorkTime.id);
  };

  const onDelete = (generalWorkTime) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          await generalWorkTimesApi.deleteGeneralWorkTime(generalWorkTime);
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "General Work Time",
            body: "General Work Time Deleted Successfully",
            onClose: () => {
              setInformationModal({ isOpen: false, title: "", body: "" });
              setGeneralWorkTimesContext({
                action: ACTIONS.REFRESH,
              });
            },
          });
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "General Work Time",
            body:
              err?.response?.data[0]?.msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      confirmColor: "danger",
      title: "Delete General Work Time",
      body: `<span class="text-center">Do you confirm you want to delete this General Work Time?</span>`,
    });
  };

  const setGeneralWorkTimesContextCb = useCallback(
    (data) => setGeneralWorkTimesContext(data),
    [setGeneralWorkTimesContext]
  );

  useEffect(() => {
    setLoading(true);
    const condition = {
      employeeId: employee?.id,
      search: generalWorkTimesContext.search,
      page: generalWorkTimesContext.page - 1,
      pageSize: generalWorkTimesContext.sizePerPage,
      sortBy: generalWorkTimesContext.sortBy,
      direction: generalWorkTimesContext.direction,
      jobSourceId: authContext.currentUser.jobSourceId,
    };
    if (periodType.value === TYPE_WEEKLY) {
      condition.startDate = moment(monday).format("YYYY-MM-DD");
      condition.endDate = moment(monday).endOf("isoWeek").format("YYYY-MM-DD");
    } else {
      condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
      condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
    }
    generalWorkTimesApi
      .getGeneralWorkTimes(condition)
      .then((generalWorkTimes) => {
        setGeneralWorkTimesContextCb({
          action: ACTIONS.GET_GENERAL_WORK_TIMES_SUCCESS,
          payload: { generalWorkTimes },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    periodType,
    monday,
    employee,
    setGeneralWorkTimesContextCb,
    generalWorkTimesContext.sortBy,
    generalWorkTimesContext.direction,
    generalWorkTimesContext.sizePerPage,
    generalWorkTimesContext.page,
    generalWorkTimesContext.search,
    generalWorkTimesContext.refresh,
    customEndDate,
    customStartDate,
    authContext.currentUser.jobSourceId,
  ]);

  const formatData = (data) =>
    data.map((d) => ({
      ...d,
      date: moment(d.date).format("MM/DD/YYYY"),
    }));

  const IS_READ_ONLY = utilsHelper.isReadOnly(authContext);

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns(onEdit, onDelete, IS_READ_ONLY)}
          data={formatData(
            generalWorkTimesContext.generalWorkTimes?.data || []
          )}
          pageSize={generalWorkTimesContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: generalWorkTimesContext.sortBy,
            direction: generalWorkTimesContext.direction,
          }}
        >
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="text-dark flex-grow-1 d-flex align-items-center flex-shrink-0">
                <h3 className="mb-0 ">Non Job Time</h3>
                <small className="text-muted ml-2 pt-1">
                  ({generalWorkTimesContext.generalWorkTimes?.count || 0})
                </small>
              </div>
              <div
                className="d-flex align-items-center justify-content-end flex-wrap"
                style={{ minWidth: 1000 }}
              >
                <div className="ml-3" style={{ minWidth: 280 }}>
                  <SelectWrapper
                    entity="employee"
                    formatItemFunction={
                      utilsHelper.getEmployeeOptionWithPreferredName
                    }
                    fetchParameters={{
                      type: EMPLOYEE_DEFAULT_TYPE,
                      pageSize: MAX_PAGE_SIZE,
                      isActive: "any",
                      jobSourceId: authContext.currentUser.jobSourceId,
                    }}
                    fetchFunction={employeesApi.getEmployees}
                    defaultSelected={employee}
                    onSelected={setEmployee}
                  />
                </div>
                {loading ? (
                  <div className="min-width-50">
                    <Loader size="sm" className="mr-3" />
                  </div>
                ) : null}
                <PeriodTypeSelector
                  periodType={periodType}
                  setPeriodType={setPeriodType}
                />
                {periodType.value === TYPE_WEEKLY ? (
                  <WeekSelector
                    loading={loading}
                    monday={monday}
                    setMonday={setMonday}
                  />
                ) : (
                  <CustomPeriodSelector
                    defaultEndDate={customEndDate}
                    defaultStartDate={customStartDate}
                    onSubmit={(startDate, endDate) => {
                      setCustomStartDate(startDate);
                      setCustomEndDate(endDate);
                    }}
                  />
                )}
                <div className="ml-3">
                  <InputGroup size="m">
                    <Input
                      maxLength="50"
                      placeholder="Search for.."
                      value={generalWorkTimesContext.search}
                      onChange={(evt) =>
                        setGeneralWorkTimesContext({
                          action: ACTIONS.SEARCH,
                          payload: { search: evt.target.value },
                        })
                      }
                    />
                    <InputGroupText className="search-input input-group-text bg-primary text-white border-left-0 border-primary">
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroupText>
                  </InputGroup>
                </div>
                <Button
                  size="sm"
                  className="ml-3 rounded-circle d-flex custom-rounded-button"
                  color="primary"
                  onClick={() =>
                    setGeneralWorkTimesContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              <AdvanceTable
                table
                isLoading={loading}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={
                  generalWorkTimesContext.generalWorkTimes?.count || 0
                }
                pageCount={generalWorkTimesContext.generalWorkTimes.totalPages}
                currentPage={generalWorkTimesContext.page - 1}
                onPageChange={(page) =>
                  setGeneralWorkTimesContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={generalWorkTimesContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setGeneralWorkTimesContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {createGeneralWorkTimeModal ? (
        <GeneralWorkTimeModal
          onSubmit={() => {
            setCreateGeneralWorkTimeModal(false);
            setGeneralWorkTimesContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => {
            setCreateGeneralWorkTimeModal(false);
          }}
        />
      ) : editGeneralWorkTimeModal ? (
        <GeneralWorkTimeModal
          generalWorkTimeId={editGeneralWorkTimeModal}
          onClose={() => {
            setEditGeneralWorkTimeModal(false);
          }}
          onSubmit={() => {
            setEditGeneralWorkTimeModal(false);
            setGeneralWorkTimesContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default NonJobTimes;
