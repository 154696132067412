import moment from "moment";

export const reportsHelper = {
  applyFilters: (flexmonster, data, columns) => {
    const selectedFilters = {};
    columns.forEach((column) => {
      const selected =
        flexmonster
          .getFilter(column)
          ?.members?.map((member) => {
            const regexp = new RegExp(`${column.toLowerCase()}.\\[(.+)\\]`);
            const match = member.match(regexp);
            if (match) {
              return match[1];
            }
            return null;
          })
          .filter(Boolean) || [];
      selectedFilters[column] = selected;
    });
    const filteredData = data.filter((entry) => {
      const matchingColumns = columns.filter((column) =>
        selectedFilters[column].length
          ? selectedFilters[column].indexOf(entry[column].toLowerCase()) > -1
          : true
      );
      return matchingColumns.length === columns.length;
    });
    return filteredData;
  },
  navigateToWorkOrder: (cell, data, nameColumn, onClose, navigate) => {
    if (
      cell.hierarchy?.uniqueName === nameColumn &&
      cell.label !== "(blank)" &&
      cell.label !== "-"
    ) {
      const workOrderId =
        data && cell.rowIndex - 1 < data.length
          ? data[cell.rowIndex - 1].workOrderId ||
            data[cell.rowIndex - 1].WorkOrderId ||
            data[cell.rowIndex - 1].inspectionTypeWorkOrder?.workOrder?.id ||
            data[cell.rowIndex - 1].id
          : null;
      if (workOrderId) {
        if (onClose) {
          onClose();
        }
        navigate(`/work-orders/details/${workOrderId}`);
      }
    }
  },
  getFileName: (monday, perWeek, reportName) => {
    if (perWeek) {
      const dateFormat = "MMDDYY";

      const startDate =
        monday || moment().startOf("isoWeek").format(dateFormat);

      const endDate = moment(startDate, dateFormat)
        .endOf("isoWeek")
        .format(dateFormat);

      return `${reportName}_${endDate}`;
    }
    return `${reportName}_all`;
  },
};
