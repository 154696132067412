import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CustomInput,
  FormGroup,
  Form,
  Label,
  Col,
} from "reactstrap";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import { utilsHelper } from "../../helpers/utilsHelper";

const OUTAGE_NONE = "NONE";

const AddWoCrewModal = ({ onClose }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const navigate = useNavigate();

  const [availableShifts, setAvailableShifts] = useState([]);
  const [availableOutagesPerShift, setAvailableOutagesPerShift] = useState([]);

  const [shift, setShift] = useState();
  const [outage, setOutage] = useState();

  const doSubmit = (event) => {
    event.preventDefault();
    navigate(
      `/work-orders/details/${workOrderDetails.workOrder.id}/crew/${shift}/${outage}/members`
    );
  };

  const getAvailableShifts = useCallback(
    () =>
      Object.keys(
        workOrderDetails.workOrder.workOrderCrews.reduce((p, c) => {
          if (c.count > 0) {
            p[c.shift] = true;
          }
          return p;
        }, {})
      ),
    [workOrderDetails.workOrder.workOrderCrews]
  );

  const getAvailableOutagesByShift = useCallback(
    (shift) => {
      if (!shift) {
        return [];
      }
      const availableWorkOrderCrews =
        workOrderDetails.workOrder.workOrderCrews.filter(
          (woc) => woc.shift === shift && woc.count > 0
        );
      return Object.keys(
        availableWorkOrderCrews.reduce((p, c) => {
          p[c.outage || OUTAGE_NONE] = true;
          return p;
        }, {})
      );
    },
    [workOrderDetails.workOrder.workOrderCrews]
  );

  useEffect(() => {
    setAvailableShifts(getAvailableShifts());
  }, [getAvailableShifts]);

  useEffect(() => {
    setAvailableOutagesPerShift(getAvailableOutagesByShift(shift));
  }, [shift, getAvailableOutagesByShift]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        Add Crew to {workOrderDetails.workOrder.workOrderNumber}
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          <p>Select the Shift and Outage for the Crew</p>
          <FormGroup row>
            <Label sm={3} className="text-sm-left">
              Shift
            </Label>
            <Col sm={9}>
              <CustomInput
                required
                id="shiftSelect"
                type="select"
                name="shiftSelect"
                onChange={(event) => setShift(event.currentTarget.value)}
                value={shift}
              >
                <option value={""}>Select a Shift</option>
                {availableShifts.map((shift) => (
                  <option key={shift} value={shift}>
                    {shift}
                  </option>
                ))}
              </CustomInput>
            </Col>
          </FormGroup>
          <FormGroup row className="mb-0">
            <Label sm={3} className="text-sm-left">
              Outage
            </Label>
            <Col sm={9}>
              <CustomInput
                required
                id="outageSelect"
                type="select"
                name="outageSelect"
                onChange={(event) => setOutage(event.currentTarget.value)}
                value={outage}
              >
                <option value={""}>Select an Outage</option>
                {availableOutagesPerShift.map((outage) => (
                  <option key={outage} value={outage}>
                    {utilsHelper.outageLabels(outage)}
                  </option>
                ))}
              </CustomInput>
            </Col>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          <Button color={"primary"} type={"submit"}>
            Continue
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddWoCrewModal;
