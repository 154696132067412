import {
  faCheck,
  faExchangeAlt,
  faPlus,
  faSearch,
  faSync,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import Loader from "../../../components/Loader";

import { employeesApi } from "../../../services/employeeServices";
import {
  ACTIONS,
  useCrewManagement,
} from "../../../providers/crewManagementProvider";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { crewManagementHelper } from "../../../helpers/crewManagementHelper";
import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";
import SlotSelectorModal from "./SlotSelectorModal";
import InformationModal from "../../InformationModal";
import AdvanceTableWrapper from "../../advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../advanceTable/AdvanceTablePagination";
import { useAuth } from "../../../providers/authProvider";
import { useWorkOrderStatus } from "../../../providers/workOrderStatusProvider";

const INIT_PAGE_SIZE = 50;
const INIT_PAGE = 1;

const STATUS_INVOICED = 6;

const EMPLOYEES_TAB = "1";
const SUBCONTRACTORS_TAB = "2";

const EMPLOYEE_DEFAULT_TYPE = "1";
const EMPLOYEE_SUBCONTRACTOR_TYPE = "2";

const NONJOB = "NONJOB";

const columns = (
  workOrderCrews,
  IS_READ_ONLY,
  addEmployee,
  isSubcontractors
) => {
  const columns = [
    {
      accessor: "firstName",
      Header: "First Name",
      headerProps: {
        className: "text-truncate",
      },
      Cell: (rowData) =>
        rowData.row.original.preferredFirstName
          ? `${rowData.row.original.firstName} (${rowData.row.original.preferredFirstName})`
          : rowData.row.original.firstName,
    },
    {
      accessor: "lastName",
      Header: "Last Name",
      headerProps: {
        className: "text-truncate",
      },
      Cell: (rowData) => rowData.row.original.lastName || "-",
    },
    {
      accessor: "role.name",
      Header: "Role",
      headerProps: {
        className: "text-truncate",
      },
      Cell: (rowData) => rowData.row.original.role?.name || "-",
    },
    {
      accessor: "sourceKey",
      Header: "Division",
      headerProps: {
        className: "text-truncate",
      },
      Cell: (rowData) => rowData.row.original.sourceKey || "-",
    },
  ];
  if (isSubcontractors) {
    columns.push({
      accessor: "company",
      Header: "Company",
      headerProps: {
        className: "text-truncate",
      },
      Cell: (rowData) => rowData.row.original.subContractorCompany?.name || "-",
    });
  }
  columns.push({
    accessor: "id",
    Header: "Actions",
    headerProps: { className: "text-truncate text-center" },
    cellProps: { className: "text-truncate" },
    Cell: (rowData) => (
      <div className="d-flex justify-content-center align-items-center">
        {utilsHelper.employeeAlreadyAdded(
          rowData.row.original,
          workOrderCrews
        ) ? (
          <Badge color="success">
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            <span>Part of the Crew</span>
          </Badge>
        ) : (
          <Button
            disabled={IS_READ_ONLY}
            className="d-flex align-items-center rounded"
            color="secondary"
            size="sm"
            onClick={() =>
              addEmployee({
                employee: rowData.row.original,
              })
            }
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            <span>Add to Crew</span>
          </Button>
        )}
      </div>
    ),
  });
  return columns;
};

const EmployeeList = ({ onSave }) => {
  const { shift = "DAY", outage } = useParams();

  const [authContext] = useAuth();

  const navigate = useNavigate();
  const [workOrderDetails] = useWorkOrderDetails();
  const [workOrderStatusContext] = useWorkOrderStatus();

  const [crewManagement, setCrewManagement] = useCrewManagement();

  const IS_READ_ONLY =
    workOrderDetails?.workOrder?.statusId === STATUS_INVOICED ||
    utilsHelper.isReadOnly(authContext);

  //employees
  const [employees, setEmployees] = useState({});
  const [sizePerPageEmployees, setSizePerPageEmployees] =
    useState(INIT_PAGE_SIZE);
  const [pageEmployees, setPageEmployees] = useState(INIT_PAGE);
  const [searchEmployees, setSearchEmployees] = useState("");
  const [sortByEmployees, setSortByEmployees] = useState();
  const [directionEmployees, setDirectionEmployees] = useState();
  const [refreshEmployees, setRefreshEmployees] = useState();

  //subcontractors
  const [subcontractors, setSubcontractors] = useState({});
  const [sizePerPageSubcontractors, setSizePerPageSubcontractors] =
    useState(INIT_PAGE_SIZE);
  const [pageSubcontractors, setPageSubcontractors] = useState(INIT_PAGE);
  const [searchSubcontractors, setSearchSubcontractors] = useState("");
  const [sortBySubcontractors, setSortBySubcontractors] = useState();
  const [directionSubcontractors, setDirectionSubcontractors] = useState();
  const [refreshSubcontractors, setRefreshSubcontractors] = useState();

  const [activeTab, setActiveTab] = useState(EMPLOYEES_TAB);
  const [loading, setLoading] = useState(true);

  const [slotSelectorModal, setSlotSelectorModal] = useState({
    isOpen: false,
    onSubmit: null,
    availableSlots: [],
    defaultRole: null,
    onClose: null,
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const toggleTab = (tab) => (activeTab !== tab ? setActiveTab(tab) : null);

  const onSortEmployees = useCallback(
    ([data]) => {
      if (data) {
        const localSortBy = data.id;
        const localDirection = data.desc ? "desc" : "asc";
        if (
          localSortBy?.id === sortByEmployees &&
          localDirection === directionEmployees
        ) {
          return;
        }
        setSortByEmployees(localSortBy);
        setDirectionEmployees(localDirection);
        setPageEmployees(INIT_PAGE);
      } else {
        setSortByEmployees(null);
        setDirectionEmployees(null);
        setPageEmployees(INIT_PAGE);
      }
    },
    [
      setSortByEmployees,
      setDirectionEmployees,
      setPageEmployees,
      directionEmployees,
      sortByEmployees,
    ]
  );

  const onSortSubcontractors = useCallback(
    ([data]) => {
      if (data) {
        const localSortBy = data.id;
        const localDirection = data.desc ? "desc" : "asc";
        if (
          localSortBy?.id === sortBySubcontractors &&
          localDirection === directionSubcontractors
        ) {
          return;
        }
        setSortBySubcontractors(localSortBy);
        setDirectionSubcontractors(localDirection);
        setPageSubcontractors(INIT_PAGE);
      } else {
        setSortBySubcontractors(null);
        setDirectionSubcontractors(null);
        setPageSubcontractors(INIT_PAGE);
      }
    },
    [
      setSortBySubcontractors,
      setDirectionSubcontractors,
      setPageSubcontractors,
      directionSubcontractors,
      sortBySubcontractors,
    ]
  );

  // Load Default Employees
  useEffect(() => {
    if (workOrderDetails.workOrder) {
      setLoading(true);
      employeesApi
        .getEmployees({
          isNonJob:
            workOrderDetails.workOrder.workOrderType === NONJOB
              ? "true"
              : "false",
          type: EMPLOYEE_DEFAULT_TYPE,
          search: searchEmployees,
          page: pageEmployees - 1,
          pageSize: sizePerPageEmployees,
          sortBy: sortByEmployees,
          direction: directionEmployees,
          crossCompany: crewManagement.crossCompany,
          jobSourceId: authContext.currentUser.jobSourceId,
          includeAssignations: true,
          isActive: true,
        })
        .then((employees) => {
          setEmployees(employees);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [
    workOrderDetails.workOrder,
    crewManagement.crossCompany,
    sortByEmployees,
    directionEmployees,
    sizePerPageEmployees,
    pageEmployees,
    searchEmployees,
    refreshEmployees,
    authContext.currentUser.jobSourceId,
  ]);

  // Load SubContractors Employees
  useEffect(() => {
    setLoading(true);
    employeesApi
      .getEmployees({
        type: EMPLOYEE_SUBCONTRACTOR_TYPE,
        search: searchSubcontractors,
        page: pageSubcontractors - 1,
        pageSize: sizePerPageSubcontractors,
        sortBy: sortBySubcontractors,
        direction: directionSubcontractors,
        crossCompany: crewManagement.crossCompany,
        jobSourceId: authContext.currentUser.jobSourceId,
        includeAssignations: true,
        isActive: true,
      })
      .then((subcontractors) => {
        setSubcontractors(subcontractors);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    crewManagement.crossCompany,
    sortBySubcontractors,
    directionSubcontractors,
    sizePerPageSubcontractors,
    pageSubcontractors,
    searchSubcontractors,
    refreshSubcontractors,
    authContext.currentUser.jobSourceId,
  ]);

  const onSubmitEmployeeSlotSelectorModal = (
    role,
    workOrder,
    workOrderCrews,
    employee
  ) => {
    setSlotSelectorModal({
      isOpen: false,
      onSubmit: null,
      defaultRole: null,
      availableSlots: [],
      onClose: null,
    });

    const workOrderCrew = workOrderCrews.find(
      (woc) => parseInt(woc.roleId) === parseInt(role.id)
    );

    const addedEmployees = workOrderCrews.flatMap((woc) => woc.employees);

    if (
      crewManagementHelper.employeeAlreadyAdded(
        workOrderCrew,
        addedEmployees,
        employee,
        workOrderDetails.workOrder.workOrderType === NONJOB
      )
    ) {
      return;
    }

    if (!workOrderCrew && workOrderDetails.workOrder.workOrderType === NONJOB) {
      const conflicts = crewManagementHelper.employeeConflicts(
        workOrderStatusContext,
        workOrder,
        employee,
        navigate
      );
      if (conflicts) {
        setInformationModal({
          isOpen: true,
          title: "Scheduling Conflict",
          rawBody: true,
          body: conflicts,
          size: "lg",
        });
      }
      return setCrewManagement({
        action: ACTIONS.ADD_WORK_ORDER_CREW,
        payload: {
          role,
          employee,
          outage,
          shift,
          workOrderId: workOrderDetails.workOrder.id,
        },
      });
    }

    if (crewManagementHelper.enabledAddEmployee(workOrderCrew, employee)) {
      const conflicts = crewManagementHelper.employeeConflicts(
        workOrderStatusContext,
        workOrder,
        employee,
        navigate
      );
      const updatedWorkOrderCrew = crewManagementHelper.updateWorkOrderCrew(
        workOrderCrew,
        employee
      );
      if (conflicts) {
        setInformationModal({
          isOpen: true,
          title: "Scheduling Conflict",
          rawBody: true,
          body: conflicts,
          size: "lg",
        });
      }
      setCrewManagement({
        action: ACTIONS.UPDATE_WORK_ORDER_CREW,
        payload: { workOrderCrew: updatedWorkOrderCrew },
      });
      if (employee.role.isSuper && !crewManagement.crewLead) {
        setCrewManagement({
          action: ACTIONS.SET_CREW_LEAD,
          payload: { crewLead: employee.id },
        });
      }
    }
  };

  const addEmployee = ({ employee }) => {
    const { workOrderCrews = [] } = crewManagement;
    const alreadyAdded = utilsHelper.employeeAlreadyAdded(
      employee,
      workOrderCrews
    );

    if (alreadyAdded) {
      return setInformationModal({
        isOpen: true,
        title: "Add Employee to Crew",
        body: "The Employee is already added to the Crew.",
      });
    }

    if (workOrderDetails.workOrder.workOrderType !== NONJOB) {
      const availableSlots = utilsHelper.getAvailableSlots(workOrderCrews);

      if (!availableSlots.length) {
        return setInformationModal({
          isOpen: true,
          title: "Add Employee to Crew",
          body: "There are no available slots to add the Employee.",
        });
      }

      const availableEmployeeRoleInSlots = availableSlots.find(
        (a) => parseInt(a.roleId) === employee.roleId
      );

      if (availableEmployeeRoleInSlots) {
        const currentAsRole = availableEmployeeRoleInSlots.roleId;
        const workOrderCrew = workOrderCrews.find(
          (woc) => parseInt(woc.roleId) === parseInt(currentAsRole)
        );
        const addedEmployees = workOrderCrews.flatMap((woc) => woc.employees);
        if (
          crewManagementHelper.employeeAlreadyAdded(
            workOrderCrew,
            addedEmployees,
            employee,
            workOrderDetails.workOrder.workOrderType === NONJOB
          )
        ) {
          return;
        }
        if (crewManagementHelper.enabledAddEmployee(workOrderCrew, employee)) {
          const conflicts = crewManagementHelper.employeeConflicts(
            workOrderStatusContext,
            workOrderDetails.workOrder,
            employee,
            navigate
          );
          const updatedWorkOrderCrew = crewManagementHelper.updateWorkOrderCrew(
            workOrderCrew,
            employee
          );
          if (conflicts) {
            setInformationModal({
              isOpen: true,
              title: "Scheduling Conflict",
              rawBody: true,
              body: conflicts,
              size: "lg",
            });
          }
          setCrewManagement({
            action: ACTIONS.UPDATE_WORK_ORDER_CREW,
            payload: { workOrderCrew: updatedWorkOrderCrew },
          });
          if (employee.role.isSuper && !crewManagement.crewLead) {
            setCrewManagement({
              action: ACTIONS.SET_CREW_LEAD,
              payload: { crewLead: employee.id },
            });
          }
        }
      } else {
        setSlotSelectorModal({
          isOpen: true,
          availableSlots,
          defaultRole: employee.roleId,
          onSubmit: (role, workOrder) =>
            onSubmitEmployeeSlotSelectorModal(
              role,
              workOrder,
              workOrderCrews,
              employee
            ),
          onClose: () =>
            setSlotSelectorModal({
              isOpen: false,
              onSubmit: null,
              defaultRole: null,
              availableSlots: [],
              onClose: null,
            }),
        });
      }
    } else {
      onSubmitEmployeeSlotSelectorModal(
        employee.role,
        workOrderDetails.workOrder,
        workOrderCrews,
        employee
      );
    }
  };

  return (
    <Card>
      <CardHeader className="d-flex align-items-center justify-content-between mt-2">
        <div className="text-dark flex-grow-1 d-flex flex-column align-items-start">
          <div className="d-flex align-items-center">
            <h3 className="mb-0 ">Employees</h3>
            <small className="text-muted ml-2 pt-1">
              ({employees?.count || 0})
            </small>
          </div>
          {crewManagement.crossCompany ? (
            <Badge color="warning" size="sm" className="small">
              Cross Company Enabled
            </Badge>
          ) : null}
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <InputGroup size="m" className="mr-3">
            <Input
              maxLength="50"
              placeholder="Search for.."
              value={searchEmployees}
              onChange={(evt) => {
                setSearchEmployees(evt.target.value);
                setSearchSubcontractors(evt.target.value);
              }}
            />
            <InputGroupText className="search-input input-group-text bg-primary text-white border-left-0 border-primary">
              <FontAwesomeIcon icon={faSearch} />
            </InputGroupText>
          </InputGroup>
          <Button
            size="sm"
            className="mr-3 rounded-circle d-flex custom-rounded-button"
            color="primary"
            onClick={() => {
              setRefreshEmployees(!refreshEmployees);
              setRefreshSubcontractors(!refreshSubcontractors);
            }}
          >
            <FontAwesomeIcon icon={faSync} />
          </Button>
          <Button
            size="sm"
            className="rounded-circle d-flex custom-rounded-button border-primary"
            color={crewManagement.crossCompany ? "primary" : "white"}
            onClick={() => {
              setCrewManagement({
                action: ACTIONS.TOGGLE_CROSS_COMPANY,
              });
            }}
          >
            <FontAwesomeIcon
              icon={faExchangeAlt}
              className={
                crewManagement.crossCompany ? "text-white" : "text-primary"
              }
            />
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <Nav
          tabs
          className="d-flex justify-content-between border-bottom-0 cursor-pointer"
        >
          <div className="d-flex">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === EMPLOYEES_TAB,
                  "font-weight-bold": activeTab === EMPLOYEES_TAB,
                })}
                onClick={() => toggleTab(EMPLOYEES_TAB)}
              >
                Regular Employees
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === SUBCONTRACTORS_TAB,
                  "font-weight-bold": activeTab === SUBCONTRACTORS_TAB,
                })}
                onClick={() => toggleTab(SUBCONTRACTORS_TAB)}
              >
                Contract Employees
              </NavLink>
            </NavItem>
          </div>
          <NavItem>
            <div className="mb-2 ml-2">
              <Button
                className="ml-2 rounded"
                size="sm"
                color="warning"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" />
                </div>
              ) : (
                <Button
                  disabled={IS_READ_ONLY}
                  className="ml-2 rounded"
                  size="sm"
                  color="primary"
                  onClick={onSave}
                >
                  Save Crew
                </Button>
              )}
            </div>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={EMPLOYEES_TAB}>
            <Col sm="12" className="px-0">
              <div className="w-100">
                <AdvanceTableWrapper
                  columns={columns(
                    crewManagement.workOrderCrews,
                    IS_READ_ONLY,
                    addEmployee,
                    activeTab === SUBCONTRACTORS_TAB
                  )}
                  data={employees?.data || []}
                  pageSize={sizePerPageEmployees}
                  sortable
                  onSort={onSortEmployees}
                  defaultSort={{
                    sortBy: sortByEmployees,
                    direction: directionEmployees,
                  }}
                >
                  <AdvanceTable
                    table
                    isLoading={loading}
                    headerClassName="text-muted small"
                    tableProps={{
                      striped: true,
                      className: "mb-0",
                    }}
                  />
                  <AdvanceTablePagination
                    totalCount={employees?.count || 0}
                    pageCount={employees.totalPages}
                    currentPage={pageEmployees - 1}
                    onPageChange={(page) => setPageEmployees(page)}
                    pageSize={sizePerPageEmployees}
                    onPageSizeChange={(sizePerPage) =>
                      setSizePerPageEmployees(sizePerPage)
                    }
                  />
                </AdvanceTableWrapper>
              </div>
            </Col>
          </TabPane>
          <TabPane tabId={SUBCONTRACTORS_TAB}>
            <Col sm="12" className="px-0">
              <div className="w-100">
                <AdvanceTableWrapper
                  columns={columns(
                    crewManagement.workOrderCrews,
                    IS_READ_ONLY,
                    addEmployee,
                    activeTab === SUBCONTRACTORS_TAB
                  )}
                  data={subcontractors?.data || []}
                  pageSize={sizePerPageSubcontractors}
                  sortable
                  onSort={onSortSubcontractors}
                  defaultSort={{
                    sortBy: sortBySubcontractors,
                    direction: directionSubcontractors,
                  }}
                >
                  <AdvanceTable
                    table
                    isLoading={loading}
                    headerClassName="text-muted small"
                    tableProps={{
                      striped: true,
                      className: "mb-0",
                    }}
                  />
                  <AdvanceTablePagination
                    totalCount={subcontractors?.count || 0}
                    pageCount={subcontractors.totalPages}
                    currentPage={pageSubcontractors - 1}
                    onPageChange={(page) => setPageSubcontractors(page)}
                    pageSize={sizePerPageSubcontractors}
                    onPageSizeChange={(sizePerPage) =>
                      setSizePerPageSubcontractors(sizePerPage)
                    }
                  />
                </AdvanceTableWrapper>
              </div>
            </Col>
          </TabPane>
        </TabContent>
      </CardBody>
      {slotSelectorModal.isOpen ? (
        <SlotSelectorModal {...slotSelectorModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Card>
  );
};

export default EmployeeList;
