import React, { useEffect, useState } from "react";

import {
  Row,
  Button,
  Col,
  CustomInput,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { reportsApi } from "../../../../services/reportsServices";

import moment from "moment";

import { useWorkOrderDetails } from "../../../../providers/workOrderDetailsProvider";

import WorkTimeFlexMillReport from "./WorkTimeFlexMillReport";
import EquipmentFlexMillReport from "./EquipmentFlexMillReport";
import { utilsHelper } from "../../../../helpers/utilsHelper";
import WeekSelector from "../../../../components/admin/WeekSelector";

const WORK_TIME = "WORK_TIME";
const EQUIPMENT = "EQUIPMENT";

const ALL_JOB_DATA = "ALL_JOB_DATA";
const WEEKLY_DATA = "WEEKLY_DATA";

const FlexMillReport = ({ workOrderId, settingsOpen, setSettingsOpen }) => {
  const [workOrderDetails] = useWorkOrderDetails();

  const [report, setReport] = useState();

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [dataMode, setDataMode] = useState(ALL_JOB_DATA);
  const [reportType, setReportType] = useState(WORK_TIME);
  const [crew, setCrew] = useState();

  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    reportsApi
      .millTimesheetsReport({
        format: "json",
        workOrderId,
        weekStart:
          dataMode === WEEKLY_DATA ? monday.format("YYYY-MM-DD") : null,
        crewId: crew,
        browserTimeOffset: moment().utcOffset() / 60,
      })
      .then((result) => {
        setLoading(false);
        setReport(result);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [reportType, crew, dataMode, monday, workOrderId]);

  const closeBtn = (
    <Button
      className="close"
      color="none"
      onClick={() => setSettingsOpen(false)}
    >
      &times;
    </Button>
  );

  return (
    <Row className="d-flex flex-column">
      <Modal isOpen={settingsOpen}>
        <ModalHeader close={closeBtn}>Report Options</ModalHeader>
        <ModalBody>
          <FormGroup row>
            <Label sm={3} className="text-sm-left">
              Report Type
            </Label>
            <Col sm={9}>
              <CustomInput
                required
                id="typeSelect"
                type="select"
                name="typeSelect"
                onChange={(event) => setReportType(event.currentTarget.value)}
                value={reportType}
              >
                <option value={WORK_TIME}>Work Time</option>
                <option value={EQUIPMENT}>Equipment</option>
              </CustomInput>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3} className="text-sm-left">
              Data included
            </Label>
            <Col sm={9}>
              <CustomInput
                required
                id="dataSelect"
                type="select"
                name="dataSelect"
                onChange={(event) => setDataMode(event.currentTarget.value)}
                value={dataMode}
              >
                <option value={ALL_JOB_DATA}>All Job Data</option>
                <option value={WEEKLY_DATA}>Weekly</option>
              </CustomInput>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3} className="text-sm-left">
              Crew
            </Label>
            <Col sm={9}>
              <CustomInput
                required
                id="crewSelect"
                type="select"
                name="crewSelect"
                onChange={(event) => setCrew(event.currentTarget.value)}
                value={crew}
              >
                <option value={""}>All Crews</option>
                {workOrderDetails.workOrder.crewWorkOrders
                  .filter((crewWorkOrder) =>
                    crewWorkOrder.crew.employeeCrews
                      .filter((ec) =>
                        utilsHelper.isEmployeeCrewActiveOnDate(ec)
                      )
                      .find((ec) => ec.isCrewLead)
                  )
                  .map((crewWorkOrder) => {
                    const sup = crewWorkOrder.crew.employeeCrews
                      .filter((ec) =>
                        utilsHelper.isEmployeeCrewActiveOnDate(ec)
                      )
                      .find((ec) => ec.isCrewLead);
                    return (
                      <option
                        key={crewWorkOrder.id}
                        value={crewWorkOrder.crew.id}
                      >
                        {`${sup.employee.firstName} ${sup.employee.lastName}`}
                      </option>
                    );
                  })}
              </CustomInput>
            </Col>
          </FormGroup>
          {dataMode === WEEKLY_DATA ? (
            <WeekSelector
              loading={loading}
              monday={monday}
              setMonday={setMonday}
            />
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between mt-n2">
          <Button color="secondary" onClick={() => setSettingsOpen(false)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <Col className="flex-shrink-0 py-3">
        {reportType === WORK_TIME ? (
          <WorkTimeFlexMillReport data={report} />
        ) : (
          <EquipmentFlexMillReport data={report} />
        )}
      </Col>
    </Row>
  );
};

export default FlexMillReport;
