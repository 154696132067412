import { FilePreviewStrategy } from "./FilePreviewStrategy";

const AutoCadViewer = ({ url }) => {
  return (
    <iframe
      style={{ border: "none" }}
      title="AutoCadViewer"
      src={`https://sharecad.org/cadframe/load?url=${url}`}
      width="100%"
      height="600px"
    />
  );
};

class AutoCadViewerStrategy extends FilePreviewStrategy {
  renderPreview(url) {
    return <AutoCadViewer url={url} />;
  }
}

export default AutoCadViewerStrategy;
