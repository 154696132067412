import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Table,
} from "reactstrap";
import { employeesApi } from "../../../services/employeeServices";
import { utilsHelper } from "../../../helpers/utilsHelper";
import Loader from "../../Loader";

const EmployeeAssignationsModal = ({ onClose, employee }) => {
  const [loading, setLoading] = useState();
  const [employeeData, setEmployeeData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const employeeData = await employeesApi.getEmployees({ id: employee.id });
      setEmployeeData(employeeData);
      setLoading(false);
    };
    fetchData();
  }, [employee.id]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Employee Assignments</ModalHeader>
      <ModalBody>
        {loading || !employeeData ? (
          <Loader size="sm" />
        ) : (
          <Table className="col-12 px-0 mb-0 border" stripped="true">
            <thead>
              <tr className="bg-lighter small">
                <th>Work Order</th>
                <th>WO Status</th>
                <th>Role</th>
                <th>Supervisor</th>
              </tr>
            </thead>
            <tbody className="small">
              {employeeData.employeeCrews.filter((ec) =>
                utilsHelper.isEmployeeCrewActiveOnDate(ec)
              ).length ? (
                employeeData.employeeCrews
                  .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec))
                  .map((employeeCrew) => {
                    const crewWorkOrders = employeeCrew.crew.crewWorkOrders;
                    if (!crewWorkOrders?.length) {
                      return null;
                    }
                    const workOrder = crewWorkOrders[0].workOrder;
                    const crewSupervisor = employeeCrew.crew.employeeCrews
                      .filter((ec) =>
                        utilsHelper.isEmployeeCrewActiveOnDate(ec, new Date())
                      )
                      .find((ec) => ec.isCrewLead);
                    return (
                      <tr key={employeeCrew.id}>
                        <td
                          className="text-underline cursor-pointer text-primary"
                          onClick={() =>
                            navigate(`/work-orders/details/${workOrder.id}`)
                          }
                        >
                          {utilsHelper.getWorkOrderNumberWithGrouped(workOrder)}
                        </td>
                        <td>{workOrder.workOrderStatus?.status || "-"}</td>
                        <td>{employeeCrew.role.name}</td>
                        <td>
                          {crewSupervisor
                            ? `${crewSupervisor.employee.firstName} ${crewSupervisor.employee.lastName}`
                            : "-"}
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <tr>
                  <td colSpan={3} className="text-center">
                    The employee has no assignments
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EmployeeAssignationsModal;
