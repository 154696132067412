import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label,
  Form,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "./Loader";
import ConfirmationModal from "./ConfirmationModal";
import InformationModal from "./InformationModal";
import { injuryReportAttachmentApi } from "../services/injuryReportAttachmentServices";
import { useAuth } from "../providers/authProvider";
import { utilsHelper } from "../helpers/utilsHelper";
import { faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { awsApi } from "../services/awsServices";
import config from "../config/config";
import DocumentPreviewModal from "./admin/DocumentPreviewModal";

const FILE_TYPE = "application/pdf";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const InjuryReportPDFModal = ({
  onCloseModal,
  injuryReportId,
  files,
  setFetchPDF,
}) => {
  const [authContext] = useAuth();

  const [loading, setLoading] = useState(false);
  const [attachmentsInfo, setAttachmentsInfo] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );
  const hiddenFileInputAttachment = useRef(null);
  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });
  const [previewModal, setPreviewModal] = useState({});

  const handleClickInputAttachments = (event) => {
    hiddenFileInputAttachment.current.click();
  };

  const mergeInfosUnique = (arr1, arr2) => {
    return arr1.concat(
      arr2.filter((item2) => {
        return !arr1.find((item1) => item1.name === item2.name);
      })
    );
  };

  const loadAttachments = (event) => {
    const files = event.target.files;
    const sizeLimit = 3 * 1024 * 1024;
    const mappedFiles = [];
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > sizeLimit) {
        setInformationModal({
          isOpen: true,
          title: `Error in the attached PDF`,
          body: `The maximum size to upload files is 3 MB`,
          onClose: () => {
            setInformationModal({ isOpen: false, title: "", body: "" });
          },
        });
        return;
      }
      mappedFiles.push({
        id: null,
        name: files[i].name,
        file: files[i],
      });
    }
    const infos = utilsHelper.mapFilesToAttachmentInfo(mappedFiles);
    setAttachmentsInfo((prev) => mergeInfosUnique(prev, infos));
    event.target.value = null;
  };

  const onPreview = (attachment) => {
    if (attachment.file) {
      setPreviewModal({
        isOpen: true,
        file: attachment.file,
      });
    } else {
      const fileName = attachment.name;
      awsApi
        .signDocuments({
          documents: [
            {
              fileName,
              fileType: FILE_TYPE,
              bucket: config.documentsBucket,
              method: "getObject",
            },
          ],
        })
        .then((result) => {
          setPreviewModal({
            isOpen: true,
            file: result?.pop().signedRequest,
          });
        });
    }
  };

  const uploadAttachments = () =>
    new Promise((resolve, reject) => {
      try {
        const readyToSignDocuments = attachmentsInfo
          .filter((att) => !att.id)
          .map((document) => {
            return {
              fileName: document.name,
              fileType: FILE_TYPE,
              bucket: config.documentsBucket,
              method: "putObject",
            };
          });
        awsApi
          .signDocuments({ documents: readyToSignDocuments })
          .then((signedURLs) => {
            const elements = signedURLs
              .filter((signedURL) => signedURL.success)
              .map((signedURL) => {
                const file = attachmentsInfo.find(
                  (document) => document.name === signedURL.fileName
                )?.file;
                return file
                  ? {
                      url: signedURL.signedRequest,
                      file,
                      fileUrl: signedURL.url,
                    }
                  : null;
              });
            const promises = elements.map((element) =>
              awsApi.putDocumentsToS3(element, FILE_TYPE)
            );
            Promise.all(promises).then((result) => {
              const urls = result.reduce((p, c) => {
                p[c.name] = c.url;
                return p;
              }, {});
              resolve(urls);
            });
          });
      } catch (error) {
        reject(error);
      }
    });

  const mergeAttachments = (urls) => {
    const newAttachments = urls.map((url) => {
      return {
        id: null,
        content: url,
        type: "PDF",
        injuryReportId,
      };
    });
    return newAttachments;
  };

  const doSubmit = async () => {
    try {
      setLoading(true);
      const urlsAttacmments = await uploadAttachments();
      const newAtachments = mergeAttachments(Object.values(urlsAttacmments));
      let promises = newAtachments.map(async (element) => {
        return injuryReportAttachmentApi.saveInjuryReportAttachment(element);
      });
      await Promise.all(promises);
      fetchData();
      setFetchPDF(true);
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Injury Report Attachments`,
        body: `${
          attachmentsInfo.filter((att) => !att.id).length > 1
            ? "Attachments"
            : "Attachment"
        } uploaded successfully`,
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
          onCloseModal();
        },
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Injury Report Attachments`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const removeAttachment = (name) => {
    setAttachmentsInfo(attachmentsInfo.filter((att) => att.name !== name));
  };

  const doDelete = (id) =>
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => {
        setConfirmationModal(initConfirmationModal);
        submitDelete(id);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Injury Report PDF",
      body: `Are you sure you want to delete this PDF?`,
    });

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const attachments =
        await injuryReportAttachmentApi.getInjuryReportAttachmentQuery({
          injuryReportId,
          type: "PDF",
        });
      if (attachments.length > 0) {
        const docs = attachments.map((att) => {
          const splitUrl = att.content.split("/");
          return {
            id: att.id,
            name: splitUrl[splitUrl.length - 1],
            file: null,
          };
        });
        const infos = utilsHelper.mapFilesToAttachmentInfo(docs);
        setAttachmentsInfo(infos);
      } else {
        setAttachmentsInfo([]);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Injury Report PDF`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  }, [injuryReportId]);

  const submitDelete = async (id) => {
    try {
      setLoading(true);
      await injuryReportAttachmentApi.deleteInjuryReportAttachment({ id });
      fetchData();
      setFetchPDF(true);
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Injury Report PDF`,
        body: `PDF deleted successfully`,
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
        },
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Injury Report PDF`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  useEffect(() => {
    if (!files) {
      fetchData();
    } else {
      setAttachmentsInfo(files);
    }
  }, [fetchData, files]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onCloseModal}>
      &times;
    </Button>
  );

  return confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : previewModal.isOpen ? (
    <DocumentPreviewModal
      file={previewModal.file}
      onClose={() => setPreviewModal({ isOpen: false, file: null })}
    />
  ) : (
    <Modal isOpen={true} onClosed={onCloseModal}>
      <ModalHeader close={closeBtn}>Injury Report PDF</ModalHeader>
      <ModalBody className="text-center">
        {loading ? (
          <Loader size={"sm"} />
        ) : (
          <Row className="inner-section">
            {attachmentsInfo.length > 0 ? (
              <div className="px-2 mb-3 col-12">
                {attachmentsInfo.map((att) => {
                  return (
                    <div
                      style={{ background: "#FDF1DB", cursor: "pointer" }}
                      className="text-left d-flex flex-column small p-2 rounded mb-3"
                      key={att.name}
                      onClick={() => onPreview(att)}
                    >
                      <div className="d-flex mb-1 justify-content-between">
                        <span className="text-muted">{att.name}</span>
                        {!utilsHelper.isReadOnly(authContext) && !files && (
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              att.id
                                ? doDelete(att.id)
                                : removeAttachment(att.name);
                            }}
                            size="sm"
                            className="rounded"
                            color="danger"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="col-12 small">No PDF file have been created</div>
            )}
            {!utilsHelper.isReadOnly(authContext) && !files ? (
              <Form className="my-2 mx-2 w-100 d-flex flex-column border-top pt-3">
                <div className="d-flex align-items-center">
                  <Label className="text-left font-weight-bold mr-2 mb-0">
                    Add PDF
                  </Label>
                  <input
                    ref={hiddenFileInputAttachment}
                    value=""
                    multiple={true}
                    type="file"
                    accept={`${FILE_TYPE}, .sizeLimit3MB`}
                    className="mb-1 text-truncate"
                    onChange={loadAttachments}
                    style={{ display: "none" }}
                  />
                  <Button
                    size="sm"
                    className="rounded mr-2"
                    onClick={handleClickInputAttachments}
                  >
                    <FontAwesomeIcon icon={faUpload} className="mr-2" />
                    Choose Files
                  </Button>
                </div>
              </Form>
            ) : null}
          </Row>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          className={files ? "ml-auto" : ""}
          color={"secondary"}
          onClick={onCloseModal}
        >
          Close
        </Button>
        {!utilsHelper.isReadOnly(authContext) && !files && (
          <Button
            className="ml-auto"
            color="primary"
            onClick={() => doSubmit()}
            disabled={
              loading ||
              !attachmentsInfo.length ||
              attachmentsInfo.every((att) => att.id)
            }
          >
            Save
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default InjuryReportPDFModal;
