import React, { useCallback, useEffect, useRef, useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";

import { reportsApi } from "../../../services/reportsServices";

import * as FlexmonsterReact from "react-flexmonster";

import moment from "moment";

import { utilsHelper } from "../../../helpers/utilsHelper";
import { reportsHelper } from "../../../helpers/reportsHelper";

import WeekSelector from "../../../components/admin/WeekSelector";
import PeriodTypeSelector from "../../../components/admin/PeriodTypeSelector";
import CustomPeriodSelector from "../../../components/admin/CustomPeriodSelector";
import Loader from "../../../components/Loader";
import { useAuth } from "../../../providers/authProvider";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TravelLivingSummaryModal from "../../../components/admin/TravelLivingSummaryModal";
import SendTLSummaryEmailModal from "../../../components/admin/SendTLSummaryEmailModal";

const report = {
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
    {
      name: "daily",
      thousandsSeparator: ",",
      decimalPlaces: 1,
      maxDecimalPlaces: 1,
    },
  ],
  dataSource: {
    data: [
      {
        Name: {
          type: "string",
        },
        "EE Code": {
          type: "string",
        },
        Email: {
          type: "string",
        },
        "Total Earned": {
          type: "number",
        },
        View: {
          type: "string",
        },
        "Send Report": {
          type: "string",
        },
      },
    ],
  },
  slice: {
    rows: [
      {
        uniqueName: "Name",
      },
      {
        uniqueName: "EE Code",
      },
      {
        uniqueName: "Email",
      },
      {
        uniqueName: "Total Earned",
      },
      {
        uniqueName: "View",
      },
      {
        uniqueName: "Send Report",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Travel and Living Summary",
    },
  },
};

const REPORT_NAME = "Travel_Living_Summary";

const TYPE_WEEKLY = "TYPE_WEEKLY";

const TravelAndLivingSummary = () => {
  const [authContext] = useAuth();
  const pivot = useRef(null);

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [data, setData] = useState([]);

  const [travelLivingSummaryModal, setTravelLivingSummaryModal] = useState();

  const [sendTLSummaryByEmailModal, setSendTLSummaryByEmailModal] = useState();

  const [loading, setLoading] = useState();

  const getFilename = () =>
    reportsHelper.getFileName(
      monday,
      periodType.value === TYPE_WEEKLY,
      REPORT_NAME
    );

  const addButtonToCells = useCallback(
    (cell, entry) => {
      if (
        entry.type !== "header" &&
        entry.measure?.uniqueName === "View" &&
        entry.rowIndex > 0
      ) {
        const employee = data[entry.rowIndex - 1];
        if (employee) {
          cell.text = `<input 
            class="btn btn-info text-white rounded text-center col-12 cursor-pointer"
            type="button" 
            value="View Report"
          >`;
          cell.style["z-index"] = 1;
        }
      } else if (
        entry.type !== "header" &&
        entry.measure?.uniqueName === "Send Report" &&
        entry.rowIndex > 0
      ) {
        const employee = data[entry.rowIndex - 1];
        if (employee) {
          cell.text = `<input 
            class="btn btn-secondary text-white rounded text-center col-12 cursor-pointer"
            type="button" 
            value="Send Report"
          >`;
          cell.style["z-index"] = 1;
        }
      }
    },
    [data]
  );

  const onButtonClick = useCallback(
    (cell) => {
      if (
        cell.hierarchy &&
        (cell.hierarchy.caption === "View" ||
          cell.hierarchy.caption === "Send Report")
      ) {
        const columns = pivot.current.flexmonster.getAllHierarchies();
        const employeeCodeIndex = columns
          ? columns.findIndex((e) => e.uniqueName === "EE Code")
          : -1;
        if (employeeCodeIndex >= 0) {
          const employeeCode =
            pivot.current.flexmonster.getCell(cell.rowIndex, employeeCodeIndex)
              ?.label || "-";
          const employee = data.find(
            (entry) => entry["EE Code"] === employeeCode
          );
          if (employee) {
            if (cell.hierarchy && cell.hierarchy.caption === "View") {
              setTravelLivingSummaryModal(employee.employeeId);
            } else if (
              cell.hierarchy &&
              cell.hierarchy.caption === "Send Report"
            ) {
              setSendTLSummaryByEmailModal([employee]);
            }
          }
        }
      }
    },
    [data]
  );

  useEffect(() => {
    if (pivot?.current?.flexmonster && data?.length) {
      pivot.current.flexmonster.updateData({
        data: [
          {
            Name: {
              type: "string",
            },
            "EE Code": {
              type: "string",
            },
            Email: {
              type: "string",
            },
            "Total Earned": {
              type: "number",
            },
            View: {
              type: "string",
            },
            "Send Report": {
              type: "string",
            },
          },
          ...data,
        ],
      });
      pivot.current.flexmonster.customizeCell(addButtonToCells);
      pivot.current.flexmonster.on("cellclick", onButtonClick);
    }
  }, [
    pivot,
    data,
    authContext.currentUser.jobSourceId,
    customEndDate,
    customStartDate,
    monday,
    periodType.value,
    addButtonToCells,
    onButtonClick,
  ]);

  useEffect(() => {
    setLoading(true);
    const condition = {
      jobSourceId: authContext.currentUser.jobSourceId,
    };
    if (periodType.value === TYPE_WEEKLY) {
      condition.startDate = moment(monday).format("YYYY-MM-DD");
      condition.endDate = moment(monday).endOf("isoWeek").format("YYYY-MM-DD");
    } else {
      condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
      condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
    }
    reportsApi
      .travelAndLivingSummaryReport(condition)
      .then((result) => {
        setLoading(false);
        setData(result);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    monday,
    setData,
    periodType,
    customEndDate,
    customStartDate,
    authContext.currentUser.jobSourceId,
  ]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-end">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : null}
              <Button
                className="rounded"
                size="sm"
                onClick={() =>
                  setSendTLSummaryByEmailModal(
                    data.map((employee) => ({
                      employeeId: employee.employeeId,
                      email: employee.Email,
                      name: employee.Name,
                    }))
                  )
                }
              >
                <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
                <span>Send All</span>
              </Button>
              <PeriodTypeSelector
                periodType={periodType}
                setPeriodType={setPeriodType}
              />
              {periodType.value === TYPE_WEEKLY ? (
                <WeekSelector
                  loading={loading}
                  monday={monday}
                  setMonday={setMonday}
                />
              ) : (
                <CustomPeriodSelector
                  defaultEndDate={customEndDate}
                  defaultStartDate={customStartDate}
                  onSubmit={(startDate, endDate) => {
                    setCustomStartDate(startDate);
                    setCustomEndDate(endDate);
                  }}
                />
              )}
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  ////key={(monday + periodType.value).toLocaleString()} this key makes the report break need to investigate why PB-2036
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {travelLivingSummaryModal ? (
        <TravelLivingSummaryModal
          periodType={periodType}
          monday={monday}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          employeeId={travelLivingSummaryModal}
          onClose={() => setTravelLivingSummaryModal()}
        />
      ) : sendTLSummaryByEmailModal ? (
        <SendTLSummaryEmailModal
          periodType={periodType}
          monday={monday}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          employees={sendTLSummaryByEmailModal}
          onClose={() => setSendTLSummaryByEmailModal()}
        />
      ) : null}
    </Container>
  );
};

export default TravelAndLivingSummary;
