import { faSearch, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";

import { useNavigate, useParams, useLocation } from "react-router";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  InputGroup,
  InputGroupText,
} from "reactstrap";

import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";

import { ACTIONS, useWorkOrders } from "../../providers/workOrdersProvider";

import { workOrdersApi } from "../../services/workOrdersServices";
import { utilsHelper } from "../../helpers/utilsHelper";
import InformationModal from "../../components/InformationModal";
import { useWorkOrderStatus } from "../../providers/workOrderStatusProvider";
import { useAuth } from "../../providers/authProvider";

import { DebounceInput } from "react-debounce-input";

const WEEK_DAYS = 7;

const STATUS_COMPLETED = 4;
const STATUS_CHANGE_ORDER_PROCESSING = 8;

const COLORS = [
  "info",
  "tertiary",
  "success",
  "warning",
  "danger",
  "secondary",
];

const columns = () => [
  {
    accessor: "workOrderNumber",
    Header: "Work Order #",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 100,
    Cell: (rowData) =>
      utilsHelper.getWorkOrderNumberWithGrouped(rowData.row.original),
  },
  {
    accessor: "supervisor",
    Header: "Supervisor",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 150,
    disableSortBy: true,
    Cell: (rowData) => {
      const { supervisor } = rowData.row.original;
      return supervisor || "-";
    },
  },
  {
    accessor: "customerName",
    Header: "Customer",
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 200,
  },
  {
    accessor: "drawName",
    Header: "Description",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    cellProps: { className: "text-truncate" },
    width: 200,
    Cell: (rowData) =>
      utilsHelper.getWorkOrderDescription(rowData.row.original),
  },
  {
    accessor: "startDate",
    Header: "Dates",
    headerProps: { className: "text-truncate text-center" },
    cellProps: { className: "text-truncate text-center" },
    width: 150,
    Cell: (rowData) => {
      const { startDate, endDate } = rowData.row.original;
      return (
        <>
          <Badge color="primary">
            {startDate
              ? utilsHelper.formatDate(startDate, "MM/DD/YYYY")
              : "N/A"}
          </Badge>
          <span className="mx-1">-</span>
          <Badge color="primary">
            {endDate ? utilsHelper.formatDate(endDate, "MM/DD/YYYY") : "N/A"}
          </Badge>
        </>
      );
    },
  },
];

const WorkOrders = () => {
  const [authContext] = useAuth();
  const [workOrderStatusContext] = useWorkOrderStatus();
  const [workOrdersContext, setWorkOrdersContext] = useWorkOrders();
  const params = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const location = useLocation();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          workOrdersContext.sortBy === sortBy?.id &&
          workOrdersContext.direction === direction
        ) {
          return;
        }
        setWorkOrdersContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setWorkOrdersContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [
      setWorkOrdersContext,
      workOrdersContext.direction,
      workOrdersContext.sortBy,
    ]
  );

  const onSeeDetails = (workOrderId) =>
    navigate(`/work-orders/details/${workOrderId}`);

  useEffect(() => {
    setLoading(true);
    const condition = {
      search: workOrdersContext.search,
      page: workOrdersContext.page - 1,
      pageSize: workOrdersContext.sizePerPage,
      sortBy: workOrdersContext.sortBy,
      direction: workOrdersContext.direction,
      jobSourceId: authContext.currentUser.jobSourceId,
    };
    if (params.statusId) {
      if (parseInt(params.statusId) === STATUS_COMPLETED) {
        condition.statusId = `[${params.statusId}, ${STATUS_CHANGE_ORDER_PROCESSING}]`;
      } else {
        condition.statusId = `[${params.statusId}]`;
      }
    }
    if (location.pathname === "/work-orders/all/recently-added") {
      condition.importedDate = WEEK_DAYS;
    }
    workOrdersApi
      .getWorkOrders(condition)
      .then((workOrders) => {
        setWorkOrdersContext({
          action: ACTIONS.GET_WORKORDERS_SUCCESS,
          payload: { workOrders, statusId: params.statusId },
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: `Work Orders`,
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
        });
      });
  }, [
    setWorkOrdersContext,
    workOrdersContext.sortBy,
    workOrdersContext.direction,
    workOrdersContext.sizePerPage,
    workOrdersContext.page,
    workOrdersContext.search,
    workOrdersContext.refresh,
    workOrdersContext.customerSearch,
    location.pathname,
    params.statusId,
    authContext.currentUser.jobSourceId,
  ]);

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={[
            ...columns(onSeeDetails),
            {
              accessor: "statusId",
              Header: "Status",
              disableSortBy: true,
              headerProps: { className: "text-truncate text-center" },
              cellProps: { className: "text-truncate text-center" },
              width: 100,
              Cell: (rowData) => {
                const { status, statusId } = rowData.row.original;
                return (
                  <Badge
                    color={
                      utilsHelper.getWorkOrderStatusById(
                        workOrderStatusContext,
                        statusId
                      ) === "Archived"
                        ? "dark"
                        : COLORS[statusId % COLORS.length]
                    }
                  >
                    {status}
                  </Badge>
                );
              },
            },
          ]}
          data={workOrdersContext.workOrders?.data || []}
          pageSize={workOrdersContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: workOrdersContext.sortBy,
            direction: workOrdersContext.direction,
          }}
        >
          <Card className="mb-3 w-100">
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="d-flex flex-column align-items-start">
                <div className="text-dark flex-grow-1 d-flex align-items-center">
                  <h3 className="mb-0 ">Work Orders</h3>
                  <small className="text-muted ml-2 pt-1">
                    ({workOrdersContext.workOrders?.count || 0})
                  </small>
                </div>
                <Badge
                  color={
                    utilsHelper.getWorkOrderStatusById(
                      workOrderStatusContext,
                      params.statusId
                    ) === "Archived"
                      ? "dark"
                      : COLORS[params.statusId % COLORS.length]
                  }
                >
                  {location.pathname.includes("recently-added")
                    ? "Recently Added"
                    : !params.statusId
                    ? "All"
                    : utilsHelper.getWorkOrderStatusById(
                        workOrderStatusContext,
                        params.statusId
                      )}
                </Badge>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup size="m" className="mr-3">
                  <DebounceInput
                    className="form-control"
                    minLength={1}
                    debounceTimeout={800}
                    maxLength="50"
                    placeholder="Search for.."
                    value={workOrdersContext.search}
                    onChange={(evt) =>
                      setWorkOrdersContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-primary text-white border-left-0 border-primary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="mr-3 rounded-circle d-flex custom-rounded-button"
                  color="primary"
                  onClick={() =>
                    setWorkOrdersContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="d-flex flex-column overflow-x-auto">
              <AdvanceTable
                onRowClick={(row) => onSeeDetails(row.original.id)}
                table
                isLoading={loading}
                headerClassName="text-muted small"
                style={{ minWidth: 1400 }}
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={workOrdersContext.workOrders?.count || 0}
                pageCount={workOrdersContext.workOrders?.totalPages || 0}
                currentPage={workOrdersContext.page - 1}
                onPageChange={(page) =>
                  setWorkOrdersContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={workOrdersContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setWorkOrdersContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default WorkOrders;
