import React from "react";
import { ListGroup } from "reactstrap";

const DustCollectorLayout = ({ inspectionAnswer }) => {
  const jsonContent = JSON.parse(inspectionAnswer.content);

  const getBackgroundColor = (status) => {
    switch (status) {
      case "replaced":
        return "#56FF00";
      case "noChange":
        return "white";
      case "replaceNextOutage":
        return "#F48100";
      default:
        return "white";
    }
  };

  if (!jsonContent) {
    return null;
  }

  return (
    <div className="col-12 px-0 mt-2">
      <div className="grid-container-dust-collector-layout">
        <div className="top-message-bark-bin-screws">
          {jsonContent?.top?.toUpperCase()}
        </div>
        <div className="left-message-bark-bin-screws">
          {jsonContent?.left?.toUpperCase()}
        </div>
        <div className="right-message-bark-bin-screws">
          {jsonContent?.right?.toUpperCase()}
        </div>
        <div className="bottom-message-bark-bin-screws">
          {jsonContent?.bottom?.toUpperCase()}
        </div>
        <div className="bars-container-bark-bin-screws">
          {jsonContent && jsonContent.rows.length !== 0 ? (
            <ListGroup className="mb-2 d-flex flex-nowrap">
              <div style={{ whiteSpace: "nowrap", position: "relative" }}>
                {jsonContent &&
                  jsonContent.rows?.[0]?.map((_, indexRow) => (
                    <div
                      key={`row-${indexRow}`}
                      className="row-dust-collector-layout"
                    >
                      {jsonContent.rows.map((column, indexColumn) => {
                        const matrixColumn = column[indexRow];
                        const status = matrixColumn?.status || "default";
                        return (
                          <div
                            key={`cell-${indexRow}-${indexColumn}`}
                            className="grid-item-dust-collector-layout"
                          >
                            <div
                              className="circle-dust-collector-layout"
                              style={{
                                backgroundColor: getBackgroundColor(status),
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  ))}
              </div>
            </ListGroup>
          ) : (
            "There is no layout information"
          )}
        </div>
      </div>
      <div className="legend-container">
        <span className="legend-container-title">LEGEND:</span>
        <div className="legend-item">
          <div
            className="circle-legend-dust-collector"
            style={{ backgroundColor: getBackgroundColor("") }}
          ></div>
          <span>NO CHANGE</span>
        </div>
        <div className="legend-item">
          <div
            className="circle-legend-dust-collector"
            style={{ backgroundColor: getBackgroundColor("replaced") }}
          ></div>
          <span>REPLACED</span>
        </div>
        <div className="legend-item">
          <div
            className="circle-legend-dust-collector"
            style={{ backgroundColor: getBackgroundColor("replaceNextOutage") }}
          ></div>
          <span>REPLACE NEXT OUTAGE</span>
        </div>
      </div>
    </div>
  );
};

export default DustCollectorLayout;
