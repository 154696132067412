import React from "react";
import { Input } from "reactstrap";

const Clockface = ({ inspectionAnswer, isEdit, inputValue, setInputValue }) => {
  const jsonContent = isEdit
    ? inputValue
    : JSON.parse(inspectionAnswer.content);

  if (!jsonContent) {
    return null;
  }

  return (
    <div
      className="center-content-layout-alignment-entry"
      style={{ marginBottom: "20px" }}
    >
      <div className="circle-clockface">
        <div className="input-container-clockface top-clockface">
          <Input
            step={0.01}
            max={9999999999}
            min={0}
            required
            disabled={!isEdit}
            className="input-clockface"
            type="number"
            value={parseFloat(jsonContent?.top || 0)}
            onChange={(e) =>
              setInputValue({
                ...inputValue,
                top: JSON.stringify(parseFloat(e.target.value)),
              })
            }
          />
          <div className="triangle-down"></div>
        </div>
        <div className="input-container-clockface right-clockface">
          <Input
            step={0.01}
            max={9999999999}
            min={0}
            required
            disabled={!isEdit}
            className="input-clockface"
            type="number"
            value={parseFloat(jsonContent?.right || 0)}
            onChange={(e) =>
              setInputValue({
                ...inputValue,
                right: JSON.stringify(parseFloat(e.target.value)),
              })
            }
          />
          <div class="triangle-left"></div>
        </div>
        <div class="input-container-clockface bottom-clockface">
          <Input
            step={0.01}
            max={9999999999}
            min={0}
            disabled={!isEdit}
            required
            type="number"
            className="input-clockface"
            value={parseFloat(jsonContent?.bottom || 0)}
            onChange={(e) =>
              setInputValue({
                ...inputValue,
                bottom: JSON.stringify(parseFloat(e.target.value)),
              })
            }
          />
          <div class="triangle-up"></div>
        </div>
        <div class="input-container-clockface left-clockface">
          <Input
            step={0.01}
            max={9999999999}
            min={0}
            disabled={!isEdit}
            className="input-clockface"
            required
            type="number"
            value={parseFloat(jsonContent?.left || 0)}
            onChange={(e) =>
              setInputValue({
                ...inputValue,
                left: JSON.stringify(parseFloat(e.target.value)),
              })
            }
          />
          <div class="triangle-right"></div>
        </div>
      </div>
    </div>
  );
};

export default Clockface;
