import React from "react";
import Routes from "./routes/Routes";
import { AuthProvider } from "./providers/authProvider";
import { EmployeesProvider } from "./providers/employeesProvider";
import { WorkOrdersProvider } from "./providers/workOrdersProvider";
import { CrewsProvider } from "./providers/crewsProvider";
import { SubContractorsProvider } from "./providers/subContractorsProvider";
import { GeneralWorkTimesProvider } from "./providers/generalWorkTimesProvider";
import { UsersProvider } from "./providers/usersProvider";
import { PayrollReportProvider } from "./providers/payrollReportProvider";
import { SidebarProvider } from "./providers/sidebarProvider";
import { WorkOrderDetailsProvider } from "./providers/workOrderDetailsProvider";
import { CommunicationLogProvider } from "./providers/communicationLogProvider";
import { CrewManagementProvider } from "./providers/crewManagementProvider";
import { EmployeeRolesProvider } from "./providers/employeeRolesProvider";
import { ExpensesProvider } from "./providers/expensesProvider";
import { WorkOrderStatusProvider } from "./providers/workOrderStatusProvider";

class App extends React.Component {
  render() {
    return (
      <SidebarProvider>
        <AuthProvider>
          <EmployeesProvider>
            <CrewsProvider>
              <WorkOrdersProvider>
                <GeneralWorkTimesProvider>
                  <SubContractorsProvider>
                    <UsersProvider>
                      <PayrollReportProvider>
                        <WorkOrderDetailsProvider>
                          <CommunicationLogProvider>
                            <CrewManagementProvider>
                              <EmployeeRolesProvider>
                                <ExpensesProvider>
                                  <WorkOrderStatusProvider>
                                    <Routes />
                                  </WorkOrderStatusProvider>
                                </ExpensesProvider>
                              </EmployeeRolesProvider>
                            </CrewManagementProvider>
                          </CommunicationLogProvider>
                        </WorkOrderDetailsProvider>
                      </PayrollReportProvider>
                    </UsersProvider>
                  </SubContractorsProvider>
                </GeneralWorkTimesProvider>
              </WorkOrdersProvider>
            </CrewsProvider>
          </EmployeesProvider>
        </AuthProvider>
      </SidebarProvider>
    );
  }
}

export default App;
