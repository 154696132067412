import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import moment from "moment";

import { utilsHelper } from "../../../helpers/utilsHelper";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";

import { nonJobProjectsApi } from "../../../services/nonJobProjectsServices";
import WorkOrderDatesSelector from "./WorkOrderDatesSelector";
import { jobsApi } from "../../../services/jobServices";

const TODAY = utilsHelper.formatDate(moment());

const STATUS_OPEN = 1;
const JOB_SOURCE_ALL = 4;

const NonJobProjectModal = ({
  nonJobProject = {
    isActive: true,
    workOrderType: "NONJOB",
    startDate: TODAY,
    endDate: TODAY,
    statusId: STATUS_OPEN,
  },
  onClose,
  onSubmit,
}) => {
  const [nonJobProjectData, setNonJobProjectData] = useState(nonJobProject);
  const [isLoading, setLoading] = useState();
  const [sources, setSources] = useState([]);
  const [types, setTypes] = useState([]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const sources = await jobsApi.getJobSources();
        const types = await nonJobProjectsApi.getNonJobTypes();
        setLoading(false);
        setTypes(types);
        setSources(sources);
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Error",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, []);

  const doSubmit = async (e) => {
    e.preventDefault();
    if (!nonJobProjectData.statusId) {
      return setInformationModal({
        isOpen: true,
        title: "Update Project",
        body: "Project Status is required",
      });
    }
    setLoading(true);
    if (nonJobProjectData.id) {
      await nonJobProjectsApi.updateNonJobProject(nonJobProjectData);
    } else {
      await nonJobProjectsApi.createNonJobProject(nonJobProjectData);
    }
    setLoading(false);
    onSubmit();
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="lg">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {nonJobProjectData.id ? "Edit" : "Create"} Non Job Time Project
        </ModalHeader>
        <ModalBody>
          {isLoading ? (
            <Loader size="sm" />
          ) : (
            <Row>
              <Col className="px-0">
                <FormGroup className="col-12">
                  <Label for="division">
                    <span>Division</span>
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    type="select"
                    name="division"
                    id="division"
                    onChange={(e) =>
                      setNonJobProjectData({
                        ...nonJobProjectData,
                        jobSourceId: e.target.value,
                      })
                    }
                    value={nonJobProjectData.jobSourceId}
                    required
                  >
                    <option value={""}>Select a Company</option>
                    {sources
                      .filter((source) => source.id !== JOB_SOURCE_ALL)
                      .map((source) => (
                        <option key={source.id} value={source.id}>
                          {source.sourceKey}
                        </option>
                      ))}
                  </Input>
                </FormGroup>
                <FormGroup className="col-12 mb-0">
                  <Label for="description">
                    <span>Description</span>
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    className="height-40p border"
                    maxLength="100"
                    type="textarea"
                    placeholder="Enter the description"
                    name="description"
                    value={nonJobProjectData.description || ""}
                    onChange={(e) =>
                      setNonJobProjectData({
                        ...nonJobProjectData,
                        description: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
              </Col>
              <Col className="px-0">
                <FormGroup className="col-12">
                  <Label for="type">
                    <span>Job Type</span>
                  </Label>
                  <Input
                    type="select"
                    name="type"
                    id="type"
                    onChange={(e) =>
                      setNonJobProjectData({
                        ...nonJobProjectData,
                        nonJobProjectTypeId: e.target.value,
                      })
                    }
                    value={nonJobProjectData.nonJobProjectTypeId}
                    required
                  >
                    <option value={""}>Select a type</option>
                    {types.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup className="col-12  mb-0">
                  <WorkOrderDatesSelector
                    defaultStartDate={nonJobProjectData.startDate}
                    defaultEndDate={nonJobProjectData.endDate}
                    modalParent={true}
                    onSubmit={(startDate, endDate) => {
                      setNonJobProjectData({
                        ...nonJobProjectData,
                        startDate: utilsHelper.formatDate(startDate),
                        endDate: utilsHelper.formatDate(endDate),
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color="secondary" onClick={onClose}>
                Cancel
              </Button>
              <Button color="primary" type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default NonJobProjectModal;
