import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import { apiHelper } from "../helpers/apiHelper";
import moment from "moment";

export const nonJobProjectsApi = {
  getNonJobTypes: () => {
    return axios(`/api/non-job-project-types`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getNonJobProjects: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/non-job-work-orders?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createNonJobProject: (data) => {
    return axios(`/api/non-job-work-orders`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateNonJobProject: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/non-job-work-orders/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  deleteNonJobProject: (data) => {
    return axios(`/api/non-job-work-orders/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  completeNonJobProject: (data) => {
    return axios(`/api/non-job-work-orders/${data.id}/complete`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "patch",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getNonJobProject: (id, token) => {
    const request = {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    };
    if (token) {
      request.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return axios(`/api/non-job-work-orders/${id}`, request)
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
