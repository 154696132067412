import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../helpers/apiHelper";
import { utilsHelper } from "../helpers/utilsHelper";

export const inspectionsJsaApi = {
  inspectionJSASummaryReport: (data) => {
    return axios(`/api/inspection-jsa/reports/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      responseType: "blob",
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  inspectionJSAReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/inspection-jsa/reports?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION }, //addes token to headers for auth
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
