import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Label,
} from "reactstrap";

import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faPen } from "@fortawesome/free-solid-svg-icons";
import DateRangePicker from "../../DateRangePicker";

const EditModal = ({
  isOpen,
  setIsOpen,
  startDate,
  endDate,
  onSelection,
  onSubmit,
}) => {
  const closeBtn = (
    <Button className="close" color="none" onClick={() => setIsOpen(false)}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={isOpen} onClosed={() => setIsOpen(false)}>
      <ModalHeader close={closeBtn}>Date Range Selector</ModalHeader>
      <ModalBody>
        <DateRangePicker
          label="Selected Dates"
          startDate={moment(startDate)}
          endDate={moment(endDate)}
          onSelection={onSelection}
        />
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          color="secondary"
          onClick={() => setIsOpen(false)}
          className="rounded"
        >
          Discard
        </Button>{" "}
        <div className="min-width-50">
          <Button
            className="rounded"
            disabled={!startDate || !endDate}
            color="primary"
            onClick={() => {
              setIsOpen(false);
              onSubmit(startDate, endDate);
            }}
          >
            Save
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const WorkOrderDatesSelector = ({
  defaultStartDate,
  defaultEndDate,
  onSubmit,
  modalParent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const onSelection = (range) => {
    setStartDate(range.from);
    setEndDate(range.to);
  };

  const Data = () => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-start; align-items-center p-2 border rounded bg-white">
            <FontAwesomeIcon
              icon={faCalendar}
              className="mr-2 text-success"
              style={{ paddingBottom: "1px" }}
            />
            <span>{moment(startDate).format("MM/DD/YYYY")}</span>
          </div>
          <div className="mx-2">to</div>
          <div className="d-flex align-items-center p-2 border rounded bg-white">
            <FontAwesomeIcon
              icon={faCalendar}
              className="mr-2 text-success"
              style={{ paddingBottom: "1px" }}
            />
            <span>{moment(endDate).format("MM/DD/YYYY")}</span>
          </div>
        </div>
        <Button onClick={() => setIsOpen(true)} size="sm" className="rounded">
          <FontAwesomeIcon icon={faPen} className="mr-1" />
          <span>Edit Dates</span>
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className="d-flex flex-column justify-content-center w-100">
        <Label>Dates</Label>
        {modalParent ? (
          isOpen ? (
            <div className="d-flex flex-column">
              <DateRangePicker
                label="Selected Dates"
                startDate={moment(startDate)}
                endDate={moment(endDate)}
                onSelection={onSelection}
              />
              <div className="d-flex justify-content-between mt-2">
                <Button
                  className="rounded"
                  color="secondary"
                  onClick={() => setIsOpen(false)}
                  size="sm"
                >
                  Discard
                </Button>{" "}
                <div className="min-width-50">
                  <Button
                    className="rounded"
                    disabled={!startDate || !endDate}
                    color="success"
                    size="sm"
                    onClick={() => {
                      setIsOpen(false);
                      onSubmit(startDate, endDate);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <Data />
          )
        ) : (
          <>
            <Data />
            <EditModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              startDate={startDate}
              endDate={endDate}
              onSelection={onSelection}
              onSubmit={onSubmit}
            />
          </>
        )}
      </div>
    </>
  );
};

export default WorkOrderDatesSelector;
