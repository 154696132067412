import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  CustomInput,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import Badge from "reactstrap/lib/Badge";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import { utilsHelper } from "../../helpers/utilsHelper";

const OUTAGE_NONE = "NONE";
const MODE_RAW = "raw";

const WO_TYPE_GROUP = "group";
const TITLE_RENTAL_SALES_PRICE = "RENTAL SALES PRICE";

const hoursFields = {
  totalHours: "Total Hours",
  overtimeHours: "Overtime Hours",
  indirectHours: "Indirect Hours",
  directHours: "Direct Hours",
  saftyTraining: "Safety Training",
  crewSizeDay: "Crew Size - Day",
  crewSizeNight: "Crew Size - Night",
  mobilize: "Mobilize",
  regularHours: "Regular Hours",
  totalCrewSize: "Total Crew Size",
  totalDayShift: "Total Day Shifts",
  totalNightShift: "Total Night Shifts",
  totalShift: "Total Shifts",
};

const hiddenCategories = [
  "TRAVEL & LIVING PRICE",
  "TRAVEL & LIVING COST",
  "TRAVEL EXPENSE",
  "LIVING EXPENSE",
  "RENTAL COST",
  "DELIVERY COST",
  "HOURSTOSITE",
];

const hiddenFeatures = ["..........ADDITONAL RENTAL FEES"];

const notTravelCurrencyCategories = [
  "TRAVEL ENTRY",
  "JOB TRAVELER INFORMATION",
];

const WorkOrderJobScope = ({ mode }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [workOrder, setWorkOrder] = useState();

  useEffect(() => {
    if (workOrderDetails.workOrder.type !== WO_TYPE_GROUP) {
      setWorkOrder(workOrderDetails.workOrder);
    }
  }, [workOrderDetails.workOrder]);

  return mode === MODE_RAW ? (
    <Row>
      <Col className="col-12 px-0">
        {workOrder ? <CustomerDetails workOrder={workOrder} /> : null}
        <Details workOrder={workOrder} setWorkOrder={setWorkOrder} />
        {workOrder ? <OutageSummary workOrder={workOrder} /> : null}
        {workOrder ? <LaborSummary workOrder={workOrder} /> : null}
        {workOrder ? <TravelSummary workOrder={workOrder} /> : null}
        {workOrder ? <EquipementRentals workOrder={workOrder} /> : null}
        {workOrder ? <Materials workOrder={workOrder} /> : null}
        {workOrder ? <Rentals workOrder={workOrder} /> : null}
        {workOrder ? <ScopeOfWorkRTF workOrder={workOrder} /> : null}
      </Col>
    </Row>
  ) : (
    <Row>
      <Col className="col-8">
        <Details workOrder={workOrder} setWorkOrder={setWorkOrder} />
        {workOrder ? <OutageSummary workOrder={workOrder} /> : null}
        {workOrder ? <LaborSummary workOrder={workOrder} /> : null}
        {workOrder ? <TravelSummary workOrder={workOrder} /> : null}
        {workOrder ? <EquipementRentals workOrder={workOrder} /> : null}
        {workOrder ? <Materials workOrder={workOrder} /> : null}
        {workOrder ? <Rentals workOrder={workOrder} /> : null}
        {workOrder ? <ScopeOfWorkRTF workOrder={workOrder} /> : null}
      </Col>
      <Col className="col-4">
        {workOrder ? <CustomerDetails workOrder={workOrder} /> : null}
      </Col>
    </Row>
  );
};

const CustomerDetails = ({ workOrder }) => (
  <Card className="mb-0 box-shadow-none">
    <CardBody className="pb-0">
      <h5 className="border bg-tertiary text-white rounded p-2">
        Customer Details
      </h5>
      <ListGroup className="col-12 p-0 my-2">
        <ListGroupItem
          className="py-2 border-bottom font-weight-bold bg-lighter d-flex justify-content-between align-items-center"
          tag="div"
        >
          {workOrder.customerName}
        </ListGroupItem>
        <ListGroupItem
          className="py-2 border-bottom d-flex justify-content-between align-items-center"
          tag="div"
        >
          {workOrder.addressLine}
        </ListGroupItem>
        <ListGroupItem
          className="py-2 border-bottom d-flex justify-content-between align-items-center"
          tag="div"
        >
          {workOrder.state} {workOrder.zip}
        </ListGroupItem>
        <ListGroupItem
          className="py-2 border-bottom d-flex justify-content-between align-items-center"
          tag="div"
        >
          {workOrder.city}
        </ListGroupItem>
        <ListGroupItem
          className="py-2 border-bottom d-flex justify-content-between align-items-center"
          tag="div"
        >
          {workOrder.phone
            ? utilsHelper.formatPhone(workOrder.phone) || workOrder.phone
            : "No Phone"}
        </ListGroupItem>
        <ListGroupItem
          className="py-2 border-bottom d-flex justify-content-between align-items-center"
          tag="div"
        >
          {workOrder.email?.length ? workOrder.email : "No Email"}
        </ListGroupItem>
      </ListGroup>
    </CardBody>
  </Card>
);

const ScopeOfWorkRTF = ({ workOrder }) => {
  const rtfComments = (workOrder.rtfComments || []).filter(
    (item) => item.rtfComment
  );
  return (
    <Card className="mb-0 box-shadow-none">
      <CardBody className="pb-0">
        <h5 className="border bg-tertiary text-white rounded p-2">
          Scope of Work
        </h5>
        {rtfComments.length ? (
          rtfComments.map(({ rtfComment }, i) => (
            <div
              dangerouslySetInnerHTML={{ __html: rtfComment }}
              className="px-2"
              key={i}
            ></div>
          ))
        ) : (
          <ListGroup className="col-12 p-0 mt-2 mb-2">
            <ListGroupItem
              className="py-2 border-bottom d-flex justify-content-between align-items-center"
              tag="div"
            >
              No data to show
            </ListGroupItem>
          </ListGroup>
        )}
      </CardBody>
    </Card>
  );
};

const Details = ({ workOrder, setWorkOrder }) => {
  const [workOrderDetails] = useWorkOrderDetails();

  const detailsFields = {
    configurationNumber: "Configuration No.:",
    quoteNumber: "Quotation No.:",
    itemNumber: "Item Number:",
    saleOrderNumber: "Sales Order No.:",
    custPONumber: "Cust PO No.:",
    drawName: "Job Description:",
    referenceNumber: "Reference Number:",
  };

  const salesFields = {
    docOwnerFullName: "Config Owner",
    salesMgrEmail: "Sales Mgr. Email",
    salesMgrName: "Sales Mgr. Name",
    salesMgrPhone: "Sales Mgr. Phone",
    salesRepEmail: "Sales Rep. Email",
    salesRepName: "Sales Rep. Name",
    salesRepPhone: "Sales Rep. Phone",
  };

  return (
    <Card className="mb-0 box-shadow-none">
      <CardBody className="pb-0">
        <h5 className="border bg-tertiary text-white rounded py-2 px-3">
          Work Order Details
        </h5>
        <div className="my-2 pb-2">
          <ListGroup className="col-12 p-0">
            <ListGroupItem
              className="py-2 border-bottom font-weight-bold bg-lighter d-flex justify-content-between align-items-center"
              tag="div"
            >
              <strong className="flex-shrink-0">Work Order</strong>
              {workOrderDetails.workOrder.type === WO_TYPE_GROUP ? (
                <div>
                  <CustomInput
                    required
                    id="childWoSelect"
                    type="select"
                    name="childWoSelect"
                    onChange={(event) => {
                      const selected =
                        workOrderDetails.workOrder.groupedWorkOrders?.find(
                          (wo) => wo.id === parseInt(event.currentTarget.value)
                        );
                      setWorkOrder(selected || "");
                    }}
                    value={workOrder?.id || ""}
                  >
                    <option value={""}>Select a grouped Work Order</option>
                    {workOrderDetails.workOrder.groupedWorkOrders?.map((wo) => (
                      <option value={wo.id}>{wo.workOrderNumber}</option>
                    ))}
                  </CustomInput>
                </div>
              ) : (
                <span>{workOrderDetails.workOrder.workOrderNumber}</span>
              )}
            </ListGroupItem>
            {workOrder
              ? Object.keys(detailsFields).map((key) => (
                  <ListGroupItem
                    className="py-2 border-bottom d-flex justify-content-between align-items-center"
                    tag="div"
                    key={key}
                  >
                    <strong>{detailsFields[key]}</strong>
                    <span>{workOrder[key] || "-"}</span>
                  </ListGroupItem>
                ))
              : null}
            {workOrder
              ? Object.keys(salesFields).map((key) => {
                  const sales = workOrder["sales"];
                  return (
                    <ListGroupItem
                      className="py-2 border-bottom d-flex justify-content-between align-items-center"
                      tag="div"
                      key={key}
                    >
                      <strong>{salesFields[key]}</strong>
                      <span>
                        {sales && sales[0] && sales[0][key]
                          ? utilsHelper.formatPhone(sales[0][key]) ||
                            sales[0][key] ||
                            "-"
                          : "-"}
                      </span>
                    </ListGroupItem>
                  );
                })
              : null}
          </ListGroup>
        </div>
      </CardBody>
    </Card>
  );
};

const OutageSummary = ({ workOrder }) => {
  const availableShifts = Object.keys(
    (workOrder.workOrderCrews || []).reduce((p, c) => {
      if (c.count > 0) {
        p[c.shift] = true;
      }
      return p;
    }, {})
  );

  const getAvailableOutagesByShift = (shift) => {
    if (!shift) {
      return [];
    }
    const availableWorkOrderCrews = (workOrder.workOrderCrews || []).filter(
      (woc) => woc.shift === shift && woc.count > 0
    );
    const availableOutages = Object.keys(
      availableWorkOrderCrews.reduce((p, c) => {
        p[c.outage || OUTAGE_NONE] = true;
        return p;
      }, {})
    );
    return availableOutages;
  };

  return (
    <Card className="mb-0 box-shadow-none">
      <CardBody className="pb-0">
        <div>
          {availableShifts.map((shift) =>
            getAvailableOutagesByShift(shift).map((outage) => {
              const availableSlots = (workOrder.workOrderCrews || []).filter(
                (woc) =>
                  woc.shift === shift &&
                  woc.outage === (outage === OUTAGE_NONE ? null : outage) &&
                  woc.count > 0
              );
              const outageScope =
                workOrder.workOrderSchedules.find(
                  (scope) =>
                    scope.outage === (outage === "NONE" ? null : outage)
                ) || workOrder;
              return (
                <div key={`${shift}-${outage}`}>
                  <div className="d-flex flex-column my-2">
                    <h5 className="border bg-tertiary text-white rounded py-2 px-3 d-flex justify-content-between align-items-center">
                      <span>{utilsHelper.capitalize(shift)} Crew Dates</span>
                      <Badge color="white" className="ml-2">
                        {utilsHelper.outageLabels(outage)}
                      </Badge>
                    </h5>
                    <ListGroup className="col-12 p-0 mb-2">
                      <ListGroupItem
                        className="py-2 border-bottom d-flex justify-content-between align-items-center"
                        tag="div"
                      >
                        <span>Start Date</span>
                        <span>
                          {utilsHelper.formatDate(
                            outageScope[
                              `${shift?.toLowerCase() || "N/A"}ShiftStartDate`
                            ],
                            "MM/DD/YYYY"
                          )}
                        </span>
                      </ListGroupItem>
                      <ListGroupItem
                        className="py-2 border-bottom d-flex justify-content-between align-items-center"
                        tag="div"
                      >
                        <span>Completion Date</span>
                        <span>
                          {utilsHelper.formatDate(
                            outageScope[
                              `${shift?.toLowerCase() || "N/A"}ShiftEndDate`
                            ],
                            "MM/DD/YYYY"
                          )}
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </div>
                  <div className="d-flex flex-column my-2">
                    <h5 className="border bg-tertiary text-white rounded py-2 px-3 d-flex justify-content-between align-items-center">
                      <span>{utilsHelper.capitalize(shift)} Crew</span>
                      <Badge color="white" className="ml-2">
                        {utilsHelper.outageLabels(outage)}
                      </Badge>
                    </h5>
                    <ListGroup className="col-12 p-0 mb-2">
                      {availableSlots.map((slot) => (
                        <ListGroupItem
                          key={slot.id}
                          className="py-2 border-bottom d-flex justify-content-between align-items-center"
                          tag="div"
                        >
                          <span>{slot.role?.name}</span>
                          <span>{slot?.count || 0}</span>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </div>
                  <div className="d-flex flex-column my-2">
                    <h5 className="border bg-tertiary text-white rounded py-2 px-3 d-flex justify-content-between align-items-center">
                      <span>{utilsHelper.capitalize(shift)} Crew Duration</span>
                      <Badge color="white" className="ml-2">
                        {utilsHelper.outageLabels(outage)}
                      </Badge>
                    </h5>
                    <ListGroup className="col-12 p-0 mb-2">
                      <ListGroupItem
                        className="py-2 border-bottom d-flex justify-content-between align-items-center"
                        tag="div"
                      >
                        <span>Weekday</span>
                        <span>
                          {
                            outageScope[
                              `${
                                shift?.toLowerCase() || "N/A"
                              }CrewDurationWeekday`
                            ]
                          }
                        </span>
                      </ListGroupItem>
                      <ListGroupItem
                        className="py-2 border-bottom d-flex justify-content-between align-items-center"
                        tag="div"
                      >
                        <span>Weekend</span>
                        <span>
                          {
                            outageScope[
                              `${
                                shift?.toLowerCase() || "N/A"
                              }CrewDurationWeekend`
                            ]
                          }
                        </span>
                      </ListGroupItem>
                      <ListGroupItem
                        className="py-2 border-bottom d-flex justify-content-between align-items-center"
                        tag="div"
                      >
                        <span>Shift</span>
                        <span>
                          {
                            outageScope[
                              `${shift?.toLowerCase() || "N/A"}CrewShift`
                            ]
                          }
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </CardBody>
    </Card>
  );
};

const LaborSummary = ({ workOrder }) => (
  <Card className="mb-0 box-shadow-none">
    <CardBody className="pb-0">
      <h5 className="border bg-tertiary text-white rounded py-2 px-3 mb-0">
        Summary Hours
      </h5>
      {workOrder.laborSummaries.length ? (
        <div className="my-2 pb-2">
          {workOrder.laborSummaries.map((laborSummary, i) => (
            <ListGroup className="col-12 p-0 mt-2" key={i}>
              <ListGroupItem
                className="py-2 border-bottom font-weight-bold bg-lighter"
                tag="div"
              >
                {laborSummary.type}
              </ListGroupItem>
              {Object.keys(hoursFields).map((field, i) => (
                <ListGroupItem
                  key={i}
                  className="py-2 border-bottom d-flex justify-content-between align-items-center"
                  tag="div"
                >
                  <span>{hoursFields[field]}</span>
                  <span>{laborSummary[field]}</span>
                </ListGroupItem>
              ))}
            </ListGroup>
          ))}
        </div>
      ) : (
        <ListGroup className="col-12 p-0 mt-2 mb-2">
          <ListGroupItem
            className="py-2 border-bottom d-flex justify-content-between align-items-center"
            tag="div"
          >
            No data to show
          </ListGroupItem>
        </ListGroup>
      )}
    </CardBody>
  </Card>
);

const TravelSummary = ({ workOrder }) => {
  let summary = {};
  (workOrder.travelExpenses || [])
    .filter(({ category }) => hiddenCategories.indexOf(category) < 0)
    .forEach(({ category, item, value }) => {
      const element = { item, value };
      if (summary[category]) {
        summary[category].push(element);
      } else {
        summary[category] = [element];
      }
    });

  const data = Object.keys(summary).filter(
    (category) => hiddenCategories.indexOf(category) < 0
  );

  //add hours to site
  if (!summary["TRAVEL ENTRY"]) {
    return null;
  }
  const travelSpeed = summary["TRAVEL ENTRY"].find(
    (d) => d.item === "TRAVEL SPEED"
  );
  const mileageToSite = summary["TRAVEL ENTRY"].find(
    (d) => d.item === "MILEAGE TO SITE"
  );
  const hoursToSite = summary["TRAVEL ENTRY"].find(
    (d) => d.item === "HOURS TO SITE"
  );
  if (!hoursToSite && travelSpeed && mileageToSite) {
    summary["TRAVEL ENTRY"].push({
      value: (mileageToSite.value / travelSpeed.value).toFixed(2),
      item: "HOURS TO SITE",
    });
  }

  return (
    <Card className="mb-0 box-shadow-none">
      <CardBody className="pb-0">
        <h5 className="border bg-tertiary text-white rounded py-2 px-3">
          Travel Summary
        </h5>
        {data?.length ? (
          data.map((category, i) => (
            <ListGroup key={i} className="col-12 p-0 mt-2 mb-2">
              <ListGroupItem
                className="py-2 border-bottom font-weight-bold bg-lighter"
                tag="div"
              >
                {category}
              </ListGroupItem>
              {summary[category].map(({ item, value }, i) => (
                <ListGroupItem
                  key={i}
                  className="py-2 border-bottom d-flex justify-content-between align-items-center"
                  tag="div"
                >
                  <span>{item}</span>
                  <span>
                    {notTravelCurrencyCategories.indexOf(category) === -1
                      ? utilsHelper.formatCurrency(value)
                      : value}
                  </span>
                </ListGroupItem>
              ))}
            </ListGroup>
          ))
        ) : (
          <ListGroup className="col-12 p-0 mt-2 mb-2">
            <ListGroupItem
              className="py-2 border-bottom d-flex justify-content-between align-items-center"
              tag="div"
            >
              No data to show
            </ListGroupItem>
          </ListGroup>
        )}
      </CardBody>
    </Card>
  );
};

const EquipementRentals = ({ workOrder }) => {
  const companyEquipmentFields = {
    pickup: "Pickup",
    consumables: "Consumables",
    equipmentCost: "Equipment Cost",
    companyOwnedVehicleCost: "Company Owned Vehicle Cost",
    toolTruck: "Tool Truck",
    toolTrail: "Tool Trail",
    gangBoxAndToolsQty: "Gang Box And Tools Qty",
    laserAlignmentQty: "Laser Alignment Qty",
    gasMonitorQty: "Gas Monitor Qty",
  };

  const notCurrencyCategories = [
    "gangBoxAndToolsQty",
    "laserAlignmentQty",
    "gasMonitorQty",
  ];

  return (
    <Card className="mb-0 box-shadow-none">
      <CardBody className="pb-0">
        <h5 className="border bg-tertiary text-white rounded py-2 px-3">
          Equipement/Rentals
        </h5>
        {workOrder.companyEquipments.length ? (
          <div className="my-2 pb-2">
            {workOrder.companyEquipments.map((companyEquipment, i) => (
              <ListGroup className="col-12 p-0 mt-2" key={i}>
                {Object.keys(companyEquipmentFields).map((field, i) => (
                  <ListGroupItem
                    key={i}
                    className="py-2 border-bottom d-flex justify-content-between align-items-center"
                    tag="div"
                  >
                    <span>{companyEquipmentFields[field]}</span>
                    <span>
                      {notCurrencyCategories.indexOf(field) === -1
                        ? utilsHelper.formatCurrency(companyEquipment[field])
                        : companyEquipment[field]}
                    </span>
                  </ListGroupItem>
                ))}
              </ListGroup>
            ))}
          </div>
        ) : (
          <ListGroup className="col-12 p-0 mt-2 mb-2">
            <ListGroupItem
              className="py-2 border-bottom d-flex justify-content-between align-items-center"
              tag="div"
            >
              No data to show
            </ListGroupItem>
          </ListGroup>
        )}
      </CardBody>
    </Card>
  );
};

const Rentals = ({ workOrder }) => {
  let summary = {};
  const rentalSalesPriceElements = [];
  (workOrder.rentals || [])
    .filter(
      ({ category, featureName }) =>
        hiddenCategories.indexOf(category) < 0 &&
        hiddenFeatures.indexOf(featureName) < 0
    )
    .forEach(
      ({
        category,
        featureName: item,
        categoryValue,
        listDescription,
        listNumberOfLines,
      }) => {
        category = category || "N/A";
        const descriptions = listDescription
          ? listDescription.split(",")
          : [""];
        const numberOfLines = listNumberOfLines
          ? listNumberOfLines.split(",")
          : [""];
        descriptions.forEach((description, index) => {
          const element = {
            item,
            description: description.trim(),
            value: categoryValue
              ? utilsHelper.formatCurrency(categoryValue)
              : utilsHelper.formatDecimal(numberOfLines[index]),
          };
          if (item === TITLE_RENTAL_SALES_PRICE) {
            rentalSalesPriceElements.push(element);
          } else {
            if (summary[category]) {
              summary[category].push(element);
            } else {
              summary[category] = [element];
            }
          }
        });
      }
    );

  Object.keys(summary).forEach((category) => {
    summary[category] = summary[category].concat(rentalSalesPriceElements);
  });

  const data = Object.keys(summary)
    .reverse()
    .filter((category) => hiddenCategories.indexOf(category) < 0);

  return (
    <Card className="mb-0 box-shadow-none">
      <CardBody className="pb-0">
        <h5 className="border bg-tertiary text-white rounded py-2 px-3">
          Rentals
        </h5>
        {data?.length ? (
          data.map((category, i) => (
            <ListGroup key={i} className="col-12 p-0 mt-2 mb-2">
              {summary[category]
                .filter(({ value }) => value !== "NaN")
                .reduce((acc, { item, value, description }) => {
                  const existingIndex = acc.findIndex(
                    (entry) => entry.item === item
                  );
                  if (existingIndex === -1) {
                    acc.push({ item, values: [{ value, description }] });
                  } else {
                    acc[existingIndex].values.push({ value, description });
                  }
                  return acc;
                }, [])
                .map(({ item, values }, i) => {
                  const newItem =
                    item === TITLE_RENTAL_SALES_PRICE ? "RENTAL PRICE" : item;
                  return (
                    <ListGroupItem
                      key={i}
                      className="py-2 border-bottom "
                      tag="div"
                      style={{ display: "block" }}
                    >
                      <div className="d-flex flex-column">
                        <span>{newItem}</span>
                        {values.map(({ description, value }, j) => (
                          <div key={j} className="d-flex align-items-center">
                            <small>{description}</small>
                            <span className="ml-auto text-right">{value}</span>
                          </div>
                        ))}
                      </div>
                    </ListGroupItem>
                  );
                })}
            </ListGroup>
          ))
        ) : (
          <ListGroup className="col-12 p-0 mt-2 mb-2">
            <ListGroupItem
              className="py-2 border-bottom d-flex justify-content-between align-items-center"
              tag="div"
            >
              No data to show
            </ListGroupItem>
          </ListGroup>
        )}
      </CardBody>
    </Card>
  );
};

const Materials = ({ workOrder }) => {
  const materials = (workOrder.materials || []).filter(
    (material) => material.amount > 0
  );

  return (
    <Card className="mb-0 box-shadow-none">
      <CardBody className="pb-0">
        <h5 className="border bg-tertiary text-white rounded py-2 px-3">
          Materials
        </h5>
        <ListGroup className="col-12 p-0 mt-2 mb-2">
          {materials.length ? (
            materials.map((material, i) => (
              <ListGroupItem
                key={i}
                className="py-2 border-bottom d-flex justify-content-between align-items-center"
                tag="div"
              >
                <span>{material.requiredMaterialName}</span>
                <span>{utilsHelper.formatCurrency(material.amount)}</span>
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem
              className="py-2 border-bottom d-flex justify-content-between align-items-center"
              tag="div"
            >
              No data to show
            </ListGroupItem>
          )}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

export default WorkOrderJobScope;
