import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Document, Page } from "react-pdf";
import { FilePreviewStrategy } from "./FilePreviewStrategy";

const PDFPreview = ({ url }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <>
      <Document
        className="d-flex justify-content-center"
        file={url}
        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <div className="d-flex justify-content-between p-3 align-items-center text-body">
        <FontAwesomeIcon
          className="cursor-pointer text-body"
          icon={faChevronLeft}
          onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
        />
        <span>
          Page {pageNumber} of {numPages}
        </span>
        <FontAwesomeIcon
          className="cursor-pointer text-body"
          icon={faChevronRight}
          onClick={() => setPageNumber(Math.min(pageNumber + 1, numPages))}
        />
      </div>
    </>
  );
};

class PDFPreviewStrategy extends FilePreviewStrategy {
  renderPreview(url) {
    return <PDFPreview url={url} />;
  }
}

export default PDFPreviewStrategy;
