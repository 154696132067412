import React, { useEffect, useState } from "react";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, CustomInput, ListGroupItem, Input, Table } from "reactstrap";
import {
  utilsHelper,
  workNonJobTimeMap,
} from "../../../../helpers/utilsHelper";
import { faPlus, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../../Loader";
import TooltipItem from "../../../TooltipItem";

const TIME_TYPE_REGULAR = "regular";
const TIME_TYPE_MILEAGE = "mileage";
const MANUAL_PER_DIEM_AMOUNT = "other";

const AddTimeNonJob = ({
  employeeCrew,
  employeeEnabled,
  setEmployeeEnabled,
  timeToAdd,
  setTimeToAdd,
  generalWorkTimesTypesToAdd,
  setGeneralWorkTimeTypesToAdd,
  perDiemAmountIdToAdd,
  setPerDiemAmountIdToAdd,
  payrollLocked,
  reasonToAdd,
  setReasonToAdd,
  selectedDate,
  crew,
  generalWorkTimeTypes,
  perDiemAmounts,
  isLoading,
  manualPerDiemToAdd,
  setManualPerDiemToAdd,
}) => {
  const [workTime, setWorkTime] = useState(null);
  const [manualPerDiemState, setManualPerDiemState] = useState(null);

  const calculateHasPerDiem = (workTime) => {
    return (
      !!(workTime?.perDiemAmountId && workTime?.perDiemSuplemented) ||
      !!workTime?.modifiedBy ||
      !!workTime?.createdBy
    );
  };

  useEffect(() => {
    const formattedSelectedDate = utilsHelper.formatDate(selectedDate);
    const crewWorkDay = crew?.crewWorkDayCrew.find(
      (crewWorkDay) => crewWorkDay.date === formattedSelectedDate
    );
    const workTime = crewWorkDay?.workTimes.find(
      (workTime) => workTime.employeeId === employeeCrew.employee.id
    );

    if (workTime && workTime.perDiemSuplemented && workTime.perDiemAmountId) {
      setPerDiemAmountIdToAdd((prevState) => ({
        ...prevState,
        [employeeCrew.employee.id]: workTime.perDiemAmountId,
      }));
      setManualPerDiemState(null);
      setManualPerDiemToAdd((prevState) => ({
        ...prevState,
        [employeeCrew.employee.id]: null,
      }));
    } else if (workTime?.manualPerDiem && workTime?.perDiemSuplemented) {
      setManualPerDiemState(MANUAL_PER_DIEM_AMOUNT);
      setManualPerDiemToAdd((prevState) => ({
        ...prevState,
        [employeeCrew.employee.id]: workTime.manualPerDiem,
      }));
      setPerDiemAmountIdToAdd((prevState) => ({
        ...prevState,
        [employeeCrew.employee.id]: null,
      }));
    } else {
      setPerDiemAmountIdToAdd((prevState) => ({
        ...prevState,
        [employeeCrew.employee.id]: null,
      }));
      setManualPerDiemState(null);
      setManualPerDiemToAdd((prevState) => ({
        ...prevState,
        [employeeCrew.employee.id]: null,
      }));
    }

    setWorkTime(workTime);
  }, [
    selectedDate,
    crew,
    employeeCrew,
    setManualPerDiemToAdd,
    setPerDiemAmountIdToAdd,
  ]);

  const handleHourChange = (event, type, employeeId) => {
    const { value } = event.target;
    setTimeToAdd((prevHours) => ({
      ...prevHours,
      [employeeId]: {
        ...prevHours[employeeId],
        [type]: value,
      },
    }));
  };

  const handlePerDiemAmount = (event) => {
    const { value } = event.target;
    if (value !== MANUAL_PER_DIEM_AMOUNT) {
      setPerDiemAmountIdToAdd({
        ...perDiemAmountIdToAdd,
        [employeeCrew.employee.id]: value,
      });
      setManualPerDiemState(null);
      setManualPerDiemToAdd({
        ...manualPerDiemToAdd,
        [employeeCrew.employee.id]: null,
      });
    } else {
      setPerDiemAmountIdToAdd({
        ...perDiemAmountIdToAdd,
        [employeeCrew.employee.id]: null,
      });
      setManualPerDiemState(MANUAL_PER_DIEM_AMOUNT);
    }
  };

  return (
    <ListGroupItem tag="div" key={employeeCrew.id}>
      <span className="d-flex justify-content-between align-items-center">
        {`${employeeCrew.employee.firstName} ${employeeCrew.employee.lastName} 
                ${!employeeCrew.employee.isActive ? "(Inactive)" : ""}`}
        {!employeeEnabled[employeeCrew.employee.id] && (
          <Button
            className="rounded"
            size="sm"
            color="warning"
            onClick={() =>
              setEmployeeEnabled({
                ...employeeEnabled,
                [employeeCrew.employee.id]: true,
              })
            }
            disabled={!employeeCrew.employee.isActive}
          >
            <FontAwesomeIcon icon={faPlus} className={"mr-2 text-white"} />
            Add Time
          </Button>
        )}
      </span>

      {employeeEnabled[employeeCrew.employee.id] && (
        <div className="mt-3 mx-auto">
          <Table className="border col-12 px-0 mb-0">
            <FontAwesomeIcon
              onClick={() =>
                setEmployeeEnabled({
                  ...employeeEnabled,
                  [employeeCrew.employee.id]: false,
                })
              }
              icon={faTimesCircle}
              className="mt-2 cursor-pointer text-danger bg-white upper-close"
            />
            <thead>
              <tr className="bg-lighter">
                <th>Type</th>
                <th style={{ width: "25%" }}>Hours</th>
                <th style={{ width: "25%" }}>Mileage</th>
                <th>Time Type</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(workNonJobTimeMap).map(([type, label]) => (
                <tr key={type}>
                  <td>{label}</td>
                  <td style={{ width: "25%" }}>
                    {type !== TIME_TYPE_MILEAGE ? (
                      <Input
                        required={
                          generalWorkTimesTypesToAdd[
                            employeeCrew.employee.id
                          ] !== undefined &&
                          generalWorkTimesTypesToAdd[
                            employeeCrew.employee.id
                          ] !== "" &&
                          type === TIME_TYPE_REGULAR
                            ? true
                            : false
                        }
                        min={type === TIME_TYPE_REGULAR ? 0 : 0.1}
                        max={24}
                        step={0.01}
                        placeholder={"Hours"}
                        type="number"
                        value={
                          timeToAdd[employeeCrew.employee.id]?.[type] || ""
                        }
                        onChange={(e) =>
                          handleHourChange(e, type, employeeCrew.employee.id)
                        }
                      />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td style={{ width: "25%" }}>
                    {type === TIME_TYPE_MILEAGE ? (
                      <Input
                        min={0.1}
                        max={999999999}
                        step={0.01}
                        placeholder={"Mileage"}
                        type="number"
                        value={
                          timeToAdd[employeeCrew.employee.id]?.[type] || ""
                        }
                        onChange={(e) =>
                          handleHourChange(e, type, employeeCrew.employee.id)
                        }
                      />
                    ) : (
                      "-"
                    )}
                  </td>
                  <td>
                    {type === TIME_TYPE_REGULAR ? (
                      <CustomInput
                        required={
                          timeToAdd[employeeCrew.employee.id]?.[type] !== "0" &&
                          timeToAdd[employeeCrew.employee.id]?.[type] !== "" &&
                          timeToAdd[employeeCrew.employee.id]?.[type] !==
                            undefined
                            ? true
                            : false
                        }
                        id="generalWorkTypeSelect"
                        type="select"
                        name="generalWorkTypeSelect"
                        onChange={(event) =>
                          setGeneralWorkTimeTypesToAdd({
                            ...generalWorkTimesTypesToAdd,
                            [employeeCrew.employee.id]:
                              event.currentTarget.value,
                          })
                        }
                        value={
                          generalWorkTimesTypesToAdd[
                            employeeCrew.employee.id
                          ] || ""
                        }
                      >
                        <option value={""}>Select Time Type</option>
                        {generalWorkTimeTypes.map((wttypes) => (
                          <option key={wttypes.id} value={wttypes.id}>
                            {utilsHelper.capitalize(wttypes.name)}
                          </option>
                        ))}
                      </CustomInput>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-between mt-3 align-items-center">
            <span className="">Per Diem Amount</span>
            {isLoading ? (
              <Loader size="sm" />
            ) : (
              <div className="d-flex align-items-center">
                <CustomInput
                  id="workTimePerDiemAmountIdSelect"
                  type="select"
                  name="type"
                  disabled={calculateHasPerDiem(workTime)}
                  value={
                    manualPerDiemState
                      ? MANUAL_PER_DIEM_AMOUNT
                      : perDiemAmountIdToAdd[employeeCrew.employee.id] || ""
                  }
                  onChange={(e) => handlePerDiemAmount(e)}
                >
                  <option value={""}>None</option>
                  <option value={MANUAL_PER_DIEM_AMOUNT}>Other</option>
                  {perDiemAmounts.map((perDiemAmount) => {
                    return (
                      <option key={perDiemAmount.id} value={perDiemAmount.id}>
                        {perDiemAmount.amount}
                      </option>
                    );
                  })}
                </CustomInput>
                {manualPerDiemState ||
                manualPerDiemToAdd[employeeCrew.employee.id] ? (
                  <Input
                    required={true}
                    min={1}
                    disabled={calculateHasPerDiem(workTime)}
                    max={185}
                    step={0.01}
                    placeholder={"Manual Per Diem"}
                    style={{ width: "190px" }}
                    type="number"
                    name="manualPerDiem"
                    className="ml-2"
                    onChange={(e) =>
                      setManualPerDiemToAdd({
                        ...manualPerDiemToAdd,
                        [employeeCrew.employee.id]: e.target.value,
                      })
                    }
                    value={manualPerDiemToAdd[employeeCrew.employee.id] || ""}
                  />
                ) : null}
                {calculateHasPerDiem(workTime) ? (
                  <TooltipItem
                    className="ml-2"
                    title="There is already a Per Diem Amount selected"
                  >
                    <FontAwesomeIcon
                      size="lg"
                      icon={faExclamationCircle}
                      className={`ml-2 text-info`}
                      style={{ paddingBottom: "1px" }}
                    />
                  </TooltipItem>
                ) : null}
              </div>
            )}
          </div>
          {payrollLocked ? (
            <ListGroupItem
              className="d-flex justify-content-center align-items-center py-2 mt-3"
              tag="div"
            >
              <Input
                required={true}
                maxLength={100}
                placeholder="Reason"
                type="text"
                value={reasonToAdd?.[employeeCrew.employee.id] || ""}
                onChange={(event) =>
                  setReasonToAdd({
                    ...reasonToAdd,
                    [employeeCrew.employee.id]: event.currentTarget.value,
                  })
                }
              />
            </ListGroupItem>
          ) : null}
        </div>
      )}
    </ListGroupItem>
  );
};

export default AddTimeNonJob;
