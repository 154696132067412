import React, { useCallback, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  InputGroup,
  Input,
  Button,
  InputGroupText,
  CardFooter,
  Badge,
} from "reactstrap";

import { employeesApi } from "../../services/employeeServices";

import {
  faSearch,
  faPen,
  faSync,
  faTrash,
  faPlus,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomCheckboxWithLabel from "../../components/CustomCheckboxWithLabel";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";
import ConfirmationModal from "../../components/ConfirmationModal";
import SubContractorModal from "../../components/admin/subcontractors/SubContractorModal";
import CompanyModal from "../../components/admin/subcontractors/CompanyModal";
import CompaniesModal from "../../components/admin/subcontractors/CompaniesModal";
import {
  ACTIONS,
  useSubContractors,
} from "../../providers/subContractorsProvider";
import InformationModal from "../../components/InformationModal";
import { useAuth } from "../../providers/authProvider";
import { utilsHelper } from "../../helpers/utilsHelper";

const EMPLOYEE_SUBCONTRACTOR_TYPE = "2";

const columns = (onEdit, onDelete, IS_READ_ONLY) => {
  const cols = [
    {
      accessor: "firstName",
      Header: "First Name",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { firstName, preferredFirstName } = rowData.row.original;
        return preferredFirstName
          ? `${firstName} (${preferredFirstName})`
          : firstName;
      },
    },
    {
      accessor: "lastName",
      Header: "Last Name",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { lastName } = rowData.row.original;
        return lastName || "-";
      },
    },
    {
      accessor: "email",
      Header: "Email",
      headerProps: { className: "text-truncate" },
      width: 200,
      Cell: (rowData) => {
        const { email } = rowData.row.original;
        return email || "-";
      },
    },
    {
      accessor: "phone",
      Header: "Phone",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { phone } = rowData.row.original;
        return phone || "-";
      },
    },
    {
      accessor: "role.name",
      Header: "Role",
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      Cell: (rowData) => {
        const { role } = rowData.row.original;
        return <Badge>{role?.name || "-"}</Badge>;
      },
    },
    {
      accessor: "subContractorCompany.name",
      Header: "Company",
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      width: 100,
      Cell: (rowData) => {
        const { subContractorCompany } = rowData.row.original;
        return subContractorCompany?.name || "-";
      },
    },
    {
      accessor: "department",
      Header: "Division",
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      Cell: (rowData) => {
        const { sourceKey } = rowData.row.original;
        return sourceKey || "-";
      },
    },
  ];
  if (!IS_READ_ONLY) {
    cols.push({
      accessor: "id",
      Header: "Actions",
      disableSortBy: true,
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      Cell: (rowData) => (
        <div>
          <Button
            size="sm"
            color="warning"
            className="rounded"
            onClick={() => onEdit(rowData.row.original)}
          >
            <FontAwesomeIcon icon={faPen} className="mr-1" />
            <span>Edit</span>
          </Button>{" "}
          <Button
            size="sm"
            className="rounded"
            color="danger"
            onClick={() => onDelete(rowData.row.original)}
          >
            <FontAwesomeIcon icon={faTrash} className="mr-1" />
            <span>Delete</span>
          </Button>
        </div>
      ),
    });
  }
  return cols;
};

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const SubContractors = () => {
  const [authContext] = useAuth();
  const [subContractorsContext, setSubContractorsContext] = useSubContractors();

  const [loading, setLoading] = useState(true);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [createSubContractorModal, setCreateSubContractorModal] =
    useState(false);

  const [companiesModal, setCompaniesModal] = useState(false);
  const [companyModal, setCompanyModal] = useState({});

  const [editSubContractorModal, setEditSubContractorModal] = useState(false);

  const [showInactive, setShowInactive] = useState();

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          subContractorsContext.sortBy === sortBy?.id &&
          subContractorsContext.direction === direction
        ) {
          return;
        }
        setSubContractorsContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setSubContractorsContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [
      setSubContractorsContext,
      subContractorsContext.direction,
      subContractorsContext.sortBy,
    ]
  );

  const onEdit = (subContractor) => setEditSubContractorModal(subContractor.id);

  const onDelete = (subContractor) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: () => {
        employeesApi.delete(subContractor).finally(() => {
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Delete Contract Employee",
            body: "Contract Employee deleted successfully.",
            onClose: () => {
              setInformationModal({ isOpen: false });
              setSubContractorsContext({
                action: ACTIONS.REFRESH,
              });
            },
          });
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setSubContractorsContext({
          action: ACTIONS.REFRESH,
        });
      },
      title: "Delete Contract Employee",
      body: `<p class="text-center">Do you confirm you want to delete this Contract Employee?</p>
        <span>${subContractor.firstName} ${subContractor.lastName} - ${subContractor.role.name}</span>`,
    });
  };

  useEffect(() => {
    setLoading(true);
    employeesApi
      .getEmployees({
        type: EMPLOYEE_SUBCONTRACTOR_TYPE,
        search: subContractorsContext.search,
        page: subContractorsContext.page - 1,
        pageSize: subContractorsContext.sizePerPage,
        sortBy: subContractorsContext.sortBy,
        direction: subContractorsContext.direction,
        isActive: showInactive ? "false" : "true",
        jobSourceId: authContext.currentUser.jobSourceId,
      })
      .then((subContractors) => {
        setSubContractorsContext({
          action: ACTIONS.GET_SUBCONTRACTORS_SUCCESS,
          payload: { subContractors },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    showInactive,
    setSubContractorsContext,
    subContractorsContext.sortBy,
    subContractorsContext.direction,
    subContractorsContext.sizePerPage,
    subContractorsContext.page,
    subContractorsContext.search,
    subContractorsContext.refresh,
    authContext.currentUser.jobSourceId,
  ]);

  const IS_READ_ONLY = utilsHelper.isReadOnly(authContext);

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns(onEdit, onDelete, IS_READ_ONLY)}
          data={subContractorsContext.subContractors?.data || []}
          pageSize={subContractorsContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: subContractorsContext.sortBy,
            direction: subContractorsContext.direction,
          }}
        >
          <Card>
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Contract Employees</h3>
                <small className="text-muted ml-2 pt-1">
                  ({subContractorsContext.subContractors?.count || 0})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup size="m" className="mr-3 border rounded px-2 py-1">
                  <CustomCheckboxWithLabel
                    label="Show Inactive"
                    checked={showInactive}
                    onClick={() => setShowInactive(!showInactive)}
                  />
                </InputGroup>
                <InputGroup size="m" className="mr-3">
                  <Input
                    maxLength="50"
                    placeholder="Search for.."
                    value={subContractorsContext.search}
                    onChange={(evt) =>
                      setSubContractorsContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-primary text-white border-left-0 border-primary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                <Button
                  size="sm"
                  className="mr-3 rounded-circle d-flex custom-rounded-button"
                  color="primary"
                  onClick={() => setCompaniesModal(true)}
                >
                  <FontAwesomeIcon icon={faBuilding} />
                </Button>
                {!utilsHelper.isReadOnly(authContext) ? (
                  <Button
                    size="sm"
                    className="mr-3 rounded-circle d-flex custom-rounded-button"
                    color="primary"
                    onClick={() => setCreateSubContractorModal(true)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                ) : null}
                <Button
                  size="sm"
                  className="mr-3 rounded-circle d-flex custom-rounded-button"
                  color="primary"
                  onClick={() =>
                    setSubContractorsContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              <AdvanceTable
                table
                isLoading={loading}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={subContractorsContext.subContractors?.count || 0}
                pageCount={subContractorsContext.subContractors.totalPages}
                currentPage={subContractorsContext.page - 1}
                onPageChange={(page) =>
                  setSubContractorsContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={subContractorsContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setSubContractorsContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {createSubContractorModal ? (
        <SubContractorModal
          onClose={() => {
            setCreateSubContractorModal(false);
            setSubContractorsContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : companiesModal ? (
        <CompaniesModal
          onClose={() => setCompaniesModal(false)}
          onCreate={() => {
            setCompaniesModal(false);
            setCompanyModal({ open: true });
          }}
          onDelete={(id) => {
            setCompaniesModal(false);
            setConfirmationModal({
              isOpen: true,
              onSubmit: async () => {
                setLoading(true);
                setConfirmationModal(initConfirmationModal);
                try {
                  await employeesApi.deleteSubContractorsCompany({ id });
                  setLoading(false);
                  setCompaniesModal(true);
                } catch (err) {
                  setLoading(false);
                  return setInformationModal({
                    isOpen: true,
                    title: "Delete Contract Employee Company",
                    body:
                      err?.response?.data[0]?.msg ||
                      "There was an error with your request.",
                    onClose: () => setCompaniesModal(true),
                  });
                }
              },
              onClose: () => {
                setConfirmationModal(initConfirmationModal);
                setCompaniesModal(true);
              },
              confirmColor: "danger",
              title: "Delete Contract Employee Company",
              body: `<span class="text-center">Do you confirm you want to delete this Contract Employee company?</span>`,
            });
          }}
          onEdit={(id) => {
            setCompaniesModal(false);
            setCompanyModal({ open: true, id });
          }}
        />
      ) : companyModal.open ? (
        <CompanyModal
          companyId={companyModal.id}
          onClose={() => {
            setCompanyModal({});
            setCompaniesModal(true);
          }}
        />
      ) : editSubContractorModal ? (
        <SubContractorModal
          subContractorId={editSubContractorModal}
          onClose={() => {
            setEditSubContractorModal(false);
          }}
          onSubmit={() => {
            setEditSubContractorModal(false);
            setSubContractorsContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default SubContractors;
