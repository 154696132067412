import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router";
import { travelTimesApi } from "../../../services/travelTimeServices";
import { workTimesApi } from "../../../services/workTimeServices";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { reportsHelper } from "../../../helpers/reportsHelper";
import WeekSelector from "../../../components/admin/WeekSelector";
import PeriodTypeSelector from "../../../components/admin/PeriodTypeSelector";
import CustomPeriodSelector from "../../../components/admin/CustomPeriodSelector";
import Loader from "../../../components/Loader";
import { useAuth } from "../../../providers/authProvider";
import { cashAdvanceApi } from "../../../services/cashAdvanceServices";

const NAME_COLUMN = "Work Order #";

const report = {
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
  ],
  dataSource: {
    data: [
      {
        Employee: {
          type: "string",
        },
        Type: {
          type: "string",
        },
        "Entry Date": {
          type: "string",
        },
        "Change Author": {
          type: "string",
        },
        "Work Order #": {
          type: "string",
        },
        Reason: {
          type: "string",
        },
        "Change Date": {
          type: "string",
        },
        "Change Applied": {
          type: "string",
        },
      },
    ],
  },
  slice: {
    rows: [
      {
        uniqueName: "Employee",
      },
      {
        uniqueName: "Type",
      },
      {
        uniqueName: "Entry Date",
      },
      {
        uniqueName: "Change Author",
      },
      {
        uniqueName: "Work Order #",
      },
      {
        uniqueName: "Reason",
      },
      {
        uniqueName: "Change Date",
      },
      {
        uniqueName: "Change Applied",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Payroll Lock/Updates",
    },
  },
};

const REPORT_NAME = "Payroll_Lock_";
const TYPE_WEEKLY = "TYPE_WEEKLY";

const PayrollLockReport = () => {
  const [authContext] = useAuth();
  const pivot = useRef(null);
  const navigate = useNavigate();
  const [data, setData] = useState(null);

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });
  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));

  const [loading, setLoading] = useState();

  const getFilename = () =>
    reportsHelper.getFileName(
      monday,
      periodType.value === TYPE_WEEKLY,
      REPORT_NAME
    );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const promises = [];
      const condition = {
        withChangeReason: true,
        onPayrollLock: true,
        jobSourceId: authContext.currentUser.jobSourceId,
      };
      if (periodType.value === TYPE_WEEKLY) {
        condition.startDate = moment(monday).format("YYYY-MM-DD");
        condition.endDate = moment(monday)
          .endOf("isoWeek")
          .format("YYYY-MM-DD");
      } else {
        condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
        condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
      }
      promises.push(travelTimesApi.getTravelTimes(condition));
      promises.push(workTimesApi.getWorkTimes(condition));
      promises.push(cashAdvanceApi.getCashAdvances(condition));
      const results = await Promise.all(promises);
      const travelTimes = results[0].data.map((tt) => {
        const user = tt.travelTimeEditor || tt.travelTimeAuthor;
        return {
          Employee: utilsHelper.getEmployeeReverseLabelWithPreferredName(
            tt.employee
          ),
          Type: "Travel Time",
          "Entry Date": moment(tt.crewWorkDay.date).format("MM/DD/YYYY"),
          "Change Author": user ? `${user.firstName} ${user.lastName}` : "-",
          "Work Order #": tt.workOrder.workOrderNumber,
          workOrderId: tt.workOrderId,
          Reason: tt.changeReason,
          "Change Date": tt.updatedAt
            ? moment(tt.updatedAt).format("MM/DD/YYYY")
            : "-",
          "Change Applied": tt.changeApplied,
        };
      });
      const workTimes = results[1].data.map((wt) => {
        const user = wt.workTimeEditor || wt.workTimeAuthor;
        return {
          Employee: utilsHelper.getEmployeeReverseLabelWithPreferredName(
            wt.employee
          ),
          Type: "Work Time",
          "Entry Date": moment(wt.crewWorkDay.date).format("MM/DD/YYYY"),
          "Change Author": user ? `${user.firstName} ${user.lastName}` : "-",
          "Work Order #": wt.workOrder.workOrderNumber,
          workOrderId: wt.workOrderId,
          Reason: wt.changeReason,
          "Change Date": wt.updatedAt
            ? moment(wt.updatedAt).format("MM/DD/YYYY")
            : "-",
          "Change Applied": wt.changeApplied,
        };
      });
      const cashAdvances = results[2].data.map((ca) => {
        const user = ca.perDiemEditor || ca.perDiemAuthor;
        return {
          Employee: utilsHelper.getEmployeeReverseLabelWithPreferredName(
            ca.employee
          ),
          Type: "Cash Advance",
          "Entry Date": moment(ca.crewWorkDay.date).format("MM/DD/YYYY"),
          "Change Author": user ? `${user.firstName} ${user.lastName}` : "-",
          "Work Order #": ca.workOrder.workOrderNumber,
          workOrderId: ca.workOrderId,
          Reason: ca.changeReason,
          "Change Date": ca.updatedAt
            ? moment(ca.updatedAt).format("MM/DD/YYYY")
            : "-",
          "Change Applied":
            ca.updatedAt !== ca.createdAt ? "Update" : "Creation",
        };
      });
      const entries = [...travelTimes, ...workTimes, ...cashAdvances];
      setData(entries);
      setLoading(false);
      if (pivot) {
        pivot.current.flexmonster.updateData({
          data: [
            {
              Employee: {
                type: "string",
              },
              Type: {
                type: "string",
              },
              "Entry Date": {
                type: "string",
              },
              "Change Author": {
                type: "string",
              },
              "Work Order #": {
                type: "string",
              },
              Reason: {
                type: "string",
              },
              "Change Date": {
                type: "string",
              },
              "Change Applied": {
                type: "string",
              },
            },
            ...entries,
          ],
        });
      }
    };
    fetchData();
  }, [
    monday,
    periodType,
    setData,
    pivot,
    customEndDate,
    customStartDate,
    authContext.currentUser.jobSourceId,
  ]);

  useEffect(() => {
    if (pivot?.current?.flexmonster && data?.length) {
      pivot.current.flexmonster.on("celldoubleclick", function (cell) {
        reportsHelper.navigateToWorkOrder(
          cell,
          data,
          NAME_COLUMN,
          null,
          navigate
        );
      });
    }
  }, [pivot, data, navigate]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-end">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : null}
              <PeriodTypeSelector
                periodType={periodType}
                setPeriodType={setPeriodType}
              />
              {periodType.value === TYPE_WEEKLY ? (
                <WeekSelector
                  loading={loading}
                  monday={monday}
                  setMonday={setMonday}
                />
              ) : (
                <CustomPeriodSelector
                  defaultEndDate={customEndDate}
                  defaultStartDate={customStartDate}
                  onSubmit={(startDate, endDate) => {
                    setCustomStartDate(startDate);
                    setCustomEndDate(endDate);
                  }}
                />
              )}
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  //key={(monday + periodType.value).toLocaleString()}
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PayrollLockReport;
