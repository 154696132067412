import React, { useEffect, useState } from "react";

import {
  Col,
  CustomInput,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import InformationModal from "../../components/InformationModal";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import Incident from "./Incident";
import JsaInspection from "./JsaInspection";
import StandDown from "./StandDown";
import ToolBoxTalk from "./ToolBoxTalk";
import Witness from "./Witness";

const TOOLBOXTALK_TAB = "TOOLBOXTALK_TAB";
const JSA_TAB = "JSA_TAB";
const STAND_DOWN_TAB = "STAND_DOWN_TAB";
const INCIDENT_REPORT_TAB = "INCIDENT_REPORT_TAB";
const WITNESS_REPORT_TAB = "WITNESS_REPORT_TAB";
const WO_TYPE_GROUP = "group";

const SafetyItems = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });

  const [selectedWorkOrder, setSelectedWorkOrder] = useState();
  const [activeTab, setActiveTab] = useState(TOOLBOXTALK_TAB);

  useEffect(() => {
    if (workOrderDetails.workOrder.type === WO_TYPE_GROUP) {
      setSelectedWorkOrder();
    } else {
      setSelectedWorkOrder(workOrderDetails.workOrder.id);
    }
  }, [workOrderDetails.workOrder]);

  return (
    <Row>
      <Col sm="12" className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-3">
          <h4 className="mb-0">Safety Items</h4>
          <div className="d-flex align-items-center">
            {workOrderDetails.workOrder.type === WO_TYPE_GROUP ? (
              <CustomInput
                className="mr-2"
                id="woSelect"
                type="select"
                name="woSelect"
                onChange={(event) =>
                  setSelectedWorkOrder(event.currentTarget.value)
                }
                value={selectedWorkOrder}
              >
                <option value={""}>Select a work order</option>
                {workOrderDetails.workOrder.groupedWorkOrders?.map(
                  (groupedWorkOrder) => (
                    <option
                      key={groupedWorkOrder.id}
                      value={groupedWorkOrder.id}
                    >
                      {groupedWorkOrder.workOrderNumber}
                    </option>
                  )
                )}
              </CustomInput>
            ) : null}
          </div>
        </div>
        <Nav tabs className="cursor-pointer safety-items-nav">
          <div className="d-flex border">
            <NavItem>
              <NavLink
                className={
                  activeTab === TOOLBOXTALK_TAB
                    ? "active border-left border-right bg-lightgreen border-bottom"
                    : ""
                }
                onClick={() => {
                  if (activeTab !== TOOLBOXTALK_TAB)
                    setActiveTab(TOOLBOXTALK_TAB);
                }}
              >
                ToolBox Talk
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={
                  activeTab === JSA_TAB
                    ? "active border-left border-right bg-lightgreen border-bottom"
                    : ""
                }
                onClick={() => {
                  if (activeTab !== JSA_TAB) setActiveTab(JSA_TAB);
                }}
              >
                JSA
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={
                  activeTab === STAND_DOWN_TAB
                    ? "active border-left border-right bg-lightgreen border-bottom"
                    : ""
                }
                onClick={() => {
                  if (activeTab !== STAND_DOWN_TAB)
                    setActiveTab(STAND_DOWN_TAB);
                }}
              >
                Safety Stand Down
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={
                  activeTab === INCIDENT_REPORT_TAB
                    ? "active border-left border-right bg-lightgreen border-bottom"
                    : ""
                }
                onClick={() => {
                  if (activeTab !== INCIDENT_REPORT_TAB)
                    setActiveTab(INCIDENT_REPORT_TAB);
                }}
              >
                Incident Reports
              </NavLink>
            </NavItem>
            {workOrderDetails.workOrder.witnessReports.length > 0 && (
              <NavItem>
                <NavLink
                  className={
                    activeTab === WITNESS_REPORT_TAB
                      ? "active border-left border-right bg-lightgreen border-bottom"
                      : ""
                  }
                  onClick={() => {
                    if (activeTab !== WITNESS_REPORT_TAB)
                      setActiveTab(WITNESS_REPORT_TAB);
                  }}
                >
                  Witness Reports
                </NavLink>
              </NavItem>
            )}
          </div>
        </Nav>
        <TabContent activeTab={activeTab} className="border">
          <TabPane tabId={TOOLBOXTALK_TAB}>
            <ToolBoxTalk />
          </TabPane>
          <TabPane tabId={JSA_TAB}>
            <JsaInspection />
          </TabPane>
          <TabPane tabId={STAND_DOWN_TAB}>
            <StandDown />
          </TabPane>
          <TabPane tabId={INCIDENT_REPORT_TAB}>
            <Incident />
          </TabPane>
          <TabPane tabId={WITNESS_REPORT_TAB}>
            <Witness />
          </TabPane>
        </TabContent>
      </Col>
      {informationModal.isOpen && (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({
              rawBody: false,
              isOpen: false,
              title: "",
              body: "",
            })
          }
        />
      )}
    </Row>
  );
};
export default SafetyItems;
