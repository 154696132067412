import {
  faChevronLeft,
  faChevronRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Row, Button, Col, CustomInput, FormGroup, Label } from "reactstrap";
import { reportsApi } from "../../../../services/reportsServices";
import moment from "moment";
import { pdfjs, Document, Page } from "react-pdf";
import Loader from "../../../../components/Loader";
import { useWorkOrderDetails } from "../../../../providers/workOrderDetailsProvider";
import { utilsHelper } from "../../../../helpers/utilsHelper";
import WeekSelector from "../../../../components/admin/WeekSelector";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TYPE_PDF = "pdf";
const TYPE_CSV = "csv";

const ALL_JOB_DATA = "ALL_JOB_DATA";
const WEEKLY_DATA = "WEEKLY_DATA";

const MillReport = ({ workOrderId }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [report, setReport] = useState();
  const [loading, setLoading] = useState();

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [dataMode, setDataMode] = useState(ALL_JOB_DATA);
  const [crew, setCrew] = useState();

  const onDownload = (type) => {
    if (type === TYPE_PDF) {
      return window.open(report, "new");
    }
    setLoading(true);
    reportsApi
      .millTimesheetsReport({
        workOrderId,
        weekStart: monday.format("YYYY-MM-DD"),
        browserTimeOffset: moment().utcOffset() / 60,
        format: TYPE_CSV,
      })
      .then((res) => {
        const data = new Blob([res], { type: "text/csv" });
        const URL = window.URL.createObjectURL(data);
        window.open(URL, "new");
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    reportsApi
      .millTimesheetsReport({
        workOrderId,
        weekStart:
          dataMode === WEEKLY_DATA ? monday.format("YYYY-MM-DD") : null,
        crewId: crew,
        browserTimeOffset: moment().utcOffset() / 60,
      })
      .then((res) => {
        const data = new Blob([res], { type: "application/pdf" });
        const URL = window.URL.createObjectURL(data);
        setLoading(false);
        setReport(URL);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [crew, dataMode, monday, workOrderId]);

  return (
    <Row className="d-flex">
      <Col className="flex-shrink-0 py-3">
        {loading ? (
          <div className="my-2">
            <Loader size="sm" />
          </div>
        ) : !report ? (
          <div className="text-center text-muted mt-4 small">
            No data has been recorded
          </div>
        ) : (
          <>
            <Document
              className="d-flex justify-content-center"
              file={report}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
            >
              <Page className="border rounded" pageNumber={pageNumber} />
            </Document>
            <div className="d-flex justify-content-between p-2 align-items-center text-body bg-white border mt-4 mx-1 rounded">
              <Button
                className="rounded"
                onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
              <span>
                Page {pageNumber} of {numPages}
              </span>
              <Button
                className="rounded"
                onClick={() =>
                  setPageNumber(Math.min(pageNumber + 1, numPages))
                }
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </div>
          </>
        )}
      </Col>
      <Col className="flex-grow-1 d-flex flex-column col my-3">
        <FormGroup className="d-flex justify-content-end">
          <Button
            color="tertiary"
            size="sm"
            className="rounded"
            onClick={() => onDownload(TYPE_PDF)}
          >
            <FontAwesomeIcon icon={faDownload} className="mr-2" />
            <span>PDF</span>
          </Button>
          <Button
            color="tertiary"
            size="sm"
            className="ml-2 rounded"
            onClick={() => onDownload(TYPE_CSV)}
          >
            <FontAwesomeIcon icon={faDownload} className="mr-2" />
            <span>CSV</span>
          </Button>
        </FormGroup>
        <FormGroup row>
          <Label sm={12} className="text-sm-left">
            Data included
          </Label>
          <Col sm={12}>
            <CustomInput
              required
              disabled={loading}
              id="dataSelect"
              type="select"
              name="dataSelect"
              onChange={(event) => setDataMode(event.currentTarget.value)}
              value={dataMode}
            >
              <option value={ALL_JOB_DATA}>All Job Data</option>
              <option value={WEEKLY_DATA}>Weekly</option>
            </CustomInput>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={12} className="text-sm-left">
            Crew
          </Label>
          <Col sm={12}>
            <CustomInput
              required
              disabled={loading}
              id="crewSelect"
              type="select"
              name="crewSelect"
              onChange={(event) => setCrew(event.currentTarget.value)}
              value={crew}
            >
              <option value={""}>All Crews</option>
              {workOrderDetails.workOrder.crewWorkOrders
                .filter((crewWorkOrder) =>
                  crewWorkOrder.crew.employeeCrews
                    .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec))
                    .find((ec) => ec.isCrewLead)
                )
                .map((crewWorkOrder) => {
                  const sup = crewWorkOrder.crew.employeeCrews
                    .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec))
                    .find((ec) => ec.isCrewLead);
                  return (
                    <option
                      key={crewWorkOrder.id}
                      value={crewWorkOrder.crew.id}
                    >
                      {`${sup.employee.firstName} ${sup.employee.lastName}`}
                    </option>
                  );
                })}
            </CustomInput>
          </Col>
        </FormGroup>
        {dataMode === WEEKLY_DATA ? (
          <WeekSelector
            loading={loading}
            monday={monday}
            setMonday={setMonday}
          />
        ) : null}
      </Col>
    </Row>
  );
};

export default MillReport;
