import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../helpers/apiHelper";

export const vehicleApi = {
  getVehicles: () => {
    return axios(`/api/vehicles`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION }, //addes token to headers for auth
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  assignVehicles: (data) => {
    return axios(`/api/vehicles/assign`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION }, //addes token to headers for auth
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
