import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import { reportsApi } from "../../../services/reportsServices";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { reportsHelper } from "../../../helpers/reportsHelper";
import WeekSelector from "../../../components/admin/WeekSelector";
import Loader from "../../../components/Loader";
import { useAuth } from "../../../providers/authProvider";
import PeriodTypeSelector from "../../../components/admin/PeriodTypeSelector";
import CustomPeriodSelector from "../../../components/admin/CustomPeriodSelector";

const HOURS_RED = "#dc35452e";

const columns = {
  "EE Code": {
    type: "string",
  },
  "1 for Earning - 2 for Tax - 3 for Deduction": {
    type: "integer",
  },
  "Earning/Deduction Code": {
    type: "string",
  },
  "Hours or Amount": {
    type: "number",
  },
};

const global = {
  localization: {
    grid: {
      blankMember: "",
    },
  },
};

if (!utilsHelper.isBetaProd()) {
  /*
  columns.Date = {
    type: "string",
  };
  columns["Work Order"] = {
    type: "string",
  };
  */
}

const report = {
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
    {
      name: "daily",
      thousandsSeparator: ",",
      decimalPlaces: 1,
      maxDecimalPlaces: 1,
    },
    {
      name: "threeDecimals",
      decimalPlaces: 3,
    },
  ],
  dataSource: {
    data: [columns],
  },
  slice: {
    measures: [
      {
        uniqueName: "EE Code",
      },
      {
        uniqueName: "1 for Earning - 2 for Tax - 3 for Deduction",
      },
      {
        uniqueName: "Earning/Deduction Code",
        sortOrder: ["PDN", "MLI", "R", "O", "RDT"], // ["PERDM", "MILE", "REG", "OT", "REST"],
      },
      {
        uniqueName: "Hours or Amount",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    nullValue: "",
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "PayCom Upload", // UKG Upload
    },
  },
};

if (!utilsHelper.isBetaProd()) {
  /*
  report.slice.measures.push({ uniqueName: "Date" });
  report.slice.measures.push({ uniqueName: "Work Order" });
  */
}

const REPORT_NAME = "PayCom_Upload"; // "UKG_Upload"
const TYPE_WEEKLY = "TYPE_WEEKLY";

const PayComUpload = () => {
  // UKGUpload
  const [authContext] = useAuth();
  const pivot = useRef(null);

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [loading, setLoading] = useState();

  const getFilename = () =>
    reportsHelper.getFileName(monday, true, REPORT_NAME);

  const customizeCell = useCallback((cell, data, rawData) => {
    if (data.type === "value") {
      const includesGuaranteedHours =
        rawData[data.rowIndex - 1] &&
        rawData[data.rowIndex - 1]["Includes G. Hours"];
      if (includesGuaranteedHours) {
        cell.style["background-color"] = HOURS_RED;
      }
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    const condition = {
      jobSourceId: authContext.currentUser.jobSourceId,
      paycomCheck: true, // ukgCheck: true
    };
    if (periodType.value === TYPE_WEEKLY) {
      condition.startDate = moment(monday).format("YYYY-MM-DD");
      condition.endDate = moment(monday).endOf("isoWeek").format("YYYY-MM-DD");
    } else {
      condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
      condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
    }
    reportsApi
      .hrPayrollReport(condition)
      .then((result) => {
        setLoading(false);
        if (pivot) {
          pivot.current.flexmonster.updateData({
            data: [columns, ...result],
          });
          pivot.current?.flexmonster?.customizeCell((cell, data) =>
            customizeCell(cell, data, result)
          );
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    authContext.currentUser.jobSourceId,
    customEndDate,
    customStartDate,
    customizeCell,
    monday,
    periodType.value,
  ]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-end">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : null}
              <PeriodTypeSelector
                periodType={periodType}
                setPeriodType={setPeriodType}
              />
              {periodType.value === TYPE_WEEKLY ? (
                <WeekSelector
                  loading={loading}
                  monday={monday}
                  setMonday={setMonday}
                />
              ) : (
                <CustomPeriodSelector
                  defaultEndDate={customEndDate}
                  defaultStartDate={customStartDate}
                  onSubmit={(startDate, endDate) => {
                    setCustomStartDate(startDate);
                    setCustomEndDate(endDate);
                  }}
                />
              )}
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  key={monday.toLocaleString()}
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  global={global}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PayComUpload; // UKGUpload
