import axios from "axios";
import config from "../config/config";
import moment from "moment";
import { apiHelper } from "../helpers/apiHelper";

export const employeeRestDaysApi = {
  createEmployeeRestDay: (data) => {
    data.createdAt = moment().toISOString();
    axios(`/api/employee-rest-days`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  deleteEmployeeRestDay: (data) =>
    axios(`/api/employee-rest-days/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
};
