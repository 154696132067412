import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
} from "reactstrap";

import { injuryReportAttachmentApi } from "../../../services/injuryReportAttachmentServices";
import Loader from "../../Loader";

const InjuryReportEditNoteModal = ({
  noteReport,
  fetchData,
  onCloseModal,
  setInformationModal,
  onClosePrincipalModal,
}) => {
  const [note, setNote] = useState(noteReport);

  const [loading, setLoading] = useState();

  const doSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (note.id) {
      const { id, content, type, injuryReportId } = note;
      try {
        await injuryReportAttachmentApi.updateInjuryReportAttachment({
          id,
          content,
          type,
          injuryReportId,
        });
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Update Note",
          body: "Note Updated Succesfully",
          onClose: () => onClosePrincipalModal(),
        });
        onCloseModal();
        fetchData();
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Update Note",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onCloseModal}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {note.id ? "Edit" : "Create"} Note
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col sm="12">
                <FormGroup>
                  <Label for="name">
                    <span>Content</span>
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    type="textarea"
                    name="content"
                    value={note.content || ""}
                    onChange={(e) =>
                      setNote({
                        ...note,
                        content: e.target.value,
                      })
                    }
                    required
                    maxLength={510}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onCloseModal}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default InjuryReportEditNoteModal;
