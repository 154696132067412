import React, { useEffect, useState } from "react";

import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";

import moment from "moment";
import { changeOrdersApi } from "../../../services/changeOrderServices";
import ConfirmationModal from "../../../components/ConfirmationModal";
import ChangeOrderPreviewModal from "../../../components/admin/ChangeOrderPreviewModal";
import { changeOrderHelper } from "../../../helpers/changeOrderHelper";
import WeekSelector from "../../../components/admin/WeekSelector";
import { useAuth } from "../../../providers/authProvider";
import Loader from "../../../components/Loader";

const COLORS = {
  Pending: "primary",
  "In Review": "secondary",
  "In Progress": "info",
  Rejected: "success",
  Approved: "warning",
  Invoiced: "danger",
};

const STATUS_CUSTOMER_APPROVED = "Approved";

const ChangeOrdersByWeek = () => {
  const [authContext] = useAuth();

  const [detailsModal, setDetailsModal] = useState();

  const [changeOrdersByCustomer, setChangeOrdersByCustomer] = useState([]);

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));

  const [loading, setLoading] = useState();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [refresh, setRefresh] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const result = await changeOrdersApi.getChangeOrders({
        startDate: monday.format("YYYY-MM-DD"),
        jobSourceId: authContext.currentUser.jobSourceId,
      });
      setLoading(false);
      const changeOrdersByCustomer = result.reduce((p, c) => {
        if (!p[c.vwworkOrder.customerName]) {
          p[c.vwworkOrder.customerName] = [];
        }
        p[c.vwworkOrder.customerName] = [...p[c.vwworkOrder.customerName], c];
        return p;
      }, {});
      setChangeOrdersByCustomer(changeOrdersByCustomer);
    };
    fetchData();
  }, [monday, refresh, authContext.currentUser.jobSourceId]);

  const onMarkAsInvoiced = (changeOrder) =>
    setConfirmationModal({
      isOpen: true,
      confirmColor: "warning",
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        await changeOrdersApi.updateChangeOrder({
          ...changeOrder,
          statusId: changeOrderHelper.STATUS_INVOICED,
        });
        setRefresh(!refresh);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Mark Change Order as Invoiced",
      body: `Do you confirm you want to mark this change order as invoiced?`,
    });

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardBody className="d-flex flex-column flex-grow-1">
              <WeekSelector
                loading={loading}
                monday={monday}
                setMonday={setMonday}
              />
              {loading ? (
                <Loader />
              ) : (
                <div className="height-100 mt-n2">
                  {Object.keys(changeOrdersByCustomer).length ? (
                    Object.keys(changeOrdersByCustomer).map(
                      (customerName, i) => (
                        <ListGroup className="mt-3" key={i}>
                          <ListGroupItem
                            className="d-flex justify-content-start align-items-center p-2 bg-lighter"
                            tag="div"
                          >
                            {customerName}
                          </ListGroupItem>
                          <ListGroupItem
                            className="d-flex justify-content-start align-items-center py-3 flex-wrap"
                            tag="div"
                          >
                            <Row className="w-100">
                              {changeOrdersByCustomer[customerName].map(
                                (changeOrder) => (
                                  <div
                                    key={changeOrder.id}
                                    className="col-4 my-1"
                                  >
                                    <Card className="col-12 px-0 mb-0">
                                      <ListGroup>
                                        <ListGroupItem className="d-flex justify-content-between align-items-center p-2">
                                          <span className="font-weight-bold">
                                            Status
                                          </span>
                                          <span className="ml-2 text-right">
                                            <Badge
                                              color={
                                                COLORS[changeOrder.statusId]
                                              }
                                            >
                                              {changeOrder.statusId}
                                            </Badge>
                                          </span>
                                        </ListGroupItem>
                                        <ListGroupItem className="d-flex justify-content-between align-items-center p-2">
                                          <span className="font-weight-bold">
                                            Submitted By
                                          </span>
                                          <span className="ml-2 text-right">
                                            {changeOrder.author
                                              ? `${changeOrder.author.lastName}, ${changeOrder.author.firstName}`
                                              : "N/A"}
                                          </span>
                                        </ListGroupItem>
                                        <ListGroupItem className="d-flex justify-content-between align-items-center p-2">
                                          <span className="font-weight-bold">
                                            Submitted At
                                          </span>
                                          <span className="ml-2 text-right">
                                            {moment(
                                              changeOrder.createdAt
                                            ).format("MM/DD/YY hh:mm a")}
                                          </span>
                                        </ListGroupItem>
                                        <ListGroupItem className="d-flex justify-content-between align-items-center p-2">
                                          <span className="font-weight-bold">
                                            Work Description
                                          </span>
                                          <span className="ml-2 text-right">
                                            {changeOrder.description}
                                          </span>
                                        </ListGroupItem>
                                        <ListGroupItem className="d-flex justify-content-around align-items-center p-2">
                                          <Button
                                            onClick={() =>
                                              setDetailsModal(changeOrder)
                                            }
                                            color="success"
                                            className="rounded"
                                            size="sm"
                                          >
                                            Review
                                          </Button>
                                          {changeOrder.statusId ===
                                          STATUS_CUSTOMER_APPROVED ? (
                                            <Button
                                              onClick={() =>
                                                onMarkAsInvoiced(changeOrder)
                                              }
                                              color="success"
                                              className="rounded"
                                              size="sm"
                                            >
                                              Mark As Invoiced
                                            </Button>
                                          ) : null}
                                        </ListGroupItem>
                                      </ListGroup>
                                    </Card>
                                  </div>
                                )
                              )}
                            </Row>
                          </ListGroupItem>
                        </ListGroup>
                      )
                    )
                  ) : (
                    <ListGroup className="mt-3 col-12">
                      <ListGroupItem
                        className="text-center p-2 bg-lighter"
                        tag="div"
                      >
                        No Change Orders Found
                      </ListGroupItem>
                    </ListGroup>
                  )}
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      {confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : detailsModal ? (
        <ChangeOrderPreviewModal
          changeOrder={detailsModal}
          onClose={() => setDetailsModal()}
        />
      ) : null}
    </Container>
  );
};

export default ChangeOrdersByWeek;
