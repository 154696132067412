import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ReactQuill from "react-quill";

import {
  Button,
  Col,
  Form,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useAuth } from "../providers/authProvider";

import { contactApi } from "../services/contactServices";
import { utilsHelper } from "../helpers/utilsHelper";
import InformationModal from "./InformationModal";
import Loader from "./Loader";

const MAX_LENGHT_EMAIL = 5120;
const MAX_LENGHT_SMS = 512;

const ContactModal = ({
  defaultContent = "",
  defaultRecipients,
  isOpen,
  isEmail,
  onClose,
  afterSubmit,
}) => {
  const [authContext] = useAuth();

  const [loading, setLoading] = useState();

  const [recipients, setRecipients] = useState(defaultRecipients);
  const [otherRecipients, setOtherRecipients] = useState();
  const [subject, setSubject] = useState("");
  const [replyTo, setReplyTo] = useState(authContext.currentUser.email);
  const [content, setContent] = useState(defaultContent);
  const [attachment, setAttachment] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const CONTACT_TYPE_EMAIL = "Email";
  const CONTACT_TYPE_SMS = "SMS";
  const CONTACT_TYPE = isEmail ? CONTACT_TYPE_EMAIL : CONTACT_TYPE_SMS;

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!recipients.length && !otherRecipients.length) {
      return setInformationModal({
        isOpen: true,
        title: `Send ${CONTACT_TYPE}`,
        body: `Please enter at least one recipient.`,
      });
    }
    if (isEmail) {
      if (content.length > MAX_LENGHT_EMAIL) {
        return setInformationModal({
          isOpen: true,
          title: `Send ${CONTACT_TYPE}`,
          body: `Characters limit of ${MAX_LENGHT_EMAIL} exceeded.`,
        });
      }
      if (!content.length) {
        return setInformationModal({
          isOpen: true,
          title: `Send ${CONTACT_TYPE}`,
          body: `Please add the email content.`,
        });
      }
      try {
        let rec = [];
        if (recipients.length) {
          rec.push(recipients.map((r) => r.content).join(", "));
        }
        if (otherRecipients.length) {
          rec.push(otherRecipients);
        }
        const payload = {
          recipients: rec.join(", "),
          content,
          subject,
          attachment,
          replyTo,
        };
        setLoading(true);
        await contactApi.sendEmail(payload);
        setLoading(false);
        afterSubmit && afterSubmit(payload);
        return setInformationModal({
          isOpen: true,
          title: `Send ${CONTACT_TYPE}`,
          body: `${CONTACT_TYPE} sent successfully`,
          onClose,
        });
      } catch (err) {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: `Send ${CONTACT_TYPE}`,
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
        });
      }
    } else {
      if (content.length > MAX_LENGHT_SMS) {
        return setInformationModal({
          isOpen: true,
          title: `Send ${CONTACT_TYPE}`,
          body: `Characters limit of ${MAX_LENGHT_SMS} exceeded.`,
        });
      }
      try {
        let rec = [];
        if (recipients.length) {
          rec.push(recipients.map((r) => r.content).join(", "));
        }
        if (otherRecipients.length) {
          rec.push(otherRecipients);
        }
        const payload = { recipients: rec.join(", "), content };
        setLoading(true);
        await contactApi.sendSms(payload);
        setLoading(false);
        afterSubmit && afterSubmit(payload);
        return setInformationModal({
          isOpen: true,
          title: `Send ${CONTACT_TYPE}`,
          body: `${CONTACT_TYPE} sent successfully`,
          onClose,
        });
      } catch (err) {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: `Send ${CONTACT_TYPE}`,
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
        });
      }
    }
  };

  const removeRecipient = (index) => {
    const newRecipients = [...recipients];
    newRecipients.splice(index, 1);
    setRecipients(newRecipients);
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={isOpen} size="xl">
      <Form onSubmit={onSubmit}>
        <ModalHeader close={closeBtn}>Send {CONTACT_TYPE}</ModalHeader>
        <ModalBody>
          {recipients?.length ? (
            <Row className="mb-3 d-flex align-items-center">
              <Col sm="4" className="d-flex flex-column">
                <Label className="mb-0" for="subject">
                  Recipients
                </Label>
                <small className="text-primary">From Crew</small>
              </Col>
              <Col sm="8">
                <ListGroup>
                  {recipients.map((recipient, index) => (
                    <ListGroupItem
                      key={index}
                      className="d-flex justify-content-between align-items-center py-2"
                      tag="div"
                    >
                      <div className="d-flex">
                        <span>{recipient.name}</span>
                        <span className="mx-2 text-muted">/</span>
                        <span className="text-muted">
                          {CONTACT_TYPE === CONTACT_TYPE_SMS
                            ? utilsHelper.formatPhone(recipient.content)
                            : recipient.content}
                        </span>
                      </div>
                      <Button
                        className="ml-2 rounded d-flex align-items-center"
                        color="none"
                        size="sm"
                        onClick={() => removeRecipient(index)}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="text-danger"
                          size="lg"
                        />
                      </Button>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            </Row>
          ) : null}
          <Row className="mb-3 d-flex align-items-center">
            <Col sm="4" className="d-flex flex-column">
              <Label className="mb-0" for="subject">
                Other Recipients
              </Label>
              <small className="text-primary">Separated by commas</small>
            </Col>
            <Col sm="8">
              <Input
                maxLength="1024"
                type="text"
                placeholder="Input recipients"
                value={otherRecipients}
                onChange={(e) => setOtherRecipients(e.target.value)}
                required={!recipients.length}
              />
            </Col>
          </Row>
          {isEmail && (
            <>
              <Row className="mb-3 d-flex align-items-center">
                <Col sm="4">
                  <Label className="mb-0" for="subject">
                    Subject
                  </Label>
                </Col>
                <Col sm="8">
                  <Input
                    maxLength="100"
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="Enter the subject..."
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required={true}
                  />
                </Col>
              </Row>
              <Row className="mb-3 d-flex align-items-center">
                <Col sm="4">
                  <Label className="mb-0" for="subject">
                    Reply To Email
                  </Label>
                </Col>
                <Col sm="8">
                  <Input
                    maxLength="50"
                    type="email"
                    placeholder="Enter an email address..."
                    value={replyTo}
                    onChange={(e) => setReplyTo(e.target.value.trim())}
                    required={true}
                  />
                </Col>
              </Row>
              <Row className="mb-3 d-flex align-items-center">
                <Col sm="4" className="d-flex flex-column">
                  <Label className="mb-0" for="attachment">
                    Attachment
                  </Label>
                  <small className="text-primary">Max 10mb.</small>
                </Col>
                <Col sm="8">
                  <Input
                    type="file"
                    id="attachment"
                    name="attachment"
                    onChange={(e) => setAttachment(e.target.files[0])}
                  />
                </Col>
              </Row>
              <div className="mt-3">
                <Col sm="12 border rounded">
                  <ReactQuill
                    placeholder="Type something..."
                    theme={"snow"}
                    value={content}
                    onChange={(content) => setContent(content)}
                  />
                </Col>
              </div>
            </>
          )}
          {!isEmail && (
            <Row className="mt-3">
              <Col sm="4">
                <Label className="mb-0" for="subject">
                  Content
                </Label>
              </Col>
              <Col sm="8">
                <Input
                  maxLength="1024"
                  type="textarea"
                  id="content"
                  name="content"
                  placeholder="Enter the content..."
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  required={true}
                />
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose}>
            Close
          </Button>
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color="primary" type="submit">
              Send {CONTACT_TYPE}
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ContactModal;
