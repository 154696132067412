import moment from "moment";
import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  workOrderCrews: null,
  refresh: false,
  crossCompany: false,
  crewLead: null,
};

const ACTIONS = {
  INIT: "INIT",
  REFRESH: "REFRESH",
  INIT_WORK_ORDER_CREWS: "INIT_WORK_ORDER_CREWS",
  SET_CREW_LEAD: "SET_CREW_LEAD",
  UPDATE_WORK_ORDER_CREWS: "UPDATE_WORK_ORDER_CREWS",
  UPDATE_WORK_ORDER_CREW: "UPDATE_WORK_ORDER_CREW",
  ADD_WORK_ORDER_CREW: "ADD_WORK_ORDER_CREW",
  UPDATE_OVERAGE: "UPDATE_OVERAGE",
  CLEAR_WORK_ORDER_CREWS: "CLEAR_WORK_ORDER_CREWS",
  TOGGLE_CROSS_COMPANY: "TOGGLE_CROSS_COMPANY",
};

const initWorkOrderCrews = (items) => {
  const workOrderCrews = items
    .filter(
      (workOrderCrew) =>
        workOrderCrew.count + (workOrderCrew.overageCount || 0) > 0
    )
    .map((workOrderCrew) => {
      return {
        roleId: workOrderCrew.role.id,
        name: workOrderCrew.role.name,
        count: workOrderCrew.count || 0,
        overageCount: 0,
        employees: [],
        role: workOrderCrew.role,
      };
    });
  return workOrderCrews;
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.REFRESH: {
      const newState = {
        ...state,
        refresh: !state.refresh,
      };

      return { ...newState };
    }

    case ACTIONS.SET_CREW_LEAD: {
      const { payload } = data;
      const { crewLead } = payload;

      const newState = {
        ...state,
        crewLead,
      };

      return { ...newState };
    }

    case ACTIONS.INIT_WORK_ORDER_CREWS: {
      const { payload } = data;
      const { items } = payload;
      return {
        ...initialState,
        workOrderCrews: items,
      };
    }

    case ACTIONS.ADD_WORK_ORDER_CREW: {
      const { payload } = data;
      const { role, employee, outage, shift, workOrderId } = payload;

      const workOrderCrew = {
        name: role.name,
        count: 0,
        overageCount: 1,
        outage,
        roleId: role.id,
        role,
        shift,
        workOrderId,
        employees: [
          {
            ...employee,
            addedTime: moment().format(),
            isOverage: true,
          },
        ],
      };

      return {
        ...state,
        refresh: !state.refresh,
        workOrderCrews: [...state.workOrderCrews, workOrderCrew],
        crewLead:
          role.isSuper && !state.crewLead ? employee.id : state.crewLead,
      };
    }

    case ACTIONS.UPDATE_WORK_ORDER_CREW: {
      const { payload } = data;
      const { workOrderCrew } = payload;
      const workOrderCrews = [...state.workOrderCrews];
      const index = state.workOrderCrews?.findIndex(
        (woc) => parseInt(woc.roleId) === parseInt(workOrderCrew.roleId)
      );
      workOrderCrews.splice(index, 1, workOrderCrew);
      return {
        ...state,
        refresh: !state.refresh,
        workOrderCrews,
      };
    }

    case ACTIONS.TOGGLE_CROSS_COMPANY: {
      const newState = {
        ...state,
        crossCompany: !state.crossCompany,
      };

      return {
        ...newState,
      };
    }

    case ACTIONS.UPDATE_WORK_ORDER_CREWS: {
      const { payload } = data;
      const { workOrderCrews } = payload;
      return {
        ...state,
        refresh: !state.refresh,
        workOrderCrews: workOrderCrews ? [...workOrderCrews] : null,
      };
    }

    case ACTIONS.UPDATE_OVERAGE: {
      const { payload } = data;
      const { roleId, amount } = payload;
      const workOrderCrews = [...state.workOrderCrews];
      const workOrderCrew = state.workOrderCrews?.find(
        (woc) => parseInt(woc.roleId) === parseInt(roleId)
      );
      workOrderCrew.overageCount = amount;
      const index = state.workOrderCrews?.findIndex(
        (woc) => parseInt(woc.roleId) === parseInt(roleId)
      );
      workOrderCrews.splice(index, 1, workOrderCrew);
      return {
        ...state,
        refresh: !state.refresh,
        workOrderCrews,
      };
    }

    case ACTIONS.CLEAR_WORK_ORDER_CREWS: {
      const newWorkOrderCrews = state.workOrderCrews.filter(
        (woc) => woc.count + woc.overageCount > 0
      );
      const newState = {
        ...state,
        refresh: !state.refresh,
        workOrderCrews: newWorkOrderCrews,
      };
      return newState;
    }

    case ACTIONS.INIT: {
      return {
        ...initialState,
      };
    }

    default:
      return {
        ...initialState,
      };
  }
};

const CrewManagementContext = createContext(initialState);

const CrewManagementProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <CrewManagementContext.Provider value={stateAndDispatch}>
      {children}
    </CrewManagementContext.Provider>
  );
};

export const useCrewManagement = () => useContext(CrewManagementContext);

export {
  CrewManagementContext,
  CrewManagementProvider,
  initWorkOrderCrews,
  ACTIONS,
};
