import ReactPlayer from "react-player";
import { FilePreviewStrategy } from "./FilePreviewStrategy";

const VideoPreview = ({ url }) => {
  return <ReactPlayer url={url} controls width="100%" height="100%" />;
};

class VideoPreviewStrategy extends FilePreviewStrategy {
  renderPreview(url) {
    return <VideoPreview url={url} />;
  }
}

export default VideoPreviewStrategy;
