import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  InputGroup,
  Label,
} from "reactstrap";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";

const EquipmentNoteModal = ({ onSubmit, onClose, note }) => {
  const [loading, setLoading] = useState(false);
  const [noteInput, setNoteInput] = useState(note);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = () => {
    const note = noteInput.trim();
    if (!note.length) {
      return setInformationModal({
        isOpen: true,
        title: `Edit Equipments Note`,
        body: `The note can't be empty.`,
      });
    }
    setLoading(true);
    onSubmit(note);
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Edit Equipments Note</ModalHeader>
      <ModalBody className="text-center">
        {loading ? (
          <Loader size={"sm"} />
        ) : (
          <div>
            <InputGroup className="col-12" size="m">
              <Label sm={3} className="text-sm-left">
                Note
              </Label>
              <Input
                maxLength="1024"
                type="textarea"
                required={true}
                value={noteInput}
                onChange={(evt) => setNoteInput(evt.target.value)}
              />
            </InputGroup>
          </div>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Cancel
        </Button>{" "}
        <Button color={"primary"} onClick={doSubmit}>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EquipmentNoteModal;
