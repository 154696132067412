import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate } from "react-router";
import { reportsApi } from "../../../services/reportsServices";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { utilsHelper } from "../../../helpers/utilsHelper";
import InformationModal from "../../../components/InformationModal";
import { reportsHelper } from "../../../helpers/reportsHelper";
import WeekSelector from "../../../components/admin/WeekSelector";
import PeriodTypeSelector from "../../../components/admin/PeriodTypeSelector";
import CustomPeriodSelector from "../../../components/admin/CustomPeriodSelector";
import Loader from "../../../components/Loader";
import { useAuth } from "../../../providers/authProvider";

const NAME_COLUMN = "Work Order #";

const COLUMNS = {
  Date: {
    type: "string",
  },
  Employee: {
    type: "string",
  },
  "Employee Code": {
    type: "string",
  },
  "Work Order #": {
    type: "string",
  },
  Customer: {
    type: "string",
  },
  Location: {
    type: "string",
  },
  Mileage: {
    type: "string",
  },
  Amount: {
    type: "number",
  },
  "Created By": {
    type: "string",
  },
  Note: {
    type: "string",
  },
  Item: {
    type: "string",
  },
  "GL Code": {
    type: "string",
  },
  "Expense Type": {
    type: "string",
  },
  "Payment Type": {
    type: "string",
  },
  Attachment: {
    type: "string",
  },
  Status: {
    type: "string",
  },
  "Approved By": {
    type: "string",
  },
};

const report = {
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
  ],
  dataSource: {
    data: [COLUMNS],
  },
  slice: {
    rows: [
      {
        uniqueName: "Date",
      },
      {
        uniqueName: "Employee",
      },
      {
        uniqueName: "Employee Code",
      },
      {
        uniqueName: "Work Order #",
      },
      {
        uniqueName: "Customer",
      },
      {
        uniqueName: "Location",
      },
      {
        uniqueName: "Mileage",
      },
      {
        uniqueName: "Amount",
      },
      {
        uniqueName: "Created By",
      },
      {
        uniqueName: "Note",
      },
      {
        uniqueName: "Item",
      },
      {
        uniqueName: "GL Code",
      },
      {
        uniqueName: "Expense Type",
      },
      {
        uniqueName: "Payment Type",
      },
      {
        uniqueName: "Attachment",
      },
      {
        uniqueName: "Status",
      },
      {
        uniqueName: "Approved By",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Expenses",
    },
  },
};

const addSignatureToCell = (cell, data) => {
  if (!(data.hierarchy && data.hierarchy.uniqueName === "Attachment")) {
    return;
  }

  if (!data.escapedLabel || cell.text === "(blank)") {
    return (cell.text = "No attachment");
  }

  const link = `<a href='${data.escapedLabel}' target='_blank' class='link cursor-pointer'>${data.escapedLabel}</a>`;

  if (data.type === "value") {
    cell.style["z-index"] = 1;
    cell.text = link;
  }
};

const REPORT_NAME = "Expense_";
const TYPE_WEEKLY = "TYPE_WEEKLY";

const ExpenseReport = () => {
  const [authContext] = useAuth();
  const pivot = useRef(null);
  const navigate = useNavigate();
  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));
  const [data, setData] = useState(null);

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });
  const [loading, setLoading] = useState();

  const getFilename = () =>
    reportsHelper.getFileName(
      monday,
      periodType.value === TYPE_WEEKLY,
      REPORT_NAME
    );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const populateAllExpenses = async () => {
      setLoading(true);
      const condition = {
        jobSourceId: authContext.currentUser.jobSourceId,
      };
      if (periodType.value === TYPE_WEEKLY) {
        condition.startDate = moment(monday).format("YYYY-MM-DD");
        condition.endDate = moment(monday)
          .endOf("isoWeek")
          .format("YYYY-MM-DD");
      } else {
        condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
        condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
      }
      const expenses = await reportsApi.weeklyExpenseReport(condition);
      const generalExpenses = await reportsApi.weeklyGeneralExpenseReport(
        condition
      );
      setLoading(false);
      const allExpenses = expenses
        .concat(generalExpenses)
        .sort(
          (x, y) => new Date(y.Date).getTime() - new Date(x.Date).getTime()
        );
      setData(allExpenses);
      if (pivot) {
        pivot.current.flexmonster.updateData({
          data: [
            COLUMNS,
            ...allExpenses.map((e) => ({
              ...e,
              Date: moment(e.Date).format("MM/DD/YYYY"),
            })),
          ],
        });
        pivot.current.flexmonster.customizeCell(addSignatureToCell);
      }
    };

    try {
      populateAllExpenses();
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: `Expense Report`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  }, [
    monday,
    setData,
    periodType,
    pivot,
    customEndDate,
    customStartDate,
    authContext.currentUser.jobSourceId,
  ]);

  useEffect(() => {
    if (pivot?.current?.flexmonster && data?.length) {
      pivot.current.flexmonster.on("celldoubleclick", function (cell) {
        reportsHelper.navigateToWorkOrder(
          cell,
          data,
          NAME_COLUMN,
          null,
          navigate
        );
      });
    }
  }, [pivot, data, navigate]);

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-end">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : null}
              <PeriodTypeSelector
                periodType={periodType}
                setPeriodType={setPeriodType}
              />
              {periodType.value === TYPE_WEEKLY ? (
                <WeekSelector
                  loading={loading}
                  monday={monday}
                  setMonday={setMonday}
                />
              ) : (
                <CustomPeriodSelector
                  defaultEndDate={customEndDate}
                  defaultStartDate={customStartDate}
                  onSubmit={(startDate, endDate) => {
                    setCustomStartDate(startDate);
                    setCustomEndDate(endDate);
                  }}
                />
              )}
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  //key={(monday + periodType.value).toLocaleString()}
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ExpenseReport;
