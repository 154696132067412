import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Loader from "../../../Loader";
import AnswerInput from "./AnswerInput";
import { useWorkOrderDetails } from "../../../../providers/workOrderDetailsProvider";

const MULTIPLE_CHOICE = 1;
const CLOCKFACE = 7;
const CREW_LIST = 9;
const CLOCKFACE_ALIGNMENT_ENTRY = 10;
const DIMENSIONS_CLOCKFACE_ALIGNMENT_ENTRY = 11;

const EditAnswerModal = ({
  onSubmit,
  onClose,
  inspectionQuestionType,
  question,
}) => {
  const inspectionAnswer = question.inspectionAnswers[0];

  const [workOrderDetails] = useWorkOrderDetails();

  const [loading, setLoading] = useState(false);
  const [explanation, setExplanation] = useState(
    inspectionAnswer.explanation || ""
  );
  const [inputValue, setInputValue] = useState();

  useEffect(() => {
    let inputValue = null;
    switch (inspectionQuestionType) {
      case MULTIPLE_CHOICE: {
        inputValue = {
          label: inspectionAnswer.inspectionQuestionOption.name,
          value: inspectionAnswer.inspectionQuestionOption.id,
          requiresExplanation: question.optionsAvailable.find(
            (op) =>
              op.inspectionQuestionOptionId ===
              inspectionAnswer.inspectionQuestionOption.id
          ).requiresExplanation,
        };
        break;
      }
      case CLOCKFACE: {
        inputValue = JSON.parse(inspectionAnswer.content);
        break;
      }
      case CREW_LIST: {
        const crewWorkOrders = workOrderDetails.workOrder.crewWorkOrders;
        const employees = crewWorkOrders.flatMap((crewWorkOrder) =>
          crewWorkOrder.crew.employeeCrews.map((ec) => ec.employee)
        );
        const employee = employees.find(
          (employee) =>
            employee.firstName + " " + employee.lastName ===
            inspectionAnswer.content
        );
        inputValue = {
          label: inspectionAnswer.content,
          value: employee?.id || null,
        };
        break;
      }
      case CLOCKFACE_ALIGNMENT_ENTRY: {
        inputValue = JSON.parse(inspectionAnswer.content);
        break;
      }
      case DIMENSIONS_CLOCKFACE_ALIGNMENT_ENTRY: {
        inputValue = JSON.parse(inspectionAnswer.content);
        break;
      }
      default:
        inputValue = inspectionAnswer.content;
        break;
    }
    setInputValue(inputValue);
  }, [
    workOrderDetails.workOrder,
    inspectionAnswer.content,
    inspectionAnswer.inspectionQuestionOption,
    inspectionQuestionType,
    question.optionsAvailable,
  ]);

  const doSubmit = (e) => {
    e.preventDefault();
    let inspectionQuestionOptionId = null;
    let content = null;
    let explanationValue = null;

    switch (inspectionQuestionType) {
      case MULTIPLE_CHOICE: {
        inspectionQuestionOptionId = inputValue.value;
        if (inputValue.requiresExplanation && explanation !== "") {
          explanationValue = explanation;
        }
        break;
      }
      case CLOCKFACE: {
        content = JSON.stringify(inputValue);
        break;
      }
      case CREW_LIST: {
        content = inputValue.label;
        break;
      }
      case CLOCKFACE_ALIGNMENT_ENTRY: {
        content = JSON.stringify(inputValue);
        break;
      }
      case DIMENSIONS_CLOCKFACE_ALIGNMENT_ENTRY: {
        content = JSON.stringify(inputValue);
        break;
      }
      default: {
        content = inputValue;
        break;
      }
    }

    setLoading(true);
    onSubmit({
      ...inspectionAnswer,
      inspectionQuestionOptionId,
      content,
      explanation: explanationValue,
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <form onSubmit={doSubmit}>
        <ModalHeader close={closeBtn}>Edit Answer</ModalHeader>
        <ModalBody>
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <div>
              <i>{question.content}</i>
              <div className="mt-2">
                <AnswerInput
                  inspectionQuestionType={inspectionQuestionType}
                  question={question}
                  inputValue={inputValue}
                  setInputValue={setInputValue}
                  explanation={explanation}
                  setExplanation={setExplanation}
                />
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          <Button disabled={loading} color="primary" type="submit">
            Confirm
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditAnswerModal;
