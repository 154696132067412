import {
  faPen,
  faPlus,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import AppExplanationTextModal from "../../../components/admin/settings/AppExplanationTextModal";

import ConfirmationModal from "../../../components/ConfirmationModal";
import InformationModal from "../../../components/InformationModal";

import Loader from "../../../components/Loader";

import { appExplanationApi } from "../../../services/appExplanationServices";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { useAuth } from "../../../providers/authProvider";

const AppExplanationTexts = () => {
  const [authContext] = useAuth();
  const [loading, setLoading] = useState(true);
  const [appExplanationTexts, setAppExplanationTexts] = useState([]);
  const [refresh, setRefresh] = useState();
  const [addModal, setAddModal] = useState();
  const [editModal, setEditModal] = useState();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await appExplanationApi.getAppExplanationTexts({});
        setAppExplanationTexts(result);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, [refresh]);

  const onSeeMore = (appExplanationText) => {
    setEditModal(appExplanationText);
  };

  const onDelete = (appExplanationText) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        try {
          setLoading(true);
          await appExplanationApi.deleteAppExplanationTexts({
            id: appExplanationText.id,
          });
          setLoading(false);
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Delete App Explanation Text",
            body: "Explanation text removed successfully.",
          });
          setRefresh(!refresh);
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
          setLoading(false);
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Delete App Explanation Text",
      body: `<span class="text-center">Do you confirm you want to remove ${appExplanationText.section}?</span>`,
    });
  };

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">App Explanation Texts</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="mr-3 rounded-circle d-flex custom-rounded-button"
              color="primary"
              onClick={() => setRefresh(!refresh)}
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
            {!utilsHelper.isReadOnly(authContext) ? (
              <Button
                size="sm"
                className="rounded-circle d-flex custom-rounded-button"
                color="primary"
                onClick={() => setAddModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            ) : null}
          </div>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loader />
          ) : appExplanationTexts.length ? (
            <ListGroup className="border-top" flush>
              <ListGroupItem
                className="py-2 border-bottom font-weight-bold bg-lighter text-muted small"
                tag="div"
              >
                Section
              </ListGroupItem>
              {appExplanationTexts.map((appExplanationText, index) => (
                <ListGroupItem
                  key={index}
                  className="py-2 border-bottom d-flex justify-content-between align-items-center"
                  tag="div"
                >
                  <span>{appExplanationText.section}</span>
                  {!utilsHelper.isReadOnly(authContext) ? (
                    <div className="d-flex">
                      <FontAwesomeIcon
                        onClick={() => onSeeMore(appExplanationText)}
                        icon={faPen}
                        className="text-warning cursor-pointer"
                      />
                      <FontAwesomeIcon
                        onClick={() => onDelete(appExplanationText)}
                        icon={faTrash}
                        className="ml-3 text-danger cursor-pointer"
                      />
                    </div>
                  ) : null}
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : (
            <div className="text-center">No texts added</div>
          )}
        </CardBody>
      </Card>
      {addModal ? (
        <AppExplanationTextModal
          onSubmit={() => {
            setAddModal();
            setInformationModal({
              isOpen: true,
              title: "Add Explanation Text",
              body: "Explanation text added successfully.",
            });
            setRefresh(!refresh);
          }}
          onClose={() => setAddModal()}
        />
      ) : editModal ? (
        <AppExplanationTextModal
          appExplanationText={editModal}
          onSubmit={() => {
            setEditModal();
            setInformationModal({
              isOpen: true,
              title: "Add Explanation Text",
              body: "Explanation text updated successfully.",
            });
            setRefresh(!refresh);
          }}
          onClose={() => setEditModal()}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default AppExplanationTexts;
