import React, { useReducer, createContext, useContext } from "react";

const MAX_PAGE_SIZE = 10;

const initialState = {
  logs: {},
  sizePerPage: 10,
  page: 1,
};

const ACTIONS = {
  PAGE_CHANGE: "PAGE_CHANGE",
  PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
  GET_LOGS_SUCCESS: "GET_LOGS_SUCCESS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_LOGS_SUCCESS: {
      const { payload } = data;
      const { logs } = payload;

      return {
        ...state,
        logs,
      };
    }

    case ACTIONS.PAGE_CHANGE: {
      const { payload } = data;
      const { page } = payload;
      return {
        ...state,
        page,
      };
    }

    case ACTIONS.PAGE_SIZE_CHANGE: {
      const { payload } = data;
      const { sizePerPage } = payload;
      return {
        ...state,
        sizePerPage,
        page: 1,
      };
    }

    default:
      throw new Error();
  }
};

const CommunicationLogContext = createContext(initialState);

const CommunicationLogProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <CommunicationLogContext.Provider value={stateAndDispatch}>
      {children}
    </CommunicationLogContext.Provider>
  );
};

export const useCommunicationLog = () => useContext(CommunicationLogContext);

export {
  CommunicationLogContext,
  CommunicationLogProvider,
  ACTIONS,
  MAX_PAGE_SIZE,
};
