import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../helpers/apiHelper";

export const crewWorkDayEquipmentApi = {
  updateCrewWorkOrderEquipment: (data) => {
    return axios(`/api/crewworkdayequipment/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  deleteCrewWorkOrderEquipment: (data) => {
    return axios(`/api/crewworkdayequipment/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createCrewWorkOrderEquipment: (data) => {
    return axios(`/api/crewworkdayequipment`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
