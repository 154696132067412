import { faPen, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import { Badge, ListGroup, ListGroupItem, Table } from "reactstrap";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../../providers/workOrderDetailsProvider";
import { utilsHelper } from "../../../helpers/utilsHelper";
import TooltipItem from "../../TooltipItem";
import AdjustPerDiemModal from "../AdjustPerDiemModal";
import { useAuth } from "../../../providers/authProvider";

const hasPerDiem = (entry) => {
  return (
    entry &&
    (entry.restDayPerDiem > 0 ||
      entry.travelTimePerDiem > 0 ||
      entry.travelMileagePerDiem > 0 ||
      entry.dailyMileagePerDiem > 0 ||
      entry.workTimePerDiem > 0)
  );
};

const EmployeeCrewRow = ({
  employeeCrew,
  perDiemEarnedForCrewWorkDay,
  crewWorkDay,
}) => {
  const [authContext] = useAuth();
  const [, setWorkOrderDetails] = useWorkOrderDetails();
  const [adjustPerDiemModal, setAdjustPerDiemModal] = useState();

  const employeeData = perDiemEarnedForCrewWorkDay.find(
    (entry) => entry.employeeId === employeeCrew.employee.id
  );

  const perDiemEarned = employeeData
    ? parseFloat(employeeData.restDayPerDiem) +
      parseFloat(employeeData.travelTimePerDiem) +
      parseFloat(employeeData.travelMileagePerDiem) +
      parseFloat(employeeData.dailyMileagePerDiem) +
      parseFloat(employeeData.workTimePerDiem) +
      parseFloat(employeeData.adjustedAmount)
    : 0;

  const cashAdvanceIssuedRaw = crewWorkDay.perDiems
    .filter((pd) => pd.employeeId === employeeCrew.employee.id)
    .reduce((p, c) => p + c.amount, 0);

  const cashAdvanceIssued = utilsHelper.formatCurrency(cashAdvanceIssuedRaw);

  return (
    <tr>
      <td colSpan={2}>
        <div className="d-flex">
          <div className="flex-grow-1 d-flex align-items-center">
            <div className="d-flex flex-column align-items-start">
              <span>{`${employeeCrew.employee.firstName} ${employeeCrew.employee.lastName}`}</span>
              {employeeCrew.isNotActiveButHasData ? (
                <Badge color="warning">Not in Crew</Badge>
              ) : null}
            </div>
          </div>
          <div className="d-flex">
            {hasPerDiem(employeeData) || cashAdvanceIssuedRaw > 0 ? (
              <>
                {employeeData?.restDayPerDiem > 0 ? (
                  <ListGroup className="ml-3 small">
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center py-2 border-bottom bg-lighter"
                      tag="div"
                    >
                      <span className="font-weight-bold">Type:</span>
                      <span className="ml-2">Rest Day</span>
                    </ListGroupItem>
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center py-2 border-bottom"
                      tag="div"
                    >
                      <span className="font-weight-bold">Per diem earned:</span>
                      <span className="ml-2">
                        {utilsHelper.formatCurrency(
                          parseFloat(employeeData.restDayPerDiem)
                        )}
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                ) : null}
                {employeeData?.dailyMileagePerDiem > 0 ? (
                  <ListGroup className="ml-3 small">
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center py-2 border-bottom bg-lighter"
                      tag="div"
                    >
                      <span className="font-weight-bold">Type:</span>
                      <span className="ml-2">Daily Mileage</span>
                    </ListGroupItem>
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center py-2 border-bottom"
                      tag="div"
                    >
                      <span className="font-weight-bold">Per diem earned:</span>
                      <span className="ml-2">
                        {utilsHelper.formatCurrency(
                          parseFloat(employeeData.dailyMileagePerDiem)
                        )}
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                ) : null}
                {employeeData?.workTimePerDiem > 0 ? (
                  <ListGroup className="ml-3 small">
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center py-2 border-bottom bg-lighter"
                      tag="div"
                    >
                      <span className="font-weight-bold">Type:</span>
                      <span className="ml-2">Work Time</span>
                    </ListGroupItem>
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center py-2 border-bottom"
                      tag="div"
                    >
                      <span className="font-weight-bold">Per diem earned:</span>
                      <span className="ml-2">
                        {utilsHelper.formatCurrency(
                          parseFloat(employeeData.workTimePerDiem)
                        )}
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                ) : null}
                {employeeData?.travelTimePerDiem > 0 ||
                employeeData?.travelMileagePerDiem > 0 ? (
                  <ListGroup className="ml-3 small">
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center py-2 border-bottom bg-lighter"
                      tag="div"
                    >
                      <span className="font-weight-bold">Type:</span>
                      <span className="ml-2">Travel Time</span>
                    </ListGroupItem>
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center py-2 border-bottom"
                      tag="div"
                    >
                      <span className="font-weight-bold">Per diem earned:</span>
                      <span className="ml-2">
                        {utilsHelper.formatCurrency(
                          parseFloat(employeeData.travelTimePerDiem) +
                            parseFloat(employeeData.travelMileagePerDiem)
                        )}
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                ) : null}
                {parseFloat(employeeData?.adjustedAmount) !== 0 ? (
                  <ListGroup className="ml-3 small max-width-300">
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center py-2 border-bottom bg-lighter"
                      tag="div"
                    >
                      <span className="font-weight-bold">
                        Per Diem Increase
                      </span>
                      {employeeData?.updatedBy ? (
                        <TooltipItem
                          id={`${employeeData.updatedBy.id}-updatedBy`}
                          title={
                            "By " +
                            employeeData.updatedBy.firstName +
                            " " +
                            employeeData.updatedBy.lastName
                          }
                        >
                          <FontAwesomeIcon
                            size="lg"
                            icon={faExclamationCircle}
                            className={`ml-2 text-warning`}
                            style={{ paddingBottom: "1px" }}
                          />
                        </TooltipItem>
                      ) : null}
                    </ListGroupItem>
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center py-2 border-bottom"
                      tag="div"
                    >
                      <span className="font-weight-bold">Amount:</span>
                      <span className="ml-2">
                        {utilsHelper.formatCurrency(
                          employeeData?.adjustedAmount
                        )}
                      </span>
                    </ListGroupItem>
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center py-2 border-bottom"
                      tag="div"
                    >
                      <span className="font-weight-bold">Reason:</span>
                      <span className="ml-2">
                        {employeeData?.adjustedReason || "Not Set"}
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                ) : null}
                <ListGroup className="ml-3 small">
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center py-2 border-bottom bg-lighter"
                    tag="div"
                  >
                    <span className="font-weight-bold">Total</span>
                    {!utilsHelper.isReadOnly(authContext) ? (
                      <div className="d-flex align-items-center ml-1">
                        <TooltipItem
                          className="ml-2"
                          id={`travel-time-${crewWorkDay.id}-adjust-tooltip`}
                          title="Adjust Per Diem"
                        >
                          <FontAwesomeIcon
                            icon={faPen}
                            size="sm"
                            className="text-warning"
                            style={{ paddingBottom: "1px" }}
                            onClick={() =>
                              setAdjustPerDiemModal(employeeData.workOrderId)
                            }
                          />
                        </TooltipItem>
                      </div>
                    ) : null}
                  </ListGroupItem>
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center py-2 border-bottom"
                    tag="div"
                  >
                    <span className="font-weight-bold">
                      Cash advance issued:
                    </span>
                    <span className="ml-2">{cashAdvanceIssued}</span>
                  </ListGroupItem>
                </ListGroup>
              </>
            ) : (
              <ListGroup className="ml-3 small">
                <ListGroupItem
                  className="d-flex justify-content-between align-items-center py-2 border-bottom"
                  tag="div"
                >
                  No per diem earned
                </ListGroupItem>
              </ListGroup>
            )}
          </div>
        </div>
        {adjustPerDiemModal ? (
          <AdjustPerDiemModal
            crewWorkDay={crewWorkDay}
            employeeId={employeeCrew.employee.id}
            employeeName={`${employeeCrew.employee.firstName} ${employeeCrew.employee.lastName}`}
            perDiemEarned={perDiemEarned}
            workOrderId={adjustPerDiemModal}
            onClose={() => setAdjustPerDiemModal(false)}
            onSubmit={() => {
              setAdjustPerDiemModal(false);
              setWorkOrderDetails({
                action: ACTIONS.REFRESH,
              });
            }}
          />
        ) : null}
      </td>
    </tr>
  );
};

const isNotActiveButHasData = (crewWorkDay, e, perDiemEarnedForCrewWorkDay) =>
  !utilsHelper.isEmployeeCrewActiveOnDate(e, crewWorkDay.date, false) &&
  perDiemEarnedForCrewWorkDay.find((pd) => pd.employeeId === e.id);

const isNotActiveOrHasData = (crewWorkDay, e, perDiemEarnedForCrewWorkDay) =>
  utilsHelper.isEmployeeCrewActiveOnDate(e, crewWorkDay.date, false) ||
  perDiemEarnedForCrewWorkDay.find((pd) => pd.employeeId === e.id);

const CrewWorkDayPerDiem = ({ crewWorkDay, perDiemEarnedForCrewWorkDay }) => {
  if (!crewWorkDay.employees?.length) {
    return <div className="p-2 small text-muted text-center">Empty crew</div>;
  }

  const allCrew = Object.values(
    crewWorkDay.employees
      .filter((e) =>
        isNotActiveOrHasData(crewWorkDay, e, perDiemEarnedForCrewWorkDay)
      )
      .reduce((p, c) => {
        c.isNotActiveButHasData = isNotActiveButHasData(
          crewWorkDay,
          c,
          perDiemEarnedForCrewWorkDay
        );
        if (!p[c.employee.id] || c.activeOnDate) {
          p[c.employee.id] = c;
        }
        return p;
      }, {})
  );

  return (
    <div key={crewWorkDay.id} className="d-flex">
      <Table className="col-12 px-0 mb-0 border">
        <thead>
          {perDiemEarnedForCrewWorkDay.length > 0 ? (
            <tr className="bg-lighter">
              <th className="align-middle">CREW</th>
              <th className="text-right align-middle">
                <div className="d-flex align-items-end flex-column">
                  <span>PER DIEM</span>
                </div>
              </th>
            </tr>
          ) : null}
        </thead>
        <tbody>
          {allCrew.length ? (
            utilsHelper.sortCrew(allCrew).map((employeeCrew, index) => {
              return (
                <EmployeeCrewRow
                  key={index}
                  employeeCrew={employeeCrew}
                  perDiemEarnedForCrewWorkDay={perDiemEarnedForCrewWorkDay}
                  crewWorkDay={crewWorkDay}
                />
              );
            })
          ) : (
            <td colSpan={4} className="p-2 small text-muted text-center">
              Empty Crew
            </td>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default CrewWorkDayPerDiem;
