import {
  faChevronDown,
  faChevronRight,
  faExclamationCircle,
  faPen,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import TooltipItem from "../../components/TooltipItem";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../providers/workOrderDetailsProvider";
import { utilsHelper } from "../../helpers/utilsHelper";
import EquipmentQuantityModal from "../../components/admin/workOrderDetails/EquipmentQuantityModal";
import EquipmentNoteModal from "../../components/admin/workOrderDetails/EquipmentNoteModal";
import { crewWorkDayEquipmentApi } from "../../services/crewWorkDayEquimentServices";
import { crewWorkDayEquipmentsApi } from "../../services/crewWorkDayEquimentsServices";
import InformationModal from "../../components/InformationModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import AddEquipmentModal from "../../components/admin/workOrderDetails/AddEquipmentModal";
import { useAuth } from "../../providers/authProvider";

const USER_ROLE_SUPERVISOR = 3;
const STATUS_INVOICED = 6;

const WorkOrderEquipments = () => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [addEquipmentModal, setAddEquipmentModal] = useState({
    isOpen: false,
    equipments: null,
  });

  const [equipmentQuantityModal, setEquipmentQuantityModal] = useState({
    isOpen: false,
    equipment: null,
  });

  const [equipmentNoteModal, setEquipmentNoteModal] = useState({
    isOpen: false,
    equipments: null,
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const changeEquipmentQuantity = async (quantity) => {
    try {
      if (quantity > 0) {
        await crewWorkDayEquipmentApi.updateCrewWorkOrderEquipment({
          ...equipmentQuantityModal.equipment,
          quantity,
        });
      } else {
        await crewWorkDayEquipmentApi.deleteCrewWorkOrderEquipment({
          ...equipmentQuantityModal.equipment,
        });
      }
      setEquipmentQuantityModal({ isOpen: false, equipment: null });
      setInformationModal({
        isOpen: true,
        title: `Edit Equipment Quantity`,
        body: "Equipment quantity updated successfully.",
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
          setWorkOrderDetails({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setEquipmentQuantityModal({ isOpen: false, equipment: null });
      setInformationModal({
        isOpen: true,
        title: `Edit Equipment Quantity`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const changeEquipmentNote = (note, equipments) => {
    const noteSent = note !== "" ? note : null;
    const equipmentSent = equipments
      ? equipments
      : equipmentNoteModal.equipments;
    crewWorkDayEquipmentsApi
      .updateCrewWorkOrderEquipments({
        ...equipmentSent,
        note: noteSent,
      })
      .then((res) => {
        setEquipmentNoteModal({ isOpen: false, equipments: null });
        setInformationModal({
          isOpen: true,
          title: `${equipments ? "Delete" : "Edit"} Equipments Note`,
          body: `Equipments note ${
            equipments ? "deleted" : "updated"
          } successfully.`,
          onClose: () => {
            setInformationModal({ isOpen: false, title: "", body: "" });
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      })
      .catch((err) => {
        setEquipmentNoteModal({ isOpen: false, equipments: null });
        setInformationModal({
          isOpen: true,
          title: `Edit Equipments Note`,
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
        });
      });
  };

  const addEquipment = async (equipment, quantity) => {
    try {
      const newCrewWorkOrderEquipment = {
        crewWorkDayEquipmentsId:
          addEquipmentModal.equipments[0].crewWorkDayEquipmentsId,
        equipmentId: equipment.id,
        equipmentName: equipment.name,
        quantity,
      };

      await crewWorkDayEquipmentApi.createCrewWorkOrderEquipment(
        newCrewWorkOrderEquipment
      );
      setInformationModal({
        isOpen: true,
        title: `Add Equipment`,
        body: `Equipment added successfully.`,
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
          setWorkOrderDetails({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: `Add Equipment`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const onNoteEdit = (equipments) => {
    setEquipmentNoteModal({
      isOpen: true,
      equipments,
    });
  };

  const IS_READ_ONLY =
    workOrderDetails?.workOrder?.statusId === STATUS_INVOICED ||
    utilsHelper.isReadOnly(authContext);

  const EquipmentsCard = ({ workOrder, equipments }) => (
    <Card className="mb-0 box-shadow-none col-12 px-0">
      <CardBody className="p-0">
        <ListGroup className="col-12 my-2 pb-2">
          {workOrderDetails.workOrder.id !== workOrder.id ? (
            <>
              <ListGroupItem
                className="d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold"
                tag="div"
              >
                {workOrder.workOrderNumber}
              </ListGroupItem>
              <ListGroupItem
                className="d-flex justify-content-between align-items-center py-2 bg-lighter font-weight-bold"
                tag="div"
              >
                <span>Equipment</span>
                <div className="d-flex justify-content-end">
                  <span>Quantity</span>
                  {!utilsHelper.isReadOnly(authContext) ? (
                    <Button
                      color="success"
                      size="sm"
                      className="rounded d-flex align-items-center p-1 ml-3"
                      onClick={() =>
                        setAddEquipmentModal({
                          isOpen: true,
                          equipments,
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faPlus} size="sm" />
                    </Button>
                  ) : null}
                </div>
              </ListGroupItem>
            </>
          ) : (
            <ListGroupItem
              className="d-flex justify-content-between align-items-center py-2 bg-lighter font-weight-bold"
              tag="div"
            >
              <span>Equipment</span>
              <div className="d-flex">
                <span>Quantity</span>
                {!utilsHelper.isReadOnly(authContext) ? (
                  <Button
                    color="success"
                    size="sm"
                    className="rounded d-flex align-items-center p-1 ml-3"
                    onClick={() =>
                      setAddEquipmentModal({
                        isOpen: true,
                        equipments,
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faPlus} size="sm" />
                  </Button>
                ) : null}
              </div>
            </ListGroupItem>
          )}
          {equipments.map(
            ({
              id,
              equipment,
              quantity,
              equipmentName,
              crewWorkDayEquipmentAuthor,
              crewWorkDayEquipmentsId,
              equipmentId,
              workOrderLinesId,
              createdBy,
              createdAt,
            }) => (
              <ListGroupItem
                key={id}
                className="py-2 border-bottom d-flex justify-content-between align-items-center"
                tag="div"
              >
                <div>
                  {utilsHelper.equipmentLabels(
                    equipment?.name || equipmentName
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <div className="mr-3">{quantity}</div>
                  {!IS_READ_ONLY && (
                    <Button
                      color="warning"
                      size="sm"
                      className="rounded d-flex align-items-center p-1"
                      onClick={() =>
                        setEquipmentQuantityModal({
                          isOpen: true,
                          equipment: {
                            id,
                            crewWorkDayEquipmentsId,
                            equipmentId,
                            workOrderLinesId,
                            equipmentName: equipment.name,
                            quantity,
                            createdBy,
                            createdAt,
                          },
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faPen} size="sm" />
                    </Button>
                  )}
                  {crewWorkDayEquipmentAuthor &&
                  crewWorkDayEquipmentAuthor.userRoleId !==
                    USER_ROLE_SUPERVISOR ? (
                    <TooltipItem
                      id={`${id}-tooltip`}
                      title={`Created by ${`${crewWorkDayEquipmentAuthor.firstName} ${crewWorkDayEquipmentAuthor.lastName}`}`}
                    >
                      <FontAwesomeIcon
                        size="lg"
                        icon={faExclamationCircle}
                        className="text-secondary"
                        style={{ paddingBottom: "1px" }}
                      />
                    </TooltipItem>
                  ) : null}
                </div>
              </ListGroupItem>
            )
          )}
        </ListGroup>
      </CardBody>
    </Card>
  );

  const NoteCard = ({ workOrder, notes, cwdEquipments }) => {
    const [workOrderDetails] = useWorkOrderDetails();

    const isSingle = workOrderDetails.workOrder.id === workOrder.id;

    const onDeleteNote = () => {
      setConfirmationModal({
        isOpen: true,
        confirmColor: "danger",
        onSubmit: () => {
          setConfirmationModal(initConfirmationModal);
          changeEquipmentNote(null, cwdEquipments);
        },
        onClose: () => {
          setConfirmationModal(initConfirmationModal);
        },
        title: "Remove Equipments Note",
        body: `Are you sure you want to delete this note?`,
      });
    };

    return (
      <Card className="mb-0 box-shadow-none">
        <CardBody className="p-0">
          <ListGroup className="col-12 p-0 my-2">
            <ListGroupItem
              className="d-flex justify-content-between align-items-center py-2 bg-lighter font-weight-bold"
              tag="div"
            >
              <span>Notes</span>
              {!IS_READ_ONLY && (
                <div className="d-flex">
                  <Button
                    color="warning"
                    size="sm"
                    className="rounded d-flex align-items-center p-1"
                    onClick={() => onNoteEdit(cwdEquipments)}
                  >
                    <FontAwesomeIcon icon={faPen} size="sm" />
                  </Button>
                  <Button
                    size="sm"
                    color="danger"
                    className="rounded d-flex align-items-center p-1 ml-1"
                    onClick={onDeleteNote}
                  >
                    <FontAwesomeIcon icon={faTrash} size="sm" />
                  </Button>
                </div>
              )}
            </ListGroupItem>
            <ListGroupItem
              className="p-3 border-bottom d-flex justify-content-between align-items-start"
              tag="div"
            >
              <div
                style={{ backgroundColor: "#FDF1DB" }}
                className={`col-${!isSingle ? 9 : 12} px-2 py-1 rounded`}
              >
                {notes}
              </div>
              <div className="col-3 text-right pr-0">
                {!isSingle ? <Badge>{workOrder.workOrderNumber}</Badge> : null}
              </div>
            </ListGroupItem>
          </ListGroup>
        </CardBody>
      </Card>
    );
  };

  return (
    <Row>
      <Col sm="12">
        <h4 className="border-bottom pb-2 mb-0">Equipment</h4>
        {!workOrderDetails.dates ||
        !Object.keys(workOrderDetails.dates).length ? (
          <div className="text-center text-muted mt-4 small">
            No data has been recorded
          </div>
        ) : (
          <Row>
            <Col sm="12">
              {Object.keys(workOrderDetails.dates)
                .sort(utilsHelper.sortDays)
                .filter(
                  (date) => workOrderDetails.dates[date].crewWorkDays.length
                )
                .map((date) => {
                  const crewWorkDays =
                    workOrderDetails.dates[date].crewWorkDays;
                  return (
                    <div className="mt-3" key={date}>
                      <Button
                        key={date}
                        onClick={() =>
                          setWorkOrderDetails({
                            action: ACTIONS.TOGGLE_DATE,
                            payload: { date },
                          })
                        }
                        style={{ width: "100%" }}
                      >
                        <div className="d-flex flex-row justify-content-between m-1 align-middle">
                          <span>{moment(date).format("MM/DD/YYYY")}</span>
                          <FontAwesomeIcon
                            icon={
                              workOrderDetails.activeDates.has(date)
                                ? faChevronDown
                                : faChevronRight
                            }
                            fixedWidth
                            className="my-1"
                          />
                        </div>
                      </Button>
                      <Collapse
                        isOpen={workOrderDetails.activeDates.has(date)}
                        className="p-2"
                      >
                        {crewWorkDays
                          .sort((a, b) =>
                            a.shift < b.shift ? -1 : b.shift < a.shift ? 1 : 0
                          )
                          .map(
                            ({
                              id,
                              crewWorkDayEquipments,
                              outage,
                              shift,
                              employees,
                              completed,
                            }) => {
                              const supervisorEmployee =
                                utilsHelper.getSupervisorEmployee(employees);
                              const supervisor = supervisorEmployee
                                ? `${supervisorEmployee?.employee.firstName} ${supervisorEmployee?.employee.lastName}`
                                : "No Supervisor Assigned";
                              return (
                                <div key={id} className="border mt-2 rounded">
                                  <div className="py-2 d-flex justify-content-between align-items-center bg-tertiary col-12">
                                    <div className="d-flex align-items-center col-3 px-0">
                                      <small className="text-white px-1 border border-white rounded font-weight-bold">
                                        {utilsHelper.outageLabels(outage)}
                                      </small>
                                      <small className="text-white px-1 border border-white rounded font-weight-bold ml-2">
                                        {completed ? "Day Closed" : "Day Open"}
                                      </small>
                                    </div>
                                    <div className="font-weight-bold mb-0 col-6 px-0 text-center text-white">
                                      {utilsHelper.capitalize(shift)} Crew
                                      Equipments
                                    </div>
                                    <div className="d-flex align-items-center col-3 px-0 justify-content-end">
                                      <small className="text-white text-truncate px-1 border border-white rounded font-weight-bold">
                                        {supervisor}
                                      </small>
                                    </div>
                                  </div>
                                  {crewWorkDayEquipments.length === 0 ? (
                                    <div className="text-center text-muted small py-2">
                                      No data has been recorded
                                    </div>
                                  ) : (
                                    crewWorkDayEquipments.map((cwde) => {
                                      const cwdEquipments = {
                                        ...cwde,
                                        workOrderId: cwde.workOrder.id,
                                      };
                                      return (
                                        <Row
                                          key={cwdEquipments.id}
                                          className="px-3 py-2"
                                        >
                                          <Col className="col-8">
                                            {cwdEquipments.equipments?.length >
                                            0 ? (
                                              <EquipmentsCard
                                                workOrder={
                                                  cwdEquipments.workOrder
                                                }
                                                equipments={
                                                  cwdEquipments.equipments
                                                }
                                              />
                                            ) : (
                                              <ListGroup className="col-12 my-2 pb-2">
                                                {workOrderDetails.workOrder
                                                  .id !==
                                                cwdEquipments.workOrderId ? (
                                                  <ListGroupItem
                                                    className="d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold"
                                                    tag="div"
                                                  >
                                                    {
                                                      cwdEquipments.workOrder
                                                        .workOrderNumber
                                                    }
                                                  </ListGroupItem>
                                                ) : null}
                                                <ListGroupItem
                                                  key={cwdEquipments.id}
                                                  className="py-2 border-bottom d-flex justify-content-between align-items-center"
                                                  tag="div"
                                                >
                                                  <span>
                                                    No equiments to show
                                                  </span>
                                                </ListGroupItem>
                                              </ListGroup>
                                            )}
                                          </Col>
                                          <Col className="col-4">
                                            {cwdEquipments.note ? (
                                              <NoteCard
                                                workOrder={
                                                  cwdEquipments.workOrder
                                                }
                                                notes={cwdEquipments.note}
                                                cwdEquipments={cwdEquipments}
                                              />
                                            ) : (
                                              <ListGroup className="col-12 my-2 pb-2">
                                                <ListGroupItem
                                                  className="d-flex justify-content-between align-items-center py-2 bg-lighter font-weight-bold"
                                                  tag="div"
                                                >
                                                  <span>Notes</span>
                                                  {!IS_READ_ONLY && (
                                                    <Button
                                                      size="sm"
                                                      color="warning"
                                                      className="rounded d-flex align-items-center p-1"
                                                      onClick={() =>
                                                        onNoteEdit(
                                                          cwdEquipments
                                                        )
                                                      }
                                                    >
                                                      <FontAwesomeIcon
                                                        icon={faPen}
                                                        size="sm"
                                                      />
                                                    </Button>
                                                  )}
                                                </ListGroupItem>
                                                <ListGroupItem
                                                  key={cwdEquipments.id}
                                                  className="py-2 border-bottom d-flex justify-content-between align-items-center"
                                                  tag="div"
                                                >
                                                  <span>No notes to show</span>
                                                </ListGroupItem>
                                              </ListGroup>
                                            )}
                                          </Col>
                                        </Row>
                                      );
                                    })
                                  )}
                                </div>
                              );
                            }
                          )}
                      </Collapse>
                    </div>
                  );
                })}
            </Col>
          </Row>
        )}
      </Col>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : equipmentQuantityModal.isOpen ? (
        <EquipmentQuantityModal
          onSubmit={(quantity) => changeEquipmentQuantity(quantity)}
          onClose={() =>
            setEquipmentQuantityModal({ isOpen: false, equipment: null })
          }
          quantity={equipmentQuantityModal.equipment?.quantity || 0}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : addEquipmentModal.isOpen ? (
        <AddEquipmentModal
          onSubmit={addEquipment}
          onClose={() =>
            setAddEquipmentModal({ isOpen: false, equipments: null })
          }
          equipments={addEquipmentModal.equipments}
        />
      ) : equipmentNoteModal.isOpen ? (
        <EquipmentNoteModal
          onSubmit={(note) => changeEquipmentNote(note)}
          onClose={() =>
            setEquipmentNoteModal({ isOpen: false, equipments: null })
          }
          note={equipmentNoteModal.equipments?.note || ""}
        />
      ) : null}
    </Row>
  );
};
export default WorkOrderEquipments;
