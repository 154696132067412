import {
  faChevronDown,
  faChevronRight,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Col,
  Collapse,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";

import InspectionDetails from "../../components/admin/workOrderDetails/inspections/InspectionDetails";

import WorkOrderAssignedInspections from "../../components/admin/workOrderDetails/inspections/WorkOrderAssignedInspections";

import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../providers/workOrderDetailsProvider";
import { inspectionsApi } from "../../services/inspectionServices";
import ConfirmationModal from "../../components/ConfirmationModal";
import InformationModal from "../../components/InformationModal";
import { useAuth } from "../../providers/authProvider";
import { utilsHelper } from "../../helpers/utilsHelper";

const STATUS_IN_PROGRESS = "IN_PROGRESS";
const STATUS_COMPLETED = "COMPLETED";
const STATUS_REVIEWED = "REVIEWED";

const WO_STATUS_INVOICED = 6;

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const WorkOrderInspections = () => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const [inspectionsAvailableModal, setInspectionsAvailableModal] = useState();
  const [inspectionDetailModal, setInspectionDetailModal] = useState();
  const [isOpen, setIsOpen] = useState({});

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const IS_READ_ONLY =
    workOrderDetails?.workOrder?.statusId === WO_STATUS_INVOICED ||
    utilsHelper.isReadOnly(authContext);

  useEffect(() => {
    if (workOrderDetails.workOrder?.inspectionTypeWorkOrders) {
      setIsOpen(
        workOrderDetails.workOrder.inspectionTypeWorkOrders.reduce((p, c) => {
          p[c.id] = true;
          return p;
        }, {})
      );
    }
  }, [workOrderDetails.workOrder.inspectionTypeWorkOrders]);

  const onSubmittedCustomer = (inspection) =>
    setConfirmationModal({
      isOpen: true,
      confirmColor: "warning",
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        await inspectionsApi.updateInspection({
          ...inspection,
          status: "REVIEWED",
        });
        setInformationModal({
          isOpen: true,
          title: `Mark Inspection as Reviewed`,
          body: `Inspection marked as reviewed successfully`,
          onClose: () => {
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Mark Inspection as Reviewed",
      body: `Do you confirm you want to mark this inspection as reviewed?`,
    });

  const getInspectionStatus = (inspection) => {
    const inspectionStatus = { status: "Assigned", color: "secondary" };
    if (inspection.status === STATUS_IN_PROGRESS) {
      inspectionStatus.status = "In Progress";
      inspectionStatus.color = "warning";
    } else if (inspection.status === STATUS_COMPLETED) {
      inspectionStatus.status = "Completed";
      inspectionStatus.color = "success";
    } else if (inspection.status === STATUS_REVIEWED) {
      inspectionStatus.status = "Reviewed";
      inspectionStatus.color = "info";
    }
    return inspectionStatus;
  };

  const onDeleteInspection = (evt, inspection) => {
    evt.stopPropagation();
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          await inspectionsApi.deleteInspection({ id: inspection.id });
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: `Delete Inspection`,
            body: `Inspection deleted successfully`,
            onClose: () => {
              setInformationModal({ isOpen: false, title: "", body: "" });
              setWorkOrderDetails({
                action: ACTIONS.REFRESH,
              });
            },
          });
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: `Delete Inspection`,
            body:
              err?.response?.data[0]?.msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setWorkOrderDetails({
          action: ACTIONS.REFRESH,
        });
      },
      confirmColor: "danger",
      title: "Delete Inspection",
      body: `<p class="text-center mb-0">Do you confirm you want to delete this Inspection?</p>`,
    });
  };

  const onDeleteAssignation = (evt, inspectionTypeWorkOrder) => {
    evt.stopPropagation();
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        try {
          await inspectionsApi.deleteInspectionAssignation({
            id: inspectionTypeWorkOrder.id,
          });
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: `Delete Inspection Assignation`,
            body: `Inspection assignation deleted successfully`,
            onClose: () => {
              setInformationModal({ isOpen: false, title: "", body: "" });
              setWorkOrderDetails({
                action: ACTIONS.REFRESH,
              });
            },
          });
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: `Delete Inspection Assignation`,
            body:
              err?.response?.data[0]?.msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setWorkOrderDetails({
          action: ACTIONS.REFRESH,
        });
      },
      confirmColor: "danger",
      title: "Delete Inspection Assignation",
      body: `<p class="text-center mb-0">Do you confirm you want to delete this inspection assignation?</p>`,
    });
  };

  return (
    <Row>
      <Col sm="12">
        <div className="d-flex justify-content-between border-bottom">
          <h4>Inspections</h4>
          {!IS_READ_ONLY && (
            <div>
              <Button
                color="success"
                size="sm"
                className="ml-2 rounded"
                onClick={() => setInspectionsAvailableModal(true)}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                <span>Assign Inspection</span>
              </Button>
            </div>
          )}
        </div>
        {!workOrderDetails.workOrder.inspectionTypeWorkOrders.length ? (
          <div className="text-center text-muted mt-4 small">
            No data has been recorded
          </div>
        ) : (
          <ListGroup className="mt-1">
            {workOrderDetails.workOrder.inspectionTypeWorkOrders.map(
              (inspectionTypeWorkOrder) => {
                return (
                  <div key={inspectionTypeWorkOrder.id} className="my-1">
                    <Button
                      onClick={() =>
                        setIsOpen({
                          ...isOpen,
                          [inspectionTypeWorkOrder.id]:
                            !isOpen[inspectionTypeWorkOrder.id],
                        })
                      }
                      style={{ width: "100%" }}
                    >
                      <div className="d-flex justify-content-between align-items-center m-1">
                        <div>
                          <span>{inspectionTypeWorkOrder.equipment}</span>
                          {" - "}
                          <Badge className="ml-1 bg-white text-secondary">
                            {inspectionTypeWorkOrder.inspectionType.name}
                          </Badge>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          {!utilsHelper.isReadOnly(authContext) ? (
                            <Button
                              size="sm"
                              className="rounded bg-secondary border-white"
                              onClick={(evt) =>
                                onDeleteAssignation(
                                  evt,
                                  inspectionTypeWorkOrder
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faTrash}
                                className="mr-1"
                              />
                              Delete
                            </Button>
                          ) : null}
                          <FontAwesomeIcon
                            icon={
                              isOpen[inspectionTypeWorkOrder.id]
                                ? faChevronDown
                                : faChevronRight
                            }
                            fixedWidth
                            className="ml-2 my-1"
                          />
                        </div>
                      </div>
                    </Button>
                    <Collapse
                      isOpen={isOpen[inspectionTypeWorkOrder.id]}
                      className="px-2"
                    >
                      <ListGroup className="my-2">
                        {inspectionTypeWorkOrder.inspections.length ? (
                          inspectionTypeWorkOrder.inspections.map(
                            (inspection, index) => {
                              const inspectionStatus =
                                getInspectionStatus(inspection);
                              return (
                                <ListGroupItem
                                  key={index}
                                  className="d-flex justify-content-between align-items-center py-2 bg-lighter"
                                  tag="div"
                                >
                                  <small className="text-muted">
                                    {moment(inspection.date).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </small>
                                  <div className="d-flex align-items-center">
                                    <Badge
                                      className="d-flex align-items-center"
                                      pill
                                      color={inspectionStatus.color}
                                    >
                                      {inspectionStatus.status}
                                    </Badge>
                                    <Button
                                      color="secondary"
                                      size="sm"
                                      className="ml-2 rounded"
                                      onClick={() =>
                                        setInspectionDetailModal(inspection.id)
                                      }
                                    >
                                      See More
                                    </Button>
                                    {inspection.status === STATUS_COMPLETED &&
                                    !utilsHelper.isReadOnly(authContext) ? (
                                      <Button
                                        color="warning"
                                        size="sm"
                                        className="ml-2 rounded"
                                        onClick={() =>
                                          onSubmittedCustomer(inspection)
                                        }
                                      >
                                        Submitted to Customer
                                      </Button>
                                    ) : null}
                                    {!utilsHelper.isReadOnly(authContext) ? (
                                      <Button
                                        color="danger"
                                        size="sm"
                                        className="ml-2 rounded"
                                        onClick={(evt) =>
                                          onDeleteInspection(evt, inspection)
                                        }
                                      >
                                        Delete
                                      </Button>
                                    ) : null}
                                  </div>
                                </ListGroupItem>
                              );
                            }
                          )
                        ) : (
                          <ListGroupItem
                            className="d-flex justify-content-between align-items-center py-2 small text-muted bg-lighter"
                            tag="div"
                          >
                            No inspections to show
                          </ListGroupItem>
                        )}
                      </ListGroup>
                    </Collapse>
                  </div>
                );
              }
            )}
          </ListGroup>
        )}
      </Col>
      {inspectionsAvailableModal ? (
        <WorkOrderAssignedInspections
          onSubmit={() => {
            setInspectionsAvailableModal(false);
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => {
            setInspectionsAvailableModal(false);
          }}
        />
      ) : inspectionDetailModal ? (
        <InspectionDetails
          inspectionId={inspectionDetailModal}
          onClose={() => setInspectionDetailModal(false)}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Row>
  );
};

export default WorkOrderInspections;
