import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../helpers/apiHelper";

export const contactApi = {
  sendEmail: ({
    recipients,
    content,
    subject,
    attachment,
    replyTo,
    fileName,
  }) => {
    const formData = new FormData();
    if (recipients) {
      recipients.split(",").forEach((element) => {
        formData.append("recipients[]", element.trim());
      });
    }
    if (content) {
      formData.append("body", content);
    }
    if (subject) {
      formData.append("subject", subject);
    }
    if (replyTo) {
      formData.append("replyTo", replyTo);
    }
    if (fileName) {
      formData.append("fileName", fileName);
    }
    if (attachment) {
      formData.append("attachment", attachment, attachment.name || fileName);
    }
    return axios(`/api/emails/attachment`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data: formData,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  sendSms: ({ recipients, content }) => {
    return axios(`/api/notifications/sms`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data: { phoneNumbers: recipients.split(", "), textMessage: content },
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
