import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Form,
  Input,
  Label,
  FormGroup,
  CustomInput,
  Col,
} from "reactstrap";
import Loader from "../../../Loader";
import { inspectionsApi } from "../../../../services/inspectionServices";
import InformationModal from "../../../InformationModal";
import { useWorkOrderDetails } from "../../../../providers/workOrderDetailsProvider";

const WorkOrderAssignedInspections = ({ onClose, onSubmit }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [loading, setLoading] = useState(false);
  const [inspectionTypes, setInspectionTypes] = useState([]);
  const [selectedInspectionType, setSelectedInspectionType] = useState();
  const [equipment, setEquipment] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const doSubmit = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    try {
      await inspectionsApi.createInspectionTypeWorkOrder({
        inspectionTypeId: selectedInspectionType,
        equipment,
        workOrderId: workOrderDetails.workOrder.id,
        isActive: true,
      });
      setLoading(false);
      onSubmit();
    } catch (err) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await inspectionsApi.getInspectionsTypes({});
        setInspectionTypes(result);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        Assign Inspections to Work Order
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" align="end" />
          ) : (
            <>
              <FormGroup row>
                <Label sm={4} className="text-sm-left">
                  Inspection Type
                  <small className="text-danger ml-1">*</small>
                </Label>
                <Col sm={8}>
                  <CustomInput
                    required={true}
                    id="inspectionTypeSelect"
                    type="select"
                    name="inspectionTypeSelect"
                    onChange={(event) =>
                      setSelectedInspectionType(event.currentTarget.value)
                    }
                    value={selectedInspectionType}
                  >
                    <option value={""}>Select an inspection type</option>
                    {inspectionTypes.map((inspectionType) => (
                      <option key={inspectionType.id} value={inspectionType.id}>
                        {inspectionType.name}
                      </option>
                    ))}
                  </CustomInput>
                </Col>
              </FormGroup>
              <FormGroup row className="mb-0">
                <Label sm={4} className="text-sm-left">
                  Equipment
                  <small className="text-danger ml-1">*</small>
                </Label>
                <Col sm={8} className="text-left">
                  <Input
                    required={true}
                    type="text"
                    placeholder="Enter the equipment.."
                    maxLength={1010}
                    value={equipment}
                    onChange={(evt) => setEquipment(evt.target.value)}
                  />
                </Col>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Close
          </Button>
          <Button color={"success"} type="submit">
            Confirm
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default WorkOrderAssignedInspections;
