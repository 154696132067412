import axios from "axios";

import config from "../config/config";
import { apiHelper } from "../helpers/apiHelper";

export const workOrderDocumentsApi = {
  getAll: async (params = {}) => {
    const queryParams = new URLSearchParams(params);

    return axios(`/api/work-order-documents?${queryParams.toString()}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "GET",
    })
      .then((response) => response.data)
      .catch((error) => apiHelper.handleError(error));
  },
  create: async (payload) => {
    return axios("/api/work-order-documents", {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "POST",
      data: payload,
    })
      .then((response) => response.data)
      .catch((error) => apiHelper.handleError(error));
  },
  delete: async (id) => {
    return axios(`/api/work-order-documents/${id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "DELETE",
    })
      .then((response) => response.data)
      .catch((error) => apiHelper.handleError(error));
  },
};
