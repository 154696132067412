import axios from "axios";
import config from "../config/config";
import moment from "moment";
import { apiHelper } from "../helpers/apiHelper";
import { utilsHelper } from "../helpers/utilsHelper";

export const injuryReportAttachmentApi = {
  getInjuryReportAttachmentQuery: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/injury-report-attachments?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION }, //addes token to headers for auth
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getInjuryReportAttachment: (data) => {
    return axios(`/api/injury-report-attachments/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getInjuryReportAttachmentByWorkOrderId: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(
      `/api/injury-report-attachments/workOrderId/${data.id}?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  saveInjuryReportAttachment: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/injury-report-attachments`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateInjuryReportAttachment: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/injury-report-attachments/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  deleteInjuryReportAttachment: (data) => {
    data.deletedAt = moment().toISOString();
    return axios(`/api/injury-report-attachments/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
