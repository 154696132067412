import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { appExplanationApi } from "../../../services/appExplanationServices";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";

import ReactQuill from "react-quill";

const MAX_CHAR_QUESTION = 10000;
const QUILL_MODULES = {
  toolbar: {
    container: [
      ["bold", "italic", "underline"], // toggled buttons

      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ align: [] }],

      ["clean"], // remove formatting button
      //["image"],
    ],
    /*
    handlers: {
      image: () => {
        document.getElementById("image-uploader")?.click();
      },
    },
    */
  },
};

const AppExplanationTextModal = ({ appExplanationText, onSubmit, onClose }) => {
  const [section, setSection] = useState();
  const [enabled, setEnabled] = useState();
  const [content, setContent] = useState();
  const [contentLength, setContentLength] = useState(0);

  useEffect(() => {
    if (appExplanationText) {
      setSection(appExplanationText.section);
      setEnabled(appExplanationText.enabled);
      setContent(appExplanationText.content);
      setContentLength(appExplanationText.content.length);
    }
  }, [appExplanationText]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();

  const doSubmit = async (e) => {
    e.preventDefault();
    if (contentLength > MAX_CHAR_QUESTION) {
      return setInformationModal({
        isOpen: true,
        title: "Error",
        body: `Content length exceeds the max. length of ${MAX_CHAR_QUESTION} characters.`,
      });
    }
    setLoading(true);
    try {
      if (appExplanationText) {
        await appExplanationApi.updateAppExplanationText({
          ...appExplanationText,
          section,
          enabled,
          content,
        });
      } else {
        await appExplanationApi.createAppExplanationText({
          section,
          enabled,
          content,
        });
      }
      onSubmit();
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "App Explanation Text Modal",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const onChangeQuillEditor = (content, delta, source, editor) => {
    setContent(content.replace(/<p><br><\/p>/g, ""));
    setContentLength(editor.getText().length - 1);
  };

  const checkCharacterCount = (event) => {
    if (contentLength >= MAX_CHAR_QUESTION && event.key !== "Backspace") {
      event.preventDefault();
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="md">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          Add App Explanation Text
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col sm="12">
                <FormGroup>
                  <div className="d-flex rounded border cursor-pointer">
                    <div
                      className={`text-center col-6 py-1 bg-${
                        enabled ? "primary" : "none"
                      } text-${enabled ? "white" : "dark"} ${
                        enabled ? "font-weight-bold" : ""
                      }`}
                      onClick={() => setEnabled(true)}
                    >
                      Enabled
                    </div>
                    <div
                      className={`text-center col-6 py-1 border-left bg-${
                        !enabled ? "primary" : "none"
                      } text-${!enabled ? "white" : "dark"} ${
                        !enabled ? "font-weight-bold" : ""
                      }`}
                      onClick={() => setEnabled(false)}
                    >
                      Disabled
                    </div>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label for="section">
                    <span>Section</span>
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <Input
                    placeholder="Enter the section..."
                    type="text"
                    name="section"
                    value={section}
                    onChange={(e) => setSection(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup className="mb-0">
                  <Label for="content">
                    <span>Content</span>
                    <small className="text-danger ml-1">*</small>
                  </Label>
                  <ReactQuill
                    name="content"
                    className="min-height-250 react-quill border rounded"
                    modules={QUILL_MODULES}
                    placeholder="Explanation content..."
                    value={content}
                    onChange={onChangeQuillEditor}
                    onKeyDown={checkCharacterCount}
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AppExplanationTextModal;
