import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import config from "../config/config";
import { awsApi } from "../services/awsServices";
import InformationModal from "./InformationModal";
import Loader from "./Loader";

const UploadPhotosModal = ({ isMulti = false, title, onClose, onSubmit }) => {
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const hiddenFileInputPhoto = useRef(null);
  const [loading, setLoading] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const selectedImages = [];

    setImages(Array.from(event.target.files));

    selectedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        selectedImages.push(reader.result);
        if (selectedImages.length === selectedFiles.length) {
          setPreviewImages(selectedImages);
        }
      };
    });
  };

  const appendImageToFormData = (formData, image) => {
    formData.append("files", image);
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("bucket", config.documentsBucket);
    images.forEach((image) => appendImageToFormData(formData, image));
    const response = await awsApi.uploadFile(formData);
    return response;
  };

  const handleUploadClick = async () => {
    setLoading(true);
    try {
      const { urls } = await handleUpload();
      setInformationModal({
        isOpen: true,
        title: "Upload Images",
        body: "Images Uploaded Successfully",
        onClose: () => {
          onSubmit(urls);
          onClose();
        },
      });
    } catch (error) {
      setInformationModal({
        isOpen: true,
        title: "Upload Images",
        body: error.message || "There was an error with your request.",
      });
    } finally {
      setLoading(false);
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        {title ? title : `Upload Photo`}
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Form>
            <div className="m-1">
              <input
                ref={hiddenFileInputPhoto}
                value=""
                multiple={isMulti}
                type="file"
                accept="image/*"
                className="text-truncate"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <Button
                size="sm"
                className="rounded mr-2"
                onClick={() => hiddenFileInputPhoto.current.click()}
              >
                <FontAwesomeIcon icon={faUpload} className="mr-2" />
                Choose Photo
              </Button>
            </div>
            <div>
              {previewImages.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${index}`}
                  className="border m-1 w-100"
                />
              ))}
            </div>
          </Form>
        )}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-between">
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleUploadClick}
          type="submit"
          disabled={!images.length}
        >
          Upload
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UploadPhotosModal;
