import React, { useState } from "react";

import {
  Button,
  Col,
  CustomInput,
  Form,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { useWorkOrderStatus } from "../../../providers/workOrderStatusProvider";
import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";

const EditStatusModal = ({ onSubmit, onClose }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [workOrderStatusContext] = useWorkOrderStatus();

  const [status, setStatus] = useState(workOrderDetails.workOrder.statusId);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const doSubmit = (evt) => {
    evt.preventDefault();
    onSubmit(status);
  };

  return (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Edit Work Order Status</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <ListGroup>
            <ListGroupItem className="d-flex align-items-center">
              <Col className="px-0" sm="4">
                <Label className="mb-0 d-flex">
                  <span>Status</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
              </Col>
              <Col className="px-0" sm="8">
                <CustomInput
                  required
                  id="statusSelect"
                  type="select"
                  name="statusSelect"
                  onChange={(event) => setStatus(event.currentTarget.value)}
                  value={status}
                >
                  <option value={""}>Select a Status</option>
                  {workOrderStatusContext.workOrderStatus.map((status) => (
                    <option key={status.id} value={status.id}>
                      {status.status}
                    </option>
                  ))}
                </CustomInput>
              </Col>
            </ListGroupItem>
          </ListGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose}>
            Discard
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditStatusModal;
