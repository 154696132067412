import axios from "axios";
import config from "../config/config";
import moment from "moment";
import { apiHelper } from "../helpers/apiHelper";

export const notesApi = {
  getNote: (data) => {
    return axios(`/api/notes/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  save: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/notes`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  update: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/notes/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  delete: (data) => {
    data.deletedAt = moment().toISOString();
    return axios(`/api/notes/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
