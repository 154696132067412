import axios from "axios";
import config from "../config/config";
import moment from "moment";
import { apiHelper } from "../helpers/apiHelper";

export const perDiemAdjustmentsApi = {
  editBulk: (data) => {
    return axios(`/api/per-diem-adjustments/bulk`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  create: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/per-diem-adjustments`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  update: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/per-diem-adjustments/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
