import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
} from "reactstrap";

import { notesApi } from "../../../services/noteServices";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";
import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";

const types = [
  {
    label: "Daily Work Description",
  },
  {
    label: "Office",
  },
];

const NoteModal = ({ noteId, crewWorkDayId, onSubmit, onClose }) => {
  const [workOrderDetails] = useWorkOrderDetails();

  const [note, setNote] = useState({
    content: "",
    type: "",
    workOrderId: workOrderDetails.workOrder.id,
    crewWorkDayId: crewWorkDayId,
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();

  const doSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (note.id) {
      const { id, content, type } = note;
      try {
        await notesApi.update({
          id,
          content,
          type,
        });
        setLoading(false);
        onSubmit();
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Update Note",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    } else {
      try {
        await notesApi.save(note);
        setLoading(false);
        onSubmit();
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Create Note",
          body:
            err?.response?.data[0].msg ||
            "There was an error with your request.",
        });
      }
    }
  };

  // Load Note
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await notesApi.getNote({ id: noteId });
        setLoading(false);
        setNote(result);
      } catch (err) {
        setLoading(false);
      }
    };
    if (noteId) {
      fetchData();
    }
  }, [noteId]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {noteId ? "Edit" : "Create"} Note
        </ModalHeader>
        <ModalBody>
          <Row>
            {loading ? (
              <Loader size="sm" />
            ) : (
              <Col sm="12">
                <FormGroup>
                  <Label for="name">
                    <span>Type</span>
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    type="select"
                    name="typeSelect"
                    id="typeSelect"
                    onChange={(e) =>
                      setNote({
                        ...note,
                        type: e.target.value,
                      })
                    }
                    value={note.type}
                    required
                  >
                    <option value={""}>Select a Type</option>
                    {types.map((type, index) => (
                      <option key={index} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="name">
                    <span>Content</span>
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    type="textarea"
                    name="content"
                    value={note.content || note.content}
                    onChange={(e) =>
                      setNote({
                        ...note,
                        content: e.target.value,
                      })
                    }
                    required
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default NoteModal;
