import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CustomInput,
  FormGroup,
  Label,
  Col,
  ListGroup,
  ListGroupItem,
  Input,
  Form,
} from "reactstrap";
import { DayPicker } from "react-day-picker";
import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";
import { utilsHelper } from "../../../helpers/utilsHelper";
import moment from "moment";
import {
  faCalendar,
  faPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "../../Loader";
import { workTimesApi } from "../../../services/workTimeServices";
import { travelTimesApi } from "../../../services/travelTimeServices";
import InformationModal from "../../InformationModal";
import CustomCheckbox from "../../CustomCheckbox";
import AddTimeNonJob from "./nonJob/AddTimeNonJob";
import { generalWorkTimesApi } from "../../../services/generalWorkTimeServices";

const TYPE_WORK_TIME = "TYPE_WORK_TIME";
const TIME_TYPE_REGULAR = "regular";
const TIME_TYPE_STANDBY_TIME = "standBy";
const TIME_TYPE_DOUBLE = "double";
const TIME_TYPE_SAFETY = "safety";
const TIME_TYPE_OVERTIME = "overtime";
const TIME_TYPE_MILEAGE = "mileage";
const TIME_TYPE_HOLIDAY = "holiday";

const TRAVEL_TO_JOB = "TRAVEL_TO_JOB";
const TRAVEL_FROM_JOB = "TRAVEL_FROM_JOB";

const NONJOB = "NONJOB";

const AddTimeModal = ({ type = TYPE_WORK_TIME, onSubmit, onClose }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crew, setCrew] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateSelectorOpen, setDateSelectorOpen] = useState();
  const [loading, setLoading] = useState();

  const [employeeEnabled, setEmployeeEnabled] = useState({});

  const [timeToAdd, setTimeToAdd] = useState({});
  const [actualTimeToAdd, setActualTimeToAdd] = useState({});
  const [reasonToAdd, setReasonToAdd] = useState({});
  const [timeTypeToAdd, setTimeTypeToAdd] = useState({});
  const [mileageToAdd, setMileageToAdd] = useState({});
  const [directionToAdd, setDirectionToAdd] = useState({});
  const [actualMileageToAdd, setActualMileageToAdd] = useState({});
  const [perDiemAmountIdToAdd, setPerDiemAmountIdToAdd] = useState({});
  const [generalWorkTimeTypes, setGeneralWorkTimeTypes] = useState([]);
  const [generalWorkTimesTypesToAdd, setGeneralWorkTimeTypesToAdd] = useState(
    {}
  );
  const [manualPerDiemToAdd, setManualPerDiemToAdd] = useState({});

  const [isDriver, setIsDriver] = useState({});
  const [workOrderId, setWorkOrderId] = useState(workOrderDetails.workOrder.id);
  const [isLoading, setIsLoading] = useState();
  const [perDiemAmounts, setPerDiemAmounts] = useState([]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const doSubmit = async (event) => {
    event.preventDefault();
    if (type === TYPE_WORK_TIME) {
      let employees;
      if (utilsHelper.isNonJobWorkOrderType(workOrderDetails)) {
        employees = [];
        Object.entries(timeToAdd).forEach(([employeeId, hoursObj]) => {
          const employeeHours = hoursObj;
          Object.entries(employeeHours).forEach(([type, hours]) => {
            if (hours !== "") {
              employees.push({
                type,
                date: moment(selectedDate).format("YYYY-MM-DD"),
                changeReason: reasonToAdd[employeeId],
                workOrderId,
                hours: type === "mileage" ? 0 : hours,
                mileage: type === "mileage" ? hours : 0,
                employeeId,
                crewId: crew.id,
                perDiemSuplemented:
                  perDiemAmountIdToAdd[employeeId] ||
                  manualPerDiemToAdd[employeeId]
                    ? true
                    : null,
                perDiemAmountId: perDiemAmountIdToAdd[employeeId] || null,
                note: "Created from the web",
                generalWorkTimeTypeId:
                  utilsHelper.isNonJobWorkOrderType(workOrderDetails) &&
                  type === TIME_TYPE_REGULAR
                    ? generalWorkTimesTypesToAdd[employeeId]
                    : null,
                manualPerDiem: manualPerDiemToAdd[employeeId] || null,
              });
            }
          });
        });
      } else {
        employees = Object.keys(timeToAdd).map((employeeId) => ({
          type: timeTypeToAdd[employeeId],
          date: moment(selectedDate).format("YYYY-MM-DD"),
          changeReason: reasonToAdd[employeeId],
          workOrderId,
          hours:
            timeTypeToAdd[employeeId] === "mileage" ? 0 : timeToAdd[employeeId],
          mileage:
            timeTypeToAdd[employeeId] === "mileage" ? timeToAdd[employeeId] : 0,
          employeeId,
          crewId: crew.id,
          note: "Created from the web",
        }));
      }
      if (!employees.length) {
        return setInformationModal({
          isOpen: true,
          title: `Add Work Time/Mileage`,
          body: "Add time to at least one employee.",
        });
      }
      setLoading(true);
      try {
        await workTimesApi.createWorkTimes({
          employees,
        });
        setLoading(false);
        onSubmit();
      } catch (err) {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: `Add Work Time/Mileage`,
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
        });
      }
    } else {
      const employees = Object.keys(actualTimeToAdd).map((employeeId) => ({
        type: TIME_TYPE_REGULAR,
        date: moment(selectedDate).format("YYYY-MM-DD"),
        workOrderId,
        hours: timeToAdd[employeeId] || 0,
        actualHours: actualTimeToAdd[employeeId] || 0,
        mileage: mileageToAdd[employeeId] || 0,
        actualMileage: actualMileageToAdd[employeeId] || 0,
        isDriver: isDriver[employeeId] || false,
        direction: directionToAdd[employeeId],
        changeReason: reasonToAdd[employeeId],
        employeeId,
        crewId: crew.id,
        note: "Created from the web",
      }));
      if (!employees.length) {
        return setInformationModal({
          isOpen: true,
          title: `Add Travel Time`,
          body: "Add time to at least one employee.",
        });
      }
      setLoading(true);
      try {
        await travelTimesApi.createTravelTime({
          employees,
        });
        setLoading(false);
        onSubmit();
      } catch (err) {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: `Create Travel Time`,
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
        });
      }
    }
  };

  const onSelectedDate = (date) => {
    setSelectedDate(date);
    setDateSelectorOpen(false);
  };

  const fetchData = async (
    apiFunction,
    setStateFunction,
    setLoadingFunction
  ) => {
    try {
      setLoadingFunction(true);
      const data = await apiFunction();
      const resultData = data.data || data;
      setStateFunction(resultData);
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Error",
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    } finally {
      setLoadingFunction(false);
    }
  };

  useEffect(() => {
    if (utilsHelper.isNonJobWorkOrderType(workOrderDetails)) {
      const apiCalls = [
        {
          apiFunction: generalWorkTimesApi.getGeneralWorkTimesTypes,
          setStateFunction: setGeneralWorkTimeTypes,
        },
        {
          apiFunction: generalWorkTimesApi.getPerDiemAmounts,
          setStateFunction: setPerDiemAmounts,
        },
      ];

      apiCalls.forEach(({ apiFunction, setStateFunction }) => {
        fetchData(apiFunction, setStateFunction, setIsLoading);
      });
    }
  }, [workOrderDetails]);

  useEffect(() => {
    setTimeToAdd({});
    setActualTimeToAdd({});
    setMileageToAdd({});
    setDirectionToAdd({});
    setActualMileageToAdd({});
    setTimeTypeToAdd({});
    setReasonToAdd({});
    setGeneralWorkTimeTypesToAdd({});
    return () =>
      setTimeToAdd({}) &&
      setActualTimeToAdd({}) &&
      setMileageToAdd({}) &&
      setDirectionToAdd({}) &&
      setActualMileageToAdd({}) &&
      setTimeTypeToAdd({}) &&
      setReasonToAdd({}) &&
      setGeneralWorkTimeTypesToAdd({});
  }, [crew, selectedDate]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal
      isOpen={true}
      onClosed={onClose}
      size={
        utilsHelper.isNonJobWorkOrderType(workOrderDetails) &&
        type === TYPE_WORK_TIME
          ? "lg"
          : null
      }
    >
      <ModalHeader close={closeBtn}>
        Add {type === TYPE_WORK_TIME ? "Work" : "Travel"} Time
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup row className="d-flex align-items-center">
                <Label className="col-4 d-flex flex-column text-left mb-0">
                  <span>Date</span>
                  <small className="text-warning">
                    Click the date to{" "}
                    {dateSelectorOpen ? "select/close" : "change"}
                  </small>
                </Label>
                <div className="col-8 d-flex align-items-center justify-content-between">
                  <div className="flex-grow-1">
                    {dateSelectorOpen ? (
                      <DayPicker
                        className="date-picker bg-white rounded border d-flex justify-content-center"
                        mode="single"
                        defaultMonth={selectedDate}
                        selected={[selectedDate]}
                        modifiersClassNames={{
                          selected: "my-selected",
                          today: "my-today",
                          range_start: "my-range_start",
                          range_end: "my-range_end",
                        }}
                        onDayClick={onSelectedDate}
                      />
                    ) : (
                      <div
                        className="float-left d-flex align-items-center px-2 py-1 border rounded bg-white cursor-pointer"
                        onClick={() => setDateSelectorOpen(true)}
                      >
                        <FontAwesomeIcon
                          icon={faCalendar}
                          className="mr-2 text-secondary"
                        />
                        <span>{moment(selectedDate).format("MM/DD/YYYY")}</span>
                      </div>
                    )}
                  </div>
                </div>
              </FormGroup>
              <FormGroup row className="d-flex align-items-center">
                <Label sm={4} className="text-sm-left">
                  Crew
                </Label>
                <Col sm={8}>
                  <CustomInput
                    required={true}
                    id="crewSelect"
                    type="select"
                    name="crewSelect"
                    value={crew?.id || ""}
                    onChange={(event) => {
                      const crews =
                        workOrderDetails.workOrder.crewWorkOrders.map(
                          (cwo) => cwo.crew
                        );
                      const crew = crews.find(
                        (c) => c.id === event.currentTarget.value
                      );
                      setCrew(crew);
                    }}
                  >
                    <option value={""}>Select a Crew</option>
                    {workOrderDetails.workOrder.crewWorkOrders
                      .filter((crewWorkOrder) =>
                        crewWorkOrder.crew.employeeCrews
                          .filter((ec) =>
                            utilsHelper.isEmployeeCrewActiveOnDate(
                              ec,
                              selectedDate,
                              false //PB-1855
                            )
                          )
                          .find((ec) => ec.isCrewLead)
                      )
                      .map((crewWorkOrder) => {
                        const sup = crewWorkOrder.crew.employeeCrews
                          .filter((ec) =>
                            utilsHelper.isEmployeeCrewActiveOnDate(
                              ec,
                              selectedDate,
                              false //PB-1855
                            )
                          )
                          .find((ec) => ec.isCrewLead);
                        return (
                          <option
                            key={crewWorkOrder.crew.id}
                            value={crewWorkOrder.crew.id}
                          >
                            {`${sup.employee.firstName} ${
                              sup.employee.lastName
                            } (${crewWorkOrder.shift}${
                              crewWorkOrder.outage
                                ? `/${crewWorkOrder.outage}`
                                : ""
                            })`}
                          </option>
                        );
                      })}
                  </CustomInput>
                </Col>
              </FormGroup>
            </>
          )}
          {workOrderDetails.workOrder.groupedWorkOrders?.length ? (
            <FormGroup row className="mb-0">
              <Label sm={4} className="text-sm-left">
                Work Order
              </Label>
              <Col sm={8}>
                <CustomInput
                  required={true}
                  id="workOrderSelect"
                  type="select"
                  name="workOrderSelect"
                  value={workOrderId || ""}
                  onChange={(event) =>
                    setWorkOrderId(event.currentTarget.value)
                  }
                >
                  <option value={""}>Select a Work Order</option>
                  {workOrderDetails.workOrder.groupedWorkOrders.map(
                    (workOrder) => {
                      return (
                        <option key={workOrder.id} value={workOrder.id}>
                          {workOrder.workOrderNumber}
                        </option>
                      );
                    }
                  )}
                </CustomInput>
              </Col>
            </FormGroup>
          ) : null}
          {crew && selectedDate && workOrderId ? (
            <ListGroup className="mt-3">
              <ListGroupItem
                className="d-flex justify-content-between align-items-center py-2 bg-lighter"
                tag="div"
              >
                <span>Crew Member</span>
                <span></span>
              </ListGroupItem>
              {utilsHelper
                .sortCrew(crew.employeeCrews)
                .filter((employeeCrew) =>
                  utilsHelper.isEmployeeCrewActiveOnDate(
                    employeeCrew,
                    selectedDate,
                    false //PB-1855
                  )
                )
                .map((employeeCrew) =>
                  utilsHelper.isNonJobWorkOrderType(workOrderDetails) &&
                  type === TYPE_WORK_TIME ? (
                    <AddTimeNonJob
                      employeeCrew={employeeCrew}
                      employeeEnabled={employeeEnabled}
                      setEmployeeEnabled={setEmployeeEnabled}
                      timeToAdd={timeToAdd}
                      setTimeToAdd={setTimeToAdd}
                      generalWorkTimesTypesToAdd={generalWorkTimesTypesToAdd}
                      setGeneralWorkTimeTypesToAdd={
                        setGeneralWorkTimeTypesToAdd
                      }
                      perDiemAmountIdToAdd={perDiemAmountIdToAdd}
                      setPerDiemAmountIdToAdd={setPerDiemAmountIdToAdd}
                      reasonToAdd={reasonToAdd}
                      setReasonToAdd={setReasonToAdd}
                      selectedDate={selectedDate}
                      crew={crew}
                      generalWorkTimeTypes={generalWorkTimeTypes}
                      perDiemAmounts={perDiemAmounts}
                      isLoading={isLoading}
                      manualPerDiemToAdd={manualPerDiemToAdd}
                      setManualPerDiemToAdd={setManualPerDiemToAdd}
                    />
                  ) : (
                    <ListGroupItem
                      key={employeeCrew.id}
                      className="d-flex justify-content-between align-items-center py-2"
                      tag="div"
                    >
                      <span>
                        {`${employeeCrew.employee.firstName} ${
                          employeeCrew.employee.lastName
                        }
                      ${!employeeCrew.employee.isActive ? "(Inactive)" : ""}`}
                      </span>
                      <div>
                        {employeeEnabled[employeeCrew.employee.id] ? (
                          <div className="width-250 d-flex align-items-center">
                            {type === TYPE_WORK_TIME ? (
                              <ListGroup className="col-12 px-0">
                                <ListGroupItem
                                  className="d-flex justify-content-center align-items-center py-2"
                                  tag="div"
                                >
                                  <CustomInput
                                    required={true}
                                    id="hoursTypeSelect"
                                    type="select"
                                    name="hoursTypeSelect"
                                    value={
                                      timeTypeToAdd[employeeCrew.employee.id] ||
                                      ""
                                    }
                                    onChange={(event) =>
                                      setTimeTypeToAdd({
                                        ...timeTypeToAdd,
                                        [employeeCrew.employee.id]:
                                          event.currentTarget.value,
                                      })
                                    }
                                  >
                                    <option value={""}>Select type...</option>
                                    {!utilsHelper.isNonJobWorkOrderType(
                                      workOrderDetails
                                    ) ? (
                                      <>
                                        <option value={TIME_TYPE_REGULAR}>
                                          Regular
                                        </option>
                                        <option value={TIME_TYPE_STANDBY_TIME}>
                                          Stand By
                                        </option>
                                        <option value={TIME_TYPE_DOUBLE}>
                                          Double
                                        </option>
                                        <option value={TIME_TYPE_SAFETY}>
                                          Safety
                                        </option>
                                        <option value={TIME_TYPE_OVERTIME}>
                                          Overtime
                                        </option>
                                        <option value={TIME_TYPE_HOLIDAY}>
                                          Holiday
                                        </option>
                                        <option value={TIME_TYPE_MILEAGE}>
                                          Daily Mileage
                                        </option>
                                      </>
                                    ) : null}
                                  </CustomInput>
                                  <FontAwesomeIcon
                                    onClick={() =>
                                      setEmployeeEnabled({
                                        ...employeeEnabled,
                                        [employeeCrew.employee.id]: false,
                                      })
                                    }
                                    icon={faTimesCircle}
                                    className="mt-2 cursor-pointer text-danger bg-white upper-close"
                                  />
                                </ListGroupItem>
                                <ListGroupItem
                                  className="d-flex justify-content-center align-items-center py-2"
                                  tag="div"
                                >
                                  <Input
                                    required={true}
                                    min={0.1}
                                    max={
                                      timeTypeToAdd[
                                        employeeCrew.employee.id
                                      ] === "mileage"
                                        ? 999999999
                                        : 24
                                    }
                                    step={0.01}
                                    placeholder={
                                      timeTypeToAdd[
                                        employeeCrew.employee.id
                                      ] === "mileage"
                                        ? "Mileage"
                                        : "Hours"
                                    }
                                    type="number"
                                    value={
                                      timeToAdd[employeeCrew.employee.id] || ""
                                    }
                                    onChange={(event) =>
                                      setTimeToAdd({
                                        ...timeToAdd,
                                        [employeeCrew.employee.id]:
                                          event.currentTarget.value,
                                      })
                                    }
                                  />
                                </ListGroupItem>
                              </ListGroup>
                            ) : (
                              <ListGroup className="col-12 px-0">
                                <ListGroupItem
                                  className="d-flex justify-content-between align-items-center cursor-pointer"
                                  onClick={() =>
                                    setIsDriver({
                                      ...isDriver,
                                      [employeeCrew.employee.id]:
                                        !isDriver[employeeCrew.employee.id],
                                    })
                                  }
                                >
                                  <div className="flex-shrink-0">Is Driver</div>
                                  <div className="min-width-50 d-flex justify-content-end">
                                    <CustomCheckbox
                                      checked={
                                        isDriver[employeeCrew.employee.id]
                                      }
                                    />
                                  </div>
                                  <FontAwesomeIcon
                                    onClick={() =>
                                      setEmployeeEnabled({
                                        ...employeeEnabled,
                                        [employeeCrew.employee.id]: false,
                                      })
                                    }
                                    icon={faTimesCircle}
                                    className="mt-2 cursor-pointer text-danger bg-white upper-close"
                                  />
                                </ListGroupItem>
                                <ListGroupItem
                                  className="d-flex justify-content-center align-items-center py-2"
                                  tag="div"
                                >
                                  <CustomInput
                                    required={true}
                                    id={`direction-${employeeCrew.employee.id}`}
                                    name={`direction-${employeeCrew.employee.id}`}
                                    type="select"
                                    onChange={(event) =>
                                      setDirectionToAdd({
                                        ...directionToAdd,
                                        [employeeCrew.employee.id]:
                                          event.currentTarget.value,
                                      })
                                    }
                                    value={
                                      directionToAdd[
                                        employeeCrew.employee.id
                                      ] || ""
                                    }
                                  >
                                    <option value={""}>
                                      Select a direction
                                    </option>
                                    <option value={TRAVEL_TO_JOB}>
                                      Travel to Job
                                    </option>
                                    <option value={TRAVEL_FROM_JOB}>
                                      Travel from Job
                                    </option>
                                  </CustomInput>
                                </ListGroupItem>
                                {workOrderDetails.workOrder.workOrderType !==
                                NONJOB ? (
                                  <ListGroupItem
                                    className="d-flex justify-content-center align-items-center py-2"
                                    tag="div"
                                  >
                                    <Input
                                      required={true}
                                      min={0}
                                      max={999999999}
                                      step={1}
                                      placeholder="Customer mileage"
                                      type="number"
                                      value={
                                        mileageToAdd[
                                          employeeCrew.employee.id
                                        ] || ""
                                      }
                                      onChange={(event) =>
                                        setMileageToAdd({
                                          ...mileageToAdd,
                                          [employeeCrew.employee.id]:
                                            event.currentTarget.value,
                                        })
                                      }
                                    />
                                  </ListGroupItem>
                                ) : null}
                                <ListGroupItem
                                  className="d-flex justify-content-center align-items-center py-2"
                                  tag="div"
                                >
                                  <Input
                                    required={true}
                                    min={0}
                                    max={999999999}
                                    step={1}
                                    placeholder={`${
                                      workOrderDetails.workOrder
                                        .workOrderType !== NONJOB
                                        ? "Actual "
                                        : ""
                                    }Mileage`}
                                    type="number"
                                    value={
                                      actualMileageToAdd[
                                        employeeCrew.employee.id
                                      ] || ""
                                    }
                                    onChange={(event) =>
                                      setActualMileageToAdd({
                                        ...actualMileageToAdd,
                                        [employeeCrew.employee.id]:
                                          event.currentTarget.value,
                                      })
                                    }
                                  />
                                </ListGroupItem>
                                {workOrderDetails.workOrder.workOrderType !==
                                NONJOB ? (
                                  <ListGroupItem
                                    className="d-flex justify-content-center align-items-center py-2"
                                    tag="div"
                                  >
                                    <Input
                                      required={true}
                                      min={0}
                                      max={1000000}
                                      step={0.01}
                                      placeholder="Customer hours"
                                      type="number"
                                      value={
                                        timeToAdd[employeeCrew.employee.id] ||
                                        ""
                                      }
                                      onChange={(event) =>
                                        setTimeToAdd({
                                          ...timeToAdd,
                                          [employeeCrew.employee.id]:
                                            event.currentTarget.value,
                                        })
                                      }
                                    />
                                  </ListGroupItem>
                                ) : null}
                                <ListGroupItem
                                  className="d-flex justify-content-center align-items-center py-2"
                                  tag="div"
                                >
                                  <Input
                                    required={true}
                                    min={0}
                                    max={24}
                                    step={0.01}
                                    placeholder={`${
                                      workOrderDetails.workOrder
                                        .workOrderType !== NONJOB
                                        ? "Actual "
                                        : ""
                                    }Hours`}
                                    type="number"
                                    value={
                                      actualTimeToAdd[
                                        employeeCrew.employee.id
                                      ] || ""
                                    }
                                    onChange={(event) =>
                                      setActualTimeToAdd({
                                        ...actualTimeToAdd,
                                        [employeeCrew.employee.id]:
                                          event.currentTarget.value,
                                      })
                                    }
                                  />
                                </ListGroupItem>
                              </ListGroup>
                            )}
                          </div>
                        ) : (
                          <Button
                            className="rounded"
                            size="sm"
                            color="warning"
                            onClick={() =>
                              setEmployeeEnabled({
                                ...employeeEnabled,
                                [employeeCrew.employee.id]: true,
                              })
                            }
                            disabled={!employeeCrew.employee.isActive}
                          >
                            <FontAwesomeIcon
                              icon={faPlus}
                              className={"mr-2 text-white"}
                            />
                            Add Time
                          </Button>
                        )}
                      </div>
                    </ListGroupItem>
                  )
                )}
            </ListGroup>
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          {loading ? (
            <div className="min-width-250">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color={"primary"} type={"submit"}>
              Confirm
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddTimeModal;
