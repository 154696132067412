import React, { useEffect, useRef } from "react";
import * as FlexmonsterReact from "react-flexmonster";
import { utilsHelper } from "../../../../helpers/utilsHelper";

const COLUMNS = {
  "Current Date": {
    type: "string",
  },
  "Work Order Number": {
    type: "string",
  },
  "Start Date": {
    type: "string",
  },
  "End Date": {
    type: "string",
  },
  "Customer Name": {
    type: "string",
  },
  "Shift Date": {
    type: "string",
  },
  Shift: {
    type: "string",
  },
  Description: {
    type: "string",
  },
  "Employee Name": {
    type: "string",
  },
  "Double Hours": {
    type: "string",
  },
  "Overtime Hours": {
    type: "string",
  },
  "Regular Hours": {
    type: "string",
  },
  "Safety Hours": {
    type: "string",
  },
  "Travel Hours": {
    type: "string",
  },
  "Holiday Hours": {
    type: "string",
  },
};

const report = {
  dataSource: {
    data: [COLUMNS],
  },
  slice: {
    rows: [
      {
        uniqueName: "Current Date",
      },
      {
        uniqueName: "Work Order Number",
      },
      {
        uniqueName: "Start Date",
      },
      {
        uniqueName: "End Date",
      },
      {
        uniqueName: "Customer Name",
      },
      {
        uniqueName: "Shift Date",
      },
      {
        uniqueName: "Shift",
      },
      {
        uniqueName: "Description",
      },
      {
        uniqueName: "Employee Name",
      },
      {
        uniqueName: "Double Hours",
      },
      {
        uniqueName: "Overtime Hours",
      },
      {
        uniqueName: "Regular Hours",
      },
      {
        uniqueName: "Safety Hours",
      },
      {
        uniqueName: "Travel Hours",
      },
      {
        uniqueName: "Holiday Hours",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Work Time Mill Report",
    },
  },
};

const WorkTimeFlexMillReport = ({ data }) => {
  const pivot = useRef(null);

  useEffect(() => {
    if (pivot && data) {
      const reportData = (data?.dateShifts || []).reduce(
        (p, c) => [
          ...p,
          ...c.crew
            .filter((employee) => employee.employeeName !== "TOTAL")
            .map((employee) => ({
              "Current Date": data.currentDate,
              "Work Order Number": data.workOrderNumber,
              "Start Date": utilsHelper.formatDate(
                data.startDate,
                "MM/DD/YYYY"
              ),
              "End Date": data.endDate
                ? utilsHelper.formatDate(data.endDate, "MM/DD/YYYY")
                : "-",
              "Customer Name": data.customerName,
              "Shift Date": c.date,
              Shift: c.shiftName,
              Description: c.description,
              "Employee Name": employee.employeeName,
              "Double Hours": employee.doubleHours,
              "Overtime Hours": employee.overtimeHours,
              "Regular Hours": employee.regularHours,
              "Safety Hours": employee.safetyHours,
              "Travel Hours": employee.travelHours,
              "Holiday Hours": employee.holidayHours,
            })),
        ],
        []
      );
      pivot.current.flexmonster.updateData({
        data: [COLUMNS, ...reportData],
      });
    }
  }, [data, pivot]);

  return (
    <div className="rounded border">
      <FlexmonsterReact.Pivot
        ref={pivot}
        licenseKey={process.env.REACT_APP_FLEX_M_KEY}
        toolbar={true}
        height="700px"
        report={report}
      />
    </div>
  );
};

export default WorkTimeFlexMillReport;
