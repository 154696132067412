import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import { apiHelper } from "../helpers/apiHelper";

export const reportsApi = {
  nonJobProjectsReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/reports/non-job-projects?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  hrPayrollReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/hr-payroll?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  cashAdvanceReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/cash-advance-week?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  travelAndLivingSummaryReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/travel-living-summary?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  travelAndLivingSummaryPdf: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/travel-living-summary-pdf?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      responseType: "blob",
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  sendEmailInspectionSummaryReport: (data) => {
    return axios(`/api/inspection-reports/${data.id}/email-customer`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  sendEmailTravelAndLivingSummaryPdf: (data) => {
    return axios(`/api/time-sheet/send-email-tl-summary`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  travelAndLivingReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/per-diem-week?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  payrollReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/all-employee-hours?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  supervisorPayrollReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/supervisor-hours?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  weeklyExpenseReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/services?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  weeklyGeneralExpenseReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/general-services?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  weeklyPayroll: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/payroll?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  sendNotification: (data) => {
    return axios(`/api/payroll/send-notification`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  existsNotification: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/payroll/exists-notification?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  laborUploadsReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/labor-uploads?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  weeklySuperFieldNotesReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/super-notes?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  millTimesheetsReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/mill/${data.workOrderId}?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      responseType: data.format === "json" ? "json" : "blob",
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  millTimesheetsSummaryReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/time-sheet/mill-summary/${data.workOrderId}?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      responseType: "blob",
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  inspectionSummaryReport: (data) => {
    return axios(`/api/inspection-reports/${data.inspectionId}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      responseType: "blob",
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
