import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import { apiHelper } from "../helpers/apiHelper";

export const changeOrdersApi = {
  getChangeOrders: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/change-orders?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateChangeOrder: (data) => {
    return axios(`/api/change-orders/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getChangeOrderPDF: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/change-orders/pdf/${data.id}?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      responseType: "blob",
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  deleteChangeOrder: (id) => {
    return axios(`/api/change-orders/${id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
