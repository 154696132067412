import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../helpers/apiHelper";

export const equipmentApi = {
  getEquipments: () => {
    return axios(`/api/equipments`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
