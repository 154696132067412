import { faPlus, faSync, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import AddNotificationEmailModal from "../../../components/admin/AddNotificationEmailModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import InformationModal from "../../../components/InformationModal";

import Loader from "../../../components/Loader";

import { notificationApi } from "../../../services/notificationServices";
import { useAuth } from "../../../providers/authProvider";
import { utilsHelper } from "../../../helpers/utilsHelper";

const JOB_SOURCE_ALL = 4;

const ChangeOrderNotifications = () => {
  const [authContext] = useAuth();
  const [loading, setLoading] = useState(true);
  const [changeOrderNotifications, setChangeOrderNotifications] = useState([]);
  const [refresh, setRefresh] = useState();
  const [addModal, setAddModal] = useState();

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await notificationApi.getChangeOrderNotifications({
          jobSourceId: authContext.currentUser.jobSourceId,
        });
        setChangeOrderNotifications(result.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, [refresh, authContext.currentUser.jobSourceId]);

  const removeEmail = (index) => {
    const changeOrderNotification = changeOrderNotifications[index];
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        try {
          setLoading(true);
          const updatedChangeOrderNotifications = [...changeOrderNotifications];
          updatedChangeOrderNotifications.splice(index, 1);
          await notificationApi.updateChangeOrderNotifications({
            emails: updatedChangeOrderNotifications.map((n) => n.email),
          });
          setChangeOrderNotifications(updatedChangeOrderNotifications);
          setLoading(false);
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Remove Email Notification",
            body: "Email removed successfully.",
          });
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
          setLoading(false);
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Email Notification",
      body: `<span class="text-center">Do you confirm you want to remove ${changeOrderNotification.email}?</span>`,
    });
  };

  const onCreate = () => {
    if (authContext.currentUser.jobSourceId === JOB_SOURCE_ALL) {
      return setInformationModal({
        isOpen: true,
        title: "Add Change Order Notification",
        body: "Please select a company in the sidebar selector to continue",
      });
    }
    setAddModal(true);
  };

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Card>
        <CardHeader className="d-flex align-items-center justify-content-between mt-2">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h3 className="mb-0 ">Change Order Notifications</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="mr-3 rounded-circle d-flex custom-rounded-button"
              color="primary"
              onClick={() => setRefresh(!refresh)}
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
            {!utilsHelper.isReadOnly(authContext) ? (
              <Button
                size="sm"
                className="rounded-circle d-flex custom-rounded-button"
                color="primary"
                onClick={onCreate}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            ) : null}
          </div>
        </CardHeader>
        <CardBody>
          {loading ? (
            <Loader />
          ) : changeOrderNotifications.length ? (
            <ListGroup flush className="border-top">
              <ListGroupItem
                className="py-2 border-bottom font-weight-bold bg-lighter text-muted small"
                tag="div"
              >
                Email
              </ListGroupItem>
              {changeOrderNotifications.map(
                (changeOrderNotification, index) => (
                  <ListGroupItem
                    key={index}
                    className="py-2 border-bottom d-flex justify-content-between align-items-center"
                    tag="div"
                  >
                    <span>{changeOrderNotification.email}</span>
                    <Button
                      className="ml-2 rounded d-flex align-items-center"
                      color="none"
                      size="sm"
                      onClick={() => removeEmail(index)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        className="text-danger"
                        size="lg"
                      />
                    </Button>
                  </ListGroupItem>
                )
              )}
            </ListGroup>
          ) : (
            <div className="text-center">No emails added</div>
          )}
        </CardBody>
      </Card>
      {addModal ? (
        <AddNotificationEmailModal
          submitFunction={notificationApi.createChangeOrderNotification}
          onSubmit={() => {
            setAddModal();
            setInformationModal({
              isOpen: true,
              title: "Add Email Notification",
              body: "Email added successfully.",
            });
            setRefresh(!refresh);
          }}
          onClose={() => setAddModal()}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </Container>
  );
};

export default ChangeOrderNotifications;
