import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Input,
  Label,
  Form,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "./Loader";
import moment from "moment";
import ConfirmationModal from "./ConfirmationModal";
import InformationModal from "./InformationModal";
import { employeeNoteApi } from "../services/employeeNoteServices";
import { useAuth } from "../providers/authProvider";
import { utilsHelper } from "../helpers/utilsHelper";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const EmployeeNotesModal = ({ onClose, employeeId }) => {
  const [authContext] = useAuth();

  const [loading, setLoading] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [notes, setNotes] = useState([]);

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = async () => {
    try {
      setLoading(true);
      await employeeNoteApi.createEmployeeNote({
        employeeId,
        content: newNote,
      });
      const notes = await employeeNoteApi.getEmployeeNotes({
        employeeId,
        jobSourceId: authContext.currentUser.jobSourceId,
      });
      setNotes(notes);
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Employee Notes`,
        body: `Note created successfully`,
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
        },
      });
      setNewNote("");
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Employee Notes`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const doDelete = (en, i) =>
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => {
        setConfirmationModal(initConfirmationModal);
        submitDelete(en.id, i);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Note from Employee",
      body: `Are you sure you want to delete this note?`,
    });

  const submitDelete = async (id) => {
    try {
      setLoading(true);
      await employeeNoteApi.deleteEmployeeNote({ id });
      const notes = await employeeNoteApi.getEmployeeNotes({
        employeeId,
        jobSourceId: authContext.currentUser.jobSourceId,
      });
      setNotes(notes);
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Employee Notes`,
        body: `Note deleted successfully`,
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
        },
      });
      setNewNote("");
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Employee Notes`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const notes = await employeeNoteApi.getEmployeeNotes({
          employeeId,
          jobSourceId: authContext.currentUser.jobSourceId,
        });
        setNotes(notes);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: `Employee Notes`,
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, [employeeId, authContext.currentUser.jobSourceId]);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Employee Notes</ModalHeader>
      <ModalBody className="text-center">
        {loading ? (
          <Loader size={"sm"} />
        ) : (
          <Row className="inner-section">
            {notes?.length ? (
              <div className="px-2 mb-3 col-12">
                {notes.map((en, i) => (
                  <div
                    style={{ background: "#FDF1DB" }}
                    className="text-left d-flex flex-column small p-2 rounded"
                    key={en.id}
                  >
                    <div className="d-flex mb-1 justify-content-between">
                      <span className="text-muted">
                        {moment(en.createdAt).format("MM-DD-YYYY, hh:mm a")}
                      </span>
                      {!utilsHelper.isReadOnly(authContext) ? (
                        <Button
                          onClick={() => doDelete(en, i)}
                          size="sm"
                          className="rounded"
                          color="danger"
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      ) : null}
                    </div>
                    <span>
                      <b>{`${en.employeeAuthor.firstName} ${en.employeeAuthor.lastName}: `}</b>
                      {en.content}
                    </span>
                  </div>
                ))}
              </div>
            ) : (
              <div className="col-12 small">No notes have been created</div>
            )}
            {!utilsHelper.isReadOnly(authContext) ? (
              <Form
                onSubmit={doSubmit}
                className="my-2 mx-2 w-100 d-flex flex-column border-top pt-3"
              >
                <Label className="text-left font-weight-bold">New Note</Label>
                <Input
                  required={true}
                  type="textarea"
                  rows={4}
                  placeholder="Enter the note..."
                  value={newNote}
                  onChange={(e) => setNewNote(e.target.value)}
                />
                <Button
                  size="sm"
                  className="mt-2 mr-auto rounded"
                  disabled={loading}
                  color="primary"
                  type="submit"
                >
                  <span>Save</span>
                </Button>
              </Form>
            ) : null}
          </Row>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button className="ml-auto" color={"secondary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EmployeeNotesModal;
