import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../helpers/apiHelper";

export const employeeTruckDriverApi = {
  updateAllEmployeeTruckDrivers: (data) => {
    return axios(`/api/employee-truck-drivers/all`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION }, //addes token to headers for auth
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
