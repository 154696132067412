import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../providers/workOrderDetailsProvider";
import { workOrdersApi } from "../services/workOrdersServices";
import WorkOrderJobScope from "./admin/WorkOrderJobScope";

const MODE_RAW = "raw";

const WorkOrderJobScopeRaw = () => {
  const { workOrderId, token } = useParams();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [loading, setLoading] = useState();

  // Load WorkOrder
  useEffect(() => {
    setLoading(true);
    const getWorkOrder = async () => {
      const data = await workOrdersApi.getWorkOrder(workOrderId, token);
      setWorkOrderDetails({
        action: ACTIONS.GET_WORKORDER_SUCCESS,
        payload: {
          workOrder: data,
        },
      });
      setLoading(false);
    };
    getWorkOrder();
  }, [workOrderId, token, setWorkOrderDetails]);

  return loading || !workOrderDetails.workOrder ? (
    <Loader />
  ) : (
    <WorkOrderJobScope mode={MODE_RAW} />
  );
};

export default WorkOrderJobScopeRaw;
