import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import { employeesApi } from "../../../services/employeeServices";
import InformationModal from "../../InformationModal";

import Loader from "../../Loader";

const EmployeeModal = ({ employeeId, onSubmit, onClose }) => {
  const [employee, setEmployee] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [loading, setLoading] = useState();

  // Load User
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await employeesApi.getEmployees({ id: employeeId });
        setLoading(false);
        setEmployee(result);
      } catch (err) {
        setLoading(false);
      }
    };
    if (employeeId) {
      fetchData();
    }
  }, [employeeId]);

  const doSubmit = async () => {
    setLoading(true);
    try {
      await employeesApi.update({
        ...employee,
      });
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: "Update employee",
        body: "Employee Updated Successfully",
        onClose: onSubmit,
      });
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Update employee",
        body:
          err?.response?.data[0].msg || "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <ModalHeader className="d-flex justify-content-between" close={closeBtn}>
        Edit Employee
      </ModalHeader>
      <ModalBody>
        <Row>
          {loading ? (
            <Loader size="sm" />
          ) : (
            <Col sm="12">
              <FormGroup>
                <Label for="firstName">
                  <span>First Name</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  disabled={true}
                  type="text"
                  name="firstName"
                  value={employee.firstName || ""}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="lastName">
                  <span>Last Name</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  disabled={true}
                  type="text"
                  name="lastName"
                  value={employee.lastName || ""}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">
                  <span>Email</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  disabled={true}
                  type="text"
                  name="email"
                  value={employee.email || ""}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="employeeCode">
                  <span>Employee Code</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  disabled={true}
                  type="text"
                  name="employeeCode"
                  value={employee.usrDfnId || "-"}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="phone">
                  <span>Phone</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  disabled={true}
                  type="text"
                  name="phone"
                  value={employee.phone || "-"}
                  required
                />
              </FormGroup>
              <FormGroup className="mb-0">
                <Label>
                  <span>Status</span>
                  <span className="text-danger ml-1">*</span>
                </Label>
                <select
                  className="form-control"
                  type="select"
                  name="statusSelect"
                  id="statusSelect"
                  onChange={(e) => {
                    setEmployee({
                      ...employee,
                      isActive: e.target.value.trim() === "true",
                    });
                  }}
                  value={employee.isActive ? "true" : "false"}
                  required
                >
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </FormGroup>
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Col>
          <Row className="justify-content-between">
            <Button color={"secondary"} onClick={onClose}>
              Cancel
            </Button>
            <Button color={"primary"} onClick={doSubmit}>
              Save
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default EmployeeModal;
