import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../helpers/apiHelper";

export const awsApi = {
  signDocuments: (data) => {
    return axios(`/api/aws/sign`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  uploadFile: (data) => {
    return axios(`/api/aws/upload`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  putDocumentsToS3: (data, type) => {
    return axios(data.url, {
      method: "put",
      data: data.file,
      headers: {
        "Content-Type": type,
      },
    })
      .then(() => {
        return { name: data.file.name, url: data.fileUrl };
      })
      .catch((err) => apiHelper.handleError(err));
  },
};
