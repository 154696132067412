import {
  faExclamationCircle,
  faQuestionCircle,
  faCopyright,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { useAuth } from "../../../providers/authProvider";
import TooltipItem from "../../TooltipItem";

const SUPER_ROLE = 3;
const TYPE_SUBCONTRACTOR = "2";
const CONTRACT_EMPLOYEE = "Contract Employee";

const WorkOrderCrewIndicators = ({ employee }) => {
  const [authContext] = useAuth();

  return (
    <div className="d-flex cursor-pointer">
      {employee.isOverage ? (
        <TooltipItem id={`${employee.id}-tooltip-1`} title={"Added as Overage"}>
          <FontAwesomeIcon
            size="lg"
            icon={faExclamationCircle}
            className={`ml-2 text-warning`}
            style={{ paddingBottom: "1px" }}
          />
        </TooltipItem>
      ) : null}
      {employee.isCrewLead ? (
        <TooltipItem id={`${employee.id}-tooltip-2`} title={"Crew Lead"}>
          <FontAwesomeIcon
            size="lg"
            icon={faExclamationCircle}
            className={`ml-2 text-secondary`}
            style={{ paddingBottom: "1px" }}
          />
        </TooltipItem>
      ) : null}
      {authContext.currentUser.jobSource &&
      employee.sourceKey !== authContext.currentUser.jobSource.sourceKey ? (
        <TooltipItem id={`${employee.id}-tooltip-3`} title={employee.sourceKey}>
          <FontAwesomeIcon
            size="lg"
            icon={faExclamationCircle}
            className={`ml-2 text-info`}
            style={{ paddingBottom: "1px" }}
          />
        </TooltipItem>
      ) : null}
      {employee.employeeCrewAuthor?.role.id === SUPER_ROLE ? (
        <TooltipItem
          id={`${employee.id}-tooltip-4`}
          title={`Added by ${employee.employeeCrewAuthor?.firstName}${
            employee.employeeCrewAuthor?.lastName
              ? ` ${employee.employeeCrewAuthor?.lastName}`
              : ""
          } on ${moment(employee.addedTime).format("YYYY-MM-DD hh:mm")}`}
        >
          <FontAwesomeIcon
            size="lg"
            icon={faExclamationCircle}
            className={`ml-2 text-success`}
            style={{ paddingBottom: "1px" }}
          />
        </TooltipItem>
      ) : null}
      {!employee.isActive ? (
        <TooltipItem id={`${employee.id}-tooltip-5`} title="Employee Inactive">
          <FontAwesomeIcon
            size="lg"
            icon={faExclamationCircle}
            className={`ml-2 text-danger`}
            style={{ paddingBottom: "1px" }}
          />
        </TooltipItem>
      ) : null}
      {employee.createdAt ? (
        <TooltipItem
          id={`${employee.id}-tooltip-6`}
          title={`Added on ${moment(employee.createdAt).format("MM/DD/YYYY")}`}
        >
          <FontAwesomeIcon
            size="lg"
            icon={faQuestionCircle}
            className={`ml-2 text-info`}
            style={{ paddingBottom: "1px" }}
          />
        </TooltipItem>
      ) : null}
      {employee.type === TYPE_SUBCONTRACTOR ? (
        <TooltipItem id={`${employee.id}-tooltip-7`} title={CONTRACT_EMPLOYEE}>
          <FontAwesomeIcon
            className={`ml-2 text-danger`}
            size="lg"
            icon={faCopyright}
          />
        </TooltipItem>
      ) : null}
    </div>
  );
};
export default WorkOrderCrewIndicators;
