import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { DayPicker } from "react-day-picker";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

import { generalWorkTimesApi } from "../../../services/generalWorkTimeServices";
import { utilsHelper } from "../../../helpers/utilsHelper";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";

const GeneralWorkTimeModal = ({ generalWorkTimeId, onSubmit, onClose }) => {
  const [generalWorkTime, setGeneralWorkTime] = useState({
    hours: 0,
    perDiemSuplemented: "",
    date: new Date(),
    generalWorkTimeTypeId: "",
    employeeId: "",
  });

  const [perDiemAmounts, setPerDiemAmounts] = useState([]);

  const [generalWorkTimeTypes, setGeneralWorkTimeTypes] = useState([]);
  const [loading, setLoading] = useState();

  const [dateSelectorOpen, setDateSelectorOpen] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (generalWorkTime.id) {
        const description = hasTypeOther(generalWorkTime)
          ? generalWorkTime.description
          : null;
        await generalWorkTimesApi.updateGeneralWorkTime({
          ...generalWorkTime,
          description,
          date: moment(generalWorkTime.date).format("YYYY-MM-DD"),
        });
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Update General Work Time",
          body: "General Work Time updated successfully",
          onClose: () => {
            setInformationModal({
              isOpen: false,
            });
            onSubmit();
          },
        });
      } else {
        await generalWorkTimesApi.createGeneralWorkTime(generalWorkTime);
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: "Create General Work Time",
          body: "General Work Time created successfully",
          onClose: () => {
            setInformationModal({
              isOpen: false,
            });
            onSubmit();
          },
        });
      }
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: generalWorkTime.id
          ? "Edit General Work Time"
          : "Create General Work Time",
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  // Load General Work Time
  useEffect(() => {
    const fetchData = async () => {
      if (generalWorkTimeId) {
        try {
          setLoading(true);
          const generalWorkTime = await generalWorkTimesApi.getGeneralWorkTime({
            id: generalWorkTimeId,
          });
          setGeneralWorkTime({
            ...generalWorkTime,
            date: generalWorkTime.date,
            perDiemSuplemented: generalWorkTime.perDiemSuplemented ? 1 : 0,
          });
          setLoading(false);
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: `General Work Time`,
            body:
              err?.response?.data[0]?.msg ||
              "There was an error with your request.",
          });
        }
      }
    };
    fetchData();
  }, [generalWorkTimeId]);

  // Load General Work Time Types, perDiem amounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const generalWorkTimesTypes =
          await generalWorkTimesApi.getGeneralWorkTimesTypes();
        const perDiemAmounts = await generalWorkTimesApi.getPerDiemAmounts();
        setGeneralWorkTimeTypes(generalWorkTimesTypes.data);
        setPerDiemAmounts(perDiemAmounts);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setInformationModal({
          isOpen: true,
          title: `General Work Time`,
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
        });
      }
    };
    fetchData();
  }, []);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onSelectedDate = (date) => {
    setGeneralWorkTime({
      ...generalWorkTime,
      date,
    });
    setDateSelectorOpen(false);
  };

  const hasTypeOther = (generalWorkTime) => {
    const typeOther = generalWorkTimeTypes.find((t) => t.name === "Other");
    return parseInt(generalWorkTime.generalWorkTimeTypeId) === typeOther?.id;
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {generalWorkTimeId ? "Edit" : "Create"} General Work Time
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <FormGroup>
                <Label for="hours">
                  Hours
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  placeholder="Enter hours..."
                  type="number"
                  name="hours"
                  min={0}
                  max={999}
                  value={generalWorkTime.hours}
                  onChange={(e) =>
                    setGeneralWorkTime({
                      ...generalWorkTime,
                      hours: e.target.value,
                    })
                  }
                  required
                />
              </FormGroup>
              <FormGroup className="d-flex flex-column">
                <Label>
                  Date
                  <span className="text-danger ml-1">*</span>
                </Label>
                <div className="col-12 px-0">
                  {dateSelectorOpen ? (
                    <DayPicker
                      className="date-picker bg-white rounded border d-flex justify-content-center"
                      mode="single"
                      defaultMonth={
                        new Date(`${generalWorkTime.date}T00:00:00`)
                      }
                      selected={[new Date(`${generalWorkTime.date}T00:00:00`)]}
                      modifiersClassNames={{
                        selected: "my-selected",
                        today: "my-today",
                        range_start: "my-range_start",
                        range_end: "my-range_end",
                      }}
                      onDayClick={onSelectedDate}
                    />
                  ) : (
                    <div
                      className="d-flex align-items-center px-2 py-1 border rounded bg-white cursor-pointer col-12 px-0"
                      onClick={() => setDateSelectorOpen(true)}
                    >
                      <FontAwesomeIcon
                        icon={faCalendar}
                        className="mr-2 text-secondary"
                      />
                      <span>
                        {moment(generalWorkTime.date).format("MM/DD/YYYY")}
                      </span>
                    </div>
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <Label for="select">
                  General Work Time Type
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  type="select"
                  name="select"
                  onChange={(e) =>
                    setGeneralWorkTime({
                      ...generalWorkTime,
                      generalWorkTimeTypeId: e.target.value,
                    })
                  }
                  value={generalWorkTime.generalWorkTimeTypeId}
                  required
                >
                  <option value={""}>Select a General Work Time Type</option>
                  {generalWorkTimeTypes.map((wttypes) => (
                    <option key={wttypes.id} value={wttypes.id}>
                      {utilsHelper.capitalize(wttypes.name)}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="description">
                  Description
                  {hasTypeOther(generalWorkTime) ? (
                    <span className="text-danger ml-1">*</span>
                  ) : null}
                </Label>
                <Input
                  placeholder="Enter a description"
                  type="text"
                  name="description"
                  onChange={(e) =>
                    setGeneralWorkTime({
                      ...generalWorkTime,
                      description: e.target.value,
                    })
                  }
                  value={generalWorkTime.description}
                  required={hasTypeOther(generalWorkTime)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="select">
                  Per Diem Suplemented
                  <span className="text-danger ml-1"></span>
                </Label>
                <Input
                  type="select"
                  name="typeSelect"
                  id="typeSelect"
                  onChange={(e) =>
                    setGeneralWorkTime({
                      ...generalWorkTime,
                      perDiemSuplemented: e.target.value,
                    })
                  }
                  value={generalWorkTime.perDiemSuplemented}
                  required
                >
                  <option value={""}>Select a Per Diem Suplemented</option>
                  <option value={1}>Yes</option>
                  <option value={0}>No</option>
                </Input>
              </FormGroup>
              {generalWorkTime.perDiemSuplemented ? (
                <FormGroup className="mb-0">
                  <Label for="select">
                    Per Diem Amount
                    <span className="text-danger ml-1"></span>
                  </Label>
                  <Input
                    type="select"
                    name="typeSelect"
                    id="typeSelect"
                    onChange={(e) =>
                      setGeneralWorkTime({
                        ...generalWorkTime,
                        perDiemAmountId: e.target.value || null,
                      })
                    }
                    value={generalWorkTime.perDiemAmountId}
                    required
                  >
                    <option value={""}>Select an amount</option>
                    {perDiemAmounts.map((pda) => (
                      <option value={pda.id}>{pda.amount}</option>
                    ))}
                  </Input>
                </FormGroup>
              ) : null}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <div className="min-width-50 d-flex align-items-center">
                {loading ? (
                  <Loader size="sm" align="end" />
                ) : (
                  <Button color={"primary"} type="submit">
                    Save
                  </Button>
                )}
              </div>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default GeneralWorkTimeModal;
