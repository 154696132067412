import React, { useCallback, useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Collapse,
  ListGroupItem,
  ListGroup,
} from "reactstrap";

import { inspectionsApi } from "../../../../services/inspectionServices";

import Loader from "../../../Loader";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faChevronDown,
  faChevronRight,
  faImages,
  faInfoCircle,
  faPen,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";

import TooltipItem from "../../../TooltipItem";
import CarouselSlider from "../../../CarouselSlider";
import InformationModal from "../../../InformationModal";
import CustomerSignOff from "./CustomerSignOff";
import EditAnswerModal from "./EditAnswerModal";
import { utilsHelper } from "../../../../helpers/utilsHelper";
import { useAuth } from "../../../../providers/authProvider";
import BarkBinScrews from "./SpecificQuestionTypes/BarkBinScrews";
import DustCollectorLayout from "./SpecificQuestionTypes/DustCollectorLayout";
import DimensionsClockfaceAlignmentEntry from "./SpecificQuestionTypes/DimesionesClockfaceAlignmentEntry";
import Clockface from "./SpecificQuestionTypes/Clockface";

const ATTACHMENT_TYPE_IMAGE = "IMAGE";
const ATTACHMENT_TYPE_NOTE = "NOTE";

const QUESTION_TYPE_CLOCKFACE = 7;
const QUESTION_TYPE_ATTACHMENT = 8;
const QUESTION_TYPE_CLOCKFACE_ALIGNMENT_ENTRY = 10;
const QUESTION_TYPE_DIMENSIONS_CLOCKFACE_ALIGNMENT_ENTRY = 11;
const QUESTION_TYPE_BARK_BIN_SCREWS = 12;
const QUESTION_TYPE_NUMBER_OF_COLUMNS_ROWS = 13;
const QUESTION_TYPE_DUST_COLLECTOR_LAYOUT = 14;
const QUESTION_NUMBER_OF_SCREWS = 11270;
const INSPECTION_SECTION_BARK_BIN_SCREWS = 1210;

const InspectionQuestionAttachments = ({
  isNotesSectionOpen,
  isPhotosSectionOpen,
  noteAttachments,
  imageAttachments,
  openImageModal,
}) => {
  return (
    <>
      <Collapse isOpen={isNotesSectionOpen} className="p-2">
        <ListGroup className="mb-2 col-12 px-0">
          <ListGroupItem
            className="col-12 py-2 d-flex justify-content-center align-items-center bg-lightgreen font-weight-bold rounded"
            tag="div"
            style={{ width: "100%" }}
          >
            <span>Notes</span>
            <span className="ml-1 text-muted">({noteAttachments.length})</span>
          </ListGroupItem>
          {noteAttachments.length ? (
            noteAttachments.map((note) => (
              <ListGroupItem
                className="col-12 py-2 border rounded mt-2"
                style={{ backgroundColor: "#FDF1DB" }}
                tag="div"
              >
                <i>{note.content}</i>
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem
              className="col-12 py-2 border rounded mt-2"
              tag="div"
            >
              No notes to show
            </ListGroupItem>
          )}
        </ListGroup>
      </Collapse>
      <Collapse isOpen={isPhotosSectionOpen} className="p-2">
        <ListGroup className="mb-2 col-12 px-0">
          <ListGroupItem
            className="col-12 py-2 d-flex justify-content-center align-items-center bg-lightgreen font-weight-bold rounded"
            tag="div"
            style={{ width: "100%" }}
          >
            <span>Photos</span>
            <span className="ml-1 text-muted">({imageAttachments.length})</span>
          </ListGroupItem>
          {imageAttachments.length ? (
            <ListGroupItem
              className="col-12 py-2 border rounded mt-2"
              tag="div"
            >
              {imageAttachments.map((photo, i) => {
                return (
                  <div
                    key={photo.id}
                    className="figure img-thumbnail cursor-pointer mx-1"
                    onClick={() =>
                      openImageModal(
                        imageAttachments.map((photo) => photo.content),
                        i
                      )
                    }
                    style={{
                      backgroundPosition: "center",
                      backgroundImage: `url(${photo.content})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      cursor: "pointer",
                      alignSelf: "center",
                      height: "150px",
                      width: "150px",
                    }}
                  ></div>
                );
              })}
            </ListGroupItem>
          ) : (
            <ListGroupItem
              className="col-12 py-2 border rounded mt-2"
              tag="div"
            >
              No photos to show
            </ListGroupItem>
          )}
        </ListGroup>
      </Collapse>
    </>
  );
};

const InspectionQuestion = ({
  inspectionQuestion,
  openImageModal,
  openEditAnswerModal,
}) => {
  const [authContext] = useAuth();
  const [isNotesSectionOpen, setIsNotesSectionOpen] = useState();
  const [isPhotosSectionOpen, setIsPhotosSectionOpen] = useState();
  const inspectionAnswer = inspectionQuestion.inspectionAnswers[0];
  const inspectionQuestionType = inspectionQuestion.inspectionQuestionTypeId;

  const noteAttachments =
    inspectionAnswer?.inspectionAnswerAttachments?.filter(
      (a) => a.type === ATTACHMENT_TYPE_NOTE
    ) || [];

  const imageAttachments =
    inspectionAnswer?.inspectionAnswerAttachments?.filter(
      (a) => a.type === ATTACHMENT_TYPE_IMAGE
    ) || [];

  const onNotesClick = () => {
    if (isNotesSectionOpen) {
      setIsNotesSectionOpen(false);
      setTimeout(() => setIsPhotosSectionOpen(!isPhotosSectionOpen), 500);
    } else {
      setIsPhotosSectionOpen(!isPhotosSectionOpen);
    }
  };

  const onPhotosClick = () => {
    if (isPhotosSectionOpen) {
      setIsPhotosSectionOpen(false);
      setTimeout(() => setIsNotesSectionOpen(!isNotesSectionOpen), 500);
    } else {
      setIsNotesSectionOpen(!isNotesSectionOpen);
    }
  };

  const getContent = (inspectionAnswerContent, type) => {
    try {
      switch (type) {
        case QUESTION_TYPE_CLOCKFACE:
          const jsonContent = JSON.parse(inspectionAnswerContent);
          return <>{!jsonContent && "-"}</>;

        case QUESTION_TYPE_CLOCKFACE_ALIGNMENT_ENTRY:
          return <></>;

        case QUESTION_TYPE_DIMENSIONS_CLOCKFACE_ALIGNMENT_ENTRY:
          return <></>;

        case QUESTION_TYPE_BARK_BIN_SCREWS:
          const contentBarkBinScrews = JSON.parse(inspectionAnswerContent);
          return (
            <span>
              {contentBarkBinScrews && contentBarkBinScrews.screws
                ? contentBarkBinScrews.screws.length
                : "-"}
            </span>
          );

        case QUESTION_TYPE_NUMBER_OF_COLUMNS_ROWS:
          const contentDustCollectorColumnsRows = JSON.parse(
            inspectionAnswerContent
          );
          return (
            <div className="d-flex flex-column">
              <div className="d-flex">
                <div>
                  <span className="font-weight-bold mx-1">Rows: </span>
                  {contentDustCollectorColumnsRows.rows || "N/A"},
                </div>
                <div>
                  <span className="font-weight-bold mx-1">Columns: </span>
                  {contentDustCollectorColumnsRows.columns || "N/A"}
                </div>
                <div></div>
              </div>
            </div>
          );

        case QUESTION_TYPE_DUST_COLLECTOR_LAYOUT:
          break;

        default:
          return inspectionAnswerContent;
      }
    } catch (err) {
      return inspectionAnswerContent;
    }
  };

  const isSpecificQuestionType =
    inspectionQuestion.inspectionQuestionTypeId === QUESTION_TYPE_ATTACHMENT ||
    inspectionQuestion.inspectionQuestionTypeId ===
      QUESTION_TYPE_BARK_BIN_SCREWS ||
    inspectionQuestion.id === QUESTION_NUMBER_OF_SCREWS ||
    inspectionQuestion.inspectionQuestionTypeId ===
      QUESTION_TYPE_DUST_COLLECTOR_LAYOUT ||
    inspectionQuestion.inspectionQuestionTypeId ===
      QUESTION_TYPE_NUMBER_OF_COLUMNS_ROWS;
  const isEditable = !isSpecificQuestionType;

  return (
    <>
      {inspectionQuestion.id !== QUESTION_NUMBER_OF_SCREWS ? (
        <ListGroupItem
          className="col-12 py-0 px-2 d-flex flex-column border rounded mt-2"
          tag="div"
        >
          <div className="d-flex justify-content-between align-items-center">
            <span className="text-left py-2 col-3 px-0">
              {inspectionQuestion.inspectionQuestionTypeId ===
              QUESTION_TYPE_BARK_BIN_SCREWS
                ? "Total Number of Screws"
                : inspectionQuestion.content}
            </span>
            <div className="d-flex align-items-center pl-4 col-9 px-0 justify-content-end">
              <i className="text-right py-2 text-break">
                {inspectionAnswer?.inspectionQuestionOption?.name ||
                  getContent(
                    inspectionAnswer?.content,
                    inspectionQuestion.inspectionQuestionTypeId
                  ) ||
                  (!isSpecificQuestionType ? "-" : "")}
              </i>
              {inspectionAnswer ? (
                <div className="d-flex">
                  <div className="d-flex justify-content-end align-items-center border-left py-2 ml-2 width-65">
                    {inspectionAnswer.explanation ? (
                      <TooltipItem
                        className="ml-2 cursor-pointer"
                        id={`${inspectionAnswer.id}-explanation-tooltip`}
                        title={inspectionAnswer.explanation}
                      >
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          className={`text-primary`}
                          style={{ paddingBottom: "1px" }}
                        />
                      </TooltipItem>
                    ) : null}
                    <TooltipItem
                      className="ml-2 cursor-pointer"
                      id={`${inspectionAnswer.id}-photos-tooltip`}
                      title="Photos"
                    >
                      <FontAwesomeIcon
                        onClick={onNotesClick}
                        icon={faImages}
                        className={`text-primary`}
                        style={{ paddingBottom: "1px" }}
                      />
                    </TooltipItem>
                    <TooltipItem
                      className="ml-2 cursor-pointer"
                      id={`${inspectionAnswer.id}-notes-tooltip`}
                      title="Notes"
                    >
                      <FontAwesomeIcon
                        onClick={onPhotosClick}
                        icon={faStickyNote}
                        className={`text-primary`}
                        style={{ paddingBottom: "1px" }}
                      />
                    </TooltipItem>
                  </div>
                  {!utilsHelper.isReadOnly(authContext) ? (
                    <div className="d-flex justify-content-end align-items-center border-left py-2 ml-2 width-65">
                      <TooltipItem
                        className={`ml-2 ${isEditable ? "cursor-pointer" : ""}`}
                        id={`${inspectionAnswer.id}-edit-tooltip`}
                        title={isEditable ? "Edit Answer" : "Not Editable"}
                      >
                        <FontAwesomeIcon
                          onClick={() =>
                            isEditable
                              ? openEditAnswerModal(
                                  inspectionQuestionType,
                                  inspectionQuestion
                                )
                              : null
                          }
                          icon={faPen}
                          className={isEditable ? "text-warning" : "text-muted"}
                          style={{ paddingBottom: "1px" }}
                        />
                      </TooltipItem>
                    </div>
                  ) : null}
                  <div className="d-flex justify-content-end align-items-center border-left py-2 ml-2 width-65">
                    <TooltipItem
                      className={`ml-2 ${
                        inspectionAnswer.updatedByUser ? "cursor-pointer" : ""
                      }`}
                      id={`${inspectionAnswer.id}-updated-by-tooltip`}
                      enabled={inspectionAnswer.updatedByUser}
                      title={
                        inspectionAnswer.updatedByUser
                          ? `Modified by ${inspectionAnswer.updatedByUser.firstName} ${inspectionAnswer.updatedByUser.lastName}`
                          : "No update has been made"
                      }
                    >
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className={
                          inspectionAnswer.updatedByUser
                            ? "text-secondary"
                            : "text-muted"
                        }
                        style={{ paddingBottom: "1px" }}
                      />
                    </TooltipItem>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {inspectionQuestion.inspectionQuestionTypeId ===
            QUESTION_TYPE_BARK_BIN_SCREWS &&
            inspectionAnswer && (
              <BarkBinScrews inspectionAnswer={inspectionAnswer} />
            )}
          {inspectionQuestion.inspectionQuestionTypeId ===
            QUESTION_TYPE_DUST_COLLECTOR_LAYOUT &&
            inspectionAnswer && (
              <DustCollectorLayout inspectionAnswer={inspectionAnswer} />
            )}
          {inspectionQuestion.inspectionQuestionTypeId ===
            QUESTION_TYPE_DIMENSIONS_CLOCKFACE_ALIGNMENT_ENTRY &&
            inspectionAnswer && (
              <DimensionsClockfaceAlignmentEntry
                inspectionAnswer={inspectionAnswer}
                withDimensions={true}
              />
            )}
          {inspectionQuestion.inspectionQuestionTypeId ===
            QUESTION_TYPE_CLOCKFACE_ALIGNMENT_ENTRY &&
            inspectionAnswer && (
              <DimensionsClockfaceAlignmentEntry
                inspectionAnswer={inspectionAnswer}
                withDimensions={false}
              />
            )}
          {inspectionQuestion.inspectionQuestionTypeId ===
            QUESTION_TYPE_CLOCKFACE &&
            inspectionAnswer && (
              <Clockface inspectionAnswer={inspectionAnswer} />
            )}
          <InspectionQuestionAttachments
            isNotesSectionOpen={isNotesSectionOpen}
            isPhotosSectionOpen={isPhotosSectionOpen}
            noteAttachments={noteAttachments}
            imageAttachments={imageAttachments}
            openImageModal={openImageModal}
          />
        </ListGroupItem>
      ) : null}
    </>
  );
};

const InspectionQuestionGroup = ({
  inspectionQuestionGroup,
  openImageModal,
  openEditAnswerModal,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [totalAnswers, setTotalAnswers] = useState(0);

  useEffect(() => {
    const answers = inspectionQuestionGroup.inspectionQuestions.filter(
      (inspectionQuestion) => inspectionQuestion.inspectionAnswers.length
    );
    setTotalAnswers(answers.length);
  }, [inspectionQuestionGroup.inspectionQuestions]);

  return inspectionQuestionGroup.name?.length ? (
    <div className="mt-2">
      <ListGroupItem
        className="col-12 py-2 d-flex justify-content-between align-items-center bg-lighter font-weight-bold cursor-pointer rounded"
        tag="div"
        key={inspectionQuestionGroup.id}
        onClick={() => setIsOpen(!isOpen)}
        style={{ width: "100%" }}
      >
        <span>{inspectionQuestionGroup.name}</span>
        <span>
          {totalAnswers} / {inspectionQuestionGroup.inspectionQuestions.length}
        </span>
      </ListGroupItem>
      <Collapse isOpen={isOpen} className="px-2">
        <div className="col-12 px-0 rounded">
          {inspectionQuestionGroup.inspectionQuestions.map(
            (inspectionQuestion) => (
              <InspectionQuestion
                key={inspectionQuestion.id}
                inspectionQuestion={inspectionQuestion}
                openImageModal={openImageModal}
                openEditAnswerModal={openEditAnswerModal}
              />
            )
          )}
        </div>
      </Collapse>
    </div>
  ) : (
    inspectionQuestionGroup.inspectionQuestions.map((inspectionQuestion) => (
      <InspectionQuestion
        key={inspectionQuestion.id}
        inspectionQuestion={inspectionQuestion}
        openImageModal={openImageModal}
        openEditAnswerModal={openEditAnswerModal}
      />
    ))
  );
};

const InspectionSection = ({
  inspectionSection,
  openImageModal,
  openEditAnswerModal,
  inspectionDiscarded,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalAnswers, setTotalAnswers] = useState(0);

  useEffect(() => {
    const questions = inspectionSection.inspectionQuestionGroups.flatMap(
      (inspectionQuestionGroup) => inspectionQuestionGroup.inspectionQuestions
    );
    const answers = questions.filter(
      (inspectionQuestion) => inspectionQuestion.inspectionAnswers.length
    );
    setTotalQuestions(questions.length);
    setTotalAnswers(answers.length);
  }, [inspectionSection.inspectionQuestionGroups]);

  return inspectionDiscarded ? null : (
    <ListGroup className="my-2 col-12 px-0" key={inspectionSection.id}>
      <ListGroupItem
        className="col-12 py-2 d-flex justify-content-between align-items-center font-weight-bold rounded bg-lightgreen cursor-pointer"
        tag="div"
        onClick={() => !inspectionDiscarded && setIsOpen(!isOpen)}
        style={{ width: "100%" }}
      >
        <div>
          <span>{inspectionSection.name}</span>
          {inspectionSection.id !== INSPECTION_SECTION_BARK_BIN_SCREWS &&
          !inspectionDiscarded ? (
            <span className="ml-1 text-muted">
              ({totalAnswers} / {totalQuestions})
            </span>
          ) : null}
        </div>

        <FontAwesomeIcon
          icon={isOpen ? faChevronDown : faChevronRight}
          fixedWidth
          className="my-1"
        />
      </ListGroupItem>
      <Collapse isOpen={inspectionDiscarded || isOpen} className="px-2">
        {inspectionDiscarded ? (
          <div className="my-2">
            This unit does not have{" "}
            {`${utilsHelper.aOrAn(inspectionSection.name)} ${
              inspectionSection.name
            }`}
          </div>
        ) : (
          inspectionSection.inspectionQuestionGroups
            .sort((a, b) => a.order - b.order)
            .map((inspectionQuestionGroup) => (
              <InspectionQuestionGroup
                key={inspectionQuestionGroup.id}
                inspectionQuestionGroup={inspectionQuestionGroup}
                openImageModal={openImageModal}
                openEditAnswerModal={openEditAnswerModal}
              />
            ))
        )}
      </Collapse>
    </ListGroup>
  );
};

const DataList = ({ inspection }) => {
  return (
    <ListGroup className="my-2 col-12 px-0">
      <ListGroupItem
        className="py-2 d-flex align-items-center bg-lightgreen font-weight-bold"
        tag="div"
      >
        <div className="col-3">Equipment</div>
        <div className="col-3">Date</div>
        <div className="col-3">Inspector</div>
        <div className="col-3">Contact Name</div>
      </ListGroupItem>
      <ListGroupItem className="py-2 d-flex align-items-center" tag="div">
        <div className="col-3">
          {inspection.inspectionTypeWorkOrder.equipment}
        </div>
        <div className="col-3">
          {moment(inspection.date).format("MM/DD/YYYY")}
        </div>
        <div className="col-3">{`${inspection.user.firstName} ${inspection.user.lastName}`}</div>
        <div className="col-3">{`${inspection.contactName || "-"}`}</div>
      </ListGroupItem>
    </ListGroup>
  );
};

const Notes = ({ inspection }) => {
  const [isOpen, setIsOpen] = useState(true);

  const notes = inspection.inspectionAttachments.filter(
    (inspectionAttachment) => inspectionAttachment.type === ATTACHMENT_TYPE_NOTE
  );
  return (
    <ListGroup className="my-2 col-12 px-0">
      <ListGroupItem
        className="col-12 py-2 d-flex justify-content-between align-items-center bg-lightgreen font-weight-bold cursor-pointer rounded"
        tag="div"
        onClick={() => setIsOpen(!isOpen)}
        style={{ width: "100%" }}
      >
        <div>
          <span>Notes</span>
          <span className="ml-1 text-muted">({notes.length})</span>
        </div>
        <FontAwesomeIcon
          icon={isOpen ? faChevronDown : faChevronRight}
          fixedWidth
          className="my-1"
        />
      </ListGroupItem>
      <Collapse isOpen={isOpen} className="px-2">
        {notes.length ? (
          notes.map((note) => (
            <ListGroupItem
              key={note.id}
              className="col-12 py-2 border rounded mt-2"
              style={{ backgroundColor: "#FDF1DB" }}
              tag="div"
            >
              <i>{note.content}</i>
            </ListGroupItem>
          ))
        ) : (
          <ListGroupItem className="col-12 py-2 border rounded mt-2" tag="div">
            No notes to show
          </ListGroupItem>
        )}
      </Collapse>
    </ListGroup>
  );
};

const Photos = ({ inspection, openImageModal }) => {
  const [isOpen, setIsOpen] = useState(true);

  const photos = inspection.inspectionAttachments.filter(
    (inspectionAttachment) =>
      inspectionAttachment.type === ATTACHMENT_TYPE_IMAGE
  );

  return (
    <ListGroup className="my-2 col-12 px-0">
      <ListGroupItem
        className="col-12 py-2 d-flex justify-content-between align-items-center bg-lightgreen font-weight-bold cursor-pointer rounded"
        tag="div"
        onClick={() => setIsOpen(!isOpen)}
        style={{ width: "100%" }}
      >
        <div>
          <span>Photos</span>
          <span className="ml-1 text-muted">({photos.length})</span>
        </div>
        <FontAwesomeIcon
          icon={isOpen ? faChevronDown : faChevronRight}
          fixedWidth
          className="my-1"
        />
      </ListGroupItem>
      <Collapse isOpen={isOpen} className="px-2">
        {photos.length ? (
          <ListGroupItem className="col-12 py-2 border rounded mt-2" tag="div">
            {photos.map((photo, i) => {
              return (
                <div
                  key={photo.id}
                  className="figure img-thumbnail cursor-pointer mx-1"
                  onClick={() =>
                    openImageModal(
                      photos.map((photo) => photo.content),
                      i
                    )
                  }
                  style={{
                    backgroundPosition: "center",
                    backgroundImage: `url(${photo.content})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    cursor: "pointer",
                    alignSelf: "center",
                    height: "150px",
                    width: "150px",
                  }}
                ></div>
              );
            })}
          </ListGroupItem>
        ) : (
          <ListGroupItem className="col-12 py-2 border rounded mt-2" tag="div">
            No photos to show
          </ListGroupItem>
        )}
      </Collapse>
    </ListGroup>
  );
};

const InspectionDetails = ({ inspectionId, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [inspection, setInspection] = useState();

  const initEditAnswerModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    inspectionQuestionType: null,
    question: null,
  };

  const [editAnswerModal, setEditAnswerModal] = useState(initEditAnswerModal);

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });

  const closeBtn = (
    <Button className="close" color="none" onClick={() => onClose()}>
      &times;
    </Button>
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await inspectionsApi.getInspections({
        id: inspectionId,
      });
      setInspection(result);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [inspectionId]);

  useEffect(() => {
    fetchData();
  }, [inspectionId, fetchData]);

  const openImageModal = (urls, selected) => {
    return setInformationModal({
      isOpen: true,
      title: "Images Preview",
      rawBody: true,
      body: (
        <CarouselSlider
          items={urls.map((url, i) => ({
            url,
            selected: i === selected,
          }))}
        />
      ),
    });
  };

  const editInspectionAnswer = async (inspectionAnswer) => {
    try {
      await inspectionsApi.updateInspectionAnswer(inspectionAnswer);
      setInformationModal({
        isOpen: true,
        title: "Answer Updated",
        rawBody: false,
        onClose: () => {
          fetchData();
          setInformationModal({
            rawBody: false,
            isOpen: false,
            title: "",
            body: "",
          });
        },
        body: "Answer updated successfully.",
      });
      setEditAnswerModal(initEditAnswerModal);
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: `Add Equipment`,
        body: "There was an error with your request.",
      });
    }
  };

  const openEditAnswerModal = (inspectionQuestionType, question) => {
    setEditAnswerModal({
      isOpen: true,
      onSubmit: editInspectionAnswer,
      onClose: () => setEditAnswerModal(initEditAnswerModal),
      inspectionQuestionType,
      question,
    });
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={
        informationModal.onClose
          ? () => informationModal.onClose()
          : () =>
              setInformationModal({
                rawBody: false,
                isOpen: false,
                title: "",
                body: "",
              })
      }
    />
  ) : editAnswerModal.isOpen ? (
    <EditAnswerModal {...editAnswerModal} />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <ModalHeader close={closeBtn}>
        {inspection?.inspectionTypeWorkOrder?.inspectionType?.name} Inspection
      </ModalHeader>
      <ModalBody className="text-center small">
        {loading || !inspection ? (
          <Loader size="sm" />
        ) : (
          <>
            <DataList inspection={inspection} />
            <Notes inspection={inspection} />
            <Photos inspection={inspection} openImageModal={openImageModal} />
            {inspection.inspectionTypeWorkOrder.inspectionType.inspectionSections
              .sort((a, b) => a.order - b.order)
              .map((inspectionSection) => {
                const isDiscarded = (
                  inspection?.inspectionSectionDiscardedIds ?? []
                ).includes(inspectionSection.id);
                return (
                  <InspectionSection
                    key={inspectionSection.id}
                    inspectionSection={inspectionSection}
                    openImageModal={openImageModal}
                    openEditAnswerModal={openEditAnswerModal}
                    inspectionDiscarded={isDiscarded}
                  />
                );
              })}
            <CustomerSignOff inspection={inspection} />
          </>
        )}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default InspectionDetails;
