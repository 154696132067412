import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CustomInput,
  FormGroup,
  Form,
} from "reactstrap";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import { workOrdersApi } from "../../services/workOrdersServices";
import InformationModal from "../InformationModal";
import ConfirmationModal from "../ConfirmationModal";
import Loader from "../Loader";
import SelectWrapper from "./SelectWrapper";
import { useAuth } from "../../providers/authProvider";

const ACTION_ADD = "ACTION_ADD";
const ACTION_REMOVE = "ACTION_REMOVE";
const MAX_PAGE_SIZE = 30;
const TYPE_SINGLE = "single";

const STATUS_COMPLETED = 4;

const EditGroupingModal = ({ onClose, onSubmit }) => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();

  const [loading, setLoading] = useState(false);
  const [selectedWo, setSelectedWo] = useState();
  const [action, setAction] = useState("");

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const ungroupWorkOrders = async (redirectToHome) => {
    try {
      setLoading(true);
      await workOrdersApi.ungroupWorkOrders({
        id: workOrderDetails.workOrder.id,
        removedId: selectedWo,
      });
      setLoading(false);
      onSubmit(redirectToHome);
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Remove Work Order from Group",
        body:
          err?.response?.data?.message ||
          "There was an error with your request.",
      });
    }
  };

  const doSubmit = async (event) => {
    event.preventDefault();
    if (action === ACTION_REMOVE) {
      if (workOrderDetails.workOrder.groupedWorkOrders?.length === 1) {
        setLoading(true);
        setConfirmationModal({
          isOpen: true,
          confirmColor: "danger",
          onSubmit: () => ungroupWorkOrders(true),
          onClose: () => {
            setConfirmationModal(initConfirmationModal);
          },
          title: "Remove Work Order from Group",
          body: `There is only one work order in the group, so the group will be removed. Do you confirm you want to continue?`,
        });
      } else {
        ungroupWorkOrders();
      }
    } else {
      if (workOrderDetails.workOrder.statusId === STATUS_COMPLETED) {
        return setInformationModal({
          isOpen: true,
          title: "Work Order Grouping Edition",
          body: "Can`t edit Completed groups.",
        });
      }
      try {
        setLoading(true);
        await workOrdersApi.addToGroup({
          id: workOrderDetails.workOrder.id,
          addId: selectedWo,
        });
        setLoading(false);
        onSubmit();
      } catch (err) {
        setLoading(false);
        return setInformationModal({
          isOpen: true,
          title: "Add Work Order to Group",
          body: err?.response?.data[0]
            ? err.response.data[0].msg
            : "There was an error with your request.",
        });
      }
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader close={closeBtn}>Edit Work Order Grouping</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          <p>Edit Grouping</p>
          <FormGroup className="mb-0">
            <CustomInput
              id="ungroup-select-action"
              type="select"
              required={true}
              name="ungroup-select-action"
              onChange={(event) => setAction(event.currentTarget.value)}
            >
              <option value={""}>Select an action</option>
              <option value={ACTION_ADD}>Add Work Order to Group</option>
              <option value={ACTION_REMOVE}>
                Remove Work Order from Group
              </option>
            </CustomInput>
          </FormGroup>
          {action === ACTION_ADD ? (
            workOrderDetails.workOrder.relatedWorkOrders.length ? (
              <FormGroup className="mb-0 mt-3">
                <CustomInput
                  id="group-select"
                  type="select"
                  required={true}
                  name="group-select"
                  onChange={(event) => setSelectedWo(event.currentTarget.value)}
                >
                  <option value={""}>Select a Work Order</option>
                  {workOrderDetails.workOrder.relatedWorkOrders.map((wo) => (
                    <option key={wo.id} value={wo.id}>
                      {wo.workOrderNumber}
                    </option>
                  ))}
                </CustomInput>
              </FormGroup>
            ) : (
              <div className="mt-3">
                <small className="text-warning">No related work orders</small>
              </div>
            )
          ) : action === ACTION_REMOVE ? (
            <FormGroup className="mb-0 mt-3">
              <CustomInput
                id="ungroup-select"
                type="select"
                required={true}
                name="ungroup-select"
                onChange={(event) => setSelectedWo(event.currentTarget.value)}
              >
                <option value={""}>Select a Work Order</option>
                {workOrderDetails.workOrder.groupedWorkOrders?.map((wo) => (
                  <option key={wo.id} value={wo.id}>
                    {wo.workOrderNumber}
                  </option>
                ))}
              </CustomInput>
            </FormGroup>
          ) : null}
          {action === ACTION_ADD ? (
            <FormGroup className="mb-0 mt-3">
              <SelectWrapper
                entity="work order"
                filterItemFunction={(wo) =>
                  workOrderDetails.workOrder.relatedWorkOrders.indexOf(
                    wo.id
                  ) === -1
                }
                formatItemFunction={(item) => ({
                  value: item.id,
                  label: item.workOrderNumber,
                })}
                fetchFunction={workOrdersApi.getWorkOrders}
                fetchParameters={{
                  pageSize: MAX_PAGE_SIZE,
                  type: TYPE_SINGLE,
                  jobSourceId: authContext.currentUser.jobSourceId,
                }}
                defaultSelected={selectedWo}
                onSelected={(wo) => setSelectedWo(wo.id)}
              />
            </FormGroup>
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button color={"primary"} type={"submit"}>
              Continue
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditGroupingModal;
