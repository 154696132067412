import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import { Badge, Button, Table } from "reactstrap";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../../providers/workOrderDetailsProvider";
import { utilsHelper } from "../../../helpers/utilsHelper";
import InformationModal from "../../InformationModal";
import WorkTimesEdit from "../WorkTimesEdit";
import { useAuth } from "../../../providers/authProvider";

const WT_TYPE_REGULAR = "regular";
const WT_TYPE_OVERTIME = "overtime";
const WT_TYPE_STANDBYTIME = "standBy";
const WT_TYPE_DOUBLETIME = "double";
const WT_TYPE_SAFETYTIME = "safety";
const WT_TYPE_HOLIDAYTIME = "holiday";
const STATUS_INVOICED = 6;

const getEmployeeTimes = (employeeId, workTimes) => {
  const employeeTimes = workTimes.filter(
    (item) => item.workOrder && item.employeeId === employeeId
  );
  return {
    mileage: employeeTimes.reduce((p, c) => p + parseFloat(c.mileage || 0), 0),
    regular: {
      hours: employeeTimes
        .filter((et) => et.type === WT_TYPE_REGULAR)
        .reduce((p, c) => p + parseFloat(c.hours), 0),
    },
    overtime: {
      hours: employeeTimes
        .filter((et) => et.type === WT_TYPE_OVERTIME)
        .reduce((p, c) => p + parseFloat(c.hours), 0),
    },
    standByTime: {
      hours: employeeTimes
        .filter((et) => et.type === WT_TYPE_STANDBYTIME)
        .reduce((p, c) => p + parseFloat(c.hours), 0),
    },
    doubleTime: {
      hours: employeeTimes
        .filter((et) => et.type === WT_TYPE_DOUBLETIME)
        .reduce((p, c) => p + parseFloat(c.hours), 0),
    },
    safetyTime: {
      hours: employeeTimes
        .filter((et) => et.type === WT_TYPE_SAFETYTIME)
        .reduce((p, c) => p + parseFloat(c.hours), 0),
    },
    holidayTime: {
      hours: employeeTimes
        .filter((et) => et.type === WT_TYPE_HOLIDAYTIME)
        .reduce((p, c) => p + parseFloat(c.hours), 0),
    },
  };
};

const getEmployeeTimesPerWo = (employeeId, workTimes) => {
  const employeeTimes = workTimes.filter(
    (item) => item.workOrder && item.employeeId === employeeId
  );

  const employeeTimesPerWo = employeeTimes.reduce((p, c) => {
    const key = c.workOrder.workOrderNumber || "Uncoded Time";
    p[key] = [...(p[key] || []), c];
    return p;
  }, {});

  Object.keys(employeeTimesPerWo).forEach((key) => {
    employeeTimesPerWo[key] = {
      notes: employeeTimesPerWo[key].length
        ? employeeTimesPerWo[key][0].note
          ? employeeTimesPerWo[key][0].note.trim()
          : null
        : null,
      mileage: employeeTimesPerWo[key].reduce(
        (p, c) => p + parseFloat(c.mileage || 0),
        0
      ),
      regular: {
        hours: employeeTimesPerWo[key]
          .filter((et) => et.type === WT_TYPE_REGULAR)
          .reduce((p, c) => p + parseFloat(c.hours), 0),
      },
      overtime: {
        hours: employeeTimesPerWo[key]
          .filter((et) => et.type === WT_TYPE_OVERTIME)
          .reduce((p, c) => p + parseFloat(c.hours), 0),
      },
      standByTime: {
        hours: employeeTimesPerWo[key]
          .filter((et) => et.type === WT_TYPE_STANDBYTIME)
          .reduce((p, c) => p + parseFloat(c.hours), 0),
      },
      doubleTime: {
        hours: employeeTimesPerWo[key]
          .filter((et) => et.type === WT_TYPE_DOUBLETIME)
          .reduce((p, c) => p + parseFloat(c.hours), 0),
      },
      safetyTime: {
        hours: employeeTimesPerWo[key]
          .filter((et) => et.type === WT_TYPE_SAFETYTIME)
          .reduce((p, c) => p + parseFloat(c.hours), 0),
      },
      holidayTime: {
        hours: employeeTimesPerWo[key]
          .filter((et) => et.type === WT_TYPE_HOLIDAYTIME)
          .reduce((p, c) => p + parseFloat(c.hours), 0),
      },
    };
  });
  return employeeTimesPerWo;
};

const EmployeeCrewRow = ({ employeeCrew, crewWorkDay }) => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const IS_READ_ONLY =
    workOrderDetails?.workOrder?.statusId === STATUS_INVOICED ||
    utilsHelper.isReadOnly(authContext);

  const [editWorkTimeModal, setEditWorkTimeModal] = useState({
    isOpen: false,
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const employeeTimes = getEmployeeTimes(
    employeeCrew.employee.id,
    crewWorkDay.workTimes
  );
  const employeeTimesPerWo = getEmployeeTimesPerWo(
    employeeCrew.employee.id,
    crewWorkDay.workTimes
  );

  const times = utilsHelper.getTimes(employeeTimes);
  const noTimeRecorded =
    !employeeTimes.regular.hours &&
    !employeeTimes.overtime.hours &&
    !employeeTimes.standByTime.hours &&
    !employeeTimes.safetyTime.hours &&
    !employeeTimes.doubleTime.hours &&
    !employeeTimes.holidayTime.hours &&
    !employeeTimes.mileage;

  const hasRestDay = employeeCrew.role.hasRestDay;
  const isRestDay =
    hasRestDay &&
    crewWorkDay.employeeRestDays.find(
      (employeeRestDay) =>
        employeeRestDay.employeeId === employeeCrew.employee.id
    );

  const leadEmployeeCrew = utilsHelper.getSupervisorEmployee(
    crewWorkDay.employees
  );

  const [hasNoTimeRecorded, setHasNoTimeRecorded] = useState(false);

  useEffect(() => {
    const hasNoTime =
      Object.keys(employeeTimesPerWo).length > 0 &&
      !isRestDay &&
      Object.values(employeeTimesPerWo).some((workOrder) => {
        const times = utilsHelper.getTimes(workOrder);
        return times.length === 0;
      });

    setHasNoTimeRecorded(hasNoTime);
  }, [employeeTimesPerWo, isRestDay]);

  return (
    <>
      <tr
        className={`${
          noTimeRecorded || isRestDay ? "text-muted" : "bg-lightblue"
        }`}
      >
        <td>
          <div className="d-flex flex-column align-items-start">
            {utilsHelper.getEmployeeLabelWithPreferredName(
              employeeCrew.employee
            )}{" "}
            {!employeeCrew.employee.isActive ? "(Inactive)" : ""}
            <div className="text-muted d-flex align-items-center">
              {employeeCrew.employee.id === leadEmployeeCrew?.employee.id ? (
                <Badge className="mr-1" color="primary">
                  Crew Lead
                </Badge>
              ) : null}
              <Badge className="mr-1">{employeeCrew.role.name}</Badge>
              {employeeCrew.isNotActiveButHasData ? (
                <Badge color="warning">Not in Crew</Badge>
              ) : null}
            </div>
          </div>
        </td>
        <td className="text-right">
          <div className="d-flex justify-content-end align-items-center">
            {isRestDay ? (
              <small className="text-muted text-uppercase font-weight-bold">
                {employeeCrew.role.name} Rest Day
              </small>
            ) : times.length || employeeTimes.mileage !== 0 ? (
              <span>
                {times.length ? times.join(" | ") : "0.0"} /{" "}
                {utilsHelper.formatMileage(employeeTimes.mileage, 1)}
              </span>
            ) : (
              <small className="text-muted">No Time Recorded</small>
            )}
            {(!IS_READ_ONLY && (times.length || employeeTimes.mileage !== 0)) ||
            (!IS_READ_ONLY && hasNoTimeRecorded) ? (
              <Button
                size="sm"
                className="rounded d-flex align-items-center ml-3"
                color={"warning"}
                onClick={() =>
                  setEditWorkTimeModal({
                    isOpen: true,
                  })
                }
                disabled={!employeeCrew.employee.isActive}
              >
                <FontAwesomeIcon size="sm" icon={faPen} className="mr-1" />
                <small>Edit</small>
              </Button>
            ) : null}
          </div>
        </td>
      </tr>
      {Object.keys(employeeTimesPerWo).length && !isRestDay
        ? Object.keys(employeeTimesPerWo).map((woNumber) => {
            const times = utilsHelper.getTimes(employeeTimesPerWo[woNumber]);
            return (
              <tr key={woNumber} className="small">
                <td
                  className={`px-4 ${
                    woNumber === "Uncoded Time" ? "text-warning" : ""
                  }`}
                >
                  {woNumber}
                </td>
                <td className="px-4 text-right">
                  <div className="d-flex flex-column align-items-end">
                    {times.length ? (
                      <span className="text-muted">
                        {times.join(" | ")} /{" "}
                        {utilsHelper.formatMileage(
                          employeeTimesPerWo[woNumber].mileage || 0,
                          1
                        )}
                      </span>
                    ) : (
                      <span className="text-muted">
                        0.0 /{" "}
                        {utilsHelper.formatMileage(
                          employeeTimesPerWo[woNumber].mileage || 0,
                          1
                        )}
                      </span>
                    )}
                    {employeeTimesPerWo[woNumber].notes ? (
                      <div
                        className="text-muted text-right px-2 py-1 rounded mt-1"
                        style={{ background: "#FDF1DB" }}
                      >
                        {employeeTimesPerWo[woNumber].notes}
                      </div>
                    ) : null}
                  </div>
                </td>
              </tr>
            );
          })
        : null}
      {editWorkTimeModal.isOpen ? (
        <WorkTimesEdit
          crewWorkDay={crewWorkDay}
          employeeCrew={employeeCrew}
          onClose={() => {
            setEditWorkTimeModal({
              isOpen: false,
            });
          }}
          onSubmit={() => {
            setEditWorkTimeModal({
              isOpen: false,
            });
            setInformationModal({
              isOpen: true,
              title: "Update Work Times",
              body: "Work times updated successfully",
              onClose: () =>
                setWorkOrderDetails({
                  action: ACTIONS.REFRESH,
                }),
            });
          }}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </>
  );
};

const CrewWorkDayWorkTimes = ({ crewWorkDay }) => {
  const isNotActiveButHasData = (crewWorkDay, e) =>
    !utilsHelper.isEmployeeCrewActiveOnDate(e, crewWorkDay.date, false) &&
    crewWorkDay.travelTimes.find((wt) => wt.employeeId === e.id);

  const isNotActiveOrHasData = (crewWorkDay, e) =>
    utilsHelper.isEmployeeCrewActiveOnDate(e, crewWorkDay.date, false) ||
    crewWorkDay.travelTimes.find((wt) => wt.employeeId === e.id);

  const allCrew = Object.values(
    crewWorkDay.employees
      .filter((e) => isNotActiveOrHasData(crewWorkDay, e))
      .reduce((p, c) => {
        c.isNotActiveButHasData = isNotActiveButHasData(crewWorkDay, c);
        if (!p[c.employee.id] || c.activeOnDate) {
          p[c.employee.id] = c;
        }
        return p;
      }, {})
  );

  return (
    <div key={crewWorkDay.id} className="d-flex">
      <Table className="col-12 px-0 mb-0 border">
        <thead>
          <tr className="bg-lighter">
            <th>
              <div className="d-flex flex-column">
                <span>CREW</span>
              </div>
            </th>
            <th className="text-right align-middle">
              WORK TIME / DAILY MILEAGE
            </th>
          </tr>
        </thead>
        <tbody>
          {allCrew.length ? (
            !crewWorkDay.workTimes?.length &&
            !crewWorkDay.employeeRestDays?.length ? (
              <tr>
                <td colSpan={4} className="p-2 small text-muted text-center">
                  No Work Time Recorded
                </td>
              </tr>
            ) : (
              utilsHelper.sortCrew(allCrew).map((employeeCrew, index) => {
                return (
                  <EmployeeCrewRow
                    key={employeeCrew.id}
                    employeeCrew={employeeCrew}
                    crewWorkDay={crewWorkDay}
                  />
                );
              })
            )
          ) : (
            <td colSpan={4} className="p-2 small text-muted text-center">
              Empty Crew
            </td>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default CrewWorkDayWorkTimes;
