import {
  faBan,
  faCheck,
  faEllipsisV,
  faInfoCircle,
  faPen,
  faPhotoVideo,
  faReceipt,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";

import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import moment from "moment";
import classNames from "classnames";

import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";

import ConfirmationModal from "../../components/ConfirmationModal";
import InformationModal from "../../components/InformationModal";

import { ACTIONS, useExpenses } from "../../providers/expensesProvider";
import { utilsHelper } from "../../helpers/utilsHelper";
import { expensesApi } from "../../services/expensesServices";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { employeesApi } from "../../services/employeeServices";
import UpdateWoExpense from "../../components/admin/UpdateWoExpense";
import { generalExpensesApi } from "../../services/generalExpensesServices";
import { workOrdersApi } from "../../services/workOrdersServices";
import { rolesApi } from "../../services/roleServices";
import ExpensePhotos from "../../components/admin/ExpensePhotosModal";
import { useAuth } from "../../providers/authProvider";
import DeclineExpenseModal from "../../components/admin/DeclineExpenseModal";
import WeekSelector from "../../components/admin/WeekSelector";
import PeriodTypeSelector from "../../components/admin/PeriodTypeSelector";
import CustomPeriodSelector from "../../components/admin/CustomPeriodSelector";
import SelectWrapper from "../../components/admin/SelectWrapper";
import CustomCheckbox from "../../components/CustomCheckbox";
import TooltipItem from "../../components/TooltipItem";

const MAX_PAGE_SIZE = 30;
const EMPLOYEE_DEFAULT_TYPE = 1;

const STATUS_PENDING = "pending";
const STATUS_APPROVED = "approved";
const STATUS_DECLINED = "declined";
const STATUS_PROCESSED_BY_ACCOUNTING = "processed_by_accounting";

const TYPE_REGULAR = "TYPE_REGULAR";
const TYPE_GENERAL = "TYPE_GENERAL";

const EMPLOYEE_ROLE = "EMPLOYEE_ROLE";
const USER_ROLE = "USER_ROLE";
const GENERAL_TYPE = "general";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const animatedComponents = makeAnimated();

const TYPE_WEEKLY = "TYPE_WEEKLY";

const Expenses = () => {
  const [authContext] = useAuth();
  const [expensesContext, setExpensesContext] = useExpenses();

  const [updateWoExpense, setUpdateWoExpense] = useState(false);
  const [declineExpenseModal, setDeclineExpenseModal] = useState(false);

  const [seePhotosModal, setSeePhotosModal] = useState(false);

  const [dropdownOpen, setDropdownOpen] = useState({});
  const toggleDropdown = (id) =>
    setDropdownOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }));

  const [loading, setLoading] = useState(true);

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [employee, setEmployee] = useState();
  const [selectedRole, setSelectedRole] = useState();

  const [workOrder, setWorkOrder] = useState();

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));

  const [selected, setSelected] = useState({});
  const [allChecked, setAllChecked] = useState();
  const [selectedStatus, setSelectedStatus] = useState({
    label: "Any Status",
    value: null,
  });
  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          expensesContext.sortBy === sortBy?.id &&
          expensesContext.direction === direction
        ) {
          return;
        }
        setExpensesContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setExpensesContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [expensesContext.direction, expensesContext.sortBy, setExpensesContext]
  );

  useEffect(() => {
    const checked = Object.keys(selected).filter((key) => selected[key]).length;
    const allChecked =
      checked && checked === expensesContext.expenses.data?.length;
    setAllChecked(allChecked);
  }, [expensesContext.expenses.data, selected]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const condition = {
          employeeId: employee?.id,
          workOrderId: workOrder?.id,
          search: expensesContext.search,
          status: selectedStatus.value,
          page: expensesContext.page - 1,
          pageSize: expensesContext.sizePerPage,
          sortBy: expensesContext.sortBy,
          direction: expensesContext.direction,
          type: expensesContext.type,
        };
        if (selectedRole) {
          const selectedRoleParts = selectedRole.match(
            /^(USER_ROLE|EMPLOYEE_ROLE)-(\d+)$/
          );
          if (selectedRoleParts[1] === EMPLOYEE_ROLE) {
            condition.createdByEmployeeRoleId = selectedRoleParts[2];
          } else {
            condition.createdByUserRoleId = selectedRoleParts[2];
          }
        }
        if (periodType.value === TYPE_WEEKLY) {
          condition.startDate = moment(monday).format("YYYY-MM-DD");
          condition.endDate = moment(monday)
            .endOf("isoWeek")
            .format("YYYY-MM-DD");
        } else {
          condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
          condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
        }
        const expenses = await expensesApi.getAllExpenses(condition);
        setExpensesContext({
          action: ACTIONS.GET_EXPENSES_SUCCESS,
          payload: {
            expenses,
          },
        });
        setSelected({});
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, [
    expensesContext.type,
    setExpensesContext,
    selectedStatus.value,
    expensesContext.sortBy,
    expensesContext.direction,
    expensesContext.sizePerPage,
    expensesContext.page,
    expensesContext.search,
    expensesContext.refresh,
    periodType,
    monday,
    employee,
    workOrder,
    selectedRole,
    customEndDate,
    customStartDate,
  ]);

  const onEdit = (expense) => setUpdateWoExpense(expense);

  const onDelete = (expense) =>
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => {
        setConfirmationModal(initConfirmationModal);
        submitDelete(expense);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Expense",
      body: `Are you sure you want to delete this expense?`,
    });

  const submitDelete = async (expense) => {
    try {
      await (expense.isGeneral
        ? generalExpensesApi.deleteGeneralExpense(expense.id)
        : expensesApi.deleteExpense(expense.id));
      setInformationModal({
        isOpen: true,
        title: `Expenses`,
        body: `Expense deleted successfully`,
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
          setExpensesContext({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: `Error`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const onDecline = (expense) => setDeclineExpenseModal(expense);

  const onAllChecked = () => {
    if (allChecked) {
      return setSelected({});
    }
    const selected = expensesContext.expenses.data.reduce((p, c) => {
      p[c.id] = { id: c.id, isGeneral: c.isGeneral, status: c.status };
      return p;
    }, {});
    setSelected(selected);
  };

  const badgeStatus = (status) => {
    const data = {
      label: status,
      hasTooltip: false,
      titleTooltip: null,
    };

    if (status === STATUS_PROCESSED_BY_ACCOUNTING) {
      data.label = "Pr. by Acc.";
      data.hasTooltip = true;
      data.titleTooltip = "Processed by Accounting";
    }

    return data;
  };

  const columns = [
    {
      accessor: "employee.firstName",
      Header: "Employee",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      width: 180,
      Cell: (rowData) => {
        const { employee } = rowData.row.original;
        return employee
          ? utilsHelper.getEmployeeReverseLabelWithPreferredName(employee)
          : "-";
      },
    },
    {
      accessor: "workOrder.workOrderNumber",
      Header: "Work Order #",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      width: 100,
      Cell: (rowData) => {
        const { workOrder } = rowData.row.original;
        return workOrder?.workOrderNumber || "-";
      },
    },
    {
      accessor: "date",
      Header: "Date",
      width: 100,
      Cell: (rowData) => {
        const { date } = rowData.row.original;
        return (
          <Badge color="success">
            {utilsHelper.formatDate(date, "MM/DD/YYYY")}
          </Badge>
        );
      },
    },
    {
      accessor: "type",
      Header: "Type",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate text-capitalize" },
      width: 80,
      Cell: (rowData) => {
        const { type } = rowData.row.original;
        return (
          <Badge className={type === GENERAL_TYPE ? "bg-info" : "bg-secondary"}>
            {type}
          </Badge>
        );
      },
    },
    {
      accessor: "expenseOption.name",
      Header: "Option",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      width: 120,
      Cell: (rowData) => {
        const { expenseOption } = rowData.row.original;
        return expenseOption?.name || "-";
      },
    },
    {
      accessor: "amount",
      Header: "Amount",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      width: 80,
      Cell: (rowData) => {
        const { amount, type } = rowData.row.original;
        return type === GENERAL_TYPE
          ? utilsHelper.formatCurrency(amount)
          : utilsHelper.formatMileage(amount);
      },
    },
    {
      accessor: "paymentType",
      Header: "Paym. Type",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate text-capitalize" },
      width: 100,
      Cell: (rowData) => {
        const { paymentType } = rowData.row.original;
        return (
          <Badge
            className={paymentType === "card" ? "bg-info" : "bg-secondary"}
          >
            {paymentType}
          </Badge>
        );
      },
    },
    {
      accessor: "status",
      Header: "Status",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate text-capitalize" },
      width: 112,
      Cell: (rowData) => {
        const { status } = rowData.row.original;
        const { label, hasTooltip, titleTooltip } = badgeStatus(status);

        return (
          <Badge
            style={{ width: "max-content" }}
            className={classNames({
              "bg-dark": status === STATUS_PENDING,
              "bg-danger": status === STATUS_DECLINED,
              "bg-success": status === STATUS_APPROVED,
              "bg-warning": status === STATUS_PROCESSED_BY_ACCOUNTING,
              "d-flex": status === STATUS_PROCESSED_BY_ACCOUNTING,
            })}
          >
            {label}

            {hasTooltip && (
              <TooltipItem
                position="top"
                className="ml-1"
                id="payroll-tooltip"
                title={titleTooltip}
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-1"
                  style={{ paddingBottom: "1px" }}
                />
              </TooltipItem>
            )}
          </Badge>
        );
      },
    },
    {
      accessor: "approvedAuthor.firstName",
      Header: "Approved By",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      width: 120,
      disableSortBy: true,
      Cell: (rowData) => {
        const { approvedAuthor } = rowData.row.original;
        return approvedAuthor
          ? `${approvedAuthor.firstName} ${approvedAuthor.lastName}`
          : "-";
      },
    },
    {
      accessor: "expenseAuthor",
      Header: "Created By",
      headerProps: { className: "text-truncate" },
      cellProps: { className: "text-truncate" },
      width: 120,
      disableSortBy: true,
      Cell: (rowData) => {
        const { expenseAuthor } = rowData.row.original;
        return expenseAuthor
          ? `${expenseAuthor.firstName} ${expenseAuthor.lastName}`
          : "-";
      },
    },
    {
      accessor: "id",
      Header: "Actions",
      disableSortBy: true,
      headerProps: { className: "text-right" },
      cellProps: { className: "text-right" },
      width: 80,
      Cell: (rowData) => (
        <Dropdown
          isOpen={dropdownOpen[rowData.row.original.id]}
          toggle={() => toggleDropdown(rowData.row.original.id)}
          className="d-flex justify-content-end"
        >
          <DropdownToggle className="ml-2 btn-sm rounded d-flex align-items-center">
            <small>More</small>
            <FontAwesomeIcon icon={faEllipsisV} className="ml-2" />
          </DropdownToggle>
          <DropdownMenu right>
            {!utilsHelper.isReadOnly(authContext) ? (
              <DropdownItem
                className="font-weight-normal d-flex align-items-center justify-content-start"
                onClick={() => onEdit(rowData.row.original)}
              >
                <FontAwesomeIcon icon={faPen} className="mr-3" />
                <span>Edit</span>
              </DropdownItem>
            ) : null}
            <DropdownItem
              className="font-weight-normal d-flex align-items-center justify-content-start"
              onClick={() => onSeePhotos(rowData.row.original)}
            >
              <FontAwesomeIcon icon={faPhotoVideo} className="mr-3" />
              <span>See Photo{!rowData.row.original.Expense ? "s" : ""}</span>
            </DropdownItem>
            {!utilsHelper.isReadOnly(authContext) ? (
              <>
                {rowData.row.original.status !== STATUS_APPROVED ? (
                  <DropdownItem
                    className="font-weight-normal d-flex align-items-center justify-content-start"
                    onClick={() =>
                      onUpdate(rowData.row.original, STATUS_APPROVED)
                    }
                  >
                    <FontAwesomeIcon icon={faCheck} className="mr-3" />
                    <span>Approve</span>
                  </DropdownItem>
                ) : null}
                {rowData.row.original.status !==
                STATUS_PROCESSED_BY_ACCOUNTING ? (
                  <DropdownItem
                    className="font-weight-normal d-flex align-items-center justify-content-start"
                    onClick={() =>
                      onUpdate(
                        rowData.row.original,
                        STATUS_PROCESSED_BY_ACCOUNTING
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faReceipt} className="mr-3" />
                    <span>Processed by Accounting</span>
                  </DropdownItem>
                ) : null}
                {rowData.row.original.status !== STATUS_DECLINED ? (
                  <DropdownItem
                    className="font-weight-normal d-flex align-items-center justify-content-start"
                    onClick={() => onDecline(rowData.row.original)}
                  >
                    <FontAwesomeIcon icon={faBan} className="mr-3" />
                    <span>Decline</span>
                  </DropdownItem>
                ) : null}
                <DropdownItem
                  className="font-weight-normal d-flex align-items-center justify-content-start"
                  onClick={() => onDelete(rowData.row.original)}
                >
                  <FontAwesomeIcon icon={faTrash} className="mr-3" />
                  <span>Delete</span>
                </DropdownItem>
              </>
            ) : null}
          </DropdownMenu>
        </Dropdown>
      ),
    },
  ];

  if (!utilsHelper.isReadOnly(authContext)) {
    columns.push({
      accessor: "selector",
      Header: expensesContext.expenses.data?.length ? (
        <div className="d-flex justify-content-end">
          <CustomCheckbox checked={allChecked} onClick={onAllChecked} />
        </div>
      ) : (
        ""
      ),
      disableSortBy: true,
      headerProps: { className: "text-right" },
      cellProps: { className: "text-right" },
      width: 80,
      Cell: (rowData) => (
        <div className="d-flex justify-content-end">
          <CustomCheckbox
            checked={selected[rowData.row.original.id]}
            onClick={() =>
              setSelected({
                ...selected,
                [rowData.row.original.id]: selected[rowData.row.original.id]
                  ? null
                  : {
                      id: rowData.row.original.id,
                      isGeneral: rowData.row.original.isGeneral,
                      status: rowData.row.original.status,
                    },
              })
            }
          />
        </div>
      ),
    });
  }

  const onSeePhotos = (expense) => {
    if (expense.expenseAttachments?.length > 0 || expense.attachmentUrl) {
      setSeePhotosModal(expense);
    } else {
      setInformationModal({
        isOpen: true,
        title: `Expenses Photos`,
        body: `There are no photos to show`,
      });
    }
  };

  const onUpdateExpenses = async ({
    generalExpenses = [],
    expenses = [],
    status = STATUS_APPROVED,
  }) => {
    const isApproved = status === STATUS_APPROVED;
    const now = moment().toISOString();

    const defaultPayload = {
      [isApproved ? "approvedAt" : "accountingProcessedAt"]: now,
    };

    const generalExpensesByStatusApi = isApproved
      ? generalExpensesApi.approveGeneralExpenseBulk
      : generalExpensesApi.processedAccountingBulk;

    const expensesByStatusApi = isApproved
      ? expensesApi.approveExpenseBulk
      : expensesApi.processedAccountingBulk;

    const updateExpenses = async (expenses, apiFunc) => {
      if (expenses.length) {
        await apiFunc({ items: expenses, ...defaultPayload });
      }
    };

    const titleModal = isApproved
      ? "Approve Expense(s)"
      : "Expense(s) Processed by Accounting";

    const bodySuccess = isApproved
      ? "Expense(s) Approved successfully."
      : "Expense(s) successfully processed by Accounting.";

    const bodyQuestion = isApproved
      ? "Are you sure you want to approve the selected expense(s)?"
      : "Are you sure you want to mark this expense(s) as processed by accounting?";

    setConfirmationModal({
      isOpen: true,
      confirmColor: "success",
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setLoading(true);
        await updateExpenses(generalExpenses, generalExpensesByStatusApi);
        await updateExpenses(expenses, expensesByStatusApi);
        setLoading(false);
        try {
          setInformationModal({
            isOpen: true,
            title: titleModal,
            body: bodySuccess,
            onClose: () => {
              setInformationModal({ isOpen: false, title: "", body: "" });
              setExpensesContext({
                action: ACTIONS.REFRESH,
              });
            },
          });
        } catch (err) {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: `Error`,
            body:
              err?.response?.data[0]?.msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: titleModal,
      body: bodyQuestion,
    });
  };

  const onUpdateSelected = async (status = STATUS_APPROVED) => {
    const checked = Object.values(selected).filter(Boolean);
    const disabled = !checked.length;

    const isApproved = status === STATUS_APPROVED;

    const titleModal = isApproved
      ? "Approve Selected Expenses"
      : "Processed by Accounting Selected";

    if (disabled) {
      return setInformationModal({
        isOpen: true,
        title: titleModal,
        body: "Select at least one expense to use this feature",
      });
    }

    const items = checked.filter((item) => item.status === status);

    const bodyModal = isApproved
      ? "At least one of the selected expenses are already approved"
      : "At least one of the selected expenses are already processed by accounting";

    if (items.length) {
      return setInformationModal({
        isOpen: true,
        title: titleModal,
        body: bodyModal,
      });
    }

    const isProcessedByAccounting = status === STATUS_PROCESSED_BY_ACCOUNTING;
    const diffApproveStatus = checked.filter(
      (item) => item.status !== STATUS_APPROVED
    );

    if (diffApproveStatus.length && isProcessedByAccounting) {
      return setInformationModal({
        isOpen: true,
        title: titleModal,
        body: "At least one of the selected expenses are not approved",
      });
    }

    const generalExpenses = checked
      .filter((item) => item.isGeneral)
      .map((item) => item.id);

    const expenses = checked
      .filter((item) => !item.isGeneral)
      .map((item) => item.id);

    await onUpdateExpenses({ generalExpenses, expenses, status });
  };

  const onUpdate = async (expense, status = STATUS_APPROVED) => {
    const key = expense.isGeneral ? "generalExpenses" : "expenses";

    const data = {
      [key]: [expense.id],
      status,
    };

    await onUpdateExpenses(data);
  };

  const fetchCreatedByRoles = async (parameters) => {
    const employeeRoles = await rolesApi.employeeRolesCreatedExpenses(
      parameters
    );
    const userRoles = await rolesApi.userRolesCreatedExpenses(parameters);
    const result = [
      ...employeeRoles.map((item) => ({
        ...item,
        id: `${EMPLOYEE_ROLE}-${item.id}`,
      })),
      ...userRoles.map((item) => ({
        ...item,
        id: `${USER_ROLE}-${item.id}`,
      })),
    ];
    return result;
  };

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <div className="w-100 h-100 d-flex flex-column">
        <AdvanceTableWrapper
          columns={columns}
          data={expensesContext.expenses?.data || []}
          pageSize={expensesContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: expensesContext.sortBy,
            direction: expensesContext.direction,
          }}
        >
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-between mt-2">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Expenses</h3>
                <small className="text-muted ml-2 pt-1">
                  ({expensesContext.expenses?.count || 0})
                </small>
              </div>
              <div className="d-flex flex-column">
                <div className="ml-3 d-none" style={{ width: 180 }}>
                  <Select
                    styles={style}
                    className="flex-grow-1 border rounded small"
                    options={[
                      {
                        label: "Any Type",
                        value: null,
                      },
                      {
                        label: "Regular Expenses",
                        value: TYPE_REGULAR,
                      },
                      {
                        label: "General Expenses",
                        value: TYPE_GENERAL,
                      },
                    ]}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    value={
                      expensesContext.type === TYPE_GENERAL
                        ? {
                            label: "General Expenses",
                            value: TYPE_GENERAL,
                          }
                        : expensesContext.type === TYPE_REGULAR
                        ? {
                            label: "Regular Expenses",
                            value: TYPE_REGULAR,
                          }
                        : {
                            label: "Any Type",
                            value: null,
                          }
                    }
                    onChange={(selected) => {
                      setExpensesContext({
                        action: ACTIONS.TYPE_CHANGE,
                        payload: { type: selected.value },
                      });
                    }}
                  />
                </div>
                <div className="d-flex align-items-center justify-content-end flex-wrap">
                  <div className="d-flex ml-3">
                    <Select
                      styles={style}
                      className="min-width-150 flex-grow-1 border rounded small"
                      options={[
                        {
                          label: "Any Status",
                          value: null,
                        },
                        {
                          label: "Pending",
                          value: STATUS_PENDING,
                        },
                        {
                          label: "Approved",
                          value: STATUS_APPROVED,
                        },
                        {
                          label: "Declined",
                          value: STATUS_DECLINED,
                        },
                        {
                          label: "Processed by Accounting",
                          value: STATUS_PROCESSED_BY_ACCOUNTING,
                        },
                      ]}
                      closeMenuOnSelect={true}
                      components={animatedComponents}
                      value={selectedStatus}
                      onChange={setSelectedStatus}
                    />
                  </div>
                  <PeriodTypeSelector
                    periodType={periodType}
                    setPeriodType={setPeriodType}
                  />
                  {periodType.value === TYPE_WEEKLY ? (
                    <WeekSelector
                      loading={loading}
                      monday={monday}
                      setMonday={(monday) => {
                        setMonday(monday);
                      }}
                    />
                  ) : (
                    <CustomPeriodSelector
                      defaultEndDate={customEndDate}
                      defaultStartDate={customStartDate}
                      onSubmit={(startDate, endDate) => {
                        setCustomStartDate(startDate);
                        setCustomEndDate(endDate);
                      }}
                    />
                  )}
                  {Object.keys(selected).filter((key) => selected[key]).length >
                  1 ? (
                    <div
                      className="d-flex flex-column"
                      style={{
                        gap: "5px",
                      }}
                    >
                      <Button
                        size="sm"
                        className="rounded ml-3"
                        color="warning"
                        onClick={() => onUpdateSelected(STATUS_APPROVED)}
                      >
                        Approve Selected
                      </Button>
                      <Button
                        size="sm"
                        className="rounded ml-3"
                        color="warning"
                        onClick={() =>
                          onUpdateSelected(STATUS_PROCESSED_BY_ACCOUNTING)
                        }
                      >
                        Processed by Accounting
                      </Button>
                    </div>
                  ) : null}
                  <Button
                    size="sm"
                    className="ml-3 rounded-circle d-flex custom-rounded-button"
                    color="primary"
                    onClick={() => {
                      setExpensesContext({
                        action: ACTIONS.REFRESH,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faSync} />
                  </Button>
                </div>
                <div className="d-flex align-items-center justify-content-end flex-wrap mt-2">
                  <div className="ml-3" style={{ minWidth: 180 }}>
                    <SelectWrapper
                      entity="role"
                      key={`${moment(monday).format("YYYY-MM-DD")}-${moment(
                        customStartDate
                      ).format("YYYY-MM-DD")}-${periodType.value}`}
                      formatItemFunction={(item) => ({
                        value: item.id,
                        label: item.name,
                      })}
                      fetchFunction={fetchCreatedByRoles}
                      fetchParameters={{
                        pageSize: MAX_PAGE_SIZE,
                        ...(periodType.value === TYPE_WEEKLY
                          ? {
                              startDate: moment(monday).format("YYYY-MM-DD"),
                              endDate: moment(monday)
                                .endOf("isoWeek")
                                .format("YYYY-MM-DD"),
                            }
                          : {
                              startDate:
                                moment(customStartDate).format("YYYY-MM-DD"),
                              endDate:
                                moment(customEndDate).format("YYYY-MM-DD"),
                            }),
                      }}
                      onSelected={(selected) => setSelectedRole(selected?.id)}
                    />
                  </div>
                  <div className="ml-3" style={{ minWidth: 180 }}>
                    <SelectWrapper
                      entity="work order"
                      formatItemFunction={(workOrder) => ({
                        label:
                          utilsHelper.getWorkOrderNumberWithGrouped(workOrder),
                        value: workOrder.id,
                      })}
                      fetchParameters={{
                        pageSize: MAX_PAGE_SIZE,
                        jobSourceId: authContext.currentUser.jobSourceId,
                      }}
                      fetchFunction={workOrdersApi.getWorkOrders}
                      defaultSelected={workOrder}
                      onSelected={(selected) => {
                        setWorkOrder(selected);
                      }}
                    />
                  </div>
                  <div className="ml-3" style={{ minWidth: 180 }}>
                    <SelectWrapper
                      entity="employee"
                      formatItemFunction={
                        utilsHelper.getEmployeeOptionWithPreferredName
                      }
                      fetchParameters={{
                        type: EMPLOYEE_DEFAULT_TYPE,
                        pageSize: MAX_PAGE_SIZE,
                        isActive: "any",
                        jobSourceId: authContext.currentUser.jobSourceId,
                      }}
                      fetchFunction={employeesApi.getEmployees}
                      defaultSelected={employee}
                      onSelected={(selected) => {
                        setEmployee(selected);
                      }}
                    />
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardBody className="overflow-x-auto">
              <AdvanceTable
                table
                isLoading={loading}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={expensesContext.expenses?.count || 0}
                pageCount={expensesContext.expenses.totalPages}
                currentPage={expensesContext.page - 1}
                onPageChange={(page) =>
                  setExpensesContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={expensesContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setExpensesContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : updateWoExpense ? (
        <UpdateWoExpense
          expense={updateWoExpense}
          onSubmit={() => {
            setUpdateWoExpense(false);
            setInformationModal({
              isOpen: true,
              title: "Update Expense",
              body: "Expense updated successfully.",
              onClose: () => {
                setInformationModal({ isOpen: false, title: "", body: "" });
                setExpensesContext({
                  action: ACTIONS.REFRESH,
                });
              },
            });
          }}
          onClose={() => setUpdateWoExpense(false)}
        />
      ) : seePhotosModal ? (
        <ExpensePhotos
          expense={seePhotosModal}
          onClose={() => setSeePhotosModal(false)}
          onDeleteImageSuccess={() => {
            setSeePhotosModal(false);
            setExpensesContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : declineExpenseModal ? (
        <DeclineExpenseModal
          expense={declineExpenseModal}
          onClose={() => setDeclineExpenseModal(false)}
          onSubmit={() => {
            setDeclineExpenseModal(false);
            setExpensesContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : null}
    </Container>
  );
};

export default Expenses;
