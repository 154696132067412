import moment from "moment";
import React from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Badge,
} from "reactstrap";
import { utilsHelper } from "../../helpers/utilsHelper";

const COLORS = {
  Pending: "primary",
  "In Review": "secondary",
  "In Progress": "info",
  Rejected: "success",
  Approved: "warning",
  Invoiced: "danger",
};

const ChangeOrderPreviewModal = ({ changeOrder, onClose }) => {
  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <ModalHeader close={closeBtn}>Change Order Review</ModalHeader>
      <ModalBody className="p-0 text-center">
        <ListGroup className="m-3">
          <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
            <span className="font-weight-bold">Status</span>
            <span className="ml-2">
              <Badge color={COLORS[changeOrder.statusId]}>
                {changeOrder.statusId}
              </Badge>
            </span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
            <span className="font-weight-bold">Submitted by</span>
            <span className="ml-2">
              {changeOrder.author
                ? `${changeOrder.author.firstName} ${changeOrder.author.lastName}`
                : "N/A"}
            </span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
            <span className="font-weight-bold">Submitted at</span>
            <span className="ml-2">
              {moment(changeOrder.createdAt).format("MM/DD/YY hh:mm a")}
            </span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
            <span className="font-weight-bold">Work Description</span>
            <span className="ml-2">{changeOrder.description}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
            <span className="font-weight-bold">Total</span>
            <span className="ml-2">
              {utilsHelper.formatCurrency(changeOrder.total)}
            </span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
            <span className="font-weight-bold">Requested by</span>
            <span className="ml-2">{changeOrder.requestedBy}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
            <span className="font-weight-bold">Purchasing Contact Name</span>
            <span className="ml-2">{changeOrder.purchasingContactName}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
            <span className="font-weight-bold">Purchasing Contact Phone</span>
            <span className="ml-2">{changeOrder.purchasingContactPhone}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
            <span className="font-weight-bold">Purchasing Contact Email</span>
            <span className="ml-2">{changeOrder.purchasingContactEmail}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
            <span className="font-weight-bold">Customer PO#</span>
            <span className="ml-2">{changeOrder.customerPO}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
            <span className="font-weight-bold">Description of Change</span>
            <span className="ml-2">{changeOrder.newDescription}</span>
          </ListGroupItem>
          <ListGroupItem className="d-flex justify-content-between align-items-center py-2">
            <span className="font-weight-bold">Job Type</span>
            <span className="ml-2">{changeOrder.changeOrderType}</span>
          </ListGroupItem>
        </ListGroup>
      </ModalBody>
      <ModalFooter className="justify-content-start">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChangeOrderPreviewModal;
