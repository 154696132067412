import React, { useEffect, useState } from "react";
import { faSearchPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Button, Badge } from "reactstrap";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import CommunicationLogModal from "../../components/admin/workOrderDetails/CommunicationLogModal";
import {
  ACTIONS,
  useCommunicationLog,
} from "../../providers/communicationLogProvider";
import { communicationLogApi } from "../../services/communicationLogServices";
import moment from "moment";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";

const columns = (onShowDetails) => [
  {
    accessor: "type",
    Header: "Type",
    headerProps: {
      className: "text-truncate text-muted small font-weight-bold",
    },
    Cell: (rowData) =>
      rowData.row.original.type.charAt(0).toUpperCase() +
      rowData.row.original.type.slice(1),
  },
  {
    accessor: "user",
    Header: "Sender",
    headerProps: {
      className: "text-truncate text-muted small font-weight-bold",
    },
    Cell: (rowData) =>
      rowData.row.original.firstName + " " + rowData.row.original.lastName,
  },
  {
    accessor: "createdAt",
    Header: "Date",
    headerProps: {
      className: "text-truncate text-muted small font-weight-bold",
    },
    Cell: (rowData) => (
      <Badge color="primary">
        {moment(rowData.row.original.createdAt).format("MM/DD/YY")}
      </Badge>
    ),
  },
  {
    accessor: "recipients",
    Header: "Recipients",
    headerProps: {
      className: "text-truncate text-muted small font-weight-bold",
    },
    Cell: (rowData) =>
      rowData.row.original.recipients.split(",").map((data, i) => (
        <span key={i} className="badge badge badge-secondary">
          {data}
        </span>
      )),
  },
  {
    accessor: "id",
    Header: "Actions",
    headerProps: {
      className: "text-truncate text-muted small font-weight-bold",
    },
    Cell: (rowData) => (
      <div className="d-flex justify-content-end align-items-center">
        <Button
          size="sm"
          className="rounded d-flex align-items-center"
          color="warning"
          onClick={() => onShowDetails(rowData.row.original)}
        >
          <FontAwesomeIcon icon={faSearchPlus} className="mr-2" />
          <span>Show</span>
        </Button>
      </div>
    ),
  },
];

const WorkOrderCommunicationLog = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [communicationLogContext, setCommunicationLogContext] =
    useCommunicationLog();

  const [loading, setLoading] = useState(true);

  const workOrderId = workOrderDetails.workOrder.id;

  const [logDetails, setLogDetails] = useState();
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const onShowDetails = (log) => {
    setLogDetails(log);
    setShowDetailsModal(true);
  };

  useEffect(() => {
    setLoading(true);
    communicationLogApi
      .getLogs({
        workOrderId: workOrderId,
        page: communicationLogContext.page - 1,
        pageSize: communicationLogContext.sizePerPage,
      })
      .then((logs) => {
        setCommunicationLogContext({
          action: ACTIONS.GET_LOGS_SUCCESS,
          payload: { logs },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    setCommunicationLogContext,
    communicationLogContext.sizePerPage,
    communicationLogContext.page,
    workOrderId,
  ]);

  return (
    <Row>
      <Col sm="12">
        <h4 className="pb-2 mb-0">Communication Log</h4>
        <div className="w-100">
          <AdvanceTableWrapper
            columns={columns(onShowDetails)}
            data={communicationLogContext.logs?.data || []}
            pageSize={communicationLogContext.sizePerPage}
          >
            <AdvanceTable
              table
              isLoading={loading}
              headerClassName="text-muted small font-weight-bold"
              tableProps={{
                striped: true,
                className: "mb-0",
              }}
            />
            <AdvanceTablePagination
              className="mt-2"
              totalCount={communicationLogContext.logs?.count || 0}
              pageCount={communicationLogContext.logs.totalPages}
              currentPage={communicationLogContext.page - 1}
              onPageChange={(page) =>
                setCommunicationLogContext({
                  action: ACTIONS.PAGE_CHANGE,
                  payload: { page },
                })
              }
              pageSize={communicationLogContext.sizePerPage}
              onPageSizeChange={(sizePerPage) =>
                setCommunicationLogContext({
                  action: ACTIONS.PAGE_SIZE_CHANGE,
                  payload: { sizePerPage },
                })
              }
            />
          </AdvanceTableWrapper>
        </div>
      </Col>
      {showDetailsModal && (
        <CommunicationLogModal
          log={logDetails}
          onClose={() => {
            setLogDetails(null);
            setShowDetailsModal(false);
          }}
        />
      )}
    </Row>
  );
};

export default WorkOrderCommunicationLog;
