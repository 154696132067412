import React, { useState } from "react";
import { useNavigate } from "react-router";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CustomInput,
  FormGroup,
  Form,
  ListGroup,
  ListGroupItem,
  Label,
} from "reactstrap";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import { workOrdersApi } from "../../services/workOrdersServices";
import InformationModal from "../InformationModal";
import Loader from "../Loader";
import SelectWrapper from "./SelectWrapper";
import { useAuth } from "../../providers/authProvider";

const MAX_PAGE_SIZE = 30;
const TYPE_SINGLE = "single";

const GroupWosModal = ({ onClose }) => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();
  const [relatedWorkOrders, setRelatedWorkOrders] = useState(
    workOrderDetails.workOrder.relatedWorkOrders
  );

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selectedWos, setSelectedWos] = useState([
    workOrderDetails.workOrder.id,
  ]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const groupedWo = await workOrdersApi.groupWorkOrders({
        workOrderIds: selectedWos,
      });
      setLoading(false);
      onClose();
      navigate(`/work-orders/details/${groupedWo.id}`);
    } catch (err) {
      setLoading(false);
      return setInformationModal({
        isOpen: true,
        title: "Remove Work Order from Group",
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Group Work Orders</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          <ListGroup>
            <ListGroupItem
              className="d-flex justify-content-between align-items-center py-2 bg-lighter"
              tag="div"
            >
              Select at least two Work Orders to group
            </ListGroupItem>
            {relatedWorkOrders?.map((related) => (
              <ListGroupItem
                key={related.id}
                className="d-flex justify-content-between align-items-center py-2"
                tag="div"
              >
                <FormGroup row className="mb-0">
                  <CustomInput
                    disabled={related.id === workOrderDetails.workOrder.id}
                    id={`${related.id}-checkbox`}
                    type="checkbox"
                    name={`${related.id}-checkbox`}
                    onChange={(event) => {
                      const newSelectedWos = [...selectedWos];
                      if (event.currentTarget.checked) {
                        newSelectedWos.push(related.id);
                      } else {
                        newSelectedWos.splice(
                          selectedWos.indexOf(related.id),
                          1
                        );
                      }
                      setSelectedWos(newSelectedWos);
                    }}
                    checked={selectedWos.indexOf(related.id) > -1}
                  >
                    {related.workOrderNumber}
                  </CustomInput>
                </FormGroup>
              </ListGroupItem>
            ))}
            <ListGroupItem
              className="d-flex justify-content-between align-items-center p-2"
              tag="div"
            >
              <Label className="mb-0 mr-2">Other WO</Label>
              <SelectWrapper
                entity="work order"
                filterItemFunction={(wo) => selectedWos.indexOf(wo.id) === -1}
                formatItemFunction={(item) => ({
                  value: item.id,
                  label: item.workOrderNumber,
                })}
                fetchFunction={workOrdersApi.getWorkOrders}
                fetchParameters={{
                  pageSize: MAX_PAGE_SIZE,
                  type: TYPE_SINGLE,
                  jobSourceId: authContext.currentUser.jobSourceId,
                }}
                onSelected={(wo) => {
                  setRelatedWorkOrders([...relatedWorkOrders, wo]);
                  setSelectedWos([...selectedWos, wo.id]);
                }}
              />
            </ListGroupItem>
          </ListGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          {loading ? (
            <div className="min-width-50">
              <Loader size="sm" />
            </div>
          ) : (
            <Button
              disabled={selectedWos.length < 2}
              color={"primary"}
              type={"submit"}
            >
              Continue
            </Button>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default GroupWosModal;
