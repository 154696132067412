const COLORS = ["primary", "secondary", "info", "success", "warning", "danger"];

const STATUS_CREATED = 1;
const STATUS_IN_REVIEW = 2;
const STATUS_COMPLETED = 3;
const STATUS_REJECTED = 4;
const STATUS_CUSTOMER_APPROVED = 5;
const STATUS_INVOICED = 6;

const STATUS_LABEL = {
  [STATUS_CREATED]: "Pending",
  [STATUS_IN_REVIEW]: "In Review",
  [STATUS_COMPLETED]: "In Progress",
  [STATUS_REJECTED]: "Rejected",
  [STATUS_CUSTOMER_APPROVED]: "Approved",
  [STATUS_INVOICED]: "Invoiced",
};

const CHANGE_ORDER_TYPE = {
  TIME_MATERIAL: "timematerial",
  FIRM_BID: "firmbid",
};

export const changeOrderHelper = {
  COLORS,
  STATUS_CREATED,
  STATUS_IN_REVIEW,
  STATUS_COMPLETED,
  STATUS_REJECTED,
  STATUS_CUSTOMER_APPROVED,
  STATUS_INVOICED,
  STATUS_LABEL,
  CHANGE_ORDER_TYPE,
};
