import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  CustomInput,
  InputGroup,
  Form,
} from "reactstrap";

const RoleSelectorModal = ({ onSubmit, onClose, availableRoles }) => {
  const [selectedRole, setSelectedRole] = useState();

  const doSubmit = (event) => {
    event.preventDefault();
    onSubmit(parseInt(selectedRole));
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Add Employee to Crew</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          <p>Select the Role that the Employee will have inside the Crew</p>
          <InputGroup className="col-12 mb-0" size="m">
            <CustomInput
              required
              id="roleSelect"
              type="select"
              name="roleSelect"
              onChange={(event) => setSelectedRole(event.currentTarget.value)}
              value={selectedRole}
            >
              <option value={""}>Select a Role</option>
              {availableRoles.map((role) => (
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </CustomInput>
          </InputGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          <Button color={"primary"} type={"submit"}>
            Confirm
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default RoleSelectorModal;
