import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import AdminLayout from "../layouts/Admin";
import AuthLayout from "../layouts/Auth";

import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

import { authRoutes, adminRoutes } from ".";

import WorkOrderJobScopeRaw from "../pages/WorkOrderJobScopeRaw";
import Page401 from "../pages/auth/Page401";

const CustomRoutes = () => {
  return (
    <Router>
      <Routes>
        {authRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact
            element={
              <AuthLayout>
                <route.component />
              </AuthLayout>
            }
          />
        ))}
        {adminRoutes().flatMap((route, index) =>
          route.entries
            ? route.entries.map((entry, index) =>
                !entry.entries ? (
                  <Route
                    key={index}
                    path={entry.path}
                    element={
                      <AdminLayout {...entry}>
                        <entry.component />
                      </AdminLayout>
                    }
                  />
                ) : (
                  entry.entries.map((child, index) => (
                    <Route
                      key={index}
                      path={child.path}
                      element={
                        <AdminLayout {...child}>
                          <child.component />
                        </AdminLayout>
                      }
                    />
                  ))
                )
              )
            : [
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <AdminLayout {...route}>
                      <route.component />
                    </AdminLayout>
                  }
                />,
              ]
        )}
        <Route path={"/"} element={<Navigate to="/work-orders/all" />} />
        <Route
          path={"/error/500"}
          element={
            <AuthLayout>
              <Page500 />
            </AuthLayout>
          }
        />
        <Route
          path={"/error/404"}
          element={
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          }
        />
        <Route
          path={"/error/401"}
          element={
            <AuthLayout>
              <Page401 />
            </AuthLayout>
          }
        />
        <Route
          path={"/work-orders/details/:workOrderId/scope/raw/:token"}
          element={<WorkOrderJobScopeRaw />}
        />
        <Route
          path={"*"}
          element={
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          }
        />
      </Routes>
    </Router>
  );
};

export default CustomRoutes;
