import React from "react";
import { Badge, ListGroup, ListGroupItem } from "reactstrap";
import { utilsHelper } from "../../../../helpers/utilsHelper";

const INSPECTION_STATUS = {
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
};

const CustomerSignOff = ({ inspection }) => {
  if (inspection.status !== INSPECTION_STATUS.COMPLETED) {
    return null;
  }

  const { signatureUrl, signatureName, updatedAt, customerNotPresentReason } =
    inspection;

  return (
    <ListGroup className="mt-2 col-12 px-0">
      <ListGroupItem
        className="d-flex justify-content-between align-items-center py-2 font-weight-bold bg-lightgreen"
        tag="div"
      >
        Customer Signature
      </ListGroupItem>
      {signatureUrl ? (
        <ListGroupItem className="d-flex justify-content-between align-items-start">
          <img
            alt={signatureName || "Signature"}
            className="my-3 col-12 col-md-6 col-lg-4 col-xl-3"
            src={signatureUrl}
          />
          <Badge color="info">
            {utilsHelper.formatDateTime(updatedAt, "MM/DD/YYYY")}
          </Badge>
        </ListGroupItem>
      ) : (
        <ListGroupItem className="d-flex justify-content-between align-items-start">
          <Badge color="danger">Signature not provided</Badge>
          <span>Reason: {customerNotPresentReason || "Not provided"}</span>
        </ListGroupItem>
      )}
    </ListGroup>
  );
};

export default CustomerSignOff;
