import DocViewer, {
  JPGRenderer,
  PNGRenderer,
  TIFFRenderer,
  MSDocRenderer,
} from "react-doc-viewer";
import { FilePreviewStrategy } from "./FilePreviewStrategy";

const DocumentPreview = ({ url }) => {
  const isExcelWord = url.endsWith(".xlsx") || url.endsWith(".docx");

  return (
    <DocViewer
      style={isExcelWord ? { minHeight: "600px" } : {}}
      pluginRenderers={[PNGRenderer, TIFFRenderer, JPGRenderer, MSDocRenderer]}
      documents={[{ uri: url }]}
      config={{
        header: {
          disableHeader: true,
          disableFileName: false,
          retainURLParams: false,
        },
      }}
    />
  );
};

class DocumentPreviewStrategy extends FilePreviewStrategy {
  renderPreview(url) {
    return <DocumentPreview url={url} />;
  }
}

export default DocumentPreviewStrategy;
