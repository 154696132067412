import React, { useState } from "react";

import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import moment from "moment";

import { utilsHelper } from "../../../helpers/utilsHelper";
import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";
import DateRangePicker from "../../DateRangePicker";

const EditDatesModal = ({ onSubmit, onClose }) => {
  const [workOrderDetails] = useWorkOrderDetails();

  const { workOrder } = workOrderDetails;

  const [startDate, setStartDate] = useState(moment(workOrder.startDate));
  const [endDate, setEndDate] = useState(moment(workOrder.endDate));

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onSelection = (range) => {
    setStartDate(range.from);
    setEndDate(range.to);
  };

  const doSubmit = (evt) => {
    evt.preventDefault();
    onSubmit({
      startDate: utilsHelper.formatDate(startDate),
      endDate: utilsHelper.formatDate(endDate),
    });
  };

  return (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Edit Work Order Dates</ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody>
          <DateRangePicker
            label="Selected Dates"
            startDate={startDate}
            endDate={endDate}
            onSelection={onSelection}
          />
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose}>
            Discard
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditDatesModal;
