import React, { useEffect, useState } from "react";

import {
  faCalendar,
  faPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { DayPicker } from "react-day-picker";

import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import { commonApi } from "../../services/commonServices";
import { expensesApi } from "../../services/expensesServices";
import { utilsHelper } from "../../helpers/utilsHelper";
import InformationModal from "../InformationModal";
import Loader from "../Loader";

const EXPENSE_TYPE_GENERAL = "general";
const EXPENSE_TYPE_MILEAGE = "mileage";
const PAYMENT_TYPE_CASH = "cash";
const PAYMENT_TYPE_CARD = "card";

const AddWoExpense = ({ onClose, onSubmit }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crew, setCrew] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateSelectorOpen, setDateSelectorOpen] = useState();
  const [loading, setLoading] = useState();

  const [expenseOptions, setExpenseOptions] = useState([]);

  const [employeeEnabled, setEmployeeEnabled] = useState({});

  const [paymentTypeToAdd, setPaymentTypeToAdd] = useState({});
  const [expenseTypeToAdd, setExpenseTypeToAdd] = useState({});
  const [descriptionToAdd, setDescriptionToAdd] = useState({});
  const [expenseOptionToAdd, setExpenseOptionToAdd] = useState({});
  const [amountToAdd, setAmountToAdd] = useState({});

  const [workOrderId, setWorkOrderId] = useState(workOrderDetails.workOrder.id);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const doSubmit = async (event) => {
    event.preventDefault();

    const expenses = Object.keys(amountToAdd).map((employeeId) => ({
      employeeId,
      crewId: crew.id,
      amount: amountToAdd[employeeId] || 0,
      description: descriptionToAdd[employeeId],
      date: moment(selectedDate).format("YYYY-MM-DD"),
      type: expenseTypeToAdd[employeeId],
      paymentType: paymentTypeToAdd[employeeId],
      expenseOptionId: expenseOptionToAdd[employeeId],
      workOrderId,
    }));

    if (!expenses.length) {
      return setInformationModal({
        isOpen: true,
        title: "Add Expenses",
        body: "Add expenses to at least one employee.",
      });
    }

    setLoading(true);

    try {
      await expensesApi.createExpense({ expenses });
      onSubmit();
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: "Add Expenses",
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }

    setLoading(false);
  };

  const onSelectedDate = (date) => {
    setSelectedDate(date);
    setDateSelectorOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const expenseOptions = await commonApi.getExpenseOptions();
        setExpenseOptions(expenseOptions);
      } catch (error) {
        console.error(`Error fetching expense options: ${error}`);
      }
    };
    fetchData();
  }, []);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        <div className="d-flex flex-column align-items-start">
          <span>
            Add Expense to {workOrderDetails.workOrder.workOrderNumber}
          </span>
        </div>
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup row className="d-flex align-items-center">
                <Label className="col-4 d-flex flex-column text-left mb-0">
                  <span>Date</span>
                  <small className="text-warning">
                    Click the date to{" "}
                    {dateSelectorOpen ? "select/close" : "change"}
                  </small>
                </Label>
                <div className="col-8 d-flex align-items-center justify-content-between">
                  <div className="flex-grow-1">
                    {dateSelectorOpen ? (
                      <DayPicker
                        className="date-picker bg-white rounded border d-flex justify-content-center"
                        mode="single"
                        defaultMonth={selectedDate}
                        selected={[selectedDate]}
                        modifiersClassNames={{
                          selected: "my-selected",
                          today: "my-today",
                          range_start: "my-range_start",
                          range_end: "my-range_end",
                        }}
                        onDayClick={onSelectedDate}
                      />
                    ) : (
                      <div
                        className="float-left d-flex align-items-center px-2 py-1 border rounded bg-white cursor-pointer"
                        onClick={() => setDateSelectorOpen(true)}
                      >
                        <FontAwesomeIcon
                          icon={faCalendar}
                          className="mr-2 text-secondary"
                        />
                        <span>{moment(selectedDate).format("MM/DD/YYYY")}</span>
                      </div>
                    )}
                  </div>
                </div>
              </FormGroup>
              <FormGroup row className="d-flex align-items-center">
                <Label sm={4} className="text-sm-left">
                  Crew
                </Label>
                <Col sm={8}>
                  <CustomInput
                    required={true}
                    id="crewSelect"
                    type="select"
                    name="crewSelect"
                    value={crew?.id || ""}
                    onChange={(event) => {
                      const crews =
                        workOrderDetails.workOrder.crewWorkOrders.map(
                          (cwo) => cwo.crew
                        );
                      const crew = crews.find(
                        (c) => c.id === event.currentTarget.value
                      );
                      setCrew(crew);
                    }}
                  >
                    <option value={""}>Select a Crew</option>
                    {workOrderDetails.workOrder.crewWorkOrders
                      .filter((crewWorkOrder) =>
                        crewWorkOrder.crew.employeeCrews
                          .filter((ec) =>
                            utilsHelper.isEmployeeCrewActiveOnDate(
                              ec,
                              selectedDate
                            )
                          )
                          .find((ec) => ec.isCrewLead)
                      )
                      .map((crewWorkOrder) => {
                        const sup = crewWorkOrder.crew.employeeCrews
                          .filter((ec) =>
                            utilsHelper.isEmployeeCrewActiveOnDate(
                              ec,
                              selectedDate
                            )
                          )
                          .find((ec) => ec.isCrewLead);
                        return (
                          <option
                            key={crewWorkOrder.crew.id}
                            value={crewWorkOrder.crew.id}
                          >
                            {utilsHelper.getEmployeeLabelWithPreferredName(
                              sup.employee
                            )}
                          </option>
                        );
                      })}
                  </CustomInput>
                </Col>
              </FormGroup>
            </>
          )}
          {workOrderDetails.workOrder.groupedWorkOrders?.length ? (
            <FormGroup row className="mb-0">
              <Label sm={4} className="text-sm-left">
                Work Order
              </Label>
              <Col sm={8}>
                <CustomInput
                  required={true}
                  id="workOrderSelect"
                  type="select"
                  name="workOrderSelect"
                  value={workOrderId || ""}
                  onChange={(event) =>
                    setWorkOrderId(event.currentTarget.value)
                  }
                >
                  <option value={""}>Select a Work Order</option>
                  {workOrderDetails.workOrder.groupedWorkOrders.map(
                    (workOrder) => {
                      return (
                        <option key={workOrder.id} value={workOrder.id}>
                          {workOrder.workOrderNumber}
                        </option>
                      );
                    }
                  )}
                </CustomInput>
              </Col>
            </FormGroup>
          ) : null}
          {crew && selectedDate && workOrderId ? (
            <ListGroup className="mt-3">
              <ListGroupItem
                className="d-flex justify-content-between align-items-center py-2 bg-lighter"
                tag="div"
              >
                <span>Crew Member</span>
                <span></span>
              </ListGroupItem>
              {crew.employeeCrews
                .filter((employeeCrew) =>
                  utilsHelper.isEmployeeCrewActiveOnDate(
                    employeeCrew,
                    selectedDate
                  )
                )
                .map((employeeCrew) => (
                  <ListGroupItem
                    key={employeeCrew.id}
                    className="d-flex justify-content-between align-items-center py-2"
                    tag="div"
                  >
                    <span>
                      {utilsHelper.getEmployeeLabelWithPreferredName(
                        employeeCrew.employee
                      )}
                    </span>
                    <div
                      style={
                        employeeEnabled[employeeCrew.employee.id]
                          ? { width: "60%" }
                          : null
                      }
                    >
                      {employeeEnabled[employeeCrew.employee.id] ? (
                        <div className="width-350 d-flex align-items-center">
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            style={{
                              position: "absolute",
                              top: 1,
                              right: 16,
                              zIndex: 1,
                            }}
                            className="cursor-pointer text-danger bg-white"
                            onClick={() =>
                              setEmployeeEnabled({
                                ...employeeEnabled,
                                [employeeCrew.employee.id]: false,
                              })
                            }
                          />
                          <ListGroup className="col-12 px-0">
                            <ListGroupItem
                              className="d-flex justify-content-center align-items-center py-2"
                              tag="div"
                            >
                              <CustomInput
                                required={true}
                                id="expenseTypeSelect"
                                type="select"
                                name="expenseTypeSelect"
                                value={
                                  expenseTypeToAdd[employeeCrew.employee.id] ||
                                  ""
                                }
                                onChange={(event) =>
                                  setExpenseTypeToAdd({
                                    ...expenseTypeToAdd,
                                    [employeeCrew.employee.id]:
                                      event.currentTarget.value,
                                  })
                                }
                              >
                                <option value={""}>
                                  Select the expense type...
                                </option>
                                <option value={EXPENSE_TYPE_GENERAL}>
                                  General
                                </option>
                                <option value={EXPENSE_TYPE_MILEAGE}>
                                  Mileage
                                </option>
                              </CustomInput>
                            </ListGroupItem>
                            {expenseTypeToAdd[employeeCrew.employee.id] ===
                            EXPENSE_TYPE_GENERAL ? (
                              <ListGroupItem
                                className="d-flex justify-content-center align-items-center py-2"
                                tag="div"
                              >
                                <CustomInput
                                  id="expenseOptionsSelect"
                                  type="select"
                                  name="expenseOptionsSelect"
                                  value={
                                    expenseOptionToAdd[
                                      employeeCrew.employee.id
                                    ] || ""
                                  }
                                  onChange={(event) =>
                                    setExpenseOptionToAdd({
                                      ...expenseOptionToAdd,
                                      [employeeCrew.employee.id]:
                                        event.currentTarget.value,
                                    })
                                  }
                                >
                                  <option value={""}>
                                    Select the expense option..
                                  </option>
                                  {expenseOptions.map((item) => (
                                    <option key={item.id} value={item.id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </CustomInput>
                              </ListGroupItem>
                            ) : null}
                            <ListGroupItem
                              className="d-flex justify-content-center align-items-center py-2"
                              tag="div"
                            >
                              <CustomInput
                                required={true}
                                id="paymentTypeSelect"
                                type="select"
                                name="paymentTypeSelect"
                                value={
                                  paymentTypeToAdd[employeeCrew.employee.id] ||
                                  ""
                                }
                                onChange={(event) =>
                                  setPaymentTypeToAdd({
                                    ...paymentTypeToAdd,
                                    [employeeCrew.employee.id]:
                                      event.currentTarget.value,
                                  })
                                }
                              >
                                <option value={""}>
                                  Select the payment type...
                                </option>
                                <option value={PAYMENT_TYPE_CASH}>Cash</option>
                                <option value={PAYMENT_TYPE_CARD}>Card</option>
                              </CustomInput>
                            </ListGroupItem>
                            <ListGroupItem
                              className="d-flex justify-content-center align-items-center py-2"
                              tag="div"
                            >
                              <Input
                                required={true}
                                min={0}
                                max={999999999}
                                placeholder="Enter the amount.."
                                type="number"
                                value={
                                  amountToAdd[employeeCrew.employee.id] || ""
                                }
                                onChange={(event) =>
                                  setAmountToAdd({
                                    ...amountToAdd,
                                    [employeeCrew.employee.id]: parseFloat(
                                      event.currentTarget.value
                                    ),
                                  })
                                }
                              />
                            </ListGroupItem>
                            <ListGroupItem
                              className="d-flex justify-content-center align-items-center py-2"
                              tag="div"
                            >
                              <Input
                                required={true}
                                rows={4}
                                placeholder="Enter the description ..."
                                type="textarea"
                                value={
                                  descriptionToAdd[employeeCrew.employee.id] ||
                                  ""
                                }
                                onChange={(event) =>
                                  setDescriptionToAdd({
                                    ...descriptionToAdd,
                                    [employeeCrew.employee.id]:
                                      event.currentTarget.value,
                                  })
                                }
                              />
                            </ListGroupItem>
                          </ListGroup>
                        </div>
                      ) : (
                        <Button
                          className="rounded"
                          size="sm"
                          color="warning"
                          onClick={() =>
                            setEmployeeEnabled({
                              ...employeeEnabled,
                              [employeeCrew.employee.id]: true,
                            })
                          }
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="mr-2 text-white"
                          />
                          Add Expense
                        </Button>
                      )}
                    </div>
                  </ListGroupItem>
                ))}
            </ListGroup>
          ) : null}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          <Button color={"primary"} type={"submit"}>
            Continue
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddWoExpense;
