import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { workOrdersApi } from "../../../services/workOrdersServices";

const TentativeModal = ({ isOpen, onClose }) => {
  const [customer, setCustomer] = useState();
  const [location, setLocation] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [appUsers, setAppUsers] = useState();
  const [crewmembers, setCrewmembers] = useState();
  const [atc, setAtc] = useState(false);
  const [quoteNumber, setQuoteNumber] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isPublic = true;
    const isActive = true;
    const isTentative = true;

    const request = {
      workOrder: {
        quoteNumber,
        isTentative,
        isActive,
        isPublic,
        atc,
      },
      workOrderSchedule: {
        dayShiftStartDate: startDate,
        dayShiftEndDate: endDate,
        nightShiftStartDate: startDate,
        nightShiftEndDate: endDate,
        isActive,
      },
      workOrderCustomer: {
        isActive,
        customerName: customer,
        contactName: customer,
        city: location,
      },
      appUsers,
      crewmembers,
    };

    await workOrdersApi.createTentativeWorkOrder(request);
    onClose();
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={isOpen}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader close={closeBtn}>Create Tentative Work Order</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="customer">Customer</Label>
            <Input
              type="text"
              id="customer"
              onChange={(e) => setCustomer(e.target.value)}
              placeholder="Enter customer..."
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="location">Location</Label>
            <Input
              type="text"
              id="location"
              onChange={(e) => setLocation(e.target.value)}
              placeholder="Enter location..."
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="startDate">Start Date</Label>
            <Input
              type="date"
              id="startDate"
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="endDate">End Date</Label>
            <Input
              type="date"
              id="endDate"
              min={startDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="appUsers">Number of App Users</Label>
            <Input
              type="number"
              id="appUsers"
              onChange={(e) => setAppUsers(e.target.value)}
              placeholder="Enter number of App Users..."
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="crewmembers">Number of Crew Members</Label>
            <Input
              type="number"
              id="crewmembers"
              onChange={(e) => setCrewmembers(e.target.value)}
              placeholder="Enter number of crew members..."
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="atc">Around the Clock</Label>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  id="atc"
                  onChange={(e) => setAtc(e.target.checked)}
                />{" "}
                Yes / No
              </Label>
            </FormGroup>
          </FormGroup>
          <FormGroup>
            <Label for="quoteNumber">Quote Number</Label>
            <Input
              type="text"
              id="quoteNumber"
              onChange={(e) => setQuoteNumber(e.target.value)}
              placeholder="Enter quote number..."
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <Button color={"primary"} type="submit">
                Save
              </Button>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default TentativeModal;
