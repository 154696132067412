import Moment from "moment";

import { extendMoment } from "moment-range";

import { DateHelper } from "@bryntum/gantt";
import { utilsHelper } from "./utilsHelper";

const moment = extendMoment(Moment);

const COLORS = ["secondary"];

const hasConflict = (workOrders, workOrder) => {
  let hasConflict = false;
  let conflictingWo = null;
  let conflictingRange = null;
  workOrders
    .filter((item) => item.id !== workOrder.id)
    .forEach((item) => {
      const range1 = moment.range(
        utilsHelper.formatDate(item.startDate),
        utilsHelper.formatDate(item.endDate)
      );
      const range2 = moment.range(
        utilsHelper.formatDate(workOrder.startDate),
        utilsHelper.formatDate(workOrder.endDate)
      );
      const intersects = range1.overlaps(range2, { adjacent: false });
      hasConflict = intersects;
      conflictingWo = item;
      conflictingRange = range1.intersect(range2);
    });
  return { hasConflict, conflictingWo, conflictingRange };
};

export const crewScheduleHelper = {
  ganttConfig: {
    tbar: { type: "gantttoolbar" },
    columns: [
      {
        type: "name",
        field: "name",
        text: "Crew Member",
      },
      {
        type: "note",
        field: "workOrderNumber",
        text: "Work Order #",
        align: "left",
      },
      {
        type: "note",
        field: "customerName",
        text: "Customer",
        align: "left",
      },
      {
        type: "date",
        field: "startDate",
        text: "Start Date",
        cls: "bg-lightgreen text-body",
      },
      {
        type: "date",
        field: "endDate",
        text: "End Date",
        cls: "bg-lightgreen text-body",
      },
    ],
    taskRenderer(data) {
      const { taskRecord, renderData } = data;
      const { originalData } = taskRecord;
      renderData.cls.add("small");
      if (Math.abs(moment(taskRecord.startDate).diff(taskRecord.endDate)) < 3) {
        return "";
      }
      if (taskRecord.children?.length) {
        renderData.cls.add("bg-primary");
        renderData.cls.add("expandable-task");
        return "+ Expand for details";
      } else {
        renderData.cls.add(`bg-${COLORS[originalData.index % COLORS.length]}`);
      }
      return `${taskRecord.customerName}`;
    },
    indicatorsFeature: {
      // Enabling and configuring the Indicators feature
      items: {
        // Early start/end dates indicator disabled
        earlyDates: false,
        lateDates: false,
        constraintDate: false,
        deadlineDate: false,
        // Custom indicator added
        conflict: (taskRecord) =>
          taskRecord.originalData.conflict?.hasConflict
            ? {
                startDate: DateHelper.add(taskRecord.endDate, 1, "days"),
                cls: "conflict",
                iconCls: "b-fa b-fa-calendar-times",
                name: "Schedule Conflict",
                tooltip: {
                  template: (data) => {
                    const { taskRecord } = data;
                    return `<div>${taskRecord.name} has a schedule conflict with work order ${taskRecord.originalData.conflict.conflictingWo.workOrderNumber}.</div>`;
                  },
                },
              }
            : null,
      },
    },
    subGridConfigs: {
      locked: {
        flex: 3,
      },
      normal: {
        flex: 4,
      },
    },
    viewPreset: "weekAndDayLetter",
    barMargin: 10,
    autoHeight: true,
    autoWidth: true,
    baselinesFeature: { disabled: true },
    cellTooltipFeature: { disabled: true },
    columnDragToolbarFeature: { disabled: true },
    columnPickerFeature: { disabled: true },
    contextMenuFeature: { disabled: true },
    criticalPathsFeature: { disabled: true },
    dependenciesFeature: { disabled: true },
    dependencyEditFeature: { disabled: true },
    projectLinesFeature: { disabled: true },
    quickFindFeature: { disabled: true },
    rollupsFeature: { disabled: true },
    rowCopyPasteFeature: { disabled: true },
    rowReorderFeature: { disabled: true },
    sortFeature: { disabled: true },
    taskContextMenuFeature: { disabled: true },
    taskCopyPasteFeature: { disabled: true },
    taskDragFeature: { disabled: true },
    taskDragCreateFeature: { disabled: true },
    taskEditFeature: { disabled: true },
    taskMenuFeature: { disabled: true },
    taskResizeFeature: { disabled: true },
  },
  buildChartData: (schedules) => {
    const rows = schedules
      .map((schedule) => {
        const children = schedule.workOrders
          .filter((wo) => wo.startDate && wo.endDate)
          .map((workOrder, index) => {
            const child = {
              index,
              id: `${schedule.id}-${workOrder.id}`,
              name: schedule.firstName + " " + schedule.lastName,
              workOrderNumber: workOrder.workOrderNumber || "WO Group",
              customerName: workOrder.customerName,
              startDate: utilsHelper.formatDate(workOrder.startDate),
              endDate: utilsHelper.formatDate(workOrder.endDate),
              conflict: hasConflict(schedule.workOrders, workOrder),
              manuallyScheduled: true,
              rollup: true,
            };
            return child;
          });
        if (!children?.length) {
          return null;
        }
        const row = {
          id: schedule.id,
          name: schedule.firstName + " " + schedule.lastName,
          customerName: "-",
          workOrderNumber: "-",
          startDate: utilsHelper.formatDate(
            moment.min(children.map((wo) => moment(wo.startDate)))
          ),
          endDate: utilsHelper.formatDate(
            moment.max(children.map((wo) => moment(wo.endDate)))
          ),
          manuallyScheduled: true,
          expanded: true,
          draggable: false,
          children,
        };
        return row;
      })
      .filter(Boolean);
    return rows;
  },
};
