import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../helpers/apiHelper";

export const authApi = {
  login: (data) =>
    axios("/api/auth/w/sign-in", {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data: {
        email: data.email,
        password: data.password,
      },
    }).then((result) => result.data),
  logout: () =>
    axios(`/api/auth/logout`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
    }).then((result) => result.data),
  validate: () =>
    axios(`/api/auth/w/validate`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
};
