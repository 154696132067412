import React, { useEffect, useState } from "react";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

import Header from "../../../components/Header";

import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { BryntumGantt } from "@bryntum/gantt-react";

import "../../../components/gantt/GanttToolbar";

import Moment from "moment";

import { extendMoment } from "moment-range";

import { utilsHelper } from "../../../helpers/utilsHelper";

import { scheduleApi } from "../../../services/scheduleService";

import AssignationsByDate from "./AssignationsByDate";
import { supervisorScheduleHelper } from "../../../helpers/supervisorScheduleHelper";
import { useAuth } from "../../../providers/authProvider";

const moment = extendMoment(Moment);

const TYPE_ASSIGNATIONS = "TYPE_ASSIGNATIONS";
const WEEKS_DIFF = 7;

const PAGE_SIZE = 20;

const SupervisorSchedule = () => {
  const [authContext] = useAuth();
  const [dates, setDates] = useState({
    startDate: utilsHelper.formatDate(moment().startOf("week")),
    endDate: utilsHelper.formatDate(
      moment().add(WEEKS_DIFF, "weeks").endOf("week")
    ),
  });

  const [schedules, setSchedules] = useState([]);
  const [data, setData] = useState([]);
  const [crewInfo, setCrewInfo] = useState([]);

  //pagination
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const condition = {
        page: page - 1,
        pageSize: PAGE_SIZE,
        startDate: utilsHelper.formatDate(dates.startDate),
        endDate: utilsHelper.formatDate(dates.endDate),
        jobSourceId: authContext.currentUser.jobSourceId,
      };
      const result = await scheduleApi.getSupervisorSchedule(condition);
      if (result.count) {
        const data = supervisorScheduleHelper.buildChartData(result.data);
        setPageCount(result.totalPages);
        setRowCount(result.count);
        setSchedules(result.data);
        setData(data);
      } else {
        const startDate = utilsHelper.formatDate(
          moment(dates.startDate).subtract(1, "week")
        );
        const endDate = utilsHelper.formatDate(
          moment(dates.endDate).subtract(1, "week")
        );
        setDates({
          startDate,
          endDate,
        });
      }
    };
    fetchData();
  }, [page, dates, authContext.currentUser.jobSourceId]);

  useEffect(() => {
    const fetchData = async () => {
      const workOrders = schedules.flatMap((data) => data.workOrders);
      const workOrderIds = Object.keys(
        workOrders.reduce((p, c) => {
          p[c.id] = true;
          return p;
        }, {})
      );
      if (workOrderIds?.length) {
        const crewInfo = await scheduleApi.getScheduleWorkOrdersInfo({
          "workOrderIds[]": workOrderIds.join("&workOrderIds[]="),
        });
        setCrewInfo(crewInfo);
      }
    };
    fetchData();
  }, [schedules]);

  const dateRangeChange = (dateRange) => {
    const startDate = utilsHelper.formatDate(dateRange.config.startDate);
    const endDate = utilsHelper.formatDate(dateRange.config.endDate);
    setPage(1);
    setPageCount(0);
    setRowCount(0);
    setDates({
      startDate,
      endDate,
    });
  };

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      <Header className="mb-3 ">
        <div className="d-flex">
          <div className="col-12 px-0 col-lg-6 d-flex flex-column">
            <h1 className="text-dark mb-0">Supervisor Schedule</h1>
            {rowCount ? (
              <small className="text-muted">
                Showing {(page - 1) * PAGE_SIZE + 1} to{" "}
                {Math.min((page - 1) * PAGE_SIZE + PAGE_SIZE, rowCount)} of{" "}
                {rowCount}
              </small>
            ) : (
              <small className="text-muted">Loading..</small>
            )}
          </div>
          <div className="d-flex justify-content-end align-items-center mt-2 mt-lg-0 col-12 col-lg-6 px-0"></div>
        </div>
      </Header>
      <Row>
        <Col>
          <Card>
            <CardBody className="d-flex flex-column border rounded p-0 m-3">
              <BryntumGantt
                {...supervisorScheduleHelper.ganttConfig}
                data={data}
                onTimeAxisChange={dateRangeChange}
              />
              <AssignationsByDate
                type={TYPE_ASSIGNATIONS}
                startDate={dates.startDate}
                workOrders={schedules.flatMap(
                  (schedule) => schedule.workOrders
                )}
                crewInfo={crewInfo}
              />
              {pageCount ? (
                <ReactPaginate
                  forcePage={page - 1}
                  previousLabel={<FontAwesomeIcon icon={faChevronLeft} />}
                  nextLabel={<FontAwesomeIcon icon={faChevronRight} />}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(event) => setPage(event.selected + 1)}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={
                    "cursor-pointer col-12 pagination my-3 d-flex justify-content-center align-items-center"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              ) : null}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SupervisorSchedule;
