import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  CardFooter,
  Input,
  InputGroup,
  InputGroupText,
  Button,
  Badge,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { ACTIONS, useUsers } from "../../providers/usersProvider";

import {
  faInfoCircle,
  faPen,
  faPlus,
  faSearch,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserModal from "../../components/admin/users/UserModal";
import { usersApi } from "../../services/userServices";
import AdvanceTableWrapper from "../../components/advanceTable/AdvanceTableWrapper";
import AdvanceTable from "../../components/advanceTable/AdvanceTable";
import AdvanceTablePagination from "../../components/advanceTable/AdvanceTablePagination";
import InformationModal from "../../components/InformationModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { useAuth } from "../../providers/authProvider";
import { utilsHelper } from "../../helpers/utilsHelper";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const EXECUTIVE_USER_ROLE = "Executive";

const Users = () => {
  const [authContext] = useAuth();
  const [usersContext, setUsersContext] = useUsers();

  const [loading, setLoading] = useState(true);
  const [editUserModal, setEditUserModal] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          usersContext.sortBy === sortBy?.id &&
          usersContext.direction === direction
        ) {
          return;
        }
        setUsersContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setUsersContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [setUsersContext, usersContext.direction, usersContext.sortBy]
  );

  useEffect(() => {
    setLoading(true);
    usersApi
      .getUsers({
        search: usersContext.search,
        page: usersContext.page - 1,
        pageSize: usersContext.sizePerPage,
        sortBy: usersContext.sortBy,
        direction: usersContext.direction,
        jobSourceId: authContext.currentUser.jobSourceId,
      })
      .then((users) => {
        setUsersContext({
          action: ACTIONS.GET_USERS_SUCCESS,
          payload: { users },
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    setUsersContext,
    usersContext.sortBy,
    usersContext.direction,
    usersContext.sizePerPage,
    usersContext.page,
    usersContext.search,
    usersContext.refresh,
    authContext.currentUser.jobSourceId,
  ]);

  const onEdit = (user) => setEditUserModal(user.id);

  const onDelete = (user) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        const result = await usersApi.delete(user);
        if (result.error) {
          setInformationModal({
            isOpen: true,
            title: "Delete User",
            body: "There was an error with your request.",
            onClose: null,
          });
        } else {
          setInformationModal({
            isOpen: true,
            title: "Delete User",
            body: "User Deleted Succesfully",
            onClose: null,
          });
        }
        setConfirmationModal(initConfirmationModal);
        setUsersContext({
          action: ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setUsersContext({
          action: ACTIONS.REFRESH,
        });
      },
      title: "Delete User",
      body: `<p class="text-center">Do you confirm you want to delete this user?</p>
        <span>${user.email} - ${user.userRoleId}</span>`,
      confirmColor: "danger",
    });
  };

  const onLastActivity = (user) => {
    setInformationModal({
      isOpen: true,
      title: "User Last Activity",
      rawBody: true,
      body: (
        <ListGroup>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2 bg-lighter font-weight-bold"
            tag="div"
          >
            Platform and version
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2"
            tag="div"
          >
            {user.appVersion || "-"}
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2 bg-lighter font-weight-bold"
            tag="div"
          >
            Activity
          </ListGroupItem>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2"
            tag="div"
          >
            {user.lastActivity || "-"}
          </ListGroupItem>
        </ListGroup>
      ),
      onClose: null,
    });
  };

  const columns = [
    {
      accessor: "firstName",
      Header: "First Name",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { firstName } = rowData.row.original;
        return firstName || "-";
      },
    },
    {
      accessor: "lastName",
      Header: "Last Name",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { lastName } = rowData.row.original;
        return lastName || "-";
      },
    },
    {
      accessor: "email",
      Header: "Email",
      headerProps: { className: "text-truncate" },
      Cell: (rowData) => {
        const { email } = rowData.row.original;
        return email || "-";
      },
    },
    {
      accessor: "role.name",
      Header: "Role",
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      Cell: (rowData) => {
        const { role } = rowData.row.original;
        return <Badge>{role?.name || "-"}</Badge>;
      },
    },
  ];

  if (!utilsHelper.isReadOnly(authContext)) {
    columns.push({
      accessor: "id",
      Header: "Actions",
      disableSortBy: true,
      headerProps: { className: "text-truncate text-center" },
      cellProps: { className: "text-truncate text-center" },
      Cell: (rowData) => (
        <div>
          {authContext.currentUser.role.name === EXECUTIVE_USER_ROLE ? (
            <Button
              size="sm"
              color="info"
              className="rounded mr-2"
              onClick={() => onLastActivity(rowData.row.original)}
            >
              <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
              <span>Last Activity</span>
            </Button>
          ) : null}
          <Button
            size="sm"
            color="warning"
            className="rounded"
            onClick={() => onEdit(rowData.row.original)}
          >
            <FontAwesomeIcon icon={faPen} className="mr-1" />
            <span>Edit</span>
          </Button>
          <Button
            size="sm"
            className="rounded ml-2"
            color="danger"
            onClick={() => onDelete(rowData.row.original)}
          >
            <FontAwesomeIcon icon={faTrash} className="mr-1" />
            <span>Delete</span>
          </Button>
        </div>
      ),
    });
  }

  return (
    <Container fluid className="h-100">
      <div className="w-100">
        <AdvanceTableWrapper
          columns={columns}
          data={usersContext.users?.data || []}
          pageSize={usersContext.sizePerPage}
          sortable
          onSort={onSort}
          defaultSort={{
            sortBy: usersContext.sortBy,
            direction: usersContext.direction,
          }}
        >
          <Card className="mb-3 w-100">
            <CardHeader className="d-flex">
              <div className="text-dark flex-grow-1 d-flex align-items-center">
                <h3 className="mb-0 ">Users</h3>
                <small className="text-muted ml-2 pt-1">
                  ({usersContext.users?.count || 0})
                </small>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <InputGroup className="mr-3">
                  <Input
                    className="border-right-0"
                    maxLength="50"
                    placeholder="Search for.."
                    value={usersContext.search}
                    onChange={(evt) =>
                      setUsersContext({
                        action: ACTIONS.SEARCH,
                        payload: { search: evt.target.value },
                      })
                    }
                  />
                  <InputGroupText className="search-input input-group-text bg-primary text-white border-left-0 border-primary">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroupText>
                </InputGroup>
                {!utilsHelper.isReadOnly(authContext) ? (
                  <Button
                    size="sm"
                    className="mr-3 rounded-circle d-flex custom-rounded-button"
                    color="primary"
                    onClick={() => setCreateUserModal(true)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                ) : null}
                <Button
                  size="sm"
                  className="mr-3 rounded-circle d-flex custom-rounded-button"
                  color="primary"
                  onClick={() =>
                    setUsersContext({
                      action: ACTIONS.REFRESH,
                    })
                  }
                >
                  <FontAwesomeIcon icon={faSync} />
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <AdvanceTable
                table
                isLoading={loading}
                headerClassName="text-muted small"
                tableProps={{
                  striped: true,
                  className: "mb-0",
                }}
              />
            </CardBody>
            <CardFooter>
              <AdvanceTablePagination
                totalCount={usersContext.users?.count || 0}
                pageCount={usersContext.users?.totalPages || 0}
                currentPage={usersContext.page - 1}
                onPageChange={(page) =>
                  setUsersContext({
                    action: ACTIONS.PAGE_CHANGE,
                    payload: { page },
                  })
                }
                pageSize={usersContext.sizePerPage}
                onPageSizeChange={(sizePerPage) =>
                  setUsersContext({
                    action: ACTIONS.PAGE_SIZE_CHANGE,
                    payload: { sizePerPage },
                  })
                }
              />
            </CardFooter>
          </Card>
        </AdvanceTableWrapper>
      </div>
      {createUserModal ? (
        <UserModal
          onSubmit={() => {
            setCreateUserModal(false);
            setInformationModal({
              isOpen: true,
              title: "Create User",
              body: "User Created Succesfully",
              onClose: null,
            });
            setUsersContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => setCreateUserModal(false)}
        />
      ) : editUserModal ? (
        <UserModal
          onSubmit={() => {
            setEditUserModal(false);
            setInformationModal({
              isOpen: true,
              title: "Update User",
              body: "User Updated Succesfully",
              onClose: null,
            });
            setUsersContext({
              action: ACTIONS.REFRESH,
            });
          }}
          onClose={() => {
            setEditUserModal(false);
          }}
          userId={editUserModal}
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : null}
    </Container>
  );
};

export default Users;
