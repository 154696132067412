import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Label,
} from "reactstrap";

import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { reportsApi } from "../../services/reportsServices";

import InformationModal from "../InformationModal";
import Loader from "../Loader";
import ReactQuill from "react-quill";
import moment from "moment";
import { useAuth } from "../../providers/authProvider";

const REPLY_TO_EMAIL = "payroll@processbarron.com";

const TYPE_WEEKLY = "TYPE_WEEKLY";

const SendTLSummaryEmailModal = ({
  periodType,
  monday,
  customStartDate,
  customEndDate,
  employees,
  onClose,
}) => {
  const [authContext] = useAuth();

  const [loading, setLoading] = useState(false);

  const [manualEmail, setManualEmail] = useState();

  const [content, setContent] = useState("");
  const [subject, setSubject] = useState("");

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const employeesWithEmail = employees.filter((employee) => employee.email);
  const employeesWithoutEmail = employees.filter((employee) => !employee.email);

  useEffect(() => {
    const endDate =
      periodType.value === TYPE_WEEKLY
        ? moment(monday).endOf("isoWeek").format("YYYY-MM-DD")
        : moment(customEndDate).format("YYYY-MM-DD");
    const subject = `Travel & Living Summary for Week ${moment(endDate).format(
      "MM/DD/YYYY"
    )}`;
    setSubject(subject);
  }, [periodType, monday, customEndDate]);

  const onSendReport = async () => {
    if (!content.length) {
      return setInformationModal({
        isOpen: true,
        title: `Send Summary`,
        body: `Please add the email content.`,
      });
    }
    try {
      const condition = {
        jobSourceId: authContext.currentUser.jobSourceId,
      };
      if (periodType.value === TYPE_WEEKLY) {
        condition.startDate = moment(monday).format("YYYY-MM-DD");
        condition.endDate = moment(monday)
          .endOf("isoWeek")
          .format("YYYY-MM-DD");
      } else {
        condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
        condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
      }
      setLoading(true);
      if (manualEmail && employees.length === 1) {
        //here it will always be only one
        condition.employees = [
          {
            email: manualEmail,
            employeeId: employees[0].employeeId,
          },
        ];
      } else {
        condition.employees = employeesWithEmail;
      }
      condition.subject = subject;
      condition.content = content;
      condition.replyTo = REPLY_TO_EMAIL;
      await reportsApi.sendEmailTravelAndLivingSummaryPdf(condition);
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Send Summary`,
        body: `Email${
          employeesWithEmail.length > 1 ? "s" : ""
        } sent succesfully.`,
        onClose: () => onClose(),
      });
    } catch (err) {
      setLoading(false);
      setInformationModal({
        isOpen: true,
        title: `Send Summary`,
        body: `There was an error with your request.`,
      });
    }
  };

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>
        <span>Send Summary PDF by email</span>
      </ModalHeader>
      <ModalBody className="d-flex flex-column align-items-center">
        {employees.length === 1 && employeesWithoutEmail.length === 1 ? (
          <ListGroup className="w-100">
            <ListGroupItem
              className="d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold"
              tag="div"
            >
              <span>
                There is no email associated to the employee, please enter it
              </span>
            </ListGroupItem>
            <ListGroupItem
              className="d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold"
              tag="div"
            >
              <Input
                maxLength="255"
                type="email"
                placeholder="Enter an email.."
                value={manualEmail}
                onChange={(e) => setManualEmail(e.target.value)}
              />
            </ListGroupItem>
          </ListGroup>
        ) : employees.length === employeesWithoutEmail.length ? (
          <ListGroupItem
            className="d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold"
            tag="div"
          >
            None of the employees has an email associated
          </ListGroupItem>
        ) : (
          <ListGroup className="w-100">
            <ListGroupItem
              className="d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold"
              tag="div"
            >
              <span>{`PDF will be sent to the following email${
                employeesWithEmail.length > 1 ? "s" : ""
              }`}</span>
            </ListGroupItem>
            {employeesWithEmail.map((emp) => (
              <ListGroupItem
                className="d-flex justify-content-between align-items-center py-2"
                tag="div"
                key={emp.id}
              >
                <span>{emp.name}:</span>
                <span className="text-muted font-italic ml-1">{emp.email}</span>
              </ListGroupItem>
            ))}
            {employeesWithoutEmail.length ? (
              <>
                <ListGroupItem
                  className="d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold"
                  tag="div"
                >
                  Missing emails
                </ListGroupItem>
                {employeesWithoutEmail.map((emp) => (
                  <ListGroupItem
                    className="d-flex justify-content-between align-items-center py-2"
                    tag="div"
                  >
                    {emp.name}
                  </ListGroupItem>
                ))}
              </>
            ) : null}
          </ListGroup>
        )}
        {employees.length !== employeesWithoutEmail.length ||
        (employees.length === 1 && employeesWithoutEmail.length === 1) ? (
          <ListGroup className="w-100 mt-2">
            <ListGroupItem
              className="d-flex justify-content-center align-items-center py-2 bg-lighter font-weight-bold"
              tag="div"
            >
              <span>Email</span>
            </ListGroupItem>
            <ListGroupItem className="align-items-center py-2" tag="div">
              <Row className="mb-3 d-flex align-items-center">
                <Col sm="4">
                  <Label className="mb-0" for="subject">
                    Subject
                  </Label>
                </Col>
                <Col sm="8">
                  <Input
                    maxLength="100"
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="Enter the subject..."
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required={true}
                  />
                </Col>
              </Row>
              <Row className="mb-3 d-flex align-items-center">
                <Col sm="4">
                  <Label className="mb-0" for="subject">
                    Reply To Email
                  </Label>
                </Col>
                <Col sm="8">
                  <Input
                    maxLength="50"
                    type="email"
                    placeholder="Enter an email address..."
                    value={REPLY_TO_EMAIL}
                    disabled
                  />
                </Col>
              </Row>
              <div className="mt-3">
                <Col sm="12 border rounded">
                  <ReactQuill
                    placeholder="Type something..."
                    theme={"snow"}
                    value={content}
                    onChange={(content) => setContent(content)}
                  />
                </Col>
              </div>
            </ListGroupItem>
          </ListGroup>
        ) : null}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Close
        </Button>
        {loading ? (
          <div className="my-2">
            <Loader size="sm" />
          </div>
        ) : employees.length !== employeesWithoutEmail.length ||
          employees.length === 1 ? (
          <Button
            color={"primary"}
            onClick={onSendReport}
            disabled={
              employees.length === 1 &&
              employeesWithoutEmail.length === 1 &&
              !manualEmail?.length
            }
          >
            <FontAwesomeIcon icon={faPaperPlane} className="mr-2" />
            <span>Confirm</span>
          </Button>
        ) : null}
      </ModalFooter>
    </Modal>
  );
};

export default SendTLSummaryEmailModal;
