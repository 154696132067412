import React, { useReducer, createContext, useContext } from "react";
import { utilsHelper } from "../helpers/utilsHelper";

const WO_STATUS_OPEN = "Open";
const WO_STATUS_SCHEDULED = "Scheduled";

const JOB_TAB = "JOB_TAB";
const CREW_TAB = "CREW_TAB";

const getDefaultTabWO = (workOrder) =>
  workOrder?.status === WO_STATUS_OPEN ||
  workOrder?.status === WO_STATUS_SCHEDULED
    ? CREW_TAB
    : JOB_TAB;

const initialState = {
  workOrder: null,
  activeTab: null,
  activeDates: new Set(),
  totalExpenses: null,
  totalPerdiem: null,
  dates: null,
};

const ACTIONS = {
  RESET: "RESET",
  REFRESH: "REFRESH",
  CHANGE_TAB: "CHANGE_TAB",
  TOGGLE_DATE: "TOGGLE_DATE",
  GET_WORKORDER_SUCCESS: "GET_WORKORDER_SUCCESS",
  UPDATE_TOTAL_DOCUMENTS: "UPDATE_TOTAL_DOCUMENTS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_WORKORDER_SUCCESS: {
      const { payload } = data;
      const { workOrder = {} } = payload;
      const isDifferentWorkOrder = workOrder.id !== state.workOrder?.id;

      const dates = {};

      let totalExpenses = 0;
      let totalPerdiem = 0;

      if (workOrder.crewWorkOrders) {
        workOrder.crewWorkOrders.forEach((item) => {
          item.crew.crewWorkDayCrew
            .map((crewWorkDay) => {
              const result = {
                ...crewWorkDay,
                crewId: item.crew.id,
                shift: item.shift,
                outage: item.outage,
                employees: item.crew.employeeCrews.map((ec) => {
                  const isEmployeeCrewActiveOnDate =
                    utilsHelper.isEmployeeCrewActiveOnDate(
                      ec,
                      crewWorkDay.date
                    );
                  return {
                    ...ec,
                    activeOnDate: isEmployeeCrewActiveOnDate,
                  };
                }),
              };
              return result;
            })
            .filter((item) => item.employees.length)
            .forEach((item) => {
              const key = utilsHelper.formatDate(item.date);
              if (!dates[key]) {
                dates[key] = { crewWorkDays: [], generalExpenses: [] };
              }
              dates[key].crewWorkDays.push(item);
              totalExpenses += item.expenses.reduce((a, b) => a + b.amount, 0);
              totalPerdiem += item.perDiems.reduce((a, b) => a + b.amount, 0);
            });
        });
      }

      if (workOrder.generalExpenses) {
        workOrder.generalExpenses.forEach((item) => {
          const key = utilsHelper.formatDate(item.date);
          if (!dates[key]) {
            dates[key] = { crewWorkDays: [], generalExpenses: [] };
          }
          dates[key].generalExpenses.push({
            ...item,
            isGeneral: true,
          });
          totalExpenses += item.amount;
        });
      }

      return {
        ...state,
        activeTab: isDifferentWorkOrder
          ? getDefaultTabWO(workOrder)
          : state.activeTab || getDefaultTabWO(workOrder),
        activeDates: new Set(Object.keys(dates)),
        workOrder,
        dates,
        totalExpenses,
        totalPerdiem,
      };
    }

    case ACTIONS.REFRESH: {
      const newState = {
        ...state,
        refresh: !state.refresh,
      };

      return { ...newState };
    }

    case ACTIONS.RESET: {
      const newState = {
        ...initialState,
        refresh: state.refresh,
        activeTab: state.activeTab,
      };

      return { ...newState };
    }

    case ACTIONS.CHANGE_TAB: {
      const { payload } = data;
      const { tab } = payload;

      return {
        ...state,
        activeTab: tab,
      };
    }

    case ACTIONS.TOGGLE_DATE: {
      const { payload } = data;
      const { date } = payload;

      const activeDates = new Set(state.activeDates);
      if (activeDates.has(date)) {
        activeDates.delete(date);
      } else {
        activeDates.add(date);
      }

      return {
        ...state,
        activeDates,
      };
    }

    case ACTIONS.UPDATE_TOTAL_DOCUMENTS: {
      const { payload } = data;

      return {
        ...state,
        workOrder: {
          ...state.workOrder,
          totalDocuments: payload,
        },
      };
    }

    default:
      return {
        ...initialState,
      };
  }
};

const WorkOrderDetailsContext = createContext(initialState);

const WorkOrderDetailsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <WorkOrderDetailsContext.Provider value={stateAndDispatch}>
      {children}
    </WorkOrderDetailsContext.Provider>
  );
};

export const useWorkOrderDetails = () => useContext(WorkOrderDetailsContext);

export { WorkOrderDetailsContext, WorkOrderDetailsProvider, ACTIONS };
