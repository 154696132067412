import {
  faExclamationCircle,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Badge, Button } from "reactstrap";
import {
  useWorkOrderDetails,
  ACTIONS,
} from "../../providers/workOrderDetailsProvider";
import TooltipItem from "../TooltipItem";
import NoteModal from "../../components/admin/notes/NoteModal";
import InformationModal from "../../components/InformationModal";
import { utilsHelper } from "../../helpers/utilsHelper";
import { useAuth } from "../../providers/authProvider";
import { notesApi } from "../../services/noteServices";
import ConfirmationModal from "../ConfirmationModal";

const USER_ROLE_EXECUTIVE = 1;
const USER_ROLE_SUPERVISOR = 3;

const Note = ({ note }) => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const isSingle = workOrderDetails.workOrder.id === note.workOrder.id;
  const canDeleteNote = authContext.currentUser.userRoleId === USER_ROLE_EXECUTIVE;

  const [editNoteModal, setEditNoteModal] = useState(false);

  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onDelete = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await notesApi.delete(note);
        setConfirmationModal(initConfirmationModal);
        setInformationModal({
          isOpen: true,
          title: `Delete Note`,
          body: `Note deleted successfully`,
          confirmColor: "danger",
          onClose: () => {
            setInformationModal({ isOpen: false, title: "", body: "" });
            setWorkOrderDetails({
              action: ACTIONS.REFRESH,
            });
          },
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
        setWorkOrderDetails({
          action: ACTIONS.REFRESH,
        });
      },
      title: "Delete Note",
      body: `<p class="text-center mb-0">Do you confirm you want to delete this Note?</p>`,
    });
  };

  const onEdit = () => {
    setEditNoteModal(true);
  };

  return (
    <div
      key={note.id}
      className="my-2 col-12 d-flex justify-content-between align-items-start px-0"
    >
      <div
        className="p-2 my-1 rounded col-12 d-flex justify-content-between align-items-start"
        style={{ backgroundColor: "#FDF1DB" }}
      >
        <div className="flex-grow-1 px-0">
          <strong>{note.type}:</strong>
          <span className="text-wrap col-12 px-0 ml-1">{note.content}</span>
        </div>
        <div className="d-flex">
          {note.noteAuthor &&
          note.noteAuthor.userRoleId !== USER_ROLE_SUPERVISOR ? (
            <TooltipItem
              className="ml-2"
              id={`${note.id}-tooltip`}
              title={`Created by ${`${note.noteAuthor.firstName} ${note.noteAuthor.lastName}`}`}
            >
              <FontAwesomeIcon
                size="lg"
                icon={faExclamationCircle}
                className="ml-2 text-info"
                style={{ paddingBottom: "1px" }}
              />
            </TooltipItem>
          ) : null}
          <div className="d-flex">
            {!isSingle ? <Badge>{note.workOrder.workOrderNumber}</Badge> : null}
            {!utilsHelper.isReadOnly(authContext) ? (
              <>
                {canDeleteNote && (
                  <Button
                    size="sm"
                    className="rounded d-flex align-items-center justify-content-center ml-2"
                    color="danger"
                    onClick={() => onDelete()}
                  >
                    <FontAwesomeIcon
                      size="sm"
                      icon={faTrash}
                      className="mr-1"
                    />
                    <small>Delete</small>
                  </Button>
                )}
                <Button
                  size="sm"
                  className="rounded d-flex align-items-center justify-content-center ml-2"
                  color="warning"
                  onClick={() => onEdit()}
                >
                  <FontAwesomeIcon size="sm" icon={faPen} className="mr-1" />
                  <small>Edit</small>
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {editNoteModal ? (
        <NoteModal
          onSubmit={() => {
            setEditNoteModal(false);
            setInformationModal({
              isOpen: true,
              title: "Update Note",
              body: "Note Updated Succesfully",
              onClose: () => {
                setWorkOrderDetails({
                  action: ACTIONS.REFRESH,
                });
              },
            });
          }}
          onClose={() => {
            setEditNoteModal(false);
          }}
          noteId={note.id}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({
                  isOpen: false,
                  title: "",
                  body: "",
                  onClose: null,
                })
          }
        />
      ) : confirmationModal.isOpen ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </div>
  );
};

export default Note;
