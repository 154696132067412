import { faDownload } from "@fortawesome/free-solid-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import { Document, Page } from "react-pdf";
import Loader from "../../components/Loader";
import { inspectionsJsaApi } from "../../services/inspectionJSAServices";

const TYPE_PDF = "pdf";

const JsaInspection = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [inspectionsJSA, setInspectionsJSA] = useState([]);

  const [selectedInspectionJSA, setSelectedInspectionJSA] = useState(null);
  const [loading, setLoading] = useState();
  const [report, setReport] = useState();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDownload = (type) => {
    if (type === TYPE_PDF) {
      return window.open(report, "new");
    }
  };

  useEffect(() => {
    if (selectedInspectionJSA) {
      setLoading(true);
      inspectionsJsaApi
        .inspectionJSASummaryReport({ id: selectedInspectionJSA.id })
        .then((res) => {
          const data = new Blob([res], { type: "application/pdf" });
          const URL = window.URL.createObjectURL(data);
          setLoading(false);
          setReport(URL);
        })
        .catch(() => {
          setReport(null);
          setLoading(false);
        });
    }
  }, [selectedInspectionJSA]);

  useEffect(() => {
    const inspectionsJSA = workOrderDetails.workOrder.inspectionsJSA.flatMap(
      (e) => e
    );
    setInspectionsJSA(inspectionsJSA);
  }, [workOrderDetails.workOrder.inspectionsJSA]);

  useEffect(() => {
    if (!report) {
      setNumPages(null);
    }
  }, [report]);

  return (
    <Row className="d-flex">
      <Col className="flex-shrink-0">
        <div className="d-flex d-flex-row justify-content-between border-bottom">
          <h4> JSA Inspections</h4>
          <div>
            {report ? (
              <Button
                color="tertiary"
                size="sm"
                className="rounded"
                onClick={() => onDownload(TYPE_PDF)}
              >
                <FontAwesomeIcon icon={faDownload} className="mr-2" />
                <span>PDF</span>
              </Button>
            ) : (
              <Badge className="d-flex align-items-center" pill>
                Click on an entry to see the report
              </Badge>
            )}
          </div>
        </div>
        {!inspectionsJSA?.length ? (
          <div className="text-center text-muted mt-4">
            No data has been recorded
          </div>
        ) : (
          <Row className="d-flex">
            <Col>
              <ListGroup className="mt-3">
                {inspectionsJSA.map((inspectionsJSA, index) => (
                  <ListGroupItem
                    action
                    key={index}
                    className="d-flex justify-content-between align-items-center py-2 cursor-pointer"
                    tag="div"
                    onClick={() => {
                      setPageNumber(1);
                      setSelectedInspectionJSA(inspectionsJSA);
                    }}
                  >
                    <div className="d-flex flex-column">
                      {inspectionsJSA.user ? (
                        <span>{`${inspectionsJSA.user.firstName} ${inspectionsJSA.user.lastName}`}</span>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="d-flex flex-column">
                      <Badge
                        className="d-flex align-items-center justify-content-center mb-1"
                        pill
                        color={"secondary"}
                      >
                        {`Work date ${moment(
                          inspectionsJSA.crewWorkDay.date
                        ).format("MM/DD/YYYY")}`}
                      </Badge>
                      <Badge
                        className="d-flex align-items-center"
                        pill
                        color={"success"}
                      >
                        {`Inspection ${
                          inspectionsJSA.completedAt
                            ? `date ${moment(inspectionsJSA.completedAt).format(
                                "MM/DD/YYYY HH:mm"
                              )}`
                            : "In progress"
                        }`}
                      </Badge>
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Col>
            <Col className="flex-grow-1 d-flex flex-column col my-3">
              {loading ? (
                <div className="my-2">
                  <Loader size="sm" />
                </div>
              ) : !report ? (
                <div className="text-center text-muted mt-4">
                  No PDF has been loaded
                </div>
              ) : (
                <>
                  <Document
                    className="d-flex flex-column justify-content-between align-items-center text-body bg-white"
                    file={report}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  >
                    <Page
                      className="w-100 border rounded"
                      pageNumber={pageNumber}
                    />
                  </Document>
                  <div className="d-flex justify-content-between p-2 align-items-center text-body bg-white border mt-4 mx-1 rounded">
                    <Button
                      className="rounded"
                      onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    <span>
                      Page {pageNumber} of {numPages}
                    </span>
                    <Button
                      className="rounded"
                      onClick={() =>
                        setPageNumber(Math.min(pageNumber + 1, numPages))
                      }
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                  </div>
                </>
              )}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default JsaInspection;
