import {
  faChevronLeft,
  faChevronRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Badge, Button, Col, ListGroup, ListGroupItem, Row } from "reactstrap";

import { Document, Page } from "react-pdf";
import Loader from "../../components/Loader";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import { witnessReportApi } from "../../services/witnessReportServices";

const Witness = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [witnessReports, setWitnessReports] = useState([]);

  const [selectedIndividual, setSelectedIndividual] = useState(null);
  const [loading, setLoading] = useState();
  const [report, setReport] = useState();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDownload = () => window.open(report, "new");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await witnessReportApi.witnessIndividualReport({
          id: selectedIndividual.id,
          browserTimeOffset: moment().utcOffset() / 60,
        });
        const data = new Blob([res], { type: "application/pdf" });
        const URL = window.URL.createObjectURL(data);
        setReport(URL);
        setLoading(false);
      } catch (err) {
        setReport(null);
        setLoading(false);
      }
    };
    if (selectedIndividual) {
      fetchData();
    }
  }, [selectedIndividual]);

  useEffect(() => {
    setWitnessReports(workOrderDetails.workOrder.witnessReports);
  }, [workOrderDetails.workOrder.witnessReports]);

  return (
    <Row className="d-flex">
      <Col className="flex-shrink-0">
        <div className="d-flex d-flex-row justify-content-between border-bottom">
          <h4>Witness Reports</h4>
          <div>
            {report ? (
              <Button
                color="tertiary"
                size="sm"
                className="rounded"
                onClick={onDownload}
              >
                <FontAwesomeIcon icon={faDownload} className="mr-2" />
                <span>PDF</span>
              </Button>
            ) : (
              <Badge className="d-flex align-items-center" pill>
                Click on an entry to see the report
              </Badge>
            )}
          </div>
        </div>
        {!witnessReports?.length ? (
          <div className="text-center text-muted mt-4">
            No data has been recorded
          </div>
        ) : (
          <Row className="d-flex">
            <Col>
              <ListGroup className="mt-3">
                {witnessReports.map((incident) => (
                  <div className="my-2">
                    <ListGroupItem
                      key={incident.id}
                      className="py-2 border-bottom font-weight-bold text-body bg-lighter text-left"
                      tag="div"
                    >
                      {incident.incidentName}
                    </ListGroupItem>
                    {incident.witnessIndividuals.map((individual) => {
                      const name =
                        individual.otherWitnessName ||
                        `${individual.employeeCrew?.employee?.firstName} ${individual.employeeCrew?.employee?.lastName}`;
                      return (
                        <ListGroupItem
                          action
                          key={individual.id}
                          className="d-flex justify-content-between align-items-center py-2 cursor-pointer"
                          tag="div"
                          onClick={() => setSelectedIndividual(individual)}
                        >
                          <div className="d-flex flex-column">
                            <span>{name}</span>
                          </div>
                          <div className="d-flex flex-column">
                            <Badge
                              className="d-flex align-items-center justify-content-center mb-1"
                              pill
                              color={"secondary"}
                            >
                              {`Work date ${
                                incident.crewWorkDay
                                  ? moment(incident.crewWorkDay.date).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "N/A"
                              }`}
                            </Badge>
                            <Badge
                              className="d-flex align-items-center"
                              pill
                              color={"success"}
                            >
                              {`Created at ${moment(
                                individual.createdAt
                              ).format("MM/DD/YYYY HH:mm")}`}
                            </Badge>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </div>
                ))}
              </ListGroup>
            </Col>
            <Col className="flex-grow-1 d-flex flex-column col my-3">
              {loading ? (
                <div className="my-2">
                  <Loader size="sm" />
                </div>
              ) : !report ? (
                <div className="text-center text-muted mt-4">
                  No PDF has been loaded
                </div>
              ) : (
                <>
                  <Document
                    className="d-flex flex-column justify-content-between align-items-center text-body bg-white"
                    file={report}
                    onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                  >
                    <Page
                      className="w-100 border rounded"
                      pageNumber={pageNumber}
                    />
                  </Document>
                  <div className="d-flex justify-content-between p-2 align-items-center text-body bg-white border mt-4 mx-1 rounded">
                    <Button
                      className="rounded"
                      onClick={() => setPageNumber(Math.max(pageNumber - 1, 1))}
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Button>
                    <span>
                      Page {pageNumber} of {numPages}
                    </span>
                    <Button
                      className="rounded"
                      onClick={() =>
                        setPageNumber(Math.min(pageNumber + 1, numPages))
                      }
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                  </div>
                </>
              )}
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default Witness;
