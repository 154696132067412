import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import moment from "moment";
import { apiHelper } from "../helpers/apiHelper";

export const notificationApi = {
  getSafetyNotifications: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(
      data.id
        ? `/api/safety-notifications/${data.id}`
        : `/api/safety-notifications?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createSafetyNotification: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/safety-notifications`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateSafetyNotifications: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/safety-notifications`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getChangeOrderNotifications: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(
      data.id
        ? `/api/change-order-notifications/${data.id}`
        : `/api/change-order-notifications?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createChangeOrderNotification: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/change-order-notifications`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateChangeOrderNotifications: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/change-order-notifications`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getInspectionNotifications: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(
      data.id
        ? `/api/inspection-notifications/${data.id}`
        : `/api/inspection-notifications?${query}`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createInspectionNotification: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/inspection-notifications`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateInspectionNotifications: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/inspection-notifications`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
