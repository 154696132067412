import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend } from "chart.js";

ChartJS.register(Tooltip, Legend);

const HoursGraphic = ({ totals }) => {
  const total = parseFloat(totals.total || 0).toFixed(2);
  const regular = parseFloat(totals.regular || 0).toFixed(2);
  const overtime = parseFloat(totals.overtime || 0).toFixed(2);
  const totalTracked = parseFloat(totals.totalTracked || 0).toFixed(2);
  const regularTracked = parseFloat(totals.regularTracked || 0).toFixed(2);
  const overtimeTracked = parseFloat(totals.overtimeTracked || 0).toFixed(2);

  const data = {
    labels: ["Total", "Regular", "Overtime"],
    datasets: [
      {
        label: "Budgeted",
        data: [total, regular, overtime, 0],
        backgroundColor: "#27b3dc",
      },
      {
        label: "Tracked",
        data: [totalTracked, regularTracked, overtimeTracked, 0],
        backgroundColor: "#f95442",
      },
    ],
  };

  const options = {
    responsive: true,
    indexAxis: "y",
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default HoursGraphic;
