import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { reportsApi } from "../../../services/safetyServices";
import WeekSelector from "../../../components/admin/WeekSelector";
import { reportsHelper } from "../../../helpers/reportsHelper";
import PeriodTypeSelector from "../../../components/admin/PeriodTypeSelector";
import CustomPeriodSelector from "../../../components/admin/CustomPeriodSelector";
import Loader from "../../../components/Loader";
import { useAuth } from "../../../providers/authProvider";
import { useNavigate } from "react-router";
import { incidentReportApi } from "../../../services/incidentReportServices";
import { witnessReportApi } from "../../../services/witnessReportServices";
import { safetyStandDownReportApi } from "../../../services/safetyStandDownReportServices";
import { toolBoxTalkApi } from "../../../services/toolBoxTalkServices";
import SelectWrapper from "../../../components/admin/SelectWrapper";
import { inspectionsJsaApi } from "../../../services/inspectionJSAServices";

const REPORT_NAME = "Safety_";
const NAME_COLUMN = "Work Order #";

const COLUMNS = {
  Type: {
    type: "string",
  },
  Topic: {
    type: "string",
  },
  Date: {
    type: "string",
  },
  "Work Order #": {
    type: "string",
  },
  "Created By": {
    type: "string",
  },
  Link: {
    type: "string",
  },
};

const report = {
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
  ],
  dataSource: {
    data: [COLUMNS],
  },
  slice: {
    rows: [
      {
        uniqueName: "Type",
      },
      {
        uniqueName: "Topic",
      },
      {
        uniqueName: "Date",
      },
      {
        uniqueName: "Work Order #",
      },
      {
        uniqueName: "Created By",
      },
      {
        uniqueName: "Link",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Safety Reports",
    },
  },
};

const TYPE_WEEKLY = "TYPE_WEEKLY";

const SafetyReport = () => {
  const [authContext] = useAuth();
  const navigate = useNavigate();
  const pivot = useRef(null);

  const [selectedTopic, setSelectedTopic] = useState();
  const [data, setData] = useState(null);

  const getFilename = () =>
    reportsHelper.getFileName(
      monday,
      periodType.value === TYPE_WEEKLY,
      REPORT_NAME
    );

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));
  const [loading, setLoading] = useState(false);

  const addButtonToCells = useCallback((cell, entry) => {
    if (
      entry.type !== "header" &&
      entry.measure?.uniqueName === "Link" &&
      entry.rowIndex > 0
    ) {
      cell.text = `<input 
            class="btn btn-info text-white rounded text-center col-12 cursor-pointer"
            type="button" 
            value="View Report"
          >`;
      cell.style["z-index"] = 1;
    }
  }, []);

  const onButtonClick = useCallback(
    async (cell) => {
      if (cell.hierarchy && cell.hierarchy.caption === "Link") {
        const entry = data[cell.rowIndex - 1];
        let report, blob, URL;
        setLoading(true);
        switch (entry.Type) {
          case "ToolBox Talk":
            report = await toolBoxTalkApi.toolBoxTalkSummaryReport({
              id: entry.id,
              browserTimeOffset: moment().utcOffset() / 60,
            });
            blob = new Blob([report], { type: "application/pdf" });
            URL = window.URL.createObjectURL(blob);
            window.open(URL, "new");
            break;
          case "Safety Stand Down":
            report =
              await safetyStandDownReportApi.safetyStandDownSummaryReport({
                id: entry.id,
                browserTimeOffset: moment().utcOffset() / 60,
              });
            blob = new Blob([report], { type: "application/pdf" });
            URL = window.URL.createObjectURL(blob);
            window.open(URL, "new");
            break;
          case "Incident":
            report = await incidentReportApi.incidentSummaryReport({
              id: entry.id,
              browserTimeOffset: moment().utcOffset() / 60,
            });
            blob = new Blob([report], { type: "application/pdf" });
            URL = window.URL.createObjectURL(blob);
            window.open(URL, "new");
            break;
          case "Witness":
            report = await witnessReportApi.witnessIndividualReport({
              id: entry.id,
              browserTimeOffset: moment().utcOffset() / 60,
            });
            blob = new Blob([report], { type: "application/pdf" });
            URL = window.URL.createObjectURL(blob);
            window.open(URL, "new");
            break;
          case "JSA":
            report = await inspectionsJsaApi.inspectionJSASummaryReport({
              id: entry.id,
              browserTimeOffset: moment().utcOffset() / 60,
            });
            blob = new Blob([report], { type: "application/pdf" });
            URL = window.URL.createObjectURL(blob);
            window.open(URL, "new");
            break;
          default:
            break;
        }
        setLoading(false);
      }
    },
    [data]
  );

  useEffect(() => {
    if (pivot?.current?.flexmonster && data?.length) {
      pivot.current.flexmonster.updateData({
        data: [COLUMNS, ...data],
      });
      pivot.current.flexmonster.customizeCell(addButtonToCells);
      pivot.current.flexmonster.on("cellclick", onButtonClick);
    }
  }, [pivot, data, addButtonToCells, onButtonClick]);

  useEffect(() => {
    setLoading(true);

    const condition = { jobSourceId: authContext.currentUser.jobSourceId };

    if (periodType.value === TYPE_WEEKLY) {
      condition.startDate = moment(monday).format("YYYY-MM-DD");
      condition.endDate = moment(monday).endOf("isoWeek").format("YYYY-MM-DD");
    } else {
      condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
      condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
    }

    if (selectedTopic) {
      condition.toolBoxTalkTopicId = selectedTopic;
    }

    reportsApi
      .getSafetyReport(condition)
      .then((result) => {
        setData(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    periodType,
    monday,
    customEndDate,
    customStartDate,
    authContext.currentUser.jobSourceId,
    selectedTopic,
  ]);

  useEffect(() => {
    if (pivot?.current?.flexmonster && data?.length) {
      pivot.current.flexmonster.on("celldoubleclick", function (cell) {
        reportsHelper.navigateToWorkOrder(
          cell,
          data,
          NAME_COLUMN,
          null,
          navigate
        );
      });
    }
  }, [pivot, data, navigate]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-end">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : null}
              <div>
                <SelectWrapper
                  entity="toolbox topic"
                  formatItemFunction={(item) => ({
                    value: item.id,
                    label: item.name,
                  })}
                  fetchFunction={toolBoxTalkApi.toolBoxTalkTopics}
                  onSelected={(item) => {
                    setSelectedTopic(item?.id);
                  }}
                />
              </div>
              <PeriodTypeSelector
                periodType={periodType}
                setPeriodType={setPeriodType}
              />
              {periodType.value === TYPE_WEEKLY ? (
                <WeekSelector
                  loading={loading}
                  monday={monday}
                  setMonday={setMonday}
                />
              ) : (
                <CustomPeriodSelector
                  defaultEndDate={customEndDate}
                  defaultStartDate={customStartDate}
                  onSubmit={(startDate, endDate) => {
                    setCustomStartDate(startDate);
                    setCustomEndDate(endDate);
                  }}
                />
              )}
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  ref={pivot}
                  key={(monday + periodType.value).toLocaleString()}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SafetyReport;
