import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import { apiHelper } from "../helpers/apiHelper";

export const usageReportApi = {
  getUsageReport: (data) => {
    //todo should have department filter
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/usage-report?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION }, //addes token to headers for auth
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
