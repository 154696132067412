import React, { useEffect, useRef, useState } from "react";
import { Card, CardHeader, CardBody, Col, Container, Row } from "reactstrap";
import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { changeOrdersApi } from "../../../services/changeOrderServices";
import WeekSelector from "../../../components/admin/WeekSelector";
import { reportsHelper } from "../../../helpers/reportsHelper";
import PeriodTypeSelector from "../../../components/admin/PeriodTypeSelector";
import CustomPeriodSelector from "../../../components/admin/CustomPeriodSelector";
import Loader from "../../../components/Loader";
import { useAuth } from "../../../providers/authProvider";

const REPORT_NAME = "Change_Orders_";

const COLUMNS = {
  Date: {
    type: "string",
  },
  "Work Order": {
    type: "string",
  },
  "Customer's name": {
    type: "string",
  },
  "Created By": {
    type: "string",
  },
  Description: {
    type: "string",
  },
  "Total $": {
    type: "number",
  },
  "Requested By": {
    type: "string",
  },
  Status: {
    type: "string",
  },
  "Customer Signature": {
    type: "string",
  },
  "Purchasing Name": {
    type: "string",
  },
  "Purchasing Email": {
    type: "string",
  },
  "Purchasing Phone": {
    type: "string",
  },
  "Customer PO#": {
    type: "string",
  },
  "Quote Description": {
    type: "string",
  },
};

const report = {
  formats: [
    {
      name: "",
      thousandsSeparator: ",",
      decimalPlaces: 2,
    },
  ],
  dataSource: {
    data: [COLUMNS],
  },
  slice: {
    rows: [
      {
        uniqueName: "Date",
      },
      {
        uniqueName: "Work Order",
      },
      {
        uniqueName: "Customer's name",
      },
      {
        uniqueName: "Created By",
      },
      {
        uniqueName: "Description",
      },
      {
        uniqueName: "Total $",
      },
      {
        uniqueName: "Requested By",
      },
      {
        uniqueName: "Status",
      },
      {
        uniqueName: "Customer Signature",
      },
      {
        uniqueName: "Purchasing Name",
      },
      {
        uniqueName: "Purchasing Email",
      },
      {
        uniqueName: "Purchasing Phone",
      },
      {
        uniqueName: "Customer PO#",
      },
      {
        uniqueName: "Quote Description",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Change Orders",
    },
  },
};

const addSignatureToCell = (cell, data) => {
  if (!(data.hierarchy && data.hierarchy.uniqueName === "Customer Signature")) {
    return;
  }

  if (!data.escapedLabel || cell.text === "(blank)") {
    return (cell.text = "No Signature");
  }

  const link = `<a href='${data.escapedLabel}' target='_blank' class='link cursor-pointer'>Click here</a>`;

  if (data.type === "value") {
    cell.style["z-index"] = 1;
    cell.text = link;
  }
};

const TYPE_WEEKLY = "TYPE_WEEKLY";

const ChangeOrdersReport = () => {
  const [authContext] = useAuth();

  const pivot = useRef(null);

  const getFilename = () =>
    reportsHelper.getFileName(
      monday,
      periodType.value === TYPE_WEEKLY,
      REPORT_NAME
    );

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));
  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const [customEndDate, setCustomEndDate] = useState(moment().endOf("isoWeek"));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const condition = { jobSourceId: authContext.currentUser.jobSourceId };
    if (periodType.value === TYPE_WEEKLY) {
      condition.startDate = moment(monday).format("YYYY-MM-DD");
      condition.endDate = moment(monday).endOf("isoWeek").format("YYYY-MM-DD");
    } else {
      condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
      condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
    }
    changeOrdersApi
      .getChangeOrders(condition)
      .then((result) => {
        setLoading(false);
        if (pivot) {
          pivot.current.flexmonster.updateData({
            data: [
              COLUMNS,
              ...result.map((changeOrder) => ({
                Date: utilsHelper.formatDate(
                  changeOrder.createdAt,
                  "MM-DD-YYYY"
                ),
                "Work Order": utilsHelper.getWorkOrderNumberWithGrouped(
                  changeOrder.vwworkOrder
                ),
                "Created By": changeOrder.author
                  ? `${changeOrder.author.lastName + ","} ${
                      changeOrder.author.firstName
                    }`
                  : "N/A",
                Description: changeOrder.description,
                "Total $": changeOrder.total,
                "Requested By": changeOrder.requestedBy,
                Status: changeOrder.statusId,
                "Customer Signature": changeOrder.signatureUrl,
                "Purchasing Name": changeOrder.purchasingContactName,
                "Purchasing Email": changeOrder.purchasingContactEmail,
                "Purchasing Phone": changeOrder.purchasingContactPhone,
                "Customer PO#": changeOrder.customerPO,
                "Quote Description": changeOrder.description,
                "Customer's name": changeOrder.vwworkOrder.customerName,
              })),
            ],
          });
          pivot.current.flexmonster.customizeCell(addSignatureToCell);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    pivot,
    periodType,
    monday,
    customEndDate,
    customStartDate,
    authContext.currentUser.jobSourceId,
  ]);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardHeader className="d-flex align-items-center justify-content-end">
              {loading ? (
                <div className="min-width-50">
                  <Loader size="sm" className="mr-3" />
                </div>
              ) : null}
              <PeriodTypeSelector
                periodType={periodType}
                setPeriodType={setPeriodType}
              />
              {periodType.value === TYPE_WEEKLY ? (
                <WeekSelector
                  loading={loading}
                  monday={monday}
                  setMonday={setMonday}
                />
              ) : (
                <CustomPeriodSelector
                  defaultEndDate={customEndDate}
                  defaultStartDate={customStartDate}
                  onSubmit={(startDate, endDate) => {
                    setCustomStartDate(startDate);
                    setCustomEndDate(endDate);
                  }}
                />
              )}
            </CardHeader>
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="rounded border height-100">
                <FlexmonsterReact.Pivot
                  ref={pivot}
                  //key={(monday + periodType.value).toLocaleString()}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="100%"
                  report={report}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ChangeOrdersReport;
