import React from "react";

import {
  Button,
  Col,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const CommunicationLogModal = ({ log, onClose }) => {
  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return (
    <Modal isOpen={true}>
      <ModalHeader close={closeBtn}>Communication Log Details</ModalHeader>
      <ModalBody>
        <ListGroup>
          <ListGroupItem className="d-flex align-items-center">
            <Col className="px-0" sm="4">
              <Label className="mb-0" for="recipients">
                Recipients:
              </Label>
            </Col>
            <Col className="px-0" sm="8">
              {log.recipients.split(",").map((recipient, index) => (
                <span key={index} className="badge badge badge-secondary">
                  {recipient}
                </span>
              ))}
            </Col>
          </ListGroupItem>
          {log.type === "email" && (
            <>
              <ListGroupItem className="d-flex align-items-center">
                <Col className="px-0" sm="4">
                  <Label className="mb-0" for="subject">
                    Subject:
                  </Label>
                </Col>
                <Col className="px-0" sm="8">
                  <Label className="mb-0" for="subject">
                    {log.subject}
                  </Label>
                </Col>
              </ListGroupItem>
              <ListGroupItem className="d-flex align-items-center">
                <Col className="px-0" sm="4">
                  <Label className="mb-0" for="subject">
                    Reply To Email:
                  </Label>
                </Col>
                <Col className="px-0" sm="8">
                  <span className="badge badge badge-secondary">
                    {log.replyTo}
                  </span>
                </Col>
              </ListGroupItem>
            </>
          )}
          <ListGroupItem className="d-flex">
            <Col className="px-0" sm="4">
              <Label className="mb-0" for="subject">
                Content:
              </Label>
            </Col>
            <Col className="px-0" sm="8">
              <div
                className="text-truncate"
                dangerouslySetInnerHTML={{
                  __html: log.content,
                }}
              />
            </Col>
          </ListGroupItem>
        </ListGroup>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CommunicationLogModal;
