import axios from "axios";

import config from "../config/config";
import { apiHelper } from "../helpers/apiHelper";

export const workOrderDocumentTypesApi = {
  findAll: async () => {
    return axios("/api/work-order-document-types", {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "GET",
    })
      .then((response) => response.data)
      .catch((error) => apiHelper.handleError(error));
  },
};
