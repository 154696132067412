import {
  faChevronDown,
  faChevronRight,
  faExclamationCircle,
  faSignature,
  faDownload,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import { Button, Col, Collapse, Row, Table } from "reactstrap";
import Note from "../../components/admin/Note";
import CrewWorkDayTravelTimes from "../../components/admin/workOrderDetails/CrewWorkDayTravelTimes";
import CrewWorkDayWorkTimes from "../../components/admin/workOrderDetails/CrewWorkDayWorkTimes";
import Expense from "../../components/admin/workOrderDetails/Expense";
import CarouselSlider from "../../components/CarouselSlider";
import ConfirmationModal from "../../components/ConfirmationModal";
import InformationModal from "../../components/InformationModal";
import NoteModal from "../../components/admin/notes/NoteModal";
import Loader from "../../components/Loader";
import TooltipItem from "../../components/TooltipItem";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../providers/workOrderDetailsProvider";
import { workOrdersApi } from "../../services/workOrdersServices";
import { utilsHelper } from "../../helpers/utilsHelper";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useAuth } from "../../providers/authProvider";
import { cashAdvanceApi } from "../../services/cashAdvanceServices";

const NONJOB = "NONJOB";

const STATUS_IN_PROGRESS = 3;
const STATUS_COMPLETED = 4;
const STATUS_READY_FOR_ACCOUNTING = 5;
const STATUS_INVOICED = 6;

const USER_ROLE_SUPERVISOR = 3;

const NEXT_STATUS = {
  [STATUS_IN_PROGRESS]: STATUS_COMPLETED,
  [STATUS_COMPLETED]: STATUS_READY_FOR_ACCOUNTING,
  [STATUS_READY_FOR_ACCOUNTING]: STATUS_INVOICED,
};

const NEXT_STATUS_TEXT = {
  [STATUS_IN_PROGRESS]: "Completed",
  [STATUS_COMPLETED]: "Ready for Accounting",
  [STATUS_READY_FOR_ACCOUNTING]: "Invoiced",
};

const NEXT_STATUS_BUTTON_TEXT = {
  [STATUS_IN_PROGRESS]: "Complete Job",
  [STATUS_COMPLETED]: "Approve for Accounting",
  [STATUS_READY_FOR_ACCOUNTING]: "Set as Invoiced",
};

const WorkOrderJobDetails = () => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();
  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const initConfirmationModal = {
    isOpen: false,
    onSubmit: null,
    onClose: null,
    title: "",
    body: "",
  };

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );
  const [createNoteModal, setCreateNoteModal] = useState(false);

  const [statusLoading, setStatusLoading] = useState();

  const openImageModal = (url, description, selected, createdBy, entity) => {
    if (!url) {
      return setInformationModal({
        isOpen: true,
        title: `${entity} Preview`,
        body: `${entity} not available`,
      });
    }
    if (typeof url === "string") {
      return setInformationModal({
        isOpen: true,
        title: `${entity} Preview`,
        rawBody: true,
        body: (
          <div className="d-flex flex-column text-center">
            <TransformWrapper initialScale={1} minScale={0.5} maxScale={3}>
              {({ zoomIn, zoomOut, ...rest }) => (
                <React.Fragment>
                  <div
                    style={{ position: "absolute", zIndex: 100 }}
                    className="tools"
                  >
                    <Button
                      className="rounded btn-light border"
                      onClick={() => {
                        utilsHelper.downloadFile(url);
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </Button>
                    <Button
                      className="rounded mx-2 btn-light border"
                      onClick={() => zoomOut()}
                    >
                      -
                    </Button>
                    <Button
                      className="rounded btn-light border"
                      onClick={() => zoomIn()}
                    >
                      +
                    </Button>
                  </div>
                  <TransformComponent>
                    <img
                      className="img-fluid rounded"
                      src={url}
                      alt={description}
                    />
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
            <span className="mt-2">{description}</span>
            {createdBy ? <span className="mt-2">{createdBy}</span> : null}
          </div>
        ),
      });
    }

    return setInformationModal({
      isOpen: true,
      title: "Images Preview",
      rawBody: true,
      body: (
        <CarouselSlider
          items={url.map((item, i) => ({
            title: description[i],
            subtitle: createdBy || null,
            url: item,
            selected: i === selected,
          }))}
        />
      ),
    });
  };

  const onApproveNextStatus = () => {
    const statusId = NEXT_STATUS[workOrderDetails.workOrder.statusId];
    setConfirmationModal({
      isOpen: true,
      confirmColor: "warning",
      rawBody: true,
      onSubmit: async () => {
        setConfirmationModal(initConfirmationModal);
        setStatusLoading(true);
        if (statusId === STATUS_COMPLETED) {
          await workOrdersApi.completeWorkOrder({
            ...workOrderDetails.workOrder,
          });
        } else {
          await workOrdersApi.updateWorkOrderStatus({
            ...workOrderDetails.workOrder,
            statusId,
          });
        }
        setStatusLoading(false);
        setWorkOrderDetails({
          action: ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: `Set Work Order to ${
        NEXT_STATUS_TEXT[workOrderDetails.workOrder.statusId]
      }`,
      body: `
        <div>
          <p>This action will change the status of the Work Order.</p>
          <span>Do you want to continue?</span>
        </div>
      `,
    });
  };

  const onDeleteCashAdvance = (id) =>
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: () => {
        submitDeleteCashAdvance(id);
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Cash Advance",
      body: `Are you sure you want to delete this cash advance?`,
    });

  const submitDeleteCashAdvance = async (id) => {
    try {
      await cashAdvanceApi.deleteCashAdvance(id);
      setConfirmationModal(initConfirmationModal);
      setInformationModal({
        isOpen: true,
        title: `Cash Advance`,
        body: `Cash Advance deleted successfully`,
        onClose: () => {
          setInformationModal({ isOpen: false, title: "", body: "" });
          setWorkOrderDetails({
            action: ACTIONS.REFRESH,
          });
        },
      });
    } catch (err) {
      setInformationModal({
        isOpen: true,
        title: `Cash Advance`,
        body:
          err?.response?.data[0]?.msg ||
          "There was an error with your request.",
      });
    }
  };

  //the crew shown is the crew of today plus the people that was on the date
  //the crew lead specified is the one on the date
  //if an employee is duplicated with different role, wins the one that was assigned on the date

  return (
    <Row>
      <Col sm="12">
        <div className="d-flex d-flex-row justify-content-between border-bottom">
          <h4>Job Details</h4>
          <div className="min-width-50">
            {statusLoading ? (
              <Loader size="sm" />
            ) : !utilsHelper.isReadOnly(authContext) &&
              workOrderDetails.workOrder?.workOrderType !== NONJOB &&
              (workOrderDetails.workOrder.statusId === STATUS_COMPLETED ||
                workOrderDetails.workOrder.statusId ===
                  STATUS_READY_FOR_ACCOUNTING ||
                (workOrderDetails.workOrder.statusId === STATUS_IN_PROGRESS &&
                  !utilsHelper.isBetaProd())) ? (
              <Button
                color="secondary"
                size="sm"
                className="rounded"
                onClick={onApproveNextStatus}
              >
                {NEXT_STATUS_BUTTON_TEXT[workOrderDetails.workOrder.statusId]}
              </Button>
            ) : null}
          </div>
        </div>
        {!workOrderDetails.dates ||
        !Object.keys(workOrderDetails.dates).length ? (
          <div className="text-center text-muted mt-4 small">
            No data has been recorded
          </div>
        ) : (
          Object.keys(workOrderDetails.dates)
            .sort(utilsHelper.sortDays)
            .filter((date) => workOrderDetails.dates[date].crewWorkDays.length)
            .map((date) => {
              const crewWorkDays = workOrderDetails.dates[date].crewWorkDays;
              return (
                <div className="mt-3" key={date}>
                  <Button
                    key={date}
                    onClick={() =>
                      setWorkOrderDetails({
                        action: ACTIONS.TOGGLE_DATE,
                        payload: { date },
                      })
                    }
                    style={{ width: "100%" }}
                  >
                    <div className="d-flex flex-row justify-content-between m-1 align-middle">
                      <span>{moment(date).format("MM/DD/YYYY")}</span>
                      <FontAwesomeIcon
                        icon={
                          workOrderDetails.activeDates.has(date)
                            ? faChevronDown
                            : faChevronRight
                        }
                        fixedWidth
                        className="my-1"
                      />
                    </div>
                  </Button>
                  <Collapse
                    isOpen={workOrderDetails.activeDates.has(date)}
                    className="p-2"
                  >
                    {crewWorkDays
                      .sort((a, b) =>
                        a.shift < b.shift ? -1 : b.shift < a.shift ? 1 : 0
                      )
                      .map((crewWorkDay) => {
                        const supervisorEmployee =
                          utilsHelper.getSupervisorEmployee(
                            crewWorkDay.employees
                          );
                        const supervisor = supervisorEmployee
                          ? `${supervisorEmployee?.employee.firstName} ${supervisorEmployee?.employee.lastName}`
                          : "No Supervisor Assigned";
                        return (
                          <div
                            className="col-12 mt-2 border px-0 rounded"
                            key={crewWorkDay.id}
                          >
                            <div className="py-2 d-flex justify-content-between align-items-center bg-tertiary col-12">
                              <div className="d-flex align-items-center col-3 px-0">
                                <small className="text-white px-1 border border-white rounded font-weight-bold">
                                  {utilsHelper.outageLabels(crewWorkDay.outage)}
                                </small>
                                <small className="text-white px-1 border border-white rounded font-weight-bold ml-2">
                                  {crewWorkDay.completed
                                    ? "Day Closed"
                                    : "Day Open"}
                                </small>
                              </div>
                              <div className="font-weight-bold mb-0 col-6 px-0 text-center text-white">
                                {utilsHelper.capitalize(crewWorkDay.shift)} Crew
                              </div>
                              <div className="d-flex align-items-center col-3 px-0 justify-content-end">
                                <small className="text-white text-truncate px-1 border border-white rounded font-weight-bold">
                                  {supervisor}
                                </small>
                              </div>
                            </div>
                            <CrewWorkDayWorkTimes crewWorkDay={crewWorkDay} />
                            <CrewWorkDayTravelTimes crewWorkDay={crewWorkDay} />
                            {crewWorkDay.perDiems.length > 0 && (
                              <div className="d-flex flex-column">
                                <Col className="col-12 px-0">
                                  <div className="h5 px-3 py-3 bg-lighter border-top border-bottom mb-0 text-center">
                                    CASH ADVANCE
                                  </div>
                                  <Table className="mb-0 border">
                                    <tbody>
                                      {!crewWorkDay.perDiems.length ? (
                                        <tr>
                                          <td className="small text-muted d-flex flex-row justify-content-center">
                                            No Cash Advances Recorded
                                          </td>
                                        </tr>
                                      ) : null}
                                      {crewWorkDay.perDiems.map((perDiem) => (
                                        <tr key={perDiem.id}>
                                          <td className="d-flex flex-row align-items-center border-top-0 border-bottom">
                                            <Button
                                              color="link"
                                              className="pl-0"
                                              onClick={() =>
                                                openImageModal(
                                                  perDiem.signatureUrl,
                                                  `${perDiem.employee.firstName} ${perDiem.employee.lastName}`,
                                                  null,
                                                  null,
                                                  "Signature"
                                                )
                                              }
                                            >
                                              <FontAwesomeIcon
                                                icon={faSignature}
                                              />
                                            </Button>
                                            {perDiem.perDiemAuthor &&
                                            perDiem.perDiemAuthor.userRoleId !==
                                              USER_ROLE_SUPERVISOR ? (
                                              <TooltipItem
                                                className="mr-2"
                                                id={`${perDiem.id}-tooltip`}
                                                title={`Created by ${`${perDiem.perDiemAuthor.firstName} ${perDiem.perDiemAuthor.lastName}`}`}
                                              >
                                                <FontAwesomeIcon
                                                  size="lg"
                                                  icon={faExclamationCircle}
                                                  className={`text-warning`}
                                                  style={{
                                                    paddingBottom: "1px",
                                                  }}
                                                />
                                              </TooltipItem>
                                            ) : null}
                                            <span>
                                              {`${perDiem.employee.firstName} ${perDiem.employee.lastName}`}
                                            </span>
                                          </td>
                                          <td className="text-success align-center text-right border-top-0 border-bottom">
                                            {utilsHelper.formatCurrency(
                                              perDiem.amount
                                            )}
                                            <FontAwesomeIcon
                                              onClick={() =>
                                                onDeleteCashAdvance(perDiem.id)
                                              }
                                              icon={faTrash}
                                              className="text-danger cursor-pointer ml-2"
                                            />
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </Col>
                                <Col className="col-12 px-0">
                                  <div className="h5 px-3 py-3 bg-lighter border-top border-bottom mb-0 text-center">
                                    EXPENSES
                                  </div>
                                  <div className="d-flex flex-wrap">
                                    {crewWorkDay.expenses.map((expense) => (
                                      <Expense
                                        key={expense.id}
                                        expense={expense}
                                      />
                                    ))}
                                  </div>
                                </Col>
                              </div>
                            )}
                            {crewWorkDay.attachments.length > 0 && (
                              <>
                                <div className="h5 px-3 py-3 bg-lighter border-top border-bottom mb-0">
                                  PHOTOS
                                </div>
                                <div className="p-3">
                                  <Row>
                                    {crewWorkDay.attachments.map(
                                      (attachment, i) => {
                                        const createdBy =
                                          attachment.attachmentAuthor &&
                                          attachment.attachmentAuthor
                                            .userRoleId !== USER_ROLE_SUPERVISOR
                                            ? `Created by ${`${attachment.attachmentAuthor.firstName} ${attachment.attachmentAuthor.lastName}`}`
                                            : null;
                                        return (
                                          <Col
                                            key={attachment.id}
                                            xs={6}
                                            md={2}
                                            className="mb-1 mt-1"
                                          >
                                            <div
                                              onClick={() =>
                                                openImageModal(
                                                  crewWorkDay.attachments.map(
                                                    (a) => a.url
                                                  ),
                                                  crewWorkDay.attachments.map(
                                                    (a) => a.description
                                                  ),
                                                  i,
                                                  createdBy,
                                                  "Attachment"
                                                )
                                              }
                                              className="rounded border"
                                              style={{
                                                backgroundPosition: "center",
                                                backgroundImage: `url(${attachment.url})`,
                                                backgroundSize: "cover",
                                                backgroundRepeat: "no-repeat",
                                                cursor: "pointer",
                                                height: "80px",
                                              }}
                                            ></div>
                                          </Col>
                                        );
                                      }
                                    )}
                                  </Row>
                                </div>
                              </>
                            )}
                            <Col className="col-12 px-0">
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="py-2 bg-lighter border-top border-bottom mb-0 d-flex w-100 d-flex align-items-center">
                                  <div className="col-4 h5 mb-0">NOTES</div>
                                  <div className="col-8 d-flex align-items-center justify-content-end">
                                    {!utilsHelper.isReadOnly(authContext) ? (
                                      <Button
                                        className="rounded"
                                        size="sm"
                                        color="success"
                                        onClick={() => {
                                          setCreateNoteModal(crewWorkDay.id);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faPlus}
                                          className="mr-1 text-white"
                                        />
                                        Add Note
                                      </Button>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 d-flex justify-content-between align-items-start flex-wrap border-top border-bottom">
                                {crewWorkDay.notes.length ? (
                                  crewWorkDay.notes.map((note) => (
                                    <Note key={note.id} note={note} />
                                  ))
                                ) : (
                                  <div className="col-12 d-flex justify-content-center align-items-start small text-muted py-2">
                                    No Notes Recorded
                                  </div>
                                )}
                              </div>
                            </Col>
                          </div>
                        );
                      })}
                  </Collapse>
                </div>
              );
            })
        )}
        {confirmationModal.isOpen ? (
          <ConfirmationModal {...confirmationModal} />
        ) : informationModal.isOpen ? (
          <InformationModal
            title={informationModal.title}
            body={informationModal.body}
            rawBody={informationModal.rawBody}
            onClose={() =>
              informationModal.onClose
                ? informationModal.onClose()
                : setInformationModal({
                    isOpen: false,
                    title: "",
                    body: "",
                    onClose: null,
                  })
            }
          />
        ) : createNoteModal ? (
          <NoteModal
            crewWorkDayId={createNoteModal}
            onSubmit={() => {
              setCreateNoteModal(false);
              setInformationModal({
                isOpen: true,
                title: "Create Note",
                body: "Note Created Succesfully",
                onClose: () => {
                  setWorkOrderDetails({
                    action: ACTIONS.REFRESH,
                  });
                },
              });
            }}
            onClose={() => {
              setCreateNoteModal(false);
            }}
          />
        ) : null}
      </Col>
    </Row>
  );
};
export default WorkOrderJobDetails;
