import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";

import { Badge, Button, Table } from "reactstrap";
import {
  ACTIONS,
  useWorkOrderDetails,
} from "../../../providers/workOrderDetailsProvider";
import InformationModal from "../../InformationModal";
import TravelTimesEdit from "../TravelTimesEdit";
import { utilsHelper } from "../../../helpers/utilsHelper";
import { useAuth } from "../../../providers/authProvider";

const STATUS_INVOICED = 6;
const NONJOB = "NONJOB";

const getEmployeeTimes = (employeeId, travelTimes) => {
  const employeeTimes = travelTimes.filter(
    (item) => item.workOrder && item.employeeId === employeeId
  );
  return {
    hours: employeeTimes.reduce((p, c) => p + parseFloat(c.hours), 0),
    mileage: employeeTimes.reduce((p, c) => p + parseFloat(c.mileage), 0),
    actualHours: employeeTimes.reduce(
      (p, c) => p + parseFloat(c.actualHours),
      0
    ),
    actualMileage: employeeTimes.reduce(
      (p, c) => p + parseFloat(c.actualMileage),
      0
    ),
  };
};

const getEmployeeTimesPerWo = (employeeId, travelTimes) => {
  const employeeTimes = travelTimes.filter(
    (item) => item.workOrder && item.employeeId === employeeId
  );

  const employeeTimesPerWo = employeeTimes.reduce((p, c) => {
    const key = c.workOrder.workOrderNumber || "Uncoded Time";
    p[key] = [...(p[key] || []), c];
    return p;
  }, {});

  Object.keys(employeeTimesPerWo).forEach((key) => {
    employeeTimesPerWo[key] = {
      notes: employeeTimesPerWo[key].length
        ? employeeTimesPerWo[key][0].notes
          ? employeeTimesPerWo[key][0].notes.trim()
          : null
        : null,
      hours: employeeTimesPerWo[key].reduce(
        (p, c) => p + parseFloat(c.hours),
        0
      ),
      mileage: employeeTimesPerWo[key].reduce(
        (p, c) => p + parseFloat(c.mileage),
        0
      ),
      actualHours: employeeTimesPerWo[key].reduce(
        (p, c) => p + parseFloat(c.actualHours),
        0
      ),
      actualMileage: employeeTimesPerWo[key].reduce(
        (p, c) => p + parseFloat(c.actualMileage),
        0
      ),
    };
  });

  return employeeTimesPerWo;
};

const EmployeeCrewRow = ({ employeeCrew, crewWorkDay }) => {
  const [authContext] = useAuth();
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const IS_READ_ONLY =
    workOrderDetails?.workOrder?.statusId === STATUS_INVOICED ||
    utilsHelper.isReadOnly(authContext);

  const employeeTimes = getEmployeeTimes(
    employeeCrew.employee.id,
    crewWorkDay.travelTimes
  );
  const employeeTimesPerWo = getEmployeeTimesPerWo(
    employeeCrew.employee.id,
    crewWorkDay.travelTimes
  );

  const [editTravelTimeModal, setEditTravelTimeModal] = useState({
    isOpen: false,
  });

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const hasRestDay = employeeCrew.role.hasRestDay;
  const isRestDay =
    hasRestDay &&
    crewWorkDay.employeeRestDays.find(
      (employeeRestDay) =>
        employeeRestDay.employeeId === employeeCrew.employee.id
    );

  const noTimeRecorded =
    !employeeTimes.hours &&
    !employeeTimes.mileage &&
    !employeeTimes.actualHours &&
    !employeeTimes.actualMileage;

  const leadEmployeeCrew = utilsHelper.getSupervisorEmployee(
    crewWorkDay.employees
  );

  return (
    <>
      <tr
        className={`${
          noTimeRecorded || isRestDay ? "text-muted" : "bg-lightblue"
        }`}
      >
        <td>
          <div className="d-flex flex-column align-items-start">
            {utilsHelper.getEmployeeLabelWithPreferredName(
              employeeCrew.employee
            )}{" "}
            {!employeeCrew.employee.isActive ? "(Inactive)" : ""}
            <div className="text-muted small d-flex align-items-center">
              {employeeCrew.employee.id === leadEmployeeCrew?.employee.id ? (
                <Badge className="mr-1" color="primary">
                  Crew Lead
                </Badge>
              ) : null}
              <Badge className="mr-1">{employeeCrew.role.name}</Badge>
              {employeeCrew.isNotActiveButHasData ? (
                <Badge color="warning">Not in Crew</Badge>
              ) : null}
            </div>
          </div>
        </td>
        <td>
          <div className="d-flex justify-content-end align-items-center">
            {isRestDay ? (
              <small className="text-muted text-uppercase font-weight-bold">
                {employeeCrew.role.name} Rest Day
              </small>
            ) : (
              <div className="d-flex flex-column small text-right">
                {workOrderDetails.workOrder.workOrderType !== NONJOB ? (
                  <div>
                    <span className="font-weight-bold">Customer:</span>
                    <span className="ml-2">
                      {employeeTimes.hours.toFixed(1)} /{" "}
                      {utilsHelper.formatMileage(employeeTimes.mileage, 1)}
                    </span>
                  </div>
                ) : null}
                <div>
                  {workOrderDetails.workOrder.workOrderType !== NONJOB ? (
                    <span className="font-weight-bold">Actual:</span>
                  ) : null}
                  <span className="ml-2">
                    {employeeTimes.actualHours.toFixed(1)} /{" "}
                    {utilsHelper.formatMileage(employeeTimes.actualMileage, 1)}
                  </span>
                </div>
              </div>
            )}
            {!IS_READ_ONLY &&
              Object.values(employeeTimes).some((value) => value !== 0) && (
                <Button
                  size="sm"
                  className="rounded d-flex align-items-center ml-3"
                  color={"warning"}
                  onClick={() =>
                    setEditTravelTimeModal({
                      isOpen: true,
                    })
                  }
                  disabled={!employeeCrew.employee.isActive}
                >
                  <FontAwesomeIcon size="sm" icon={faPen} className="mr-1" />
                  <small>Edit</small>
                </Button>
              )}
          </div>
        </td>
      </tr>
      {Object.keys(employeeTimesPerWo).length && !isRestDay
        ? Object.keys(employeeTimesPerWo).map((woNumber) => (
            <tr key={woNumber} className="small">
              <td
                className={`px-4 ${
                  woNumber === "Uncoded Time" ? "text-warning" : ""
                }`}
              >
                {woNumber}
              </td>
              <td className="px-4 text-right">
                <div className="d-flex flex-column align-items-end">
                  <div>
                    {employeeTimesPerWo[woNumber].hours.toFixed(1)} /{" "}
                    {utilsHelper.formatMileage(
                      employeeTimesPerWo[woNumber].mileage,
                      1
                    )}
                  </div>
                  {employeeTimesPerWo[woNumber].notes ? (
                    <div
                      className="text-muted text-right px-2 py-1 rounded mt-1"
                      style={{ background: "#FDF1DB" }}
                    >
                      {employeeTimesPerWo[woNumber].notes}
                    </div>
                  ) : null}
                </div>
              </td>
            </tr>
          ))
        : null}
      {editTravelTimeModal.isOpen ? (
        <TravelTimesEdit
          crewWorkDay={crewWorkDay}
          employeeCrew={employeeCrew}
          onClose={() => {
            setEditTravelTimeModal({
              isOpen: false,
            });
          }}
          onSubmit={() => {
            setEditTravelTimeModal({
              isOpen: false,
            });
            setInformationModal({
              isOpen: true,
              title: "Update Travel Times",
              body: "Travel times updated successfully",
              onClose: () =>
                setWorkOrderDetails({
                  action: ACTIONS.REFRESH,
                }),
            });
          }}
        />
      ) : informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          onClose={() =>
            informationModal.onClose
              ? informationModal.onClose()
              : setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </>
  );
};

const CrewWorkDayTravelTimes = ({ crewWorkDay }) => {
  const isNotActiveButHasData = (crewWorkDay, e) =>
    !utilsHelper.isEmployeeCrewActiveOnDate(e, crewWorkDay.date, false) &&
    crewWorkDay.travelTimes.find((tt) => tt.employeeId === e.id);

  const isNotActiveOrHasData = (crewWorkDay, e) =>
    utilsHelper.isEmployeeCrewActiveOnDate(e, crewWorkDay.date, false) ||
    crewWorkDay.travelTimes.find((tt) => tt.employeeId === e.id);

  const allCrew = Object.values(
    crewWorkDay.employees
      .filter((e) => isNotActiveOrHasData(crewWorkDay, e))
      .reduce((p, c) => {
        c.isNotActiveButHasData = isNotActiveButHasData(crewWorkDay, c);
        if (!p[c.employee.id] || c.activeOnDate) {
          p[c.employee.id] = c;
        }
        return p;
      }, {})
  );

  return (
    <div key={crewWorkDay.id} className="d-flex">
      <Table className="col-12 px-0 mb-0 border">
        <thead>
          <tr className="bg-lighter">
            <th>
              <div className="d-flex flex-column">
                <span>CREW</span>
              </div>
            </th>
            <th className="text-right align-middle">TRAVEL TIME / MILEAGE</th>
          </tr>
        </thead>
        <tbody>
          {allCrew.length ? (
            !crewWorkDay.travelTimes?.length &&
            !crewWorkDay.employeeRestDays?.length ? (
              <tr>
                <td colSpan={4} className="p-2 small text-muted text-center">
                  No Travel Time Recorded
                </td>
              </tr>
            ) : (
              utilsHelper.sortCrew(allCrew).map((employeeCrew) => {
                return (
                  <EmployeeCrewRow
                    key={employeeCrew.id}
                    employeeCrew={employeeCrew}
                    crewWorkDay={crewWorkDay}
                  />
                );
              })
            )
          ) : (
            <td colSpan={4} className="p-2 small text-muted text-center">
              Empty Crew
            </td>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default CrewWorkDayTravelTimes;
