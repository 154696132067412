import React from "react";
import { Badge } from "reactstrap";
import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";
import { utilsHelper } from "../../../helpers/utilsHelper";

const COMPANY_AH_MH = "AH/MH";
const COMPANY_AH_MH_ID = 1;

const COMPANY_APC = "APC";
const COMPANY_APC_ID = 2;

const WO_TYPE_GROUP = "group";

const JOB_SOURCES = {
  [COMPANY_AH_MH_ID]: COMPANY_AH_MH,
  [COMPANY_APC_ID]: COMPANY_APC,
};

const NonJobProjectInfo = () => {
  const [workOrderDetails] = useWorkOrderDetails();

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex flex-column align-items-start">
        <h4 className="mb-1">
          {workOrderDetails.workOrder.description ||
            "No description name provided"}
        </h4>
        <Badge>{workOrderDetails.workOrder.status}</Badge>
      </div>
      <div className="d-flex flex-column align-items-end">
        <h4 className="mb-1">
          <span hidden={workOrderDetails.workOrder.type === WO_TYPE_GROUP}>
            {workOrderDetails.workOrder.workOrderNumber}
          </span>
          <span
            hidden={workOrderDetails.workOrder.type === WO_TYPE_GROUP}
            className="mx-2"
          >
            -
          </span>
          <span>{JOB_SOURCES[workOrderDetails.workOrder.jobSourceId]}</span>
          <span
            hidden={workOrderDetails.workOrder.type === WO_TYPE_GROUP}
            className="mx-2"
          >
            -
          </span>
          <span>Non Job</span>
        </h4>
        <div>
          <span>Dates:</span>
          <span className="text-muted ml-2">
            <Badge color="success">
              {workOrderDetails.workOrder?.startDate
                ? utilsHelper.formatDate(
                    workOrderDetails.workOrder.startDate,
                    "MM/DD/YYYY"
                  )
                : "N/A"}
            </Badge>
            {" - "}
            <Badge color="success">
              {workOrderDetails.workOrder?.endDate
                ? utilsHelper.formatDate(
                    workOrderDetails.workOrder.endDate,
                    "MM/DD/YYYY"
                  )
                : "N/A"}
            </Badge>
          </span>
        </div>
      </div>
    </div>
  );
};

export default NonJobProjectInfo;
