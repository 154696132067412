import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import DaysGraphic from "../../components/admin/graphics/DaysGraphic";
import HoursGraphic from "../../components/admin/graphics/HoursGraphic";
import { workOrdersApi } from "../../services/workOrdersServices";
import Loader from "../../components/Loader";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";

const MAX_PAGE_SIZE = 999;

const WorkOrderBudget = () => {
  const [workOrderDetails] = useWorkOrderDetails();

  const [loading, setLoading] = useState();
  const [jobTotalHours, setJobTotalHours] = useState();
  const [jobDayHours, setJobDayHours] = useState();

  useEffect(() => {
    setLoading(true);
    Promise.all([
      workOrdersApi.getWorkOrderWorkTimes({
        workOrderId: workOrderDetails.workOrder.id,
        pageSize: MAX_PAGE_SIZE,
      }),
      workOrdersApi.getWorkOrderLaborSummary({
        workOrderId: workOrderDetails.workOrder.id,
      }),
    ])
      .then(([{ data: workOrderWorkTimes }, workOrderLaborSummary]) => {
        let map = {};

        if (workOrderWorkTimes && workOrderWorkTimes.length > 0) {
          workOrderWorkTimes.forEach((item) => {
            const day = item.crewWorkDay.date;
            const hours = item.hours;
            const type = item.type;
            if (!map[day]) {
              map[day] = {
                hours: 0,
                regular: 0,
                overtime: 0,
                mileage: 0,
              };
            }
            map[day].hours += hours;
            map[day][type] += hours;
          });

          setJobDayHours(map);
        }
        if (workOrderLaborSummary && workOrderLaborSummary.length > 0) {
          let totalTracked = 0;
          let regularTracked = 0;
          let overtimeTracked = 0;
          const { total, overtime, regular } = workOrderLaborSummary.reduce(
            (p, c) => {
              p.total += c.totalHours;
              p.overtime += c.overtimeHours;
              p.regular += c.regularHours;
              return p;
            },
            { total: 0, overtime: 0, regular: 0 }
          );

          Object.keys(map).forEach((key) => {
            totalTracked += map[key].hours;
            regularTracked += map[key].regular;
            overtimeTracked += map[key].overtime;
          });

          setJobTotalHours({
            total,
            regular,
            overtime,
            totalTracked,
            regularTracked,
            overtimeTracked,
          });
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [workOrderDetails.workOrder.id]);

  return (
    <Row>
      <Col sm="12">
        <h4 className="border-bottom pb-2">Job Budget</h4>
        {loading ? (
          <div className="mt-4">
            <Loader size="sm" />
          </div>
        ) : jobTotalHours && jobDayHours ? (
          <Row>
            <Col className="col-lg-12 mt-3 col-xl-6">
              <h5 className="text-center">Total Job Hours</h5>
              <HoursGraphic totals={jobTotalHours} />
            </Col>
            <Col className="col-lg-12 mt-3 col-xl-6">
              <h5 className="text-center">Total Hours by Day</h5>
              <DaysGraphic days={jobDayHours} />
            </Col>
          </Row>
        ) : (
          <div className="text-center text-muted mt-4 small">
            No data has been recorded
          </div>
        )}
      </Col>
    </Row>
  );
};
export default WorkOrderBudget;
