import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { expensesApi } from "../../services/expensesServices";
import InformationModal from "../InformationModal";
import CarouselSlider from "../CarouselSlider";
import { utilsHelper } from "../../helpers/utilsHelper";
import ConfirmationModal from "../ConfirmationModal";

const initConfirmationModal = {
  isOpen: false,
  onSubmit: null,
  onClose: null,
  title: "",
  body: "",
};

const ExpensePhotosModal = ({ expense, onClose, onDeleteImageSuccess }) => {
  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
    onClose: null,
  });

  const [confirmationModal, setConfirmationModal] = useState(
    initConfirmationModal
  );

  const onDeleteImage = (attachment) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        try {
          await expensesApi.deleteExpensesAttachment(attachment);
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Expenses Image",
            body: "Expense Image deleted successfully",
            onClose: () => {
              setInformationModal({ isOpen: false, title: "", body: "" });
              onDeleteImageSuccess();
            },
          });
        } catch (err) {
          setConfirmationModal(initConfirmationModal);
          setInformationModal({
            isOpen: true,
            title: "Expense Image",
            body:
              err?.response?.data[0]?.msg ||
              "There was an error with your request.",
          });
        }
      },
      onClose: () => {
        setConfirmationModal(initConfirmationModal);
      },
      title: "Remove Image",
      body: `Are you sure you want to delete this image?`,
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      rawBody={informationModal.rawBody}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : confirmationModal.isOpen ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader close={closeBtn}>Expense's Photos</ModalHeader>
      <ModalBody>
        {expense.attachmentUrl ? (
          <TransformWrapper initialScale={1} minScale={0.5} maxScale={3}>
            {({ zoomIn, zoomOut, ...rest }) => (
              <React.Fragment>
                <div
                  style={{ position: "absolute", zIndex: 100 }}
                  className="tools"
                >
                  <Button
                    className="rounded btn-light"
                    onClick={() => {
                      utilsHelper.downloadFile(expense.attachmentUrl);
                    }}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <Button
                    className="rounded mx-2 btn-light"
                    onClick={() => zoomOut()}
                  >
                    -
                  </Button>
                  <Button
                    className="rounded btn-light"
                    onClick={() => zoomIn()}
                  >
                    +
                  </Button>
                </div>
                <TransformComponent>
                  <img
                    alt="Expense Attachment"
                    className="w-100 rounded"
                    src={expense.attachmentUrl}
                  />
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
        ) : (
          <CarouselSlider
            onDelete={onDeleteImage}
            items={expense.expenseAttachments.map((item, i) => ({
              id: item.id,
              title: `Photo ${i + 1}`,
              url: item.attachmentUrl,
            }))}
          />
        )}
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <Button color={"primary"} onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ExpensePhotosModal;
