import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import { apiHelper } from "../helpers/apiHelper";

export const scheduleApi = {
  getWorkOrderSchedule: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/work-orders/job-schedule?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION }, //addes token to headers for auth
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getScheduleWorkOrdersInfo: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/work-orders/crew-info?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION }, //addes token to headers for auth
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getSupervisorSchedule: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/supervisors?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION }, //addes token to headers for auth
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  getCrewSchedule: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/crew-schedules?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION }, //addes token to headers for auth
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
