import Moment from "moment";
import { extendMoment } from "moment-range";
import { AssignmentField } from "@bryntum/gantt";
import { utilsHelper } from "./utilsHelper";
import { vehicleApi } from "../services/vehicleServices";

const POST = "POST";
const PRE = "PRE";

const SORTING_OUTAGE = 2;
const SORTING_POST = 3;
const SORTING_PRE = 1;

const moment = extendMoment(Moment);

const calculateCrewSize = (schedule, crewInfo) => {
  const woCrewInfo = crewInfo.find((ci) => ci.id === schedule.id);
  if (!woCrewInfo) {
    return {};
  }
  const crewSize = woCrewInfo.crewWorkOrders.reduce(
    (prev, current) => {
      const currentCrew = current.crew.employeeCrews
        .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec))
        .reduce(
          (p, c) => {
            const isSupervisor = c.isCrewLead;
            return {
              supervisors: isSupervisor ? p.supervisors + 1 : p.supervisors,
              members: isSupervisor ? p.members : p.members + 1,
            };
          },
          { supervisors: 0, members: 0 }
        );
      return {
        supervisors: prev.supervisors + currentCrew.supervisors,
        members: prev.members + currentCrew.members,
      };
    },
    { supervisors: 0, members: 0 }
  );
  return crewSize;
};

export const jobScheduleHelper = {
  chartConfig: {
    tbar: { type: "gantttoolbar" },
    columns: [
      {
        type: "name",
        field: "customerName",
        text: "Customer",
        cls: "bg-lightgreen text-body",
        editor: false,
      },
      {
        type: "note",
        field: "location",
        text: "Location",
        cls: "bg-lightgreen text-body",
        editor: false,
      },
      {
        type: "note",
        field: "workOrderNumber",
        text: "Work Order #",
        cls: "bg-lightgreen text-body",
        editor: false,
      },
      {
        type: "note",
        field: "quoteNumber",
        text: "Quote Number/Estimator",
        cls: "bg-lightgreen text-body",
        editor: false,
      },
      {
        type: "note",
        field: "outageLabel",
        text: "Outage",
        cls: "bg-lightgreen text-body",
        editor: false,
      },
      {
        type: "date",
        field: "startDate",
        text: "Start Date",
        cls: "bg-lightgreen text-body",
        editor: false,
      },
      {
        type: "date",
        field: "endDate",
        text: "End Date",
        cls: "bg-lightgreen text-body",
        editor: false,
      },
      {
        type: "note",
        field: "drawName",
        text: "Drawing Name",
        cls: "bg-lightgreen text-body",
        editor: false,
      },
      {
        type: "resourceassignment",
        text: "Trailer / Truck",
        width: 250,
        showAvatars: false,
        itemTpl: (assignment) => assignment.resourceName,
        editor: {
          type: AssignmentField.type,
          chipView: {
            itemTpl: (assignment) => assignment.resourceName,
          },
          picker: {
            unitsColumn: { hidden: true },
            height: 350,
            width: 300,
            features: {
              filterBar: false,
              group: "resource.type",
              headerMenu: false,
              cellMenu: false,
            },
          },
        },
      },
      {
        type: "note",
        field: "customerContact",
        text: "Customer Contact",
        cls: "bg-lightgreen text-body",
        editor: false,
      },
    ],
    labelsFeature: {
      left: {
        field: "name",
        editor: {
          type: "textfield",
        },
      },
    },
    subGridConfigs: {
      locked: {
        flex: 3,
      },
      normal: {
        flex: 4,
      },
    },
    taskRenderer(data) {
      const { taskRecord, renderData } = data;
      const { originalData } = taskRecord;
      renderData.cls.add("small");
      if (originalData.isTentative) {
        renderData.cls.add("bg-danger");
      } else if (originalData.outage && originalData.outage === PRE) {
        renderData.cls.add("bg-tertiary");
      } else if (originalData.outage && originalData.outage === POST) {
        renderData.cls.add("bg-secondary");
      } else if (!taskRecord.children) {
        renderData.cls.add("bg-info");
      }
      if (Math.abs(moment(taskRecord.startDate).diff(taskRecord.endDate)) < 3) {
        return "";
      }
      if (taskRecord.children?.length) {
        renderData.cls.add("bg-success");
        renderData.cls.add("expandable-task");
        return "+ Expand for details";
      }
      return `${originalData.customerName} | #${originalData.workOrderNumber}`;
    },
    taskTooltipFeature: {
      template: (data) => {
        const { taskRecord } = data;
        const { originalData } = taskRecord;
        return `
                    <table>
                        <tr>
                        <td>WorkOrder: </td>
                        <td><strong>${
                          originalData.workOrderNumber
                        }</strong></td>
                        </tr>
                        <tr>
                        <td>Customer: </td>
                        <td colspan="2">${originalData.customerName}</td>
                        </tr>
                        ${
                          originalData.outageLabel !== undefined
                            ? `<tr><td>Outage: </td><td>${originalData.outageLabel}</td></tr>`
                            : ""
                        }
                        <tr>
                        <td>Start: </td>
                        <td>${originalData.startDate}</td>
                        </tr>
                        <tr>
                        <td>End: </td>
                        <td>${originalData.endDate}</td>
                        </tr>
                        <tr>
                        <td>Location: </td>
                        <td>${originalData.location}</td>
                        </tr>
                        <tr>
                        <td>Crew Size: </td>
                        <td>${originalData.crewSize.supervisors} SUP / ${
          originalData.crewSize.members
        } CREW</td>
                        </tr>
                    </table>
                `;
      },
    },
    viewPreset: "weekAndDayLetter",
    barMargin: 10,
    autoHeight: true,
    autoWidth: true,
    indicatorsFeature: { disabled: true },
    baselinesFeature: { disabled: true },
    cellTooltipFeature: { disabled: true },
    columnDragToolbarFeature: { disabled: true },
    columnPickerFeature: { disabled: true },
    contextMenuFeature: { disabled: true },
    criticalPathsFeature: { disabled: true },
    dependenciesFeature: { disabled: true },
    dependencyEditFeature: { disabled: true },
    projectLinesFeature: { disabled: true },
    quickFindFeature: { disabled: true },
    rollupsFeature: { disabled: true },
    rowCopyPasteFeature: { disabled: true },
    rowReorderFeature: { disabled: true },
    sortFeature: { disabled: true },
    taskContextMenuFeature: { disabled: true },
    taskCopyPasteFeature: { disabled: true },
    taskDragFeature: { disabled: true },
    taskDragCreateFeature: { disabled: true },
    taskEditFeature: { disabled: true },
    taskMenuFeature: { disabled: true },
    taskResizeFeature: { disabled: true },
  },
  buildChartData: (schedules, crewInfo) => {
    const map = {};
    schedules.forEach((schedule, index) => {
      const {
        outage,
        dayShiftStartDate,
        dayShiftEndDate,
        nightShiftStartDate,
        nightShiftEndDate,
      } = schedule;

      const parentKey =
        schedule.workOrderNumber +
        schedule.customerName +
        schedule.location +
        schedule.contactName;

      const crewSize = calculateCrewSize(schedule, crewInfo);

      if (!map[parentKey]) {
        map[parentKey] = {
          id: `${schedule.id}_${index}`,
          name: schedule.customerName,
          customerName: schedule.customerName,
          workOrderNumber: schedule.workOrderNumber,
          contactName: schedule.contactName,
          quoteNumber: schedule.quoteNumber,
          drawName: schedule.drawName,
          customerContact: schedule.contactName,
          location: schedule.city,
          isTentative: schedule.isTentative,
          manuallyScheduled: true,
          expanded: true,
          draggable: false,
          outage: "-",
          outageLabel: "-",
          crewSize,
          children: [],
        };
      }

      const startDate = utilsHelper.calculateScheduleStartDate(
        dayShiftStartDate,
        nightShiftStartDate
      );
      const endDate = utilsHelper.calculateScheduleEndDate(
        dayShiftEndDate,
        nightShiftEndDate
      );
      const sort =
        outage === PRE
          ? SORTING_PRE
          : outage === POST
          ? SORTING_POST
          : SORTING_OUTAGE;

      const child = {
        id: `${schedule.id}_${index}_${outage || "NULL"}`,
        name: schedule.customerName,
        customerName: schedule.customerName,
        workOrderNumber: schedule.workOrderNumber,
        contactName: schedule.contactName,
        quoteNumber: schedule.quoteNumber,
        drawName: schedule.drawName,
        customerContact: schedule.contactName,
        location: schedule.city,
        isTentative: schedule.isTentative,
        startDate: utilsHelper.formatDate(startDate),
        endDate: utilsHelper.formatDate(endDate),
        outageLabel: utilsHelper.outageLabels(outage),
        outage,
        sort,
        crewSize,
        manuallyScheduled: true,
        rollup: true,
      };

      map[parentKey].children.push(child);
    });

    const chartData = Object.keys(map).map((key) => {
      map[key].startDate = utilsHelper.formatDate(
        moment.min(map[key].children.map((wo) => moment(wo.startDate)))
      );
      map[key].endDate = utilsHelper.formatDate(
        moment.max(map[key].children.map((wo) => moment(wo.endDate)))
      );

      const { children } = map[key];
      map[key].children = children.sort((a, b) => a.sort - b.sort);

      return map[key];
    });

    return chartData;
  },
  onSaveResources: (editorContext) => {
    const eventId = editorContext._id;
    const added = editorContext.record.project.assignmentStore.added.idMap;
    const oldOnes = editorContext.value.map((old) => old.data.resourceId);
    const values = Object.values(added);
    const woId = eventId.split("_")[0];
    const vehicleIds = values
      .filter((v) => v.data.eventId === eventId)
      .map((a) => a.resource.originalData.id);
    oldOnes.forEach((o) => {
      if (!vehicleIds.includes(o)) {
        vehicleIds.push(o);
      }
    });
    vehicleApi.assignVehicles({
      workOrderId: woId,
      vehicleIds: vehicleIds,
    });
  },
};
