import React from "react";
import { Input } from "reactstrap";
import clockfaceAlignmentEntryImage from "../../../../../assets/img/ClockfaceAlignmentEntry.png";
import valueOffsetImage from "../../../../../assets/img/ValueOffset.png";
import valueAngImage from "../../../../../assets/img/ValueAng.png";
import valueFFImage from "../../../../../assets/img/ValueFF.png";
import valueBFImage from "../../../../../assets/img/ValueBF.png";

const DimensionsClockfaceAlignmentEntry = ({
  inspectionAnswer,
  isEdit,
  inputValue,
  setInputValue,
  withDimensions,
}) => {
  const jsonContent = isEdit
    ? inputValue
    : JSON.parse(inspectionAnswer.content);

  if (!jsonContent) {
    return null;
  }

  return (
    <div className="center-content-layout-alignment-entry">
      <div className="grid-container-alignment-entry">
        <div className="image-container-alignment-entry">
          <img
            src={clockfaceAlignmentEntryImage}
            alt="Clockface Alignment Entry"
            style={{ width: "93%", height: "93%" }}
          />
        </div>
        <div class="text-container-alignment-entry">
          {withDimensions && (
            <>
              <p className="dimensions-label">Dimensions</p>
              <div class="values-alignment-entry">
                <p>A:</p>
                <Input
                  step={0.01}
                  max={9999999999}
                  min={0}
                  type="number"
                  disabled={!isEdit}
                  required
                  className="small-input-alignment-entry"
                  value={parseFloat(jsonContent?.dimensionA || 0)}
                  onChange={(e) =>
                    setInputValue({
                      ...jsonContent,
                      dimensionA: JSON.stringify(parseFloat(e.target.value)),
                    })
                  }
                />
                <p>B:</p>
                <Input
                  step={0.01}
                  max={9999999999}
                  min={0}
                  type="number"
                  disabled={!isEdit}
                  required
                  className="small-input-alignment-entry"
                  value={parseFloat(jsonContent?.dimensionB || 0)}
                  onChange={(e) =>
                    setInputValue({
                      ...jsonContent,
                      dimensionB: JSON.stringify(parseFloat(e.target.value)),
                    })
                  }
                />
                <p>C:</p>
                <Input
                  step={0.01}
                  max={9999999999}
                  min={0}
                  type="number"
                  disabled={!isEdit}
                  required
                  className="small-input-alignment-entry"
                  value={parseFloat(jsonContent?.dimensionC || 0)}
                  onChange={(e) =>
                    setInputValue({
                      ...jsonContent,
                      dimensionC: JSON.stringify(parseFloat(e.target.value)),
                    })
                  }
                />
              </div>
            </>
          )}
          <div class="values-alignment-entry">
            <img
              src={valueOffsetImage}
              alt="Value Offset"
              style={{ width: "19%" }}
            />
            <Input
              step={0.01}
              max={9999999999}
              min={0}
              type="number"
              disabled={!isEdit}
              required
              className="small-input-alignment-entry-values"
              value={parseFloat(jsonContent?.valueOffset || 0)}
              onChange={(e) =>
                setInputValue({
                  ...jsonContent,
                  valueOffset: JSON.stringify(parseFloat(e.target.value)),
                })
              }
            />
            <img
              src={valueAngImage}
              alt="Value Ang"
              style={{ width: "19%", marginLeft: "10px" }}
            />
            <Input
              step={0.01}
              max={9999999999}
              min={0}
              type="number"
              disabled={!isEdit}
              required
              className="small-input-alignment-entry-values"
              value={parseFloat(jsonContent?.valueAng || 0)}
              onChange={(e) =>
                setInputValue({
                  ...jsonContent,
                  valueAng: JSON.stringify(parseFloat(e.target.value)),
                })
              }
            />
          </div>
          <div class="values-alignment-entry">
            <img src={valueFFImage} alt="Value FF" style={{ width: "19%" }} />
            <Input
              step={0.01}
              max={9999999999}
              min={0}
              type="number"
              disabled={!isEdit}
              required
              className="small-input-alignment-entry-values"
              value={parseFloat(jsonContent?.valueFF || 0)}
              onChange={(e) =>
                setInputValue({
                  ...jsonContent,
                  valueFF: JSON.stringify(parseFloat(e.target.value)),
                })
              }
            />
            <img
              src={valueBFImage}
              alt="Value BF"
              style={{ width: "19%", marginLeft: "10px" }}
            />
            <Input
              step={0.01}
              max={9999999999}
              min={0}
              type="number"
              disabled={!isEdit}
              required
              className="small-input-alignment-entry-values"
              value={parseFloat(jsonContent?.valueBF || 0)}
              onChange={(e) =>
                setInputValue({
                  ...jsonContent,
                  valueBF: JSON.stringify(parseFloat(e.target.value)),
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DimensionsClockfaceAlignmentEntry;
