import React from "react";
import { Badge } from "reactstrap";
import { useWorkOrderDetails } from "../../../providers/workOrderDetailsProvider";
import { utilsHelper } from "../../../helpers/utilsHelper";

const COMPANY_AH_MH = "AH/MH";
const COMPANY_AH_MH_ID = 1;

const COMPANY_APC = "APC";
const COMPANY_APC_ID = 2;

const COMPANY__PRECSN = "PRECISION";
const COMPANY__PRECSN_ID = 3;

const WO_TYPE_GROUP = "group";

const JOB_SOURCES = {
  [COMPANY_AH_MH_ID]: COMPANY_AH_MH,
  [COMPANY_APC_ID]: COMPANY_APC,
  [COMPANY__PRECSN_ID]: COMPANY__PRECSN,
};

const WorkOrderInfo = ({ reduced }) => {
  const [workOrderDetails] = useWorkOrderDetails();

  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex justify-content-between">
        <div className="mr-3">
          <div className="d-flex align-items-center mb-2">
            <h4 className="mb-0">
              {workOrderDetails.workOrder.customerName ||
                "No customer name provided"}
            </h4>
            {!reduced ? (
              <Badge className="ml-2">
                {workOrderDetails.workOrder.status}
              </Badge>
            ) : null}
          </div>
          {!reduced ? (
            <div>
              Contact:
              <span className="text-muted ml-1">
                {workOrderDetails.workOrder?.contactName || "N/A"}
              </span>
            </div>
          ) : null}
          <div>
            Address:
            <span className="text-muted ml-1">
              {utilsHelper.workOrderAddress(workOrderDetails.workOrder)}
            </span>
          </div>
          {!reduced ? (
            <div>
              Phone:
              <span className="text-muted ml-1">
                {workOrderDetails.workOrder.phone || "N/A"}
              </span>
            </div>
          ) : null}
          {!reduced ? (
            <div>
              Drawing:
              <span className="text-muted ml-1">
                {workOrderDetails.workOrder.drawName || "N/A"}
              </span>
            </div>
          ) : null}
          {!reduced ? (
            <div>
              Contract Type:
              <span className="text-muted ml-1">
                {workOrderDetails.workOrder.workOrderContractType
                  ?.contractType || "N/A"}
              </span>
            </div>
          ) : null}
        </div>
      </div>
      <div>
        <div className="h4">
          <span hidden={workOrderDetails.workOrder.type === WO_TYPE_GROUP}>
            {workOrderDetails.workOrder.workOrderNumber}
          </span>
          <span
            hidden={workOrderDetails.workOrder.type === WO_TYPE_GROUP}
            className="mx-2"
          >
            -
          </span>
          <span>{JOB_SOURCES[workOrderDetails.workOrder.jobSourceId]}</span>
        </div>
        {!reduced ? (
          <>
            <div hidden={workOrderDetails.workOrder.type === WO_TYPE_GROUP}>
              Quote #:
              <span className="text-muted ml-1">
                {workOrderDetails.workOrder.quoteNumber}
              </span>
            </div>
            <div hidden={workOrderDetails.workOrder.type === WO_TYPE_GROUP}>
              Config #:
              <span className="text-muted ml-1">
                {workOrderDetails.workOrder.configurationNumber}
              </span>
            </div>
            <div hidden={workOrderDetails.workOrder.type === WO_TYPE_GROUP}>
              PSO #:
              <span className="text-muted ml-1">
                {workOrderDetails.workOrder.saleOrderNumber || "N/A"}
              </span>
            </div>
          </>
        ) : null}
        <div>
          <span>Dates:</span>
          <span className="text-muted ml-2">
            <Badge color="success">
              {workOrderDetails.workOrder?.startDate
                ? utilsHelper.formatDate(
                    workOrderDetails.workOrder.startDate,
                    "MM/DD/YYYY"
                  )
                : "N/A"}
            </Badge>
            {" - "}
            <Badge color="success">
              {workOrderDetails.workOrder?.endDate
                ? utilsHelper.formatDate(
                    workOrderDetails.workOrder.endDate,
                    "MM/DD/YYYY"
                  )
                : "N/A"}
            </Badge>
          </span>
        </div>
      </div>
    </div>
  );
};

export default WorkOrderInfo;
