import React, { useState } from "react";
import {
  Carousel,
  CarouselIndicators,
  CarouselItem,
  CarouselCaption,
  CarouselControl,
  Button,
} from "reactstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faDownload } from "@fortawesome/free-solid-svg-icons";
import { utilsHelper } from "../helpers/utilsHelper";
import { useAuth } from "../providers/authProvider";

const CarouselSlider = ({ items, onDelete }) => {
  const [authContext] = useAuth();
  const [selectedIndex, setSelectedIndex] = useState(0);

  return (
    <Carousel
      activeIndex={selectedIndex}
      next={() => setSelectedIndex((selectedIndex + 1) % items.length)}
      previous={() =>
        setSelectedIndex((selectedIndex - 1 + items.length) % items.length)
      }
    >
      <CarouselIndicators
        className="d-none"
        items={items.map((item, i) => ({
          caption: item.title || `Item ${i + 1}`,
          key: i + 1,
          src: item.url,
        }))}
        activeIndex={selectedIndex}
        onClickHandler={(index) => setSelectedIndex(index)}
      />
      {items.map((item, i) => (
        <CarouselItem key={i} className="bg-dark rounded">
          <TransformWrapper initialScale={1} minScale={0.5} maxScale={3}>
            {({ zoomIn, zoomOut }) => (
              <React.Fragment>
                <div
                  style={{ position: "absolute", zIndex: 100 }}
                  className="tools"
                >
                  <Button
                    className="rounded btn-light"
                    onClick={() => {
                      utilsHelper.downloadFile(item.url);
                    }}
                  >
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                  <Button
                    className="rounded mx-2 btn-light"
                    onClick={() => zoomOut()}
                  >
                    -
                  </Button>
                  <Button
                    className="rounded btn-light"
                    onClick={() => zoomIn()}
                  >
                    +
                  </Button>
                </div>
                <TransformComponent>
                  <img
                    alt={item.title || `Item ${i + 1}`}
                    src={item.url}
                    className="rounded"
                  />
                </TransformComponent>
              </React.Fragment>
            )}
          </TransformWrapper>
          {onDelete && !utilsHelper.isReadOnly(authContext) ? (
            <Button
              className="z-1 text-white w-100 rounded-0"
              color="danger"
              onClick={() => onDelete(item)}
            >
              <FontAwesomeIcon
                icon={faTrash}
                className="mr-1 text-withe cursor-pointer"
              />
              Remove
            </Button>
          ) : null}
          <CarouselCaption
            className="p-0"
            captionHeader={item.title || `Item ${i + 1}`}
            captionText={item.subtitle || ""}
          />
        </CarouselItem>
      ))}
      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={() =>
          setSelectedIndex((selectedIndex - 1 + items.length) % items.length)
        }
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={() =>
          setSelectedIndex((selectedIndex + 1) % items.length)
        }
      />
    </Carousel>
  );
};

export default CarouselSlider;
