import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CustomInput,
} from "reactstrap";

import { workOrdersApi } from "../../services/workOrdersServices";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";

import * as FlexmonsterReact from "react-flexmonster";
import moment from "moment";

import { utilsHelper } from "../../helpers/utilsHelper";
import classnames from "classnames";

const LABOR_REPORT_TAB = "1";
const EQUIPMENT_REPORT_TAB = "2";
const WO_TYPE_GROUP = "group";

const EMPLOYEES_REPORT = {
  dataSource: {
    data: [
      {
        "Employee Name": {
          type: "string",
        },
        "Position/ Craft": {
          type: "string",
        },
        Date: {
          type: "string",
        },
        "Days Living": {
          type: "number",
        },
        "Travel Time": {
          type: "number",
        },
        REG: {
          type: "number",
        },
        OT: {
          type: "number",
        },
      },
    ],
  },
  slice: {
    rows: [
      {
        uniqueName: "Employee Name",
      },
      {
        uniqueName: "Position/ Craft",
      },
    ],
    columns: [
      {
        uniqueName: "Date",
      },
    ],
    measures: [
      {
        uniqueName: "Days Living",
        aggregation: "sum",
      },
      {
        uniqueName: "Travel Time",
        aggregation: "sum",
      },
      {
        uniqueName: "REG",
        aggregation: "sum",
      },
      {
        uniqueName: "OT",
        aggregation: "sum",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "classic",
      showTotals: "off",
      showGrandTotals: "on",
      title: "Labor Report",
    },
    showAggregationLabels: false,
  },
};

const EQUIPMENT_REPORT = {
  dataSource: {
    data: [
      {
        Equipment: {
          type: "string",
        },
        Date: {
          type: "string",
        },
        Totals: {
          type: "number",
        },
      },
    ],
  },
  slice: {
    rows: [
      {
        uniqueName: "Equipment",
      },
    ],
    columns: [
      {
        uniqueName: "Date",
      },
    ],
    measures: [
      {
        uniqueName: "Totals",
        aggregation: "sum",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    configuratorActive: false,
    grid: {
      type: "compact",
      showTotals: "off",
      showGrandTotals: "on",
      title: "Equipment Report",
    },
    showAggregationLabels: false,
  },
};

const getFilename = () => `CWR_${moment(Date.now()).format("MMDDYY")}`;

const WorkOrderCWR = () => {
  const pivot = useRef(null);
  const pivotEquipment = useRef(null);

  const [workOrderDetails] = useWorkOrderDetails();

  const [activeTab, setActiveTab] = useState(LABOR_REPORT_TAB);

  const [selectedWorkOrder, setSelectedWorkOrder] = useState();

  useEffect(() => {
    if (workOrderDetails.workOrder.type === WO_TYPE_GROUP) {
      setSelectedWorkOrder();
    } else {
      setSelectedWorkOrder(workOrderDetails.workOrder.id);
    }
  }, [workOrderDetails.workOrder]);

  useEffect(() => {
    if (selectedWorkOrder) {
      workOrdersApi
        .getWorkOrdersDetailsCWR(selectedWorkOrder)
        .then((result) => {
          if (result) {
            if (pivot) {
              pivot.current.flexmonster.updateData({
                data: [
                  {
                    "Employee Name": {
                      type: "string",
                    },
                    "Position/ Craft": {
                      type: "string",
                    },
                    "Days Living": {
                      type: "number",
                    },
                    "Travel Time": {
                      type: "number",
                    },
                    Date: {
                      type: "string",
                    },
                  },
                  ...result.employees,
                ],
              });
            }
            if (pivotEquipment.current) {
              pivotEquipment.current.flexmonster.updateData({
                data: [
                  {
                    Equipment: {
                      type: "string",
                    },
                    Date: {
                      type: "string",
                    },
                  },
                  ...result.equipments,
                ],
              });
            }
          }
        })
        .catch(() => {});
    }
  }, [selectedWorkOrder, pivotEquipment]);

  const toggleTab = (tab) => (activeTab !== tab ? setActiveTab(tab) : null);

  return (
    <div className="d-flex flex-column">
      <Nav
        tabs
        className="d-flex justify-content-between flex-row border-bottom-0 cursor-pointer"
      >
        <div className="d-flex">
          <NavItem>
            <Col sm="12" className="px-0">
              <NavLink
                className={classnames({
                  active: activeTab === LABOR_REPORT_TAB,
                  "font-weight-bold": activeTab === LABOR_REPORT_TAB,
                })}
                onClick={() => {
                  toggleTab(LABOR_REPORT_TAB);
                }}
              >
                Labor Report
              </NavLink>
            </Col>
          </NavItem>
          <NavItem>
            <Col sm="12" className="px-0">
              <NavLink
                className={classnames({
                  active: activeTab === EQUIPMENT_REPORT_TAB,
                  "font-weight-bold": activeTab === EQUIPMENT_REPORT_TAB,
                })}
                onClick={() => {
                  toggleTab(EQUIPMENT_REPORT_TAB);
                  if (pivotEquipment.current) {
                    pivotEquipment.current.flexmonster.setOptions({
                      grid: { type: "classic" },
                    });
                    pivotEquipment.current.flexmonster.refresh();
                  }
                }}
              >
                Equipment Report
              </NavLink>
            </Col>
          </NavItem>
        </div>
        <div>
          {workOrderDetails.workOrder.type === WO_TYPE_GROUP ? (
            <CustomInput
              className="mr-2"
              id="woSelect"
              type="select"
              name="woSelect"
              onChange={(event) =>
                setSelectedWorkOrder(event.currentTarget.value)
              }
              value={selectedWorkOrder}
            >
              <option value={""}>Select a work order</option>
              {workOrderDetails.workOrder.groupedWorkOrders?.map(
                (groupedWorkOrder) => (
                  <option key={groupedWorkOrder.id} value={groupedWorkOrder.id}>
                    {groupedWorkOrder.workOrderNumber}
                  </option>
                )
              )}
            </CustomInput>
          ) : null}
        </div>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={LABOR_REPORT_TAB}>
          <Col sm="12" className="px-0 text-center">
            {selectedWorkOrder ? (
              <div className="w-600 h-100">
                <FlexmonsterReact.Pivot
                  ref={pivot}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="600px"
                  report={EMPLOYEES_REPORT}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivot,
                      getFilename()
                    )
                  }
                />
              </div>
            ) : (
              <span>Please select a work order</span>
            )}
          </Col>
        </TabPane>
        <TabPane tabId={EQUIPMENT_REPORT_TAB}>
          <Col sm="12" className="px-0 text-center">
            {selectedWorkOrder ? (
              <div className="w-600 h-100">
                <FlexmonsterReact.Pivot
                  reportcomplete={() =>
                    utilsHelper.adjustTableSizes(
                      pivotEquipment.current.flexmonster
                    )
                  }
                  ref={pivotEquipment}
                  licenseKey={process.env.REACT_APP_FLEX_M_KEY}
                  toolbar={true}
                  height="600px"
                  report={EQUIPMENT_REPORT}
                  beforetoolbarcreated={(toolbar) =>
                    utilsHelper.customizeToolbarReport(
                      toolbar,
                      pivotEquipment,
                      getFilename()
                    )
                  }
                />
              </div>
            ) : (
              <span>Please select a work order</span>
            )}
          </Col>
        </TabPane>
      </TabContent>
    </div>
  );
};
export default WorkOrderCWR;
