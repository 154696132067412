import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  CustomInput,
  Button,
  Input,
} from "reactstrap";
import AdvanceTable from "../../../components/advanceTable/AdvanceTable";
import AdvanceTableWrapper from "../../../components/advanceTable/AdvanceTableWrapper";

import { commonApi } from "../../../services/commonServices";
import { utilsHelper } from "../../../helpers/utilsHelper";

const LAST_APP_RELEASE_VERSION = "LAST_APP_RELEASE_VERSION";
const PAYROLL_LOCK_SETTING_ENABLED = "PAYROLL_LOCK_SETTING_ENABLED";
const PAYROLL_LOCK_SETTING_DAY = "PAYROLL_LOCK_SETTING_DAY";

const getPayrollLockDate = (value) => {
  return moment(value)
    .tz("America/Chicago") // Alabama is under this timezone
    .set({ weekday: 1, hour: 9, minute: 0, second: 0 })
    .format();
};

const columns = (onChangeSettingStatus, onChangeSettingValue) => [
  {
    accessor: "name",
    Header: "Setting",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      if (rowData.row.original.name === PAYROLL_LOCK_SETTING_DAY) {
        return (
          <div className="d-flex flex-column">
            <span>{PAYROLL_LOCK_SETTING_DAY}</span>
            <small className="text-muted">
              Every{" "}
              {moment().set("day", rowData.row.original.value).format("dddd")}{" "}
              the payroll will be locked for the previous week.
            </small>
            <small className="text-muted">
              The lock will be set until Sunday 23:59.
            </small>
          </div>
        );
      } else if (rowData.row.original.name === PAYROLL_LOCK_SETTING_ENABLED) {
        return (
          <div className="d-flex flex-column">
            <span>{PAYROLL_LOCK_SETTING_ENABLED}</span>
            <small className="text-muted">
              Showing the last lock date & time
            </small>
          </div>
        );
      } else {
        return rowData.row.original.name;
      }
    },
  },
  {
    accessor: "status",
    Header: "Status",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      if (
        rowData.row.original.name === LAST_APP_RELEASE_VERSION ||
        rowData.row.original.name === PAYROLL_LOCK_SETTING_DAY
      ) {
        return null;
      } else {
        return (
          <CustomInput
            checked={rowData.row.original.status}
            onChange={(event) =>
              onChangeSettingStatus(
                rowData.row.original,
                event.currentTarget.checked
              )
            }
            type="switch"
            id={rowData.row.original.id}
            name={rowData.row.original.id}
            label={rowData.row.original.status ? "Enabled" : "Disabled"}
            className="cursor-pointer d-flex align-items-center small setting-switch"
          />
        );
      }
    },
  },
  {
    accessor: "value",
    Header: "Value",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { name, value } = rowData.row.original;
      if (!value) {
        return "N/A";
      }
      if (name === PAYROLL_LOCK_SETTING_ENABLED) {
        if (!utilsHelper.isBetaProd()) {
          return (
            <Input
              type="date"
              id="date"
              onChange={(event) => {
                //set monday 9:00 AM
                const value = getPayrollLockDate(event.currentTarget.value);
                onChangeSettingValue(rowData.row.original, value);
              }}
              value={value ? `${moment(value).format("YYYY-MM-DD")}` : null}
              required
            />
          );
        } else {
          return `${moment(value)
            .tz("America/Chicago")
            .format("YYYY-MM-DD hh:mm")}`;
        }
      } else if (name === PAYROLL_LOCK_SETTING_DAY) {
        return (
          <div className="d-flex align-items-center">
            <CustomInput
              id="payrollLockDaySelect"
              type="select"
              name="payrollLockDaySelect"
              onChange={(event) =>
                onChangeSettingValue(
                  rowData.row.original,
                  event.currentTarget.value
                )
              }
              value={value || ""}
            >
              <option value={""}>Select a day</option>
              {[0, 1, 2, 3, 4, 5, 6].map((day) => (
                <option
                  key={day}
                  value={moment()
                    .startOf("week")
                    .add(day, "day")
                    .format("ddd")
                    .toLowerCase()}
                >
                  {moment().startOf("week").add(day, "day").format("dddd")}
                </option>
              ))}
            </CustomInput>
            <small className="ml-2 flex-shrink-0 text-muted">
              at 9:00 a.m.
            </small>
          </div>
        );
      } else {
        return value;
      }
    },
  },
];

const GeneralSettings = () => {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState([]);
  const [refresh, setRefresh] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await commonApi.getSettings({});
        setSettings(result.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, [refresh]);

  const onChangeSettingStatus = (setting, status) => {
    setLoading(true);
    setting.status = status;

    if (setting.name === PAYROLL_LOCK_SETTING_ENABLED) {
      if (moment(setting.value).day() !== 1) {
        setting.value = getPayrollLockDate(setting.value);
      }
    }
    const newSettings = [...settings];
    newSettings.splice(
      newSettings.findIndex((s) => s.id === setting.id),
      1,
      setting
    );
    setSettings(newSettings);
    commonApi
      .setSetting(setting)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  //only for QA
  const onChangeSettingValue = (setting, value) => {
    setLoading(true);
    setting.value = value;
    const newSettings = [...settings];
    newSettings.splice(
      newSettings.findIndex((s) => s.id === setting.id),
      1,
      setting
    );
    setSettings(newSettings);
    commonApi
      .setSetting(setting)
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <Container fluid className="flex-grow-1 flex-column d-flex">
      {settings.length ? (
        <div className="w-100">
          <AdvanceTableWrapper
            columns={columns(onChangeSettingStatus, onChangeSettingValue)}
            data={settings.filter(
              //temporary hide payroll lock day as it is not being used at the moment
              (setting) => setting.name !== PAYROLL_LOCK_SETTING_DAY
            )}
            pageSize={settings.length}
          >
            <Card className="mb-3 w-100">
              <CardHeader className="d-flex align-items-center justify-content-between mt-2">
                <div className="text-dark flex-grow-1 d-flex align-items-center">
                  <h3 className="mb-0 ">General Settings</h3>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <Button
                    size="sm"
                    className="mr-3 rounded-circle d-flex custom-rounded-button"
                    color="primary"
                    onClick={() => setRefresh(!refresh)}
                  >
                    <FontAwesomeIcon icon={faSync} />
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="d-flex flex-column overflow-x-auto">
                <AdvanceTable
                  table
                  isLoading={loading}
                  headerClassName="text-muted small"
                  tableProps={{
                    striped: true,
                    className: "mb-0",
                  }}
                />
              </CardBody>
            </Card>
          </AdvanceTableWrapper>
        </div>
      ) : null}
    </Container>
  );
};

export default GeneralSettings;
