import React, { useEffect, useRef } from "react";
import * as FlexmonsterReact from "react-flexmonster";
import { utilsHelper } from "../../../../helpers/utilsHelper";

const COLUMNS = {
  "Current Date": {
    type: "string",
  },
  "Work Order Number": {
    type: "string",
  },
  "Start Date": {
    type: "string",
  },
  "End Date": {
    type: "string",
  },
  "Customer Name": {
    type: "string",
  },
  "Shift Date": {
    type: "string",
  },
  Shift: {
    type: "string",
  },
  Description: {
    type: "string",
  },
  "Equipment Name": {
    type: "string",
  },
  Quantity: {
    type: "string",
  },
};

const report = {
  dataSource: {
    data: [COLUMNS],
  },
  slice: {
    rows: [
      {
        uniqueName: "Current Date",
      },
      {
        uniqueName: "Work Order Number",
      },
      {
        uniqueName: "Start Date",
      },
      {
        uniqueName: "End Date",
      },
      {
        uniqueName: "Customer Name",
      },
      {
        uniqueName: "Shift Date",
      },
      {
        uniqueName: "Shift",
      },
      {
        uniqueName: "Description",
      },
      {
        uniqueName: "Equipment Name",
      },
      {
        uniqueName: "Quantity",
      },
    ],
    expands: {
      expandAll: true,
    },
  },
  options: {
    grid: {
      type: "flat",
      showTotals: "off",
      showGrandTotals: "off",
      title: "Work Time Mill Report",
    },
  },
};

const EquipmentFlexMillReport = ({ data }) => {
  const pivot = useRef(null);

  useEffect(() => {
    if (pivot && data) {
      const reportData = (data?.dateShifts || []).reduce(
        (p, c) => [
          ...p,
          ...c.equipment.map((equipment) => ({
            "Current Date": data.currentDate,
            "Work Order Number": data.workOrderNumber,
            "Start Date": utilsHelper.formatDate(data.startDate, "MM/DD/YYYY"),
            "End Date": data.endDate
              ? utilsHelper.formatDate(data.endDate, "MM/DD/YYYY")
              : "-",
            "Customer Name": data.customerName,
            "Shift Date": c.date,
            Shift: c.shiftName,
            Description: c.description,
            "Equipment Name": equipment.equipmentName,
            Quantity: equipment.quantity,
          })),
        ],
        []
      );
      pivot.current.flexmonster.updateData({
        data: [COLUMNS, ...reportData],
      });
    }
  }, [data, pivot]);

  return (
    <div className="rounded border">
      <FlexmonsterReact.Pivot
        ref={pivot}
        licenseKey={process.env.REACT_APP_FLEX_M_KEY}
        toolbar={true}
        height="700px"
        report={report}
      />
    </div>
  );
};

export default EquipmentFlexMillReport;
