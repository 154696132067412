import {
  faBell,
  faPlus,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";

import {
  Button,
  Col,
  Input,
  InputGroup,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledAlert,
} from "reactstrap";

import Badge from "reactstrap/lib/Badge";

import { ACTIONS, useEmployees } from "../../providers/employeesProvider";

import { crewsApi } from "../../services/crewServices";
import { employeesApi } from "../../services/employeeServices";
import { rolesApi } from "../../services/roleServices";
import AdvanceTable from "../advanceTable/AdvanceTable";
import AdvanceTablePagination from "../advanceTable/AdvanceTablePagination";
import AdvanceTableWrapper from "../advanceTable/AdvanceTableWrapper";
import InformationModal from "../InformationModal";
import RoleSelectorModal from "./crewManagement/RoleSelectorModal";
import { utilsHelper } from "../../helpers/utilsHelper";
import { useAuth } from "../../providers/authProvider";

const EMPLOYEE_DEFAULT_TYPE = 1;
const ROLES_MAX_PAGE_SIZE = 999;

const columns = (members, supervisor, onAddEmployee) => [
  {
    accessor: "firstName",
    Header: "Name",
    headerProps: {
      className: "text-truncate text-muted font-size-75 bg-lighter text-left",
    },
    Cell: (rowData) => (
      <div className="text-left">{`${rowData.row.original.firstName} ${rowData.row.original.lastName}`}</div>
    ),
  },
  {
    accessor: "role.name",
    Header: "Role",
    headerProps: {
      className: "text-truncate text-muted font-size-75 bg-lighter",
    },
    Cell: (rowData) => {
      const { role } = rowData.row.original;
      return role?.name || "-";
    },
  },
  {
    accessor: "id",
    Header: "Actions",
    headerProps: { className: "text-truncate text-right" },
    Cell: (rowData) => {
      const { id } = rowData.row.original;
      const isMember =
        members.find((member) => member.id === id) || supervisor?.id === id;
      return (
        <div className="text-right">
          {isMember ? (
            <Badge color="success">Part of the Crew</Badge>
          ) : (
            <Button
              color="secondary"
              size="sm"
              className="rounded"
              onClick={() => onAddEmployee(rowData.row.original, supervisor)}
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              <span>Add to Crew</span>
            </Button>
          )}
        </div>
      );
    },
  },
];

const BaseCrewModal = ({ crewId, onClose, onSubmit }) => {
  const [authContext] = useAuth();

  const [employeesContext, setEmployeesContext] = useEmployees();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [supervisor, setSupervisor] = useState();
  const [members, setMembers] = useState([]);

  const [roleSelectorModal, setRoleSelectorModal] = useState(false);

  const onDismiss = () => setError(false);

  const [roles, setRoles] = useState([]);

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  // Load Roles
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await rolesApi.getEmployeeRoles({
          pageSize: ROLES_MAX_PAGE_SIZE,
          isEnabledForBaseCrew: true,
        });
        setRoles(result.data);
      } catch (err) {
        setError(
          err?.response?.data[0]?.msg || "There was an error with your request."
        );
      }
    };
    fetchData();
  }, []);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const onSubmitAddEmployee = (role, employee) => {
    setMembers((prevMembers) => {
      if (prevMembers.find((member) => member.id === employee.id)) {
        return prevMembers;
      }
      return [
        ...prevMembers,
        {
          id: employee.id,
          name: `${employee.firstName} ${employee.lastName}`,
          role: role,
        },
      ];
    });
  };

  const onRemoveEmployee = (member) => {
    setMembers((prevMembers) => {
      const newMembers = [];
      prevMembers.forEach((prevMember) => {
        if (prevMember.id !== member.id) {
          newMembers.push(prevMember);
        }
      });
      return newMembers;
    });
  };

  const onAddEmployee = async (employee, supervisor) => {
    if (employee.role?.isSuper && !supervisor) {
      return setSupervisor({
        id: employee.id,
        name: `${employee.firstName} ${employee.lastName}`,
        role: employee.role,
      });
    }
    if (
      supervisor?.id === employee.id ||
      members.find((member) => member.id === employee.id)
    ) {
      return setInformationModal({
        isOpen: true,
        title: "Add Employee to base Crew",
        body: `The employee is already part of the Crew.`,
      });
    }
    setRoleSelectorModal({ isOpen: true, employee });
  };

  const onSaveCrew = () => {
    if (!supervisor) {
      return setError("Please add a supervisor.");
    }
    const data = {
      isBase: true,
      employees: members
        .map((member) => ({
          employeeId: member.id,
          roleId: member.role.id,
          isCrewLead: false,
        }))
        .concat({
          employeeId: supervisor.id,
          roleId: supervisor.role.id,
          isCrewLead: true,
        }),
    };
    if (crewId) {
      data.id = crewId;
      crewsApi
        .updateBaseCrew(data)
        .then(() => onSubmit())
        .catch((err) => {
          setError(
            err?.response?.data[0]?.msg ||
              "There was an error with your request."
          );
        });
    } else {
      crewsApi
        .saveBaseCrew(data)
        .then(() => onSubmit())
        .catch((err) => {
          setError(
            err?.response?.data[0]?.msg ||
              "There was an error with your request."
          );
        });
    }
  };

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        const sortBy = data.id;
        const direction = data.desc ? "desc" : "asc";
        if (
          employeesContext.sortBy === sortBy?.id &&
          employeesContext.direction === direction
        ) {
          return;
        }
        setEmployeesContext({
          action: ACTIONS.SORT,
          payload: { sortBy, direction },
        });
      } else {
        setEmployeesContext({
          action: ACTIONS.SORT,
          payload: { sortBy: null, direction: null },
        });
      }
    },
    [employeesContext.direction, employeesContext.sortBy, setEmployeesContext]
  );

  // Load Base Crew
  useEffect(() => {
    const fetchData = async () => {
      try {
        const crew = await crewsApi.getCrews({ id: crewId });
        const employeeCrews = crew.employeeCrews
          .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec))
          .map((employeeCrew) => {
            const { employee, role, isCrewLead } = employeeCrew;
            return {
              id: employee.id,
              name: `${employee.firstName} ${employee.lastName}`,
              role: role,
              isCrewLead,
            };
          });
        const supervisor = employeeCrews.find(
          (employeeCrew) => employeeCrew.isCrewLead
        );
        setSupervisor(supervisor);
        const _members = employeeCrews.filter(
          (employeeCrew) => !employeeCrew.isCrewLead
        );
        setMembers(_members);
      } catch (err) {
        setInformationModal({
          isOpen: true,
          title: `Employee Notes`,
          body:
            err?.response?.data[0]?.msg ||
            "There was an error with your request.",
        });
      }
    };
    if (crewId) {
      fetchData();
    }
  }, [crewId]);

  // Load Employees
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const employees = await employeesApi.getEmployees({
          type: EMPLOYEE_DEFAULT_TYPE,
          search: employeesContext.search,
          page: employeesContext.page - 1,
          pageSize: employeesContext.sizePerPage,
          sortBy: employeesContext.sortBy,
          direction: employeesContext.direction,
          jobSourceId: authContext.currentUser.jobSourceId,
          isActive: true,
        });
        setEmployeesContext({
          action: ACTIONS.GET_EMPLOYEES_SUCCESS,
          payload: { employees },
        });
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };
    fetchData();
  }, [
    setEmployeesContext,
    employeesContext.sortBy,
    employeesContext.direction,
    employeesContext.sizePerPage,
    employeesContext.page,
    employeesContext.search,
    employeesContext.refresh,
    authContext.currentUser.jobSourceId,
  ]);

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        setInformationModal({ isOpen: false, title: "", body: "" })
      }
    />
  ) : roleSelectorModal.isOpen ? (
    <RoleSelectorModal
      availableRoles={roles}
      onSubmit={(asRole) => {
        setRoleSelectorModal({});
        onSubmitAddEmployee(
          roles.find((role) => role.id === asRole),
          roleSelectorModal.employee
        );
      }}
      onClose={() => setRoleSelectorModal({})}
    />
  ) : (
    <Modal isOpen={true} size="xl">
      <ModalHeader className="d-flex justify-content-between" close={closeBtn}>
        {crewId ? "Edit" : "Create"} Base Crew
      </ModalHeader>
      <ModalBody className="text-center">
        <Row>
          <Col sm="12">
            <UncontrolledAlert
              isOpen={!!error}
              color="warning"
              toggle={onDismiss}
            >
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message text-left">
                <span className="ml-2">{error}</span>
              </div>
            </UncontrolledAlert>
          </Col>
        </Row>
        <Row>
          <Col sm="5">
            <ListGroup className="col-12 p-0 text-center">
              {supervisor ? (
                <>
                  <ListGroupItem
                    className="text-underline py-2 border-bottom font-weight-bold text-body bg-lighter text-capitalize"
                    tag="div"
                  >
                    Crew Lead
                  </ListGroupItem>
                  <ListGroupItem>
                    <div className="d-flex justify-content-between text-capitalize">
                      <span>{`${supervisor.name}`}</span>
                      <div className="text-right">
                        <Badge>
                          {supervisor?.role?.name?.toLowerCase() || "N/A"}
                        </Badge>
                        {!utilsHelper.isReadOnly(authContext) ? (
                          <Button
                            size="sm"
                            className="rounded ml-2"
                            color="danger"
                            onClick={() => setSupervisor(null)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </ListGroupItem>
                </>
              ) : (
                <ListGroupItem
                  className="py-2 d-flex justify-content-between"
                  tag="div"
                >
                  <small className="text-muted">No Crew Lead added yet</small>
                </ListGroupItem>
              )}
            </ListGroup>
            <ListGroup className="mt-4">
              <ListGroupItem
                className="text-underline py-2 border-bottom font-weight-bold text-body bg-lighter"
                tag="div"
              >
                Members
              </ListGroupItem>
              {members.length > 0 ? (
                members.map((member) => (
                  <ListGroupItem key={member.id}>
                    <div className="d-flex justify-content-between">
                      <span>{member.name}</span>
                      <div className="text-right">
                        <Badge color="info">{member.role.name}</Badge>
                        {!utilsHelper.isReadOnly(authContext) ? (
                          <Button
                            size="sm"
                            className="rounded ml-2"
                            color="danger"
                            onClick={() => onRemoveEmployee(member)}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        ) : null}
                      </div>
                    </div>
                  </ListGroupItem>
                ))
              ) : (
                <ListGroupItem
                  className="py-2 d-flex justify-content-between"
                  tag="div"
                >
                  <small className="text-muted">No members added yet</small>
                </ListGroupItem>
              )}
            </ListGroup>
          </Col>
          <Col sm="7">
            <ListGroup>
              <ListGroupItem
                className="d-flex justify-content-between align-items-center py-2 border-bottom bg-lighter"
                tag="div"
              >
                <span className="text-underline font-weight-bold text-body">
                  Add Members
                </span>
                <div>
                  <Button
                    size="sm"
                    className="rounded"
                    color={"warning"}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="sm"
                    className="rounded ml-2"
                    color={"primary"}
                    type="submit"
                    onClick={onSaveCrew}
                  >
                    Save
                  </Button>
                </div>
              </ListGroupItem>
              <ListGroupItem
                className="py-2 d-flex justify-content-between flex-column px-0"
                tag="div"
              >
                <div className="mb-2 d-flex align-items-center justify-content-between">
                  <InputGroup className="mr-3 col-4 px-0" size="m">
                    <Input
                      className="border-0"
                      maxLength="50"
                      value={employeesContext.search}
                      onChange={(evt) =>
                        setEmployeesContext({
                          action: ACTIONS.SEARCH,
                          payload: { search: evt.target.value },
                        })
                      }
                      placeholder="Search for..."
                    />
                  </InputGroup>
                  <span className="cursor-pointer mr-1 d-flex align-items-center text-muted">
                    <small className="mr-4">
                      Showing {employeesContext.employees?.count || 0} employees
                    </small>
                    <Button
                      size="sm"
                      className="mr-3 rounded-circle d-flex custom-rounded-button"
                      color="primary"
                      onClick={() =>
                        setEmployeesContext({
                          action: ACTIONS.REFRESH,
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faSync} />
                    </Button>
                  </span>
                </div>
                <div className="w-100 small">
                  <AdvanceTableWrapper
                    columns={columns(members, supervisor, onAddEmployee)}
                    data={employeesContext.employees?.data || []}
                    pageSize={employeesContext.sizePerPage}
                    sortable
                    onSort={onSort}
                    defaultSort={{
                      sortBy: employeesContext.sortBy,
                      direction: employeesContext.direction,
                    }}
                  >
                    <AdvanceTable
                      table
                      isLoading={loading}
                      headerClassName="text-muted small"
                      tableProps={{
                        striped: true,
                        className: "mb-0",
                      }}
                    />
                    <AdvanceTablePagination
                      className="px-2 pt-2"
                      totalCount={employeesContext.employees?.count || 0}
                      pageCount={employeesContext.employees.totalPages}
                      currentPage={employeesContext.page - 1}
                      onPageChange={(page) =>
                        setEmployeesContext({
                          action: ACTIONS.PAGE_CHANGE,
                          payload: { page },
                        })
                      }
                      pageSize={employeesContext.sizePerPage}
                      onPageSizeChange={(sizePerPage) =>
                        setEmployeesContext({
                          action: ACTIONS.PAGE_SIZE_CHANGE,
                          payload: { sizePerPage },
                        })
                      }
                    />
                  </AdvanceTableWrapper>
                </div>
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Col>
          <Row className="justify-content-between">
            <Button color={"warning"} onClick={onClose}>
              Cancel
            </Button>
            <Button color={"primary"} type="submit" onClick={onSaveCrew}>
              Save
            </Button>
          </Row>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

export default BaseCrewModal;
