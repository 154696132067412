import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useState } from "react";
import {
  Button,
  Col,
  CustomInput,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";
import { workOrdersApi } from "../../services/workOrdersServices";
import Loader from "../Loader";
import { utilsHelper } from "../../helpers/utilsHelper";
import WeekSelector from "./WeekSelector";

const ALL_JOB_DATA = "ALL_JOB_DATA";
const WEEKLY_DATA = "WEEKLY_DATA";

const JobTimeReportModal = ({ onClose }) => {
  const [workOrderDetails] = useWorkOrderDetails();

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));

  const [dataMode, setDataMode] = useState(ALL_JOB_DATA);
  const [crew, setCrew] = useState();
  const [loading, setLoading] = useState();

  const handleGenerateReport = async () => {
    setLoading(true);
    await workOrdersApi.fetchJobTimeReport({
      format: "csv",
      id: workOrderDetails.workOrder.id,
      weekStart: dataMode === WEEKLY_DATA ? monday.format("YYYY-MM-DD") : null,
      crewId: crew,
    });
    setLoading(false);
    onClose();
  };

  return (
    <Modal isOpen={true}>
      <ModalHeader>Job Time Report</ModalHeader>
      <ModalBody>
        <FormGroup row>
          <Label sm={3} className="text-sm-left">
            Data included
          </Label>
          <Col sm={9}>
            <CustomInput
              required
              id="dataSelect"
              type="select"
              name="dataSelect"
              onChange={(event) => setDataMode(event.currentTarget.value)}
              value={dataMode}
            >
              <option value={ALL_JOB_DATA}>All Job Data</option>
              <option value={WEEKLY_DATA}>Weekly</option>
            </CustomInput>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3} className="text-sm-left">
            Crew
          </Label>
          <Col sm={9}>
            <CustomInput
              required
              id="crewSelect"
              type="select"
              name="crewSelect"
              onChange={(event) => setCrew(event.currentTarget.value)}
              value={crew}
            >
              <option value={""}>All Crews</option>
              {workOrderDetails.workOrder.crewWorkOrders
                .filter((crewWorkOrder) =>
                  crewWorkOrder.crew.employeeCrews
                    .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec))
                    .find((ec) => ec.isCrewLead)
                )
                .map((crewWorkOrder) => {
                  const sup = crewWorkOrder.crew.employeeCrews
                    .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec))
                    .find((ec) => ec.isCrewLead);
                  return (
                    <option
                      key={crewWorkOrder.id}
                      value={crewWorkOrder.crew.id}
                    >
                      {`${sup.employee.firstName} ${sup.employee.lastName}`}
                    </option>
                  );
                })}
            </CustomInput>
          </Col>
        </FormGroup>
        {dataMode === WEEKLY_DATA ? (
          <WeekSelector
            loading={loading}
            monday={monday}
            setMonday={setMonday}
          />
        ) : null}
      </ModalBody>
      <ModalFooter className="justify-content-between mt-n2">
        <Button color={"secondary"} onClick={onClose}>
          Cancel
        </Button>
        {loading ? (
          <div className="min-width-50">
            <Loader size="sm" />
          </div>
        ) : (
          <Button color={"primary"} onClick={handleGenerateReport}>
            <FontAwesomeIcon icon={faFileCsv} className="mr-2" />
            Generate
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default JobTimeReportModal;
