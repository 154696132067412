import axios from "axios";
import config from "../config/config";
import moment from "moment";
import { utilsHelper } from "../helpers/utilsHelper";
import { apiHelper } from "../helpers/apiHelper";

export const cashAdvanceApi = {
  getCashAdvances: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/perdiem?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createCashAdvance: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/perdiem`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  deleteCashAdvance: (id) =>
    axios(`/api/perdiem/${id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  updateCashAdvance: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/perdiem/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
