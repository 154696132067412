import React, { useState, useEffect, useCallback } from "react";
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import moment from "moment";
import { usageReportApi } from "../../../services/usageReportService";
import Loader from "../../../components/Loader";
import WeekSelector from "../../../components/admin/WeekSelector";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomPeriodSelector from "../../../components/admin/CustomPeriodSelector";
import PeriodTypeSelector from "../../../components/admin/PeriodTypeSelector";
import { useNavigate } from "react-router-dom";

const TYPE_WEEKLY = "TYPE_WEEKLY";

const UsageReport = () => {
  const navigate = useNavigate();

  const [monday, setMonday] = useState(moment().startOf("isoWeek"));

  const [periodType, setPeriodType] = useState({
    value: TYPE_WEEKLY,
    label: "Weekly",
  });

  const [customStartDate, setCustomStartDate] = useState(
    moment().startOf("isoWeek").format("YYYY-MM-DD")
  );

  const [customEndDate, setCustomEndDate] = useState(
    moment().endOf("isoWeek").format("YYYY-MM-DD")
  );

  const [loading, setLoading] = useState();
  const [refresh, setRefresh] = useState();

  const [usageData, setUsageData] = useState([]);

  const startDate =
    periodType.value === TYPE_WEEKLY ? moment(monday) : moment(customStartDate);

  const endDate =
    periodType.value === TYPE_WEEKLY
      ? moment(monday).add(1, "week")
      : moment(customEndDate).add(1, "day");

  const getUsageData = useCallback(
    async (usage) => {
      const rows = [];
      for (let i = 0; i < usage.length; i++) {
        const supervisor = `${usage[i].firstName}${
          usage[i].lastName ? ` ${usage[i].lastName}` : ""
        }`;
        const assignedWorkOrders = usage[i].assignedWorkOrders;
        const startDate =
          periodType.value === TYPE_WEEKLY
            ? moment(monday)
            : moment(customStartDate);
        const endDate =
          periodType.value === TYPE_WEEKLY
            ? moment(monday).add(1, "week")
            : moment(customEndDate).add(1, "day");
        Object.entries(assignedWorkOrders).forEach(
          ([woNumber, { id, customerName, woStartDate, woEndDate, dates }]) => {
            const usageByDay = [];
            let currentDay = moment(startDate);
            while (currentDay.isBefore(endDate)) {
              let dayUsage;
              const hasWorked = dates.find(
                (date) => moment(date).isSame(moment(currentDay)),
                "date"
              );
              if (hasWorked) {
                dayUsage = "bg-success";
              } else if (
                !(
                  moment(currentDay).isSameOrAfter(moment(woStartDate)) &&
                  moment(currentDay).isSameOrBefore(moment(woEndDate))
                )
              ) {
                dayUsage = "";
              } else if (moment(currentDay).isSameOrBefore(moment(), "date")) {
                //ignore future days
                dayUsage = "bg-danger";
              }
              usageByDay.push(dayUsage);
              currentDay.add(1, "day");
            }
            rows.push({
              id,
              supervisor,
              woNumber,
              customerName,
              usageByDay,
            });
          }
        );
      }
      return rows;
    },
    [monday, customStartDate, customEndDate, periodType.value]
  );

  useEffect(() => {
    setLoading(true);
    const condition = {
      onlyActiveSupervisors: true,
      withAssignedWorkOrders: true,
    };
    if (periodType.value === TYPE_WEEKLY) {
      condition.weekStart = moment(monday).format("YYYY-MM-DD");
    } else {
      condition.startDate = moment(customStartDate).format("YYYY-MM-DD");
      condition.endDate = moment(customEndDate).format("YYYY-MM-DD");
    }
    usageReportApi
      .getUsageReport(condition)
      .then(async (usage) => {
        const rows = await getUsageData(usage);
        setLoading(false);
        setUsageData(rows);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [
    monday,
    getUsageData,
    refresh,
    periodType,
    customEndDate,
    customStartDate,
  ]);

  const days = endDate.diff(startDate, "days");

  const onSeeWoDetails = (id) => navigate(`/work-orders/details/${id}`);

  return (
    <Container fluid className="d-flex flex-column flex-grow-1">
      <Row className="flex-grow-1">
        <Col className="d-flex flex-column">
          <Card className="flex-grow-1">
            <CardBody className="d-flex flex-column flex-grow-1">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h4>Usage Report</h4>
                <div className="d-flex justify-content-center align-items-center">
                  {loading ? (
                    <div className="min-width-50">
                      <Loader size="sm" className="mr-3" />
                    </div>
                  ) : (
                    <Button
                      size="sm"
                      className="rounded-circle d-flex custom-rounded-button"
                      color="primary"
                      onClick={() => setRefresh(!refresh)}
                    >
                      <FontAwesomeIcon icon={faSync} />
                    </Button>
                  )}
                  <PeriodTypeSelector
                    periodType={periodType}
                    setPeriodType={setPeriodType}
                  />
                  {periodType.value === TYPE_WEEKLY ? (
                    <WeekSelector
                      loading={loading}
                      monday={monday}
                      setMonday={setMonday}
                    />
                  ) : (
                    <CustomPeriodSelector
                      defaultEndDate={customEndDate}
                      defaultStartDate={customStartDate}
                      onSubmit={(startDate, endDate) => {
                        setCustomStartDate(startDate);
                        setCustomEndDate(endDate);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="rounded border height-100 overflow-x-auto">
                {loading ? (
                  <Loader />
                ) : (
                  <Table className="col-12 px-0 mb-0 small">
                    <thead>
                      <tr className="bg-lighter  text-muted">
                        <th style={{ minWidth: 150 }} className="align-middle">
                          Supervisor
                        </th>
                        <th style={{ minWidth: 110 }} className="align-middle">
                          WO#
                        </th>
                        <th
                          className="align-middle text-truncate"
                          style={{ maxWidth: 150 }}
                        >
                          Customer
                        </th>
                        {Array.from(Array(days).keys()).map((days, i) => {
                          const startDate =
                            periodType.value === TYPE_WEEKLY
                              ? moment(monday)
                              : moment(customStartDate);

                          const day = moment(startDate).add(days, "days");
                          const format =
                            periodType.value === TYPE_WEEKLY
                              ? "MM/DD"
                              : "YY/MM/DD";

                          return (
                            <th
                              style={{
                                minWidth:
                                  periodType.value === TYPE_WEEKLY ? 70 : 90,
                                maxWidth:
                                  periodType.value === TYPE_WEEKLY ? 70 : 90,
                              }}
                              key={i}
                              className="text-center align-middle"
                            >
                              {moment(day).format("ddd")}
                              <br></br>
                              {moment(day).format(format)}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {usageData.length ? (
                        usageData.map((usage, index) => {
                          return (
                            <tr key={index}>
                              <td style={{ minWidth: 150 }}>
                                {usage.supervisor}
                              </td>
                              <td style={{ minWidth: 110 }}>
                                <span
                                  className="text-link"
                                  onClick={() => onSeeWoDetails(usage.id)}
                                >
                                  {usage.woNumber}
                                </span>
                              </td>
                              <td
                                className="text-truncate"
                                style={{ maxWidth: 150 }}
                              >
                                {usage.customerName}
                              </td>
                              {usage.usageByDay.map((day, indexDay) => {
                                return (
                                  <td
                                    style={{
                                      minWidth:
                                        periodType.value === TYPE_WEEKLY
                                          ? 70
                                          : 90,
                                      maxWidth:
                                        periodType.value === TYPE_WEEKLY
                                          ? 70
                                          : 90,
                                    }}
                                    key={indexDay}
                                    className={`usage-report-cells ${day}`}
                                  ></td>
                                );
                              })}
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={17} className="text-center text-muted">
                            No supervisors to show
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UsageReport;
