import axios from "axios";
import config from "../config/config";
import { utilsHelper } from "../helpers/utilsHelper";
import { apiHelper } from "../helpers/apiHelper";

export const communicationLogApi = {
  createCommunicationLog: (data) =>
    axios(`/api/communicationLog`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data: data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err)),
  getLogs: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/communicationLog?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
