import React, { useEffect, useState } from "react";

import { utilsHelper } from "../../../helpers/utilsHelper";

import "../../../components/gantt/GanttToolbar";

import Moment from "moment";

import { extendMoment } from "moment-range";

import { ListGroup, ListGroupItem } from "reactstrap";
import InformationModal from "../../../components/InformationModal";

const moment = extendMoment(Moment);

const WEEKS_DIFF = 7;

const TYPE_ASSIGNATIONS = "TYPE_ASSIGNATIONS";

const calculateCrewWorkOrderMembers = (crewWorkOrders, date) =>
  crewWorkOrders.reduce(
    (p, c) => {
      const crewSupervisors = c.crew.employeeCrews
        .filter((ec) => utilsHelper.isEmployeeCrewActiveOnDate(ec))
        .filter((ec) => {
          const employeeWorksInDate = c.employeeWorkDays.find(
            (ewd) =>
              ewd.employeeId === ec.employee.id &&
              date.isBetween(moment(ewd.startDate), moment(ewd.endDate))
          );
          return ec.isCrewLead && employeeWorksInDate;
        }).length;
      const crewEmployees = c.crew.employeeCrews.filter(
        (ec) => !ec.disabledAt && !ec.isCrewLead
      ).length;
      p.supervisors += crewSupervisors;
      p.employees += crewEmployees;
      return p;
    },
    { supervisors: 0, employees: 0 }
  );

const calculateWorkOrderCrewMembers = (workOrderCrews) =>
  workOrderCrews.reduce(
    (p, c) => {
      const crewSupervisors = c.role.isSuper ? c.count : 0;
      const crewEmployees = c.role.isSuper ? 0 : c.count;
      p.supervisors += crewSupervisors;
      p.employees += crewEmployees;
      return p;
    },
    { supervisors: 0, employees: 0 }
  );

const calculateAssignationsByDate = (type, date, scheduleWorkOrdersInfo) => {
  const workOrdersForDate = scheduleWorkOrdersInfo.filter((wo) => {
    return (
      date.isBetween(moment(wo.startDate), moment(wo.endDate), "date", "[]") &&
      wo.crewWorkOrders?.length
    );
  });
  return workOrdersForDate.reduce(
    (p, c) => {
      const { supervisors, employees } =
        type === TYPE_ASSIGNATIONS
          ? calculateCrewWorkOrderMembers(c.crewWorkOrders, date)
          : calculateWorkOrderCrewMembers(c.workOrderCrews);
      const workOrderIdentifier =
        c.workOrderNumber ||
        `Group WO${c.customerName ? `- ${c.customerName}` : ""}`;
      p.supervisors[workOrderIdentifier] =
        (p.supervisors[workOrderIdentifier] || 0) + supervisors;
      p.employees[workOrderIdentifier] =
        (p.employees[workOrderIdentifier] || 0) + employees;
      return p;
    },
    { supervisors: {}, employees: {} }
  );
};

const getWeeksDates = (startDate) => {
  const initialWeek = moment(startDate).startOf("week");
  const weeks = [initialWeek];
  while (weeks.length <= WEEKS_DIFF) {
    weeks.push(moment(initialWeek).add(weeks.length, "week"));
  }
  return weeks;
};

const AssignationsByDate = ({
  type,
  startDate,
  workOrders = [],
  crewInfo = [],
}) => {
  const [assignationsByDate, setAsignationsByDate] = useState({});

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const onOpenDetails = (role, data) =>
    setInformationModal({
      isOpen: true,
      title: `${role} Assignation Details`,
      body: (
        <ListGroup>
          <ListGroupItem
            className="d-flex justify-content-between align-items-center py-2 bg-lighter"
            tag="div"
          >
            <span>Work Order</span>
            <span>
              {type === TYPE_ASSIGNATIONS ? "Assignations" : "Expected"}
            </span>
          </ListGroupItem>
          {Object.entries(data).map(([key, value]) => (
            <ListGroupItem
              key={key}
              className="d-flex justify-content-between align-items-center py-2"
              tag="div"
            >
              <span>{key}</span>
              <span>{value}</span>
            </ListGroupItem>
          ))}
        </ListGroup>
      ),
    });

  useEffect(() => {
    const resultWithDates = crewInfo
      .map((wo) => {
        const scheduleWo = workOrders.find(
          (scheduleWo) => scheduleWo.id === wo.id
        );
        if (!scheduleWo) {
          return null;
        }
        const startDate =
          scheduleWo.startDate ||
          utilsHelper.calculateScheduleStartDate(
            scheduleWo.dayShiftStartDate,
            scheduleWo.nightShiftStartDate
          );
        const endDate =
          scheduleWo.endDate ||
          utilsHelper.calculateScheduleEndDate(
            scheduleWo.dayShiftEndDate,
            scheduleWo.nightShiftEndDate
          );
        return {
          ...wo,
          startDate,
          endDate,
          customerName: scheduleWo.customerName,
        };
      })
      .filter(Boolean);
    const currentDate = moment(startDate);
    const endDate = moment(startDate).add(WEEKS_DIFF, "weeks");
    const assignationsByDate = {};
    while (moment(currentDate).isSameOrBefore(endDate)) {
      assignationsByDate[utilsHelper.formatDate(currentDate)] =
        calculateAssignationsByDate(type, currentDate, resultWithDates);
      currentDate.add(1, "days");
    }
    setAsignationsByDate(assignationsByDate);
  }, [type, workOrders, startDate, crewInfo]);

  return (
    <div className="d-flex col-12 mt-3 px-0 overflow-x-auto border-top border-bottom">
      <div className="d-flex align-items-center justify-content-center px-3 bg-lightgreen text-dark text-center border-right">
        Daily
        <br />
        Totals
      </div>
      {getWeeksDates(startDate).map((week, j) => {
        return (
          <div
            key={j}
            className={`flex-grow-1 d-flex flex-column ${
              j !== 0 ? "border-left" : ""
            }`}
          >
            <div className="text-center text-dark bg-lightgreen border-bottom py-2">
              {week.format("ddd DD MMM YYYY")}
            </div>
            <div className="d-flex flex-grow-1 justify-content-between align-items-center">
              {[0, 1, 2, 3, 4, 5, 6].map((weekDay, i) => {
                const date = week.set("weekday", weekDay);
                const scheduleByDate =
                  assignationsByDate[utilsHelper.formatDate(date)];
                if (!scheduleByDate) {
                  return (
                    <div
                      key={i}
                      className={`d-flex flex-column align-items-stretch justify-content-center flex-grow-1 ${
                        i !== 0 ? "border-left" : ""
                      }`}
                    >
                      <span className="px-1 text-center font-weight-bold bg-light">
                        {moment().set("weekday", weekDay).format("dd")[0]}
                      </span>
                      <span className="text-muted bg-white px-1 text-center border-top border-bottom">
                        0
                      </span>
                      <span className="text-muted text-secondary bg-white px-1 text-center">
                        0
                      </span>
                    </div>
                  );
                }
                const supervisors = Object.keys(
                  scheduleByDate.supervisors
                ).reduce((p, c) => p + scheduleByDate.supervisors[c], 0);
                const employees = Object.keys(scheduleByDate.employees).reduce(
                  (p, c) => p + scheduleByDate.employees[c],
                  0
                );
                return (
                  <div
                    key={i}
                    className={`d-flex flex-column align-items-stretch justify-content-center flex-grow-1 ${
                      i !== 0 ? "border-left" : ""
                    }`}
                  >
                    <span className="px-1 text-center font-weight-bold bg-light">
                      {moment().set("weekday", weekDay).format("dd")[0]}
                    </span>
                    <div
                      onClick={() =>
                        supervisors
                          ? onOpenDetails(
                              "Supervisors",
                              scheduleByDate.supervisors
                            )
                          : null
                      }
                      className={`${
                        !supervisors
                          ? "text-muted"
                          : "cursor-pointer font-weight-bold text-success"
                      } bg-white px-1 text-center border-top border-bottom`}
                    >
                      {supervisors}
                    </div>
                    <div
                      onClick={() =>
                        employees
                          ? onOpenDetails("Employees", scheduleByDate.employees)
                          : null
                      }
                      className={`${
                        !employees
                          ? "text-muted"
                          : "cursor-pointer font-weight-bold text-secondary"
                      } bg-white px-1 text-center`}
                    >
                      {employees}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      {informationModal.isOpen ? (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={true}
          onClose={() =>
            setInformationModal({ isOpen: false, title: "", body: "" })
          }
        />
      ) : null}
    </div>
  );
};

export default AssignationsByDate;
