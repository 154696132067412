import axios from "axios";
import config from "../config/config";
import { apiHelper } from "../helpers/apiHelper";
import { utilsHelper } from "../helpers/utilsHelper";

export const incidentReportApi = {
  incidentSummaryReport: (data) => {
    const query = utilsHelper.buildQueryString(data);
    return axios(`/api/injury-reports/reports/pdf/${data.id}?${query}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      responseType: "blob",
      method: "get",
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
