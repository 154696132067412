import axios from "axios";
import config from "../config/config";
import moment from "moment";
import { apiHelper } from "../helpers/apiHelper";

export const appExplanationApi = {
  getAppExplanationTexts: (data) => {
    return axios(
      data.id ? `/api/app-explanations/${data.id}` : `/api/app-explanations`,
      {
        baseURL: config.apiURL,
        withCredentials: true,
        headers: { "web-version": process.env.REACT_APP_VERSION },
        method: "get",
      }
    )
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  createAppExplanationText: (data) => {
    data.createdAt = moment().toISOString();
    return axios(`/api/app-explanations`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "post",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  updateAppExplanationText: (data) => {
    data.updatedAt = moment().toISOString();
    return axios(`/api/app-explanations/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "put",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
  deleteAppExplanationTexts: (data) => {
    return axios(`/api/app-explanations/${data.id}`, {
      baseURL: config.apiURL,
      withCredentials: true,
      headers: { "web-version": process.env.REACT_APP_VERSION },
      method: "delete",
      data,
    })
      .then((result) => result.data)
      .catch((err) => apiHelper.handleError(err));
  },
};
