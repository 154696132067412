import moment from "moment";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend } from "chart.js";

ChartJS.register(Tooltip, Legend);

const DaysGraphic = ({ days }) => {
  const [data, setData] = useState();

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  useEffect(() => {
    const rawLabels = Object.keys(days).sort((x, y) =>
      moment(x).isBefore(moment(y)) ? -1 : 1
    );
    const labels = rawLabels.map((day) => moment(day).format("MM/DD/YYYY"));

    const avg =
      Object.keys(days)
        .map((key) => days[key].hours)
        .reduce((accumulator, current) => accumulator + current, 0) /
      Object.keys(days).length;

    const _data = {
      labels,
      datasets: [
        {
          label: "Hours",
          data: [
            ...rawLabels.map((label) =>
              parseFloat(days[label].hours || 0).toFixed(2)
            ),
            0,
          ],
          backgroundColor: "#27b3dc",
        },
        {
          label: "Average",
          data: [...rawLabels.map(() => parseFloat(avg).toFixed(2)), 0],
          backgroundColor: "#f95442",
        },
      ],
    };

    setData(_data);
  }, [days]);

  if (!data) {
    return null;
  }

  return <Bar data={data} options={options} />;
};

export default DaysGraphic;
