import React, { useReducer, createContext, useContext } from "react";

const MAX_PAGE_SIZE = 100;

const initialState = {
  expenses: {},
  sizePerPage: 25,
  page: 1,
  search: "",
  sortBy: "amount",
  direction: "desc",
  type: null,
};

const ACTIONS = {
  SORT: "SORT",
  SEARCH: "SEARCH",
  REFRESH: "REFRESH",
  PAGE_CHANGE: "PAGE_CHANGE",
  PAGE_SIZE_CHANGE: "PAGE_SIZE_CHANGE",
  GET_EXPENSES_SUCCESS: "GET_EXPENSES_SUCCESS",
  TYPE_CHANGE: "TYPE_CHANGE",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.TYPE_CHANGE: {
      const { payload } = data;
      const { type } = payload;

      return {
        ...initialState,
        type,
      };
    }

    case ACTIONS.GET_EXPENSES_SUCCESS: {
      const { payload } = data;
      const { expenses } = payload;
      return {
        ...state,
        expenses,
      };
    }

    case ACTIONS.SORT: {
      const { payload } = data;
      const { sortBy, direction } = payload;

      return {
        ...state,
        sortBy,
        direction,
        page: 1,
      };
    }

    case ACTIONS.SEARCH: {
      const { payload } = data;
      const { search } = payload;

      return {
        ...state,
        search,
        page: 1,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case ACTIONS.PAGE_CHANGE: {
      const { payload } = data;
      const { page } = payload;
      return {
        ...state,
        page,
      };
    }

    case ACTIONS.PAGE_SIZE_CHANGE: {
      const { payload } = data;
      const { sizePerPage } = payload;
      return {
        ...state,
        sizePerPage,
        page: 1,
      };
    }

    default:
      throw new Error();
  }
};

const ExpensesContext = createContext(initialState);

const ExpensesProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <ExpensesContext.Provider value={stateAndDispatch}>
      {children}
    </ExpensesContext.Provider>
  );
};

export const useExpenses = () => useContext(ExpensesContext);

export { ExpensesContext, ExpensesProvider, ACTIONS, MAX_PAGE_SIZE };
