import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { useWorkOrderDetails } from "../../../../providers/workOrderDetailsProvider";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import TooltipItem from "../../../TooltipItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CodeEntryTimeModal from "./CodeEntryTimeModal";
import { workTimesApi } from "../../../../services/workTimeServices";
import { useAuth } from "../../../../providers/authProvider";

const PAGE_SIZE = 999;

const CodeTimeModal = ({ onClose }) => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();
  const [codeEntryTimeModal, setCodeEntryTimeModal] = useState();
  const [data, setData] = useState({});
  const [rawData, setRawData] = useState([]);

  const [employeesById, setEmployeesById] = useState({});
  const [crewWorkDaysById, setCrewWorkDaysById] = useState({});

  const [refresh, setRefresh] = useState();

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  useEffect(() => {
    const fetchData = async () => {
      const result = await workTimesApi.getWorkTimes({
        workOrderId: workOrderDetails.workOrder.id,
        pageSize: PAGE_SIZE,
        jobSourceId: authContext.currentUser.jobSourceId,
      });
      const data = {};
      const employeesById = {};
      const crewWorkDaysById = {};
      result.data.forEach((workTime) => {
        if (!data[workTime.crewWorkDay.date]) {
          data[workTime.crewWorkDay.date] = {};
        }
        if (!data[workTime.crewWorkDay.date][workTime.employeeId]) {
          data[workTime.crewWorkDay.date][workTime.employeeId] = {};
        }
        if (
          !data[workTime.crewWorkDay.date][workTime.employeeId][workTime.type]
        ) {
          data[workTime.crewWorkDay.date][workTime.employeeId][workTime.type] =
            [];
        }
        data[workTime.crewWorkDay.date][workTime.employeeId][
          workTime.type
        ].push(workTime);
        employeesById[workTime.employeeId] = workTime.employee;
        crewWorkDaysById[workTime.crewWorkDayId] = workTime.crewWorkDay;
      });
      setCrewWorkDaysById(crewWorkDaysById);
      setEmployeesById(employeesById);
      setRawData(result.data);
      setData(data);
    };
    fetchData();
  }, [
    workOrderDetails.workOrder.id,
    refresh,
    authContext.currentUser.jobSourceId,
  ]);

  return codeEntryTimeModal ? (
    <CodeEntryTimeModal
      data={codeEntryTimeModal}
      onSubmit={() => {
        setCodeEntryTimeModal(false);
        setRefresh(!refresh);
      }}
      onClose={() => setCodeEntryTimeModal(false)}
    />
  ) : (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <ModalHeader close={closeBtn}>Code Time</ModalHeader>
      <ModalBody className="text-center">
        <ListGroup>
          <ListGroupItem
            className="d-flex py-0 border-bottom font-weight-bold bg-lighter"
            tag="div"
          >
            <div className="col-2 py-2">Date</div>
            <div className="col-2 py-2 border-left">Employee</div>
            <div className="col-2 py-2 border-left">Type</div>
            <div className="col-2 py-2 border-left">Coded Hours</div>
            <div className="col-2 py-2 border-left">Uncoded Hours</div>
            <div className="col-2 py-2 border-left">Actions</div>
          </ListGroupItem>
          {rawData.length ? (
            Object.keys(data).map((date) =>
              Object.keys(data[date]).map((employeeId) =>
                Object.keys(data[date][employeeId]).map((type) => {
                  const workTimes = data[date][employeeId][type];
                  const codedHours = workTimes.reduce(
                    (p, c) =>
                      p +
                      (c.workOrderId !== workOrderDetails.workOrder.id
                        ? parseFloat(c.hours)
                        : 0),
                    0
                  );
                  const uncodedHours = workTimes.reduce(
                    (p, c) =>
                      p +
                      (c.workOrderId === workOrderDetails.workOrder.id
                        ? parseFloat(c.hours)
                        : 0),
                    0
                  );
                  const employee = employeesById[employeeId];
                  const crewWorkDay =
                    crewWorkDaysById[workTimes[0].crewWorkDayId];
                  return (
                    <ListGroupItem
                      className="d-flex py-0 border-bottom"
                      tag="div"
                      key={`${date}-${employeeId}-${type}`}
                    >
                      <div className="col-2 py-2">{date}</div>
                      <div className="col-2 py-2 border-left">{`${employee.firstName} ${employee.lastName}`}</div>
                      <div className="col-2 py-2 border-left text-capitalize">
                        {type}
                      </div>
                      <div className="col-2 py-2 border-left">
                        {codedHours.toFixed(2)}
                      </div>
                      <div className="col-2 py-2 border-left">
                        {uncodedHours.toFixed(2)}
                      </div>
                      <div className="col-2 py-2 border-left">
                        {crewWorkDay.completed ? (
                          <Button
                            size="sm"
                            className="rounded"
                            onClick={() =>
                              setCodeEntryTimeModal({
                                crewWorkDayId: crewWorkDay.id,
                                date,
                                employee,
                                type,
                                workTimes,
                              })
                            }
                          >
                            Code Time
                          </Button>
                        ) : (
                          <TooltipItem
                            id={`${date}-${employeeId}-${type}-tooltip`}
                            title="You must complete the work day from the APP to code this time"
                          >
                            <FontAwesomeIcon
                              size="lg"
                              icon={faExclamationCircle}
                              className={`ml-2 text-warning`}
                              style={{ paddingBottom: "1px" }}
                            />
                          </TooltipItem>
                        )}
                      </div>
                    </ListGroupItem>
                  );
                })
              )
            )
          ) : (
            <ListGroupItem className="d-flex py-2 border-bottom" tag="div">
              No time to code
            </ListGroupItem>
          )}
        </ListGroup>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color={"secondary"} onClick={onClose}>
          Cancel
        </Button>{" "}
        <Button color={"primary"} type={"submit"}>
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CodeTimeModal;
