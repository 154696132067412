import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  CustomInput,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";

import InformationModal from "../../components/InformationModal";
import MillReport from "./reports/millReport/MillReport";
import FlexMillReport from "./reports/millReport/FlexMillReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import MillReportSummary from "./reports/millReport/MillReportSummary";
import { useWorkOrderDetails } from "../../providers/workOrderDetailsProvider";

const MILL_TAB = "MILL_TAB";
const MILL_SUMMARY_TAB = "MILL_SUMMARY_TAB";
const FLEX_TAB = "FLEX_TAB";
const WO_TYPE_GROUP = "group";

const WorkOrderTimesheets = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [informationModal, setInformationModal] = useState({
    rawBody: false,
    isOpen: false,
    title: "",
    body: "",
  });

  const [selectedWorkOrder, setSelectedWorkOrder] = useState();
  const [activeTab, setActiveTab] = useState(MILL_TAB);
  const [settingsOpen, setSettingsOpen] = useState(false);

  useEffect(() => {
    if (workOrderDetails.workOrder.type === WO_TYPE_GROUP) {
      setSelectedWorkOrder();
    } else {
      setSelectedWorkOrder(workOrderDetails.workOrder.id);
    }
  }, [workOrderDetails.workOrder]);

  return (
    <Row>
      <Col sm="12" className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center border-bottom pb-2 mb-3">
          <h4 className="mb-0">Reports</h4>
          <div className="d-flex align-items-center">
            {workOrderDetails.workOrder.type === WO_TYPE_GROUP ? (
              <CustomInput
                className="mr-2"
                id="woSelect"
                type="select"
                name="woSelect"
                onChange={(event) =>
                  setSelectedWorkOrder(event.currentTarget.value)
                }
                value={selectedWorkOrder}
              >
                <option value={""}>Select a work order</option>
                {workOrderDetails.workOrder.groupedWorkOrders?.map(
                  (groupedWorkOrder) => (
                    <option
                      key={groupedWorkOrder.id}
                      value={groupedWorkOrder.id}
                    >
                      {groupedWorkOrder.workOrderNumber}
                    </option>
                  )
                )}
              </CustomInput>
            ) : null}
            {activeTab === FLEX_TAB ? (
              <Button
                className="rounded flex-shrink-0"
                size="sm"
                color={"primary"}
                onClick={() => setSettingsOpen(true)}
              >
                <FontAwesomeIcon icon={faCog} className="mr-2" />
                Report Settings
              </Button>
            ) : null}
          </div>
        </div>
        <Nav tabs className="timesheets-nav cursor-pointer">
          <div className="d-flex border">
            <NavItem>
              <NavLink
                className={
                  activeTab === MILL_TAB
                    ? "active border-left border-right bg-lightgreen border-bottom"
                    : ""
                }
                onClick={() => {
                  if (activeTab !== MILL_TAB) setActiveTab(MILL_TAB);
                }}
              >
                Mill Report
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={
                  activeTab === MILL_SUMMARY_TAB
                    ? "active border-left border-right bg-lightgreen border-bottom"
                    : ""
                }
                onClick={() => {
                  if (activeTab !== MILL_SUMMARY_TAB)
                    setActiveTab(MILL_SUMMARY_TAB);
                }}
              >
                Mill Report Summary
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={
                  activeTab === FLEX_TAB
                    ? "active border-left border-right bg-lightgreen border-bottom"
                    : ""
                }
                onClick={() => {
                  if (activeTab !== FLEX_TAB) setActiveTab(FLEX_TAB);
                }}
              >
                Flex Report
              </NavLink>
            </NavItem>
          </div>
        </Nav>
        <TabContent activeTab={activeTab} className="border">
          <TabPane tabId={MILL_TAB}>
            {activeTab === MILL_TAB ? (
              selectedWorkOrder ? (
                <MillReport workOrderId={selectedWorkOrder} />
              ) : (
                <div className="text-center small">
                  Please select a work order
                </div>
              )
            ) : null}
          </TabPane>
          <TabPane tabId={MILL_SUMMARY_TAB}>
            {activeTab === MILL_SUMMARY_TAB ? (
              selectedWorkOrder ? (
                <MillReportSummary workOrderId={selectedWorkOrder} />
              ) : (
                <div className="text-center small">
                  Please select a work order
                </div>
              )
            ) : null}
          </TabPane>
          <TabPane tabId={FLEX_TAB}>
            {activeTab === FLEX_TAB ? (
              selectedWorkOrder ? (
                <FlexMillReport
                  workOrderId={selectedWorkOrder}
                  settingsOpen={settingsOpen}
                  setSettingsOpen={setSettingsOpen}
                />
              ) : (
                <div className="text-center small">
                  Please select a work order
                </div>
              )
            ) : null}
          </TabPane>
        </TabContent>
      </Col>
      {informationModal.isOpen && (
        <InformationModal
          title={informationModal.title}
          body={informationModal.body}
          rawBody={informationModal.rawBody}
          onClose={() =>
            setInformationModal({
              rawBody: false,
              isOpen: false,
              title: "",
              body: "",
            })
          }
        />
      )}
    </Row>
  );
};
export default WorkOrderTimesheets;
