import React, { useEffect, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { employeesApi } from "../../../services/employeeServices";
import { rolesApi } from "../../../services/roleServices";
import { utilsHelper } from "../../../helpers/utilsHelper";
import InformationModal from "../../InformationModal";
import Loader from "../../Loader";

const MAX_PAGE_SIZE = 999;
const SUBCONTRACTOR = "2";

const SubContractorModal = ({ subContractorId, onClose }) => {
  const [employee, setEmployee] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    roleId: "",
    type: SUBCONTRACTOR,
    isActive: true,
    subContractorCompanyId: "",
  });

  const [roles, setRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState();

  const [informationModal, setInformationModal] = useState({
    isOpen: false,
    title: "",
    body: "",
  });

  const doSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (employee.id) {
      const {
        id,
        firstName,
        lastName,
        email,
        phone,
        jobSourceId,
        isActive,
        type,
        roleId,
        subContractorCompanyId,
      } = employee;
      employeesApi
        .update({
          id,
          firstName,
          lastName,
          email,
          phone,
          jobSourceId,
          isActive,
          type,
          roleId,
          subContractorCompanyId,
        })
        .then(() => {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Update Contract Employee",
            body: "Contract employee updated successfully",
            onClose,
          });
        })
        .catch((err) => {
          setLoading(false);
          return setInformationModal({
            isOpen: true,
            title: "Update Contract Employee",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        });
    } else {
      employeesApi
        .save(employee)
        .then(() => {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Create Contract Employee",
            body: "Contract employee created successfully",
            onClose,
          });
        })
        .catch((err) => {
          setLoading(false);
          setInformationModal({
            isOpen: true,
            title: "Create Contract Employee",
            body:
              err?.response?.data[0].msg ||
              "There was an error with your request.",
          });
        });
    }
  };

  // Load Contract Employee Employee
  useEffect(() => {
    if (subContractorId) {
      employeesApi.getEmployees({ id: subContractorId }).then((result) => {
        setEmployee(result);
      });
    }
  }, [subContractorId]);

  // Load Roles
  useEffect(() => {
    rolesApi
      .getEmployeeRoles({ pageSize: MAX_PAGE_SIZE })
      .then(({ data }) => setRoles(data));
  }, []);

  useEffect(() => {
    setLoading(true);
    employeesApi
      .getSubContractorsCompanies({ pageSize: MAX_PAGE_SIZE })
      .then((data) => {
        setLoading(false);
        setCompanies(data.data);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  return informationModal.isOpen ? (
    <InformationModal
      title={informationModal.title}
      body={informationModal.body}
      onClose={() =>
        informationModal.onClose
          ? informationModal.onClose()
          : setInformationModal({
              isOpen: false,
              title: "",
              body: "",
              onClose: null,
            })
      }
    />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          close={closeBtn}
        >
          {subContractorId ? "Edit" : "Create"} Contract Employee
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm="12">
              <FormGroup>
                <Label for="firstname">
                  First Name
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  placeholder="Enter first name..."
                  type="text"
                  name="firstname"
                  value={employee.firstName}
                  onChange={(e) =>
                    setEmployee({
                      ...employee,
                      firstName: e.target.value,
                    })
                  }
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="lastName">
                  Last Name
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  placeholder="Enter last name..."
                  type="text"
                  name="lastName"
                  value={employee.lastName}
                  onChange={(e) =>
                    setEmployee({ ...employee, lastName: e.target.value })
                  }
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="email">
                  Email
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  placeholder="Enter the email"
                  type="email"
                  name="email"
                  value={employee.email}
                  onChange={(e) =>
                    setEmployee({ ...employee, email: e.target.value.trim() })
                  }
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="phone">
                  Phone
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  placeholder="Enter the phone..."
                  type="text"
                  name="phone"
                  value={employee.phone}
                  onChange={(e) =>
                    setEmployee({ ...employee, phone: e.target.value })
                  }
                  required
                />
              </FormGroup>
              {loading ? (
                <Loader size="sm" />
              ) : (
                <FormGroup>
                  <Label for="subContractorCompany">
                    Company
                    <span className="text-danger ml-1">*</span>
                  </Label>
                  <Input
                    placeholder="Enter the company..."
                    type="select"
                    name="subContractorCompany"
                    onChange={(e) =>
                      setEmployee({
                        ...employee,
                        subContractorCompanyId: e.target.value,
                      })
                    }
                    value={employee.subContractorCompanyId}
                    required
                  >
                    <option value={""}>Select a Company</option>
                    {companies.map((company) => (
                      <option key={company.id} value={company.id}>
                        {company.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              )}
              <FormGroup>
                <Label for="select">
                  Role
                  <span className="text-danger ml-1">*</span>
                </Label>
                <Input
                  type="select"
                  name="select"
                  onChange={(e) =>
                    setEmployee({ ...employee, roleId: e.target.value })
                  }
                  value={employee.roleId}
                  required
                >
                  <option value={""}>Select a Role</option>
                  {roles.map((role) => (
                    <option key={role.id} value={role.id}>
                      {utilsHelper.capitalize(role.name)}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col>
            <Row className="justify-content-between">
              <Button color={"secondary"} onClick={onClose}>
                Cancel
              </Button>
              <div className="min-width-50">
                {loading ? (
                  <Loader size="sm" align="end" />
                ) : (
                  <Button color={"primary"} type="submit">
                    Save
                  </Button>
                )}
              </div>
            </Row>
          </Col>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SubContractorModal;
